import { JSONValue } from "@faro-lotv/foundation";
import { GUID } from "@faro-lotv/ielement-types";
import {
  BaseMutation,
  MutationTypes,
  createBaseMutation,
} from "./mutation-base";

/**
 * Project API reference:
 * https://dev.azure.com/faro-connect/_git/SphereProjectAPI?path=/src/app/HoloModel/Mutations/Types/MetaData/MutationSetMetaData.cs
 */

type MetaDataEntry = {
  /** key of the metadata entry */
  key: string;

  /**
   * value of the entry.
   */
  value: JSONValue;

  /**  Whether to skip setting the value of this entry if the key is already present in the metadata of the IElement. */
  skipIfPresent: boolean;
};

/**
 * Set/update element metadata
 */
export interface MutationSetMetaData extends BaseMutation {
  type: MutationTypes.MutationSetMetaData;

  /** The metadata to set */
  entries: MetaDataEntry[];
}

/**
 * @returns a mutation to set/update an IElements metadata
 * @param elementId id of the element to set the metadata
 * @param entries the metadata to set/update
 */
export function createMutationSetElementMetaData(
  elementId: GUID,
  entries: MetaDataEntry[],
): MutationSetMetaData {
  return {
    ...createBaseMutation(MutationTypes.MutationSetMetaData, elementId),
    entries,
  };
}
