import {
  IElementGenericPointCloudStream,
  IElementSectionDataSession,
  isIElementGenericPointCloudStream,
  isValid,
} from "@faro-lotv/ielement-types";
import { State, selectChildDepthFirst } from "@faro-lotv/project-source";

const mergedPointCloudLabelType = "merged-point-cloud";

/**
 * Selector that returns the main point cloud for the given data session.
 * The main point cloud is the first point cloud stream found directly in the DataSet section.
 * This avoids selecting, for example, flash scans from orbis datasets.
 *
 * @param dataSession the IElementSection to search point clouds streams in.
 * @returns A function that takes a state and returns the main point cloud for the given dataSession.
 */
export function selectMainPointCloudFromDataSession(
  dataSession: IElementSectionDataSession,
) {
  return (state: State): IElementGenericPointCloudStream | undefined => {
    // Section.DataSession -> Section.DataSet -> PointCloudStream
    const searchDepth = 2;
    return selectChildDepthFirst(
      dataSession,
      isIElementGenericPointCloudStream,
      searchDepth,
    )(state);
  };
}

/**
 * @param dataSession The data session to check
 * @returns Whether the data session is the result of merging other datasets.
 */
export function isMergedDataSession(
  dataSession: IElementSectionDataSession,
): boolean {
  return !!dataSession.labels?.some(
    (e) => e.labelType === mergedPointCloudLabelType,
  );
}

/**
 * Used by the multi registration / inspect and publish view to get the point clouds
 * that should be shown. This excludes point clouds which the result of a merge,
 * the workflow aims to offer a visual inspection of the original datasets only.
 *
 * @param dataSessions The dataSessions to find the point clouds in.
 * @returns All "main" point cloud streams for the data sessions in the time series.
 *  Does not include data sessions that were created by merging other data sessions
 *  and only the main point clouds, so, for example, no flash scans from orbis datasets.
 */
export function selectAllMainPointCloudsFromUnmergedDataSessions(
  dataSessions: IElementSectionDataSession[],
) {
  return (state: State): IElementGenericPointCloudStream[] =>
    dataSessions
      .filter((session) => !isMergedDataSession(session))
      .map((session) => selectMainPointCloudFromDataSession(session)(state))
      .filter(isValid);
}
