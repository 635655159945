import { SplitDirection } from "@/store/modes/alignment-ui-types";
import {
  selectAlignedLayerForLayerToLayerAlignment,
  selectReferenceForLayerToLayerAlignment,
  selectSplitDirectionForLayerToLayerAlignment,
} from "@/store/modes/layer-to-layer-alignment-mode-selectors";
import { setLayerToLayerAlignmentSplitDirection } from "@/store/modes/layer-to-layer-alignment-mode-slice";
import { useAppDispatch, useAppSelector } from "@/store/store-hooks";
import { selectIElement } from "@faro-lotv/project-source";
import { useCallback } from "react";
import { AlignmentModeSplitScreenOverlay } from "../alignment-modes-commons/alignment-mode-split-screen-overlay";

/** @returns The layer to layer alignment split screen */
export function LayerToLayerAlignmentSplitScreen(): JSX.Element {
  const dispatch = useAppDispatch();
  const splitDirection = useAppSelector(
    selectSplitDirectionForLayerToLayerAlignment,
  );

  // callback function for changing split-screen direction
  const changeSplitDirection = useCallback(() => {
    const newDirection =
      splitDirection === SplitDirection.horizontalSplit
        ? SplitDirection.verticalSplit
        : SplitDirection.horizontalSplit;
    dispatch(setLayerToLayerAlignmentSplitDirection(newDirection));
  }, [dispatch, splitDirection]);

  const alignedLayerId = useAppSelector(
    selectAlignedLayerForLayerToLayerAlignment,
  );
  const alignedLayer = useAppSelector(selectIElement(alignedLayerId));

  const refLayerId = useAppSelector(selectReferenceForLayerToLayerAlignment);
  const refLayer = useAppSelector(selectIElement(refLayerId));

  return (
    <AlignmentModeSplitScreenOverlay
      splitDirection={splitDirection}
      onChangeSplitDirection={changeSplitDirection}
      alignedElement={alignedLayer}
      referenceElement={refLayer}
    />
  );
}
