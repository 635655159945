import { neutral, orange, useToast } from "@faro-lotv/flat-ui";
import { useCallback, useState } from "react";
import {
  Color,
  DoubleSide,
  Mesh,
  MeshBasicMaterial,
  Shape,
  ShapeGeometry,
} from "three";
import { Line2, LineGeometry, LineMaterial } from "three-stdlib";
import { useThreeEventTarget } from "../hooks";
import { CreateAnnotationControls } from "./create-annotation-controls";

type CreateRectangleAnnotationProps = {
  /**
   * True to enable the tool
   *
   * @default true
   */
  isEnabled?: boolean;

  /** Callback executed when an annotation has been created */
  onCreated(mesh: Mesh): void;

  /** Callback executed when the user cancels an annotation while drawing */
  onCancelled?(): void;

  /** Callback that notifies when the user start to drag a rectangle */
  onDragStart(): void;

  /** Callback that notifies when the user ends the rectangle drawing */
  onDragEnd(): void;
};

/**
 *  @returns the ability to create rectangle annotations.
 */
export function CreateRectangleAnnotation({
  isEnabled = true,
  onCreated,
  onCancelled,
  onDragEnd,
  onDragStart,
}: CreateRectangleAnnotationProps): JSX.Element {
  const { openToast } = useToast();

  const domElement = useThreeEventTarget();

  const [currentAnnotation, setCurrentAnnotation] = useState<Line2>();

  const [rectangle] = useState(() => {
    const rectangleShape = createRoundedRectangleShape();

    // Create the rectangle mesh
    const material = new MeshBasicMaterial({
      color: new Color(neutral[100]).getHex(),
      transparent: true,
      opacity: 0.2,
      side: DoubleSide,
      depthTest: false,
    });
    const rectangleGeometry = new ShapeGeometry(rectangleShape);
    const mesh = new Mesh(rectangleGeometry, material);

    // Create border for the rectangle using line
    rectangleShape.autoClose = true;
    const points = rectangleShape.getPoints();
    const lineGeometry = new LineGeometry();
    lineGeometry.setPositions(points.flatMap((point) => [point.x, point.y, 0]));

    const lineMaterial = new LineMaterial({
      color: new Color(orange[400]).getHex(),
      depthTest: false,
      linewidth: 4,
      dashed: true,
    });

    lineMaterial.resolution.set(
      domElement.clientWidth,
      domElement.clientHeight,
    );

    const line = new Line2(lineGeometry, lineMaterial);

    // computes the array of distance values necessary for dashed lines
    line.computeLineDistances();

    line.add(mesh);
    return line;
  });

  const onAnnotationUpdated = useCallback(
    (annotation?: Line2) => {
      if (annotation) {
        // Update the dashed scale based on the scale of the annotation so that the dashes are not cluttered or too sparse
        annotation.material.dashScale =
          Math.max(annotation.scale.x, annotation.scale.y) * 10;
      } else {
        onCancelled?.();
      }

      setCurrentAnnotation(annotation);
    },
    [onCancelled],
  );

  const onAnnotationCreated = useCallback(
    (newAnnotation: Line2) => {
      setCurrentAnnotation(undefined);
      if (newAnnotation.scale.x > 0.1 && newAnnotation.scale.y > 0.1) {
        // Clone the material so that the new annotation does not share the same material and remove dashed lines
        newAnnotation.material = newAnnotation.material.clone();
        newAnnotation.material.dashed = false;

        onCreated(newAnnotation);
      } else {
        openToast({
          title: "Annotation is too small",
          variant: "error",
        });
      }
    },
    [openToast, onCreated],
  );

  return (
    <>
      <CreateAnnotationControls
        mesh={rectangle}
        onAnnotationCreated={onAnnotationCreated}
        onAnnotationUpdated={onAnnotationUpdated}
        onDragEnd={onDragEnd}
        onDragStart={onDragStart}
        isEnabled={isEnabled}
      />

      {currentAnnotation && <primitive object={currentAnnotation} />}
    </>
  );
}

/**
 * @returns a rounded rectangle shape which can be used to create a mesh
 */
function createRoundedRectangleShape(): Shape {
  const radius = 0.05;
  const width = 1;
  const height = 1;
  const x = -0.5;
  const y = -0.5;

  const roundedRectShape = new Shape();

  roundedRectShape.moveTo(x, y + radius);
  roundedRectShape.lineTo(x, y + height - radius);
  roundedRectShape.quadraticCurveTo(x, y + height, x + radius, y + height);
  roundedRectShape.lineTo(x + width - radius, y + height);
  roundedRectShape.quadraticCurveTo(
    x + width,
    y + height,
    x + width,
    y + height - radius,
  );
  roundedRectShape.lineTo(x + width, y + radius);
  roundedRectShape.quadraticCurveTo(x + width, y, x + width - radius, y);
  roundedRectShape.lineTo(x + radius, y);
  roundedRectShape.quadraticCurveTo(x, y, x, y + radius);

  return roundedRectShape;
}
