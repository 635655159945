import { PointCloudObject } from "@/object-cache";
import { isObjPointCloudPoint } from "@/types/threejs-type-guards";
import {
  CopyToScreenPass,
  EffectPipelineWithSubScenes,
  FilteredRenderPass,
  SubScene,
} from "@faro-lotv/app-component-toolbox";
import { useMemo } from "react";
import { Object3D } from "three";
import { MultiCloudRenderingPolicy } from "./multi-cloud-rendering-policy";

type RevisionRenderingPipelineProps = {
  /** The point clouds to render in the pipeline. */
  pointCloudObjects: PointCloudObject[];
};

/**
 * @returns A custom rendering pipeline for more efficient point cloud rendering.
 */
export function RevisionRenderingPipeline({
  pointCloudObjects,
}: RevisionRenderingPipelineProps): JSX.Element {
  const renderingPolicy = useMemo(
    () => new MultiCloudRenderingPolicy(pointCloudObjects),
    [pointCloudObjects],
  );

  return (
    <EffectPipelineWithSubScenes>
      {/* Efficiently render point clouds separately */}
      <SubScene
        filter={(obj: Object3D) => isObjPointCloudPoint(obj)}
        renderingPolicy={renderingPolicy}
      />
      {/* All other objects */}
      <FilteredRenderPass
        filter={(obj: Object3D) => !isObjPointCloudPoint(obj)}
        clear={false}
        clearDepth={false}
      />
      <CopyToScreenPass />
    </EffectPipelineWithSubScenes>
  );
}
