import { useAppSelector } from "@/store/store-hooks";
import { IElementBase } from "@faro-lotv/ielement-types";
import { selectIElementChildren } from "@faro-lotv/project-source";
import { isEqual } from "es-toolkit";
import { useMemo } from "react";
import { ElementIconType, iconForElement } from "./element-icon-type";

/**
 * @returns the icon associated with an Element if available or a simple folder icon
 * @param nodeElement the node attached project element if available
 * @param directParent the type/hints of the node parent if available
 */
export function useElementIcon(
  nodeElement?: Pick<IElementBase, "id" | "type" | "typeHint">,
  directParent?: Pick<IElementBase, "type" | "typeHint">,
): ElementIconType {
  const childrenTypeHints = useAppSelector(
    (state) =>
      nodeElement ? selectIElementChildren(nodeElement.id)(state) : [],
    isEqual,
  ).map((el: IElementBase) => el.typeHint);

  // Specific icon to display for the current node
  return useMemo(() => {
    if (nodeElement) {
      return iconForElement(
        nodeElement.type,
        nodeElement.typeHint,
        childrenTypeHints,
        directParent?.type,
        directParent?.typeHint,
      );
    }
    // Nodes that do not map to an element are folders
    return ElementIconType.FolderIcon;
  }, [childrenTypeHints, directParent, nodeElement]);
}
