import { useEffect, useState } from "react";

type MinimapHeightType = number | string;

// Width (percentage of parent) of the minimap container
export const CONTAINER_WIDTH_PERCENTAGE = 25;
export const CONTAINER_WIDTH_PERCENTAGE_HOVERED = 35;

type MinimapHeightProps = {
  /** The parent div containing the minimap */
  container: HTMLDivElement | null;
  /**
   * A flag specifying if the minimap has been hovered by the user
   *
   * @default false
   */
  isContainerHovered?: boolean;
  /**
   * A flag specifying if the minimap is minimized
   *
   * @default false
   */
  isMinimized?: boolean;
  /**
   * A flag specifying if the floor plan label has been hovered by the user
   *
   * @default false
   */
  isFloorPlanLabelHovered?: boolean;
  /**
   * A flag specifying if the minimap is full screen
   *
   * @default false
   */
  isFullScreen?: boolean;
};

/**
 * @returns The height of the minimap based on the width of the parent container
 */
export function useMinimapHeight({
  container,
  isContainerHovered = false,
  isMinimized = false,
  isFloorPlanLabelHovered = false,
  isFullScreen = false,
}: MinimapHeightProps): MinimapHeightType {
  // The height depends on the width of the container.
  // It's always half of the width.
  const [canvasHeight, setCanvasHeight] = useState<MinimapHeightType>(0);

  // Update height on mount, when the deps change and when the window resizes
  // If the minimap is minimized and not hovered, the height is 0, otherwise it's half of the width.
  useEffect(() => {
    function updateHeight(): void {
      if (container === null) return;

      const widthPercentage = isContainerHovered
        ? CONTAINER_WIDTH_PERCENTAGE_HOVERED
        : CONTAINER_WIDTH_PERCENTAGE;

      // Get the width in px so that the height can be calculated
      // In some cases (for example pano-pano split screen offsetWidth is zero when useEffect triggered)
      // in this case we can use offsetHeight to define defaultHeight for minimap
      const width =
        (container.offsetWidth || container.offsetHeight) * widthPercentage;

      const defaultHeight = `${(width / 100) * 0.5}px`;

      if (isMinimized) {
        setCanvasHeight(
          isFloorPlanLabelHovered || isContainerHovered ? defaultHeight : 0,
        );
      } else {
        setCanvasHeight(defaultHeight);
      }
    }

    updateHeight();
    window.addEventListener("resize", updateHeight);

    // Cleanup
    return () => window.removeEventListener("resize", updateHeight);
  }, [
    isContainerHovered,
    isFloorPlanLabelHovered,
    isFullScreen,
    isMinimized,
    container,
  ]);

  return canvasHeight;
}
