import { GUID } from "@faro-lotv/ielement-types";
import {
  findAnalysis,
  PointCloudAnalysis,
} from "./point-cloud-analysis-tool-slice";
import { RootState } from "./store";

/**
 *  @returns the list of analyses associated to a point cloud element
 *  @param id The id of the element which analyses we want to get
 */
export function selectPointCloudAnalyses(id: GUID | undefined) {
  return (state: RootState): PointCloudAnalysis[] => {
    if (!id) return [];

    return id in state.pointCloudAnalysisTool.analyses
      ? state.pointCloudAnalysisTool.analyses[id]
      : [];
  };
}

/**
 *  @returns the analysis matching a given id
 *  @param id The id of the analysis to get
 */
export function selectAnalysis(id: GUID) {
  return (state: RootState): PointCloudAnalysis | undefined =>
    findAnalysis(state.pointCloudAnalysisTool.analyses, id);
}

/**
 * @returns the id of the active analysis or undefined if none
 * @param root The Root State
 */
export function selectActiveAnalysisId(root: RootState): GUID | undefined {
  return root.pointCloudAnalysisTool.activeAnalysisId;
}

/**
 * @returns the active analysis or undefined if none
 * @param root The Root State
 */
export function selectActiveAnalysis(
  root: RootState,
): PointCloudAnalysis | undefined {
  const id = selectActiveAnalysisId(root);
  if (!id) return;
  return selectAnalysis(id)(root);
}

/**
 * @returns the number of analyses taken in this browser session
 * @param root current app state
 */
export function selectNumberOfSessionAnalyses(root: RootState): number {
  return root.pointCloudAnalysisTool.numberOfSessionAnalyses;
}

/**
 *  @returns the dirty flag of the analysis matching a given id
 *  @param id The id of the analysis
 */
export function selectAnalysisIsDirty(id: GUID) {
  return (state: RootState): boolean | undefined =>
    findAnalysis(state.pointCloudAnalysisTool.analyses, id)?.isDirty;
}
