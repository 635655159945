import { GUID } from "@faro-lotv/foundation";
import { RegistrationEdgeRevision } from "@faro-lotv/service-wires";

/** A connection between two scans. */
export type ScanConnection = {
  /** The ID of the source scan. */
  sourceId: GUID;
  /** The ID of the target scan. */
  targetId: GUID;
};

/**
 * @param connection1 The first connection
 * @param connection2 The second connection
 * @returns `true` if the connections connect the same scans (in any direction), else `false`.
 */
export function areConnectionsEqual(
  connection1: ScanConnection,
  connection2: ScanConnection,
): boolean {
  return (
    (connection1.sourceId === connection2.sourceId &&
      connection1.targetId === connection2.targetId) ||
    (connection1.sourceId === connection2.targetId &&
      connection1.targetId === connection2.sourceId)
  );
}

/**
 * @param edge1 The first edge to compare
 * @param edge2 The second edge to compare
 * @returns `true` if the edges should be considered as equal, `false` otherwise.
 *  Two edges are considered equal if they have the same ID or if they connect the same scans (ignoring direction) and have the same type.
 */
export function isEdgeEqual(
  edge1: RegistrationEdgeRevision,
  edge2: RegistrationEdgeRevision,
): boolean {
  return (
    edge1.id === edge2.id ||
    (areConnectionsEqual(edge1, edge2) && edge1.type === edge2.type)
  );
}
