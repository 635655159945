import {
  DeepLink,
  ValidDeepLink,
  decodeDeepLink,
  isValidDeepLink,
} from "@/components/common/deep-link/deep-link-encoding";
import { decodeOrientedBoundingBox } from "@/components/r3f/utils/oriented-bounding-box";
import { getMode } from "@/modes";
import { setClippingBox } from "@/store/clipping-box-slice";
import { parseFeaturesAndValuesFromUrl } from "@/store/features/features";
import {
  selectActiveElementFromLookAtId,
  selectModeFromElement,
} from "@/store/mode-selectors";
import { setDeepLink } from "@/store/mode-slice";
import { RootState } from "@/store/store";
import { useAppDispatch, useAppSelector } from "@/store/store-hooks";
import { selectIElement } from "@faro-lotv/app-component-toolbox";
import { GUID } from "@faro-lotv/foundation";
import { isEqual } from "es-toolkit";
import {
  PropsWithChildren,
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

type DeepLinkContext = {
  /** An element to load the sub-tree for to properly parse the deep link */
  requiredElement: GUID | undefined;

  /** The parsed and validated deep link */
  deepLink: ValidDeepLink | Error | undefined;
};

const DeepLinkContext = createContext<DeepLinkContext | undefined>(undefined);

function useDeepLinkContext(): DeepLinkContext {
  const context = useContext(DeepLinkContext);
  if (!context) {
    throw new Error("DeepLinkContext is not initialized.");
  }
  return context;
}

/**
 * @returns a provider for the DeepLinkContext
 * The context is needed because the deep link keeps changing when we use
 * the navigate function to remove the deep links query parameters. With the context,
 * we compute the deep link only once and then the URL can freely change
 */
export function DeepLinkContextProvider({
  children,
}: PropsWithChildren): JSX.Element {
  const [searchParams] = useSearchParams();
  const deepLink = useParseDeepLink(searchParams);
  const [requiredElement] = useState(() => getRequiredElement(searchParams));

  useDeepLinkClippingParameters(deepLink);

  const value = useMemo<DeepLinkContext>(
    () => ({
      deepLink,
      requiredElement,
    }),
    [deepLink, requiredElement],
  );

  return (
    <DeepLinkContext.Provider value={value}>
      {children}
    </DeepLinkContext.Provider>
  );
}

/**
 * @returns The app state from a deep link. Undefined means that the url does not contain a deep link,
 * while an Error identifies an invalid deep link
 */
export function useDeepLink(): DeepLink | Error | undefined {
  return useDeepLinkContext().deepLink;
}

/**
 * @returns the elements for which the sub-tree needs to be loaded to properly parse the current deep link
 */
export function useDeepLinkRequiredElement(): GUID | undefined {
  return useDeepLinkContext().requiredElement;
}

/**
 * This selector takes a deep link as argument and checks whether it represents
 * a valid state of the application. In some circumstances, it can add data to the
 * deepLink object, for example by computing a convenient viewer mode if no mode was given.
 *
 * @param searchParams The initial search parameters
 * @returns the deep link with all the missing value resolved
 */
function selectOrParseDeepLink(searchParams: URLSearchParams) {
  return (state: RootState): ValidDeepLink | Error | undefined => {
    if (state.mode.deepLink) {
      return state.mode.deepLink;
    }
    const deepLink = decodeDeepLink(searchParams);
    if (deepLink instanceof Error) {
      return deepLink;
    }
    if (!deepLink) {
      return;
    }

    // Check whether the active element exists and is found in the project. If not, it can be inferred by the lookAtId.
    const activeElement =
      (deepLink.id && selectIElement(deepLink.id)(state)) ??
      selectActiveElementFromLookAtId(deepLink.lookAtId)(state);
    if (!activeElement) {
      return;
    }
    deepLink.id = activeElement.id;

    // If a mode was not specified infer it from the activeElement and the lookAt element
    if (!deepLink.mode) {
      deepLink.mode = selectModeFromElement(
        activeElement,
        deepLink.lookAtId,
      )(state);
      if (!deepLink.mode) {
        return;
      }
    }

    const mode = getMode(deepLink.mode);

    // Check if the mode can be opened with the current element
    if (mode.canBeStartedWith && !mode.canBeStartedWith(activeElement, state)) {
      return;
    }

    if (mode.initialState) {
      try {
        mode.initialState.parse(state, deepLink);
      } catch {
        return;
      }
    }

    if (!isValidDeepLink(deepLink)) {
      return;
    }

    return deepLink;
  };
}

/**
 * Parse the search params and resolve the deep link data
 *
 * The parsed deep link is stored in the app store to make sure it is not re-computed
 * if the DeepLinkProvider component is unmounted and then mounted again
 *
 * After the deep link was successfully parsed the url will be cleaned up from the deep link params
 *
 * @param searchParams the url search params
 * @returns the parsed deep link
 */
function useParseDeepLink(
  searchParams: URLSearchParams,
): ValidDeepLink | undefined | Error {
  const navigate = useNavigate();
  const deepLink = useAppSelector(selectOrParseDeepLink(searchParams), isEqual);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!deepLink || deepLink instanceof Error) {
      return;
    }
    dispatch(setDeepLink(deepLink));

    const enabledFeatures = parseFeaturesAndValuesFromUrl(searchParams);
    // get the list of feature flags with optional value
    const enabledFeaturesWithValue = enabledFeatures.map((feature) => {
      let queryParam = feature.urlQuery;
      if (feature.value !== null && feature.value !== "") {
        // only add value when non empty
        queryParam += `=${feature.value}`;
      }
      return queryParam;
    });

    navigate(
      {
        pathname: window.location.pathname,
        search: `?${enabledFeaturesWithValue.join("&")}`,
      },
      {
        replace: true,
      },
    );
  }, [deepLink, dispatch, navigate, searchParams]);
  return deepLink;
}

/**
 * @returns True if the deep link econding was successful
 * @param deepLink The deep link to check
 */
export function isDeepLinkDefined(
  deepLink: DeepLink | Error | undefined,
): deepLink is DeepLink {
  return !!deepLink && !(deepLink instanceof Error);
}

/**
 * @returns the mode name defined in the deep link if available
 */
export function useDeepLinkModeIfAvailable(): string | undefined {
  const context = useContext(DeepLinkContext);
  const link = context?.deepLink;
  if (!link || link instanceof Error) {
    return;
  }
  return link.mode;
}

/**
 * Apply the clipping parameters defined in the deep link if they're available
 *
 * @param deepLink to parse
 */
function useDeepLinkClippingParameters(
  deepLink: DeepLink | Error | undefined,
): void {
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!deepLink || deepLink instanceof Error) return;

    const parsed = decodeOrientedBoundingBox(deepLink.clipping);
    dispatch(setClippingBox(parsed));
  }, [deepLink, dispatch]);
}

/**
 * @param searchParams of the current url
 * @returns the id of an element for which the sub-tree is required to parse the deep link
 */
function getRequiredElement(searchParams: URLSearchParams): GUID | undefined {
  const deepLink = decodeDeepLink(searchParams);
  if (deepLink && !(deepLink instanceof Error)) {
    return deepLink.id ?? deepLink.lookAtId;
  }
}
