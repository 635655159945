import {
  ENTIRE_PROJECT_KEY,
  invalidateContents,
  setAreaContents,
} from "@/store/area-contents-slice";
import {
  AreaContentType,
  CAPTURES_ELEMENT_TYPES,
  ROOMS_ELEMENT_TYPES,
  SHEETS_ELEMENT_TYPES,
} from "@/store/area-contents-types";
import { Features, selectHasFeature } from "@/store/features/features-slice";
import { selectActiveArea } from "@/store/selections-selectors";
import { AppThunk } from "@/store/store";
import { addIElements } from "@faro-lotv/project-source";
import {
  GetIElementsParams,
  IElementInVolume,
  ProjectApi,
} from "@faro-lotv/service-wires";

export interface UpdateProjectProps {
  /** The project api client for the current project */
  projectApi: ProjectApi;

  /**
   * Query to fetch updated iElements. Should include all elements that have been expected to change,
   * but be limited to the smallest possible set to avoid long query times.
   */
  iElementQuery?: GetIElementsParams;

  /**
   * Whether the area contents should be updated with a new volume query.
   * Set this to true, if you expect that a dataset could have been removed or added to areas.
   */
  invalidateAreaContents?: boolean;
}

/** @returns Redux thunk that updates parts of the current project data while the application is running */
export function updateProject({
  projectApi,
  iElementQuery,
  invalidateAreaContents,
}: UpdateProjectProps): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const useNewVolumeQuery = selectHasFeature(Features.AreaNavigation)(
      getState(),
    );

    const iElementsPromise = iElementQuery
      ? projectApi.getAllIElements(iElementQuery)
      : undefined;

    let areaContentsCapturesPromise: Promise<IElementInVolume[]> | undefined;
    let areaContentsRoomsPromise: Promise<IElementInVolume[]> | undefined;
    let areaContentsSheetsPromise: Promise<IElementInVolume[]> | undefined;

    const currentArea = selectActiveArea(getState());

    if (invalidateAreaContents && useNewVolumeQuery) {
      // Invalidating all contents before fetching to prevent race conditions when the user switches while we know that data is outdated.
      dispatch(
        invalidateContents({
          except: currentArea?.id ?? ENTIRE_PROJECT_KEY,
        }),
      );

      areaContentsCapturesPromise = projectApi.getAllIElementsByVolume({
        areaId: currentArea?.id,
        elementTypes: CAPTURES_ELEMENT_TYPES,
      });

      areaContentsRoomsPromise = projectApi.getAllIElementsByVolume({
        areaId: currentArea?.id,
        elementTypes: ROOMS_ELEMENT_TYPES,
      });

      areaContentsSheetsPromise = projectApi.getAllIElementsByVolume({
        areaId: currentArea?.id,
        elementTypes: SHEETS_ELEMENT_TYPES,
      });
    }

    // Wait for all parallel fetching to complete before dispatching actions, to avoid race conditions caused by partial states.
    const [
      iElements,
      areaContentsRooms,
      areaContentsCaptures,
      areaContentsSheets,
    ] = await Promise.all([
      iElementsPromise,
      areaContentsRoomsPromise,
      areaContentsCapturesPromise,
      areaContentsSheetsPromise,
    ]);

    if (iElements) {
      dispatch(addIElements(iElements));
    }

    if (areaContentsCaptures) {
      dispatch(
        setAreaContents({
          areaContentsKey: currentArea?.id ?? ENTIRE_PROJECT_KEY,
          elements: areaContentsCaptures,
          type: AreaContentType.captures,
        }),
      );
    }

    if (areaContentsRooms) {
      dispatch(
        setAreaContents({
          areaContentsKey: currentArea?.id ?? ENTIRE_PROJECT_KEY,
          elements: areaContentsRooms,
          type: AreaContentType.rooms,
        }),
      );
    }

    if (areaContentsSheets) {
      dispatch(
        setAreaContents({
          areaContentsKey: currentArea?.id ?? ENTIRE_PROJECT_KEY,
          elements: areaContentsSheets,
          type: AreaContentType.sheets,
        }),
      );
    }
  };
}
