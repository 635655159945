import { useObjectBoundingBox } from "@/hooks/use-object-bounding-box";
import { useCurrentScene } from "@/modes/mode-data-context";
import { CurrentAreaData, CurrentScene } from "@/modes/mode-selectors";
import { selectWalkSceneElements } from "@/modes/walk-mode/walk-scene-selectors";
import { useCached3DObjectIfExists } from "@/object-cache";
import { selectWalkMainSceneType } from "@/store/modes/walk-mode-slice";
import { RootState } from "@/store/store";
import { useAppSelector } from "@/store/store-hooks";
import {
  PC_HEIGHT_OFFSET,
  selectPanoCameraTransform,
} from "@/utils/camera-transform";
import { assert } from "@faro-lotv/foundation";
import { isIElementModel3dStream } from "@faro-lotv/ielement-types";
import { memberWithPrivateData } from "@faro-lotv/lotv";
import { selectIElementWorldPosition } from "@faro-lotv/project-source";
import { useThree } from "@react-three/fiber";
import { isEqual } from "es-toolkit";
import { useMemo } from "react";
import {
  Camera,
  Matrix4,
  Object3D,
  Quaternion,
  Vector3,
  Vector3Tuple,
} from "three";
import { Models3dAnimation } from "./models-3d-animation";

type OverviewToWalkProps = {
  /** The current area */
  currentArea: CurrentAreaData;
  /** Rotate the camera to look at the center of the model */
  lookAtModel: boolean;
  /** Callback issued when the transition is finished */
  onCompleted(): void;
};

/** @returns a component implementing a camera transition from overview mode to walk mode when the 3D models are visible */
export function To3dWalkAnimation({
  currentArea,
  lookAtModel,
  onCompleted,
}: OverviewToWalkProps): JSX.Element {
  const camera = useThree((s) => s.camera);

  const currentScene = useCurrentScene();

  const walkPosition = useAppSelector(
    selectWalkBestPosition(camera.position.toArray(), currentScene),
    isEqual,
  );

  const targetCameraPosition = useMemo(
    () => new Vector3().fromArray(walkPosition),
    [walkPosition],
  );

  const walkSceneFilter = useAppSelector(selectWalkMainSceneType);

  const [main, overlayElement] = useAppSelector(
    selectWalkSceneElements(
      currentScene,
      currentArea,
      camera.position,
      walkSceneFilter,
    ),
    isEqual,
  );

  assert(main !== undefined, "Walk mode main element should be defined");

  /** Get the current 3D object (do not report loading failure for the IElementModel3dStream)*/
  const displayLoadingErrorCurrentElement = !isIElementModel3dStream(main);
  const currentObject = useCached3DObjectIfExists(
    main,
    displayLoadingErrorCurrentElement,
  );

  const box = useObjectBoundingBox(currentObject, main.id);
  const target = useMemo(
    () =>
      box?.getCenter(new Vector3()) ??
      new Vector3(
        targetCameraPosition.x,
        targetCameraPosition.y,
        targetCameraPosition.z + 1,
      ),
    [box, targetCameraPosition],
  );
  const targetCameraQuaternion = useMemo(
    () =>
      lookAtModel
        ? lookAt(targetCameraPosition, target)
        : toHorizontalFocalAxis(camera),
    [camera, lookAtModel, target, targetCameraPosition],
  );

  return (
    <Models3dAnimation
      targetCameraPosition={targetCameraPosition}
      targetCameraQuaternion={targetCameraQuaternion}
      mainElement={main}
      overlayElement={overlayElement}
      onCompleted={onCompleted}
      isSecondaryView={false}
    />
  );
}

/**
 * Compute a nice walk position to start walking from a overview 3d position
 *
 * Searches for the closest scan to the current camera position.
 * If one is available move the camera at that pano altitude.
 * If not lower the user to the floor map level
 *
 * @param cameraPosition to use to search for the closest pano
 * @param scene rendered
 * @returns the best walk position to move to from the current camera position
 */
function selectWalkBestPosition(
  cameraPosition: Vector3Tuple,
  scene: CurrentScene,
) {
  return (state: RootState): Vector3Tuple => {
    const panoPositions = scene.panos.map(
      (pano) => selectPanoCameraTransform(pano, scene.sheet)(state).position,
    );
    const closestPano = panoPositions.reduce<Vector3Tuple | undefined>(
      (closest, current) => {
        if (
          !closest ||
          squaredDistance(current, cameraPosition) <
            squaredDistance(closest, cameraPosition)
        ) {
          return current;
        }
        return closest;
      },
      undefined,
    );
    if (closestPano) {
      return [cameraPosition[0], closestPano[1], cameraPosition[2]];
    }
    const sheetPosition = selectIElementWorldPosition(scene.sheet?.id)(state);
    return [
      cameraPosition[0],
      sheetPosition[1] + PC_HEIGHT_OFFSET,
      cameraPosition[2],
    ];
  };
}

/** @returns  the squared distance between two point */
const squaredDistance = memberWithPrivateData(() => {
  const A2D = new Vector3();
  const B2D = new Vector3();
  return (a: Vector3Tuple, b: Vector3Tuple): number =>
    A2D.fromArray(a).distanceToSquared(B2D.fromArray(b));
});

const EPSILON_SQ = 1e-6;

/**
 * This function takes as input a camera, projects its focal axis on the horizontal
 * plane, and returns the quaternion that expresses the new rotation of the camera.
 *
 * @param camera Current camera
 * @returns A quaternion expressing the rotation that the camera has only around the vertical axis
 */
function toHorizontalFocalAxis(camera: Camera): Quaternion {
  const dir = camera.getWorldDirection(new Vector3());
  dir.y = 0;
  if (dir.lengthSq() < EPSILON_SQ) dir.x = 1;
  const mat = new Matrix4().lookAt(new Vector3(), dir, Object3D.DEFAULT_UP);
  return new Quaternion().setFromRotationMatrix(mat);
}

/**
 * @returns The quaternion to rotate the camera at position "pos"
 * looking at "target"
 * @param pos The position of the camera
 * @param target The target looked by the camera
 */
function lookAt(pos: Vector3, target: Vector3): Quaternion {
  const meaningfulTarget =
    pos.distanceTo(target) < Number.EPSILON
      ? new Vector3(pos.x, pos.y, pos.z - 1)
      : target;
  const mat = new Matrix4().lookAt(pos, meaningfulTarget, Object3D.DEFAULT_UP);
  return new Quaternion().setFromRotationMatrix(mat);
}
