import {
  GENERIC_DATASET_TYPE_HINTS,
  IElementType,
  IElementTypeHint,
} from "@faro-lotv/ielement-types";
import { GetIElementsByVolumeParams } from "@faro-lotv/service-wires";

export enum AreaContentType {
  /** The captures of an area. Includes all "dataset" types. */
  captures = "captures",

  /** The rooms of an area */
  rooms = "rooms",

  /** The sheets of an area */
  sheets = "sheets",
}

/** The elementTypes parameter to use when requesting elements via the volume query for area contents of type "captures" */
export const CAPTURES_ELEMENT_TYPES: GetIElementsByVolumeParams["elementTypes"] =
  [
    ...GENERIC_DATASET_TYPE_HINTS.map((typeHint) => ({
      type: IElementType.section,
      typeHint,
    })),
  ];

/** The elementTypes parameter to use when requesting elements via the volume query for area contents of type "captures" */
export const ROOMS_ELEMENT_TYPES: GetIElementsByVolumeParams["elementTypes"] = [
  {
    type: IElementType.timeSeries,
    typeHint: IElementTypeHint.room,
  },
];

/** The elementTypes parameter to use when requesting elements via the volume query for area contents of type "sheets" */
export const SHEETS_ELEMENT_TYPES: GetIElementsByVolumeParams["elementTypes"] =
  [
    // Fetch all sheet types regardless of typeHint
    {
      type: IElementType.imgSheet,
    },
    {
      type: IElementType.imgSheetTiled,
    },
  ];
