import {
  selectActiveCadHasMesh,
  selectActiveCadId,
} from "@/store/cad/cad-slice";
import { changeMode } from "@/store/mode-slice";
import {
  setWizardElementToAlignId,
  setWizardReferenceElementId,
} from "@/store/modes/alignment-wizard-mode-slice";
import { selectActiveElement } from "@/store/selections-selectors";
import { AppDispatch, store } from "@/store/store";
import { GUID, isIElementGenericDataSession } from "@faro-lotv/ielement-types";
import { selectAncestor } from "@faro-lotv/project-source";
import { getValidReferenceCloud } from "./align-wizard-mode-scene";

export type AlignmentWizardArgs = {
  /** Id of the element to align */
  elementIdToAlign: GUID;

  /** Function to update the state. */
  dispatch: AppDispatch;
};

/**
 * Start Alignment Wizard
 */
export function openAlignmentWizard({
  dispatch,
  elementIdToAlign,
}: AlignmentWizardArgs): void {
  dispatch(setWizardElementToAlignId(elementIdToAlign));

  // current active CAD used as an initial suggested reference in Alignment Wizard
  const activeCadId = selectActiveCadId(store.getState());
  if (activeCadId) {
    if (activeCadId === elementIdToAlign) {
      // When aligning a CAD, set the visible cloud as the suggested reference
      // The selected element must be isIElementGenericDataSession===true to match te tree contents.
      // When aligning a CAD, set the active cloud as the suggested reference
      const activeElement = selectActiveElement(store.getState());
      // The active element can either be the DataSession, or a specific scan in the timeseries.
      // But we only support DataSession as reference for now.
      let referenceElement =
        activeElement && isIElementGenericDataSession(activeElement)
          ? activeElement
          : undefined;
      if (activeElement && !referenceElement) {
        // Get the data section element ancestor from the active element in case its a specific scan in the timeseries
        referenceElement = selectAncestor(
          activeElement,
          isIElementGenericDataSession,
        )(store.getState());
      }
      if (referenceElement) {
        // if we found a referenceElement, check that it does contain a cloud that could be used for alignment
        if (getValidReferenceCloud(referenceElement, store.getState())) {
          dispatch(setWizardReferenceElementId(referenceElement.id));
        }
      }
    } else {
      // We are typically aligning a non CAD to something else; set the active CAD as the suggested reference
      const activeCadHasMesh = selectActiveCadHasMesh(store.getState());
      if (activeCadHasMesh) {
        dispatch(setWizardReferenceElementId(activeCadId));
      }
    }
  }

  dispatch(changeMode("alignWizard"));
}
