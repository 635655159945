import { useErrorHandlers } from "@/errors/components/error-handling-context";
import { projectUnsupportedError } from "@/errors/unrecoverable-error";
import { setActiveCad } from "@/store/cad/cad-slice";
import { setProjectIntegrations } from "@/store/integrations/integrations-slice";
import { setActiveArea, setActiveElement } from "@/store/selections-slice";
import { AppDispatch } from "@/store/store";
import { useAppDispatch } from "@/store/store-hooks";
import { addNewTags } from "@/store/tags/tags-slice";
import { GUID } from "@faro-lotv/foundation";
import {
  IElement,
  IElementGenericDataset,
  IElementType,
  IElementTypeHint,
  isIElementAreaSection,
  isIElementGenericDataset,
  validateIElement,
} from "@faro-lotv/ielement-types";
import {
  addAreaDataSets,
  addIElements,
  fetchProjectIElements,
  initializeProject,
  setRootId,
} from "@faro-lotv/project-source";
import {
  ProjectApi,
  ProjectApiError,
  isProjectNotFoundError,
  useApiClientContext,
} from "@faro-lotv/service-wires";
import { chunk } from "es-toolkit";
import { useEffect, useState } from "react";
import { fetchBimModelGroupAtRoot } from "../point-cloud-file-upload-context/cad-upload-utils";
import { IElementFetchRequests } from "./project-loading-context";

/** Chunk size that will result in API request URLs that are not too long */
const REQUESTS_CHUNK_SIZE = 50;

/**
 * @param projectId of the project to prepare
 * @param requiredItem inside the project we need the sub-tree of
 * @returns true when the minimum required data from the project was loaded from the backend
 */
export function useProjectInitialData(
  projectId: GUID,
  requiredItem?: GUID | null,
): boolean {
  const dispatch = useAppDispatch();
  const { handleErrorWithPage } = useErrorHandlers();

  const [isProjectReady, setIsProjectReady] = useState(false);

  const { projectApiClient: client } = useApiClientContext();

  // Load all initial data required to work on a project
  // * The project tree up to the list of area sections
  // * All the extra data defined in the fetchInitExtraData function
  useEffect(() => {
    dispatch(initializeProject(projectId));
    const ac = new AbortController();

    // Retry fetchProject if it fails
    fetchProjectInitialData(dispatch, client, ac.signal)
      .then((elements: IElement[]) => {
        // The getAllIElements request returns an empty array if the request is aborted
        if (ac.signal.aborted) {
          return;
        }
        const areaSections = elements.filter(
          (element) =>
            element.typeHint === IElementTypeHint.area &&
            element.type === IElementType.section,
        );
        const isProjectWithArea = areaSections.length > 0;

        if (isProjectWithArea) {
          dispatch(setActiveElement(areaSections[0].id));
          dispatch(setActiveArea(areaSections[0].id));
        } else {
          const slideContainers = elements.filter(
            (element) =>
              element.typeHint === IElementTypeHint.slideContainer &&
              element.type === IElementType.group,
          );
          if (slideContainers.length > 0) {
            dispatch(setActiveElement(slideContainers[0].id));
          }
        }

        const cadModels = elements.filter(
          (element) => element.type === IElementType.model3dStream,
        );

        if (cadModels.length) {
          // sort models in alphabetical order of names
          const sortedCadModels = cadModels.sort((a, b) =>
            a.name.localeCompare(b.name),
          );
          dispatch(setActiveCad(sortedCadModels[0].id));
        }

        return fetchInitExtraData(
          dispatch,
          client,
          ac.signal,
          requiredItem ?? undefined,
        ).then(() => {
          // The getAllIElements request returns an empty array if the request is aborted
          if (ac.signal.aborted) {
            return;
          }

          setIsProjectReady(true);
        });
      })
      .catch((e: ProjectApiError) => {
        // In case the request was aborted due to the hook unmount, don't show the error page to avoid race conditions
        if (ac.signal.aborted) {
          return;
        }

        if (isProjectNotFoundError(e)) {
          handleErrorWithPage(projectUnsupportedError(e, projectId));
          return;
        }
        handleErrorWithPage(e);
      });

    return () => {
      ac.abort();

      // Clear only the project related state
      dispatch(initializeProject(undefined));
      setIsProjectReady(false);
    };
  }, [client, dispatch, handleErrorWithPage, projectId, requiredItem]);

  return isProjectReady;
}

/**
 * Update the store with the elements fetched from the ProjectAPI
 *
 * @param elementCollection the list of elements fetched from the ProjectAPI
 * @param dispatch function to update the application store
 * @returns the list of elements
 */
function updateStoreWithElements(
  elementCollection: IElement[],
  dispatch: AppDispatch,
): IElement[] {
  // Build the initial tree removing the duplicated nodes
  const elements = elementCollection.reduce<IElement[]>((result, next) => {
    if (!result.some((el) => el.id === next.id)) {
      result.push(next);
    }
    return result;
  }, []);
  const root = elements.find((el) => el.id === elements[0].rootId);

  dispatch(addIElements(elements));
  if (root) {
    const projectIntegrations =
      root.metaDataMap?.projectIntegrations ?? undefined;
    dispatch(setProjectIntegrations(projectIntegrations));
    dispatch(setRootId(root.id));
  }
  return elements;
}

/**
 * Fetch the initial data needed for the viewer to render a project
 *
 * For now we require all the nodes up the the area section so we can open the Sheet Mode
 *
 * @param dispatch function to update the application store
 * @param client to talk to the project api endpoint
 * @param signal to abort this fetch
 * @returns the list of element returned by the backend or an empty array if the request was aborted
 */
async function fetchProjectInitialData(
  dispatch: AppDispatch,
  client: ProjectApi,
  signal: AbortSignal,
): Promise<IElement[]> {
  // Fetch all the area sections
  const areaSections = await client.getAllIElements({
    signal,
    types: [IElementType.section],
    typeHints: [IElementTypeHint.area],
  });
  if (signal.aborted) return [];

  if (areaSections.length === 0) {
    // The project is a new one and empty, i.e no area is present.
    // Hence fetch everything that is available
    const elements = await client.getAllIElements({
      signal,
    });

    return updateStoreWithElements(elements, dispatch);
  }
  const areaAndSheets = fetchAreasAndSheets(areaSections, client, signal);
  const cads = fetchCads(client, signal);
  const markupTemplates = fetchMarkupTemplates(client, signal);
  const captureTree = fetchCaptureTreeMainStructure(client, signal);
  const labelsPromise = client.getProjectLabels(signal);

  const [elements, labels] = await Promise.all([
    Promise.all([areaAndSheets, cads, markupTemplates, captureTree]),
    labelsPromise,
  ]);

  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  if (signal.aborted) return [];

  dispatch(addNewTags(labels.allowedToUse));
  return updateStoreWithElements(elements.flat(), dispatch);
}

/**
 * @returns the project branches for all the areas up to all the img-sheets tiled or not
 * @param areaSections of all the project areas
 * @param client to query the elements
 * @param signal to abort the request
 */
async function fetchAreasAndSheets(
  areaSections: IElement[],
  client: ProjectApi,
  signal: AbortSignal,
): Promise<IElement[]> {
  const areaIds = areaSections.map((el) => el.id);
  const areaIdChunks = chunk(areaIds, REQUESTS_CHUNK_SIZE);

  const chunkedImgSheetsRequests: IElementFetchRequests = [];
  const chunkedImgSheetTiledRequests: IElementFetchRequests = [];

  // Get all the sheets in all the areas of the project, subdividing by chunks
  // to reduce the request size
  for (const areaIdChunk of areaIdChunks) {
    // Get all the imgSheets of this chunk
    chunkedImgSheetsRequests.push(
      client.getAllIElements({
        signal,
        ancestorIds: areaIdChunk,
        types: [IElementType.imgSheet],
      }),
    );

    chunkedImgSheetTiledRequests.push(
      // Get all the imgSheetTiled of this chunk
      client.getAllIElements({
        signal,
        ancestorIds: areaIdChunk,
        types: [IElementType.imgSheetTiled],
      }),
    );
  }

  // Get all ancestors of the areas (from the area to the Root)
  const areasAncestors = client.getAllIElements({
    signal,
    descendantIds: [areaIds[0]],
  });

  // Get all the Group-Areas so that the tree from the root up to the sheet is loaded locally
  const areaGroupRequest = client.getAllIElements({
    signal,
    types: [IElementType.group],
    typeHints: [IElementTypeHint.area],
  });

  const sheetResponses = await Promise.all([
    Promise.all(chunkedImgSheetsRequests),
    Promise.all(chunkedImgSheetTiledRequests),
  ]);
  const sheets = sheetResponses[0];
  const tiledSheets = sheetResponses[1];

  // Among all the sheets in an area, there could be sheets that are not inside a Group-Area:
  // for example this happens for Geoslam data. We need to load the dataset though, so that
  // we can pick the right sheet when the user selects the area containing this dataset, instead
  // of picking the default grid. To do so, we need to load all the ancestors of the sheets whose
  // parent is not an area group
  const groups = await areaGroupRequest;
  const missingSheetIds: GUID[] = [];
  for (const sheet of [...sheets.flat(), ...tiledSheets.flat()]) {
    if (!groups.find((g) => g.id === sheet.parentId)) {
      missingSheetIds.push(sheet.id);
    }
  }
  const sheetIdChunks = chunk(missingSheetIds, REQUESTS_CHUNK_SIZE);
  const chunkedMissingSheetsRequests: IElementFetchRequests = [];
  for (const sheetChunk of sheetIdChunks) {
    chunkedMissingSheetsRequests.push(
      // Get all the imgSheetTiled of this chunk
      client.getAllIElements({
        signal,
        descendantIds: sheetChunk,
      }),
    );
  }

  const missingSheets = await Promise.all(chunkedMissingSheetsRequests);
  const ancestors = await areasAncestors;

  return [
    ...areaSections,
    ...ancestors,
    ...groups,
    ...sheets.flat(),
    ...tiledSheets.flat(),
    ...missingSheets.flat(),
  ];
}

/**
 * @returns all the cad IElements in the project
 * @param client to query the project
 * @param signal to abort the request
 */
function fetchCads(
  client: ProjectApi,
  signal: AbortSignal,
): Promise<IElement[]> {
  return fetchBimModelGroupAtRoot(client, signal).then((cadGroup) =>
    cadGroup ? client.getAllIElements({ ancestorIds: [cadGroup.id] }) : [],
  );
}

/**
 * @returns all the markup templates for the project
 * @param client to query the project
 * @param signal to abort the request
 */
async function fetchMarkupTemplates(
  client: ProjectApi,
  signal: AbortSignal,
): Promise<IElement[]> {
  const markupTemplatesGroup = await client.getAllIElements({
    signal,
    types: [IElementType.group],
    typeHints: [IElementTypeHint.markup],
  });
  if (markupTemplatesGroup.length === 0) return [];

  return client.getAllIElements({
    signal,
    ancestorIds: markupTemplatesGroup.map((group) => group.id),
  });
}

/**
 * Fetch all the extra data needed at app init for a specific area section
 *
 * @param dispatch function to update the application store
 * @param client to talk to the project api endpoint
 * @param signal to abort this fetch
 * @param requiredItem GUID of an element we need to have the entire sub-tree in the project at the start
 */
async function fetchInitExtraData(
  dispatch: AppDispatch,
  client: ProjectApi,
  signal: AbortSignal,
  requiredItem?: GUID,
): Promise<void> {
  // Fetch all the laserScan sections as we need them for UI validation when the viewer starts
  await dispatch(
    fetchProjectIElements({
      fetcher: () =>
        client.getAllIElements({
          signal,
          types: [IElementType.section],
          typeHints: [IElementTypeHint.dataSession],
        }),
    }),
  );

  // If we need a specific element, fetch the sub-tree containing that element
  if (!requiredItem) return;
  const response = await dispatch(
    fetchProjectIElements({
      fetcher: () =>
        client.getAllIElements({
          signal,
          descendantIds: [requiredItem],
        }),
    }),
  );

  const { payload } = response;
  if (!Array.isArray(payload)) {
    return;
  }

  const loadedSubtree = payload.filter(validateIElement);

  // If the ancestors list contains an area, load all the descendants of that area
  let areaIdToFetch = loadedSubtree.find(isIElementAreaSection)?.id;

  // If the element is in the capture tree, we need to load an area that contains it, as well as the data set
  const captureTreeDataSet = loadedSubtree.find(isIElementGenericDataset);
  let dataSetToFetch: GUID | undefined;

  if (!areaIdToFetch && captureTreeDataSet) {
    // There is no race condition here, areaIdToFetch does not change by the call to fetchAreaForCaptureTreeDataSet.
    // eslint-disable-next-line require-atomic-updates
    areaIdToFetch = await fetchAreaForCaptureTreeDataSet(
      captureTreeDataSet,
      dispatch,
      client,
      signal,
    );

    // Loading the data set makes sure that split-screen links can be validated on load
    dataSetToFetch = captureTreeDataSet.id;
  }

  const ancestorIds = [
    ...(areaIdToFetch ? [areaIdToFetch] : []),
    ...(dataSetToFetch ? [dataSetToFetch] : []),
  ];

  if (!ancestorIds.length) {
    return;
  }

  await dispatch(
    fetchProjectIElements({
      fetcher: () =>
        client.getAllIElements({
          signal,
          ancestorIds,
        }),
    }),
  );
}

/**
 * @returns The first area id that a capture tree data set is aligned to
 * @param captureTreeDataSet the data set to look up the area for
 * @param dispatch store dispatch function
 * @param client project api client to use
 * @param signal abort signal for network requests
 */
async function fetchAreaForCaptureTreeDataSet(
  captureTreeDataSet: IElementGenericDataset,
  dispatch: AppDispatch,
  client: ProjectApi,
  signal: AbortSignal,
): Promise<GUID | undefined> {
  const areas = await client.queryAreaVolumeInverse(
    captureTreeDataSet.id,
    signal,
  );

  // Use the first fitting area to show a deep-linked element in
  const areaId = areas[0]?.elementId;

  if (!areaId) {
    return;
  }

  // Also make sure the store contains the alignment edges of the area, so further logic can use them
  const dataSets = await client.queryAreaVolume(areaId, signal);
  dispatch(addAreaDataSets({ areaId, dataSets }));

  return areaId;
}

/**
 * Load the main structure of the capture tree as it will be needed to link
 * the datasets to the rest of the project
 *
 * The datasets will be loaded incrementally each time an area is loaded
 *
 * @returns the capture tree main structure without the single datasets
 * @param client to query data
 * @param signal to abort the request
 */
async function fetchCaptureTreeMainStructure(
  client: ProjectApi,
  signal: AbortSignal,
): Promise<IElement[]> {
  const captureTreeSectionRequest = await client.getAllIElements({
    signal,
    type: IElementType.section,
    typeHints: [IElementTypeHint.captureTree],
  });
  const captureTreeRoot = captureTreeSectionRequest.at(0);

  if (!captureTreeRoot?.childrenIds) {
    return [];
  }

  const captureTreeChildren = await client.getAllIElements({
    signal,
    ids: captureTreeRoot.childrenIds,
  });
  return [captureTreeRoot, ...captureTreeChildren];
}
