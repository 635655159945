import { selectSheetForElement } from "@/modes/mode-selectors";
import { selectAreaFor } from "@/store/selections-selectors";
import { RootState } from "@/store/store";
import { useAppSelector } from "@/store/store-hooks";
import { selectIElementWorldMatrix4 } from "@/utils/transform-conversion-parsed";
import {
  IElement,
  IElementGenericImgSheet,
  IElementImg360,
  IElementTypeHint,
  isIElementAreaSection,
  isIElementDataSetVideoWalk,
  isIElementGenericImgSheet,
  isIElementGroup,
  isIElementTimeTravelSection,
  isIElementTimeseries,
} from "@faro-lotv/ielement-types";
import {
  CachedWorldTransform,
  selectAncestor,
  selectChildDepthFirst,
  selectIElement,
  selectIElementWorldPosition,
  selectIElementWorldTransform,
} from "@faro-lotv/project-source";
import { isEqual } from "es-toolkit";
import { useMemo } from "react";
import {
  Matrix4,
  Matrix4Tuple,
  Quaternion,
  Vector3,
  Vector3Tuple,
} from "three";

const POSITION = new Vector3();
const QUATERNION = new Quaternion();
const SCALE = new Vector3();

/** Height offset to mimic camera height during capture */
export const PC_HEIGHT_OFFSET = 1.7;

type BaseProps = {
  /** scale that needs to be multiplied to height while updating with PC_HEIGHT_OFFSET */
  scale?: number;
};

interface PropsWithTransform extends BaseProps {
  /** transform which has to be updated with PC_HEIGHT_OFFSET */
  transform: CachedWorldTransform;
}

interface PropsWithPosition extends BaseProps {
  /** position which has to be updated with PC_HEIGHT_OFFSET */
  position: Vector3Tuple;
}

type Props = PropsWithTransform | PropsWithPosition;

function isPropsWithTransform(props: Props): props is PropsWithTransform {
  const toCheck: Partial<PropsWithTransform> = props;
  return toCheck.transform !== undefined;
}

export function computeCameraTransformOrPosForIElement(
  props: PropsWithTransform,
): CachedWorldTransform;
export function computeCameraTransformOrPosForIElement(
  props: PropsWithPosition,
): Vector3Tuple;
/**
 * @param props either the transform or position which needs to be updated with PC_HEIGHT_OFFSET
 * @returns the function to calculate camera transform or position from passed transform or position
 */
export function computeCameraTransformOrPosForIElement(
  props: Props,
): CachedWorldTransform | Vector3Tuple {
  if (isPropsWithTransform(props)) {
    const { worldMatrix, position } = props.transform;
    const matrix = new Matrix4().fromArray(worldMatrix);
    // Update the y position by adding the offset height
    matrix.setPosition(
      position[0],
      position[1] + PC_HEIGHT_OFFSET * (props.scale ?? 1),
      position[2],
    );
    matrix.decompose(POSITION, QUATERNION, SCALE);

    return {
      worldMatrixMixedHd: matrix.toArray(),
      worldMatrix: matrix.toArray(),
      position: [POSITION.x, POSITION.y, POSITION.z],
      quaternion: [QUATERNION.x, QUATERNION.y, QUATERNION.z, QUATERNION.w],
      scale: [SCALE.x, SCALE.y, SCALE.z],
    };
  }
  return [
    props.position[0],
    props.position[1] + PC_HEIGHT_OFFSET * (props.scale ?? 1),
    props.position[2],
  ];
}

/**
 * @returns the transform of an element projected onto the active floor
 * @param element the element to get the floor position of
 */
function selectIElementFloorTransform(element: IElement) {
  return (state: RootState): CachedWorldTransform => {
    const transform = selectIElementWorldTransform(element.id)(state);
    const sheetForPano = selectSheetForElement(element)(state);
    const activeArea = selectAreaFor(element)(state);
    const sheetPosition = selectIElementWorldPosition(
      sheetForPano?.id ?? activeArea?.id,
    )(state);

    return {
      ...transform,
      position: [
        transform.position[0],
        sheetPosition[1],
        transform.position[2],
      ],
    };
  };
}

/**
 * @returns the element's position projected onto its floor
 * @param element the element to get the position of
 */
export function selectIElementFloorPosition(element: IElement) {
  return (state: RootState): Vector3Tuple =>
    selectIElementFloorTransform(element)(state).position;
}

/**
 * @returns true if the pose of the Img360 node is the real camera pose during capture
 * @param element to check
 */
export function selectIsPanoCameraPoseAccurate(element: IElementImg360) {
  return (state: RootState): boolean => {
    // Pano position is not accurate if it's coming from a simple pano camera
    const isSimple360Capture = !!selectAncestor(
      element,
      (el) => isIElementGroup(el) && el.typeHint === IElementTypeHint.rooms,
    )(state);
    if (isSimple360Capture) return false;

    // Pano position is not accurate if it's coming from an un-aligned video recording
    const videoRecording = selectAncestor(
      element,
      isIElementDataSetVideoWalk,
    )(state);
    if (videoRecording) return false;

    return true;
  };
}

/**
 * @returns a good camera position to use when moving to model rendering from a 360
 * @param pano the active 360 before we move to model rendering
 * @param sheet to project 360s without a valid height
 */
export function selectPanoCameraTransform(
  pano: IElementImg360,
  sheet?: IElementGenericImgSheet,
) {
  return (state: RootState): CachedWorldTransform => {
    if (!sheet) {
      const area = selectAncestor(pano, isIElementAreaSection)(state);
      sheet = selectChildDepthFirst(area, isIElementGenericImgSheet)(state);
    }

    const panoPose = selectIElementWorldTransform(pano.id)(state);
    const sheetPosition = selectIElementWorldPosition(sheet?.id)(state);

    const hasAccuratePosition = selectIsPanoCameraPoseAccurate(pano)(state);

    const adjustedPosition: Vector3Tuple = [...panoPose.position];

    if (!hasAccuratePosition) {
      // Default: we want the camera in the 360 position, but at a fixed height from the floor
      adjustedPosition[1] = computeCameraTransformOrPosForIElement({
        position: sheetPosition,
      })[1];
    }
    // Updating the pose matrix in column-major array form with the new position
    const newWorldMatrix: Matrix4Tuple = [...panoPose.worldMatrix];
    for (let i = 0; i < 3; ++i) {
      newWorldMatrix[12 + i] = adjustedPosition[i];
    }

    return {
      ...panoPose,
      position: adjustedPosition,
      worldMatrix: newWorldMatrix,
    };
  };
}

/**
 * Memoized wrapper hook for the selectPanoCameraTransform selector
 *
 * @param pano the pano to render
 * @param sheet to project 360s without a valid height
 * @returns the position of the camera for the given pano, including a height-offset to eye-height
 */
export function usePanoCameraTransform(
  pano: IElementImg360,
  sheet?: IElementGenericImgSheet,
): CachedWorldTransform {
  return useAppSelector(selectPanoCameraTransform(pano, sheet), isEqual);
}

/**
 * PAIN POINT: 360s and annotations are not related, so if we change the 360 (like we do), the alignment breaks
 *
 * This selector computes the proper world position offset to apply to an annotation so it will appear correct in relation to its Scan
 * This is harder than it looks as the annotation is not a child of the scan itself and in the frontend we're moving the scans to make them
 * appear correctly in the 3d scene, so we need to apply the same offset movement to the annotations too
 *
 * @param pano where the annotations needs to be displayed
 * @returns an adjusted pose matrix to use to properly display those annotations
 */
export function selectPanoAnnotationsAdjustedPose(pano: IElementImg360) {
  return (state: RootState): Matrix4Tuple => {
    const panoProjectWorld = selectIElementWorldMatrix4(pano.id)(state);
    const panoObjectWorld = selectPanoCameraTransform(pano)(state);
    const adjustedHeight = selectPanoAdjustedHeight(pano)(state);
    const matrix = new Matrix4()
      .fromArray(panoObjectWorld.worldMatrix)
      .multiply(panoProjectWorld.invert());
    if (adjustedHeight) {
      matrix.elements[13] = adjustedHeight;
    }
    return new Matrix4().copyPosition(matrix).toArray();
  };
}

/**
 * PAIN POINT: 360s and annotations are not related, so if we change the 360 (like we do), the alignment breaks
 *
 * This hook wraps @see selectPanoAnnotationsAdjustedPose for compatibility with the existing code
 *
 * @param pano where the annotations needs to be displayed
 * @returns an adjusted pose matrix to use to properly display those annotations
 */
export function usePanoAnnotationsAdjustedPose(pano: IElementImg360): Matrix4 {
  const matrix = useAppSelector(
    selectPanoAnnotationsAdjustedPose(pano),
    isEqual,
  );
  return useMemo(() => new Matrix4().fromArray(matrix), [matrix]);
}

/**
 * @returns the adjusted pano height to use to properly place annotations (accounting for annotations on 360s cloned from video mode)
 * @param pano panorama element from either odometry path or a normal Img360 to get the parent section from
 */
function selectPanoAdjustedHeight(pano: IElementImg360) {
  return (state: RootState): number | undefined => {
    if (pano.typeHint === IElementTypeHint.odometryPath && pano.targetId) {
      const panoSection = selectIElement(pano.targetId)(state);

      // Once the duplicate panoSection is available, need to check if it is a child of a time series which contains a time travel section
      const timeSeries = selectAncestor(
        panoSection,
        isIElementTimeseries,
      )(state);

      const timeTravel = selectChildDepthFirst(
        timeSeries,
        isIElementTimeTravelSection,
      )(state);

      // If the time travel section is available, no need to update the y position of the pose
      // but if it is not available, the y position of the pose needs to be 1.7
      if (!timeTravel) {
        return PC_HEIGHT_OFFSET;
      }
    }
  };
}
