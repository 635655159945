import { CloudToSheetAlignmentStepNames } from "@/alignment-tool/alignment-steps/steps";
import { clearStore } from "@faro-lotv/app-component-toolbox";
import { GUID } from "@faro-lotv/ielement-types";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { uniq } from "es-toolkit";
import { Vector3Tuple, Vector4Tuple } from "three";

/** Type of the action used for loading the WebShare projects */
export const loadWebShareProjectsAction = "alignment/fetchWebShareProjects";

/**
 * Type to store the modified transform during the use of the tool
 */
export type AlignmentTransform = {
  position: Vector3Tuple;
  quaternion: Vector4Tuple;
  scale: Vector3Tuple;
};

type AlignmentState = {
  /** The step the is currently active */
  activeStep?: CloudToSheetAlignmentStepNames;

  /** All already completed steps */
  completedSteps: CloudToSheetAlignmentStepNames[];

  /**
   * The cached transform of the element wrapper group object
   * This is a global transform and hence while mutating the element to align, one must calculate this to local transform
   *
   * If not defined it means the element was never aligned and an initial coarse transformation need to be
   * computed by the app to bring it closer to the desired floor plan
   */
  elementToAlignTransform?: AlignmentTransform;

  /**
   * Id of the cloud used in cloud-to-area alignment
   */
  cloudToAlign: GUID | undefined;

  /**
   * alignment Area used in cloud-to-area alignment
   */
  alignmentArea: GUID | undefined;

  /**
   * Flag to indicate if the AlignmentTool is mounted
   * Usage of this slice is only allowed if this flag is true
   *
   * NOTE: This is a workaround in order to keep the code of the AlignmentTool and
   * the Viewer as disconnected as possible
   */
  isAlignmentToolMounted: boolean;

  /** Set to true to trigger a full page spinner like during async project mutations */
  isBusy: boolean;

  /** True to enable scale changes during the alignment */
  isScaleEnabled: boolean;

  /** True to while the user is scaling during the alignment */
  isScaling: boolean;

  /** True when the alignment has been completed successfully */
  isAlignmentCompleted: boolean;
};

/**
 * Throws if the alignment tool is not mounted
 *
 * @param state The current alignment tool state
 */
export function assertAlignmentMounted(state: AlignmentState): void {
  if (!state.isAlignmentToolMounted) {
    throw Error(
      "Usage of the alignment slice is only used from within the AlignmentTool",
    );
  }
}

/**
 * Identity transform
 *
 * Used also as default initial transformation to apply to a Webshare PointCloud to be used in the
 * alignment tool.
 *
 * As Webshare PointClouds are defined in a Z-UP coordinate system we need to rotate them
 * to an Y-UP coordinate system to then work properly in a Sphere project where the rest of
 * the data comes from the HoloBuilder ProjectAPI that assumes a Y-UP coordinate system instead
 */
export const IDENTITY: AlignmentTransform = {
  position: [0, 0, 0],
  quaternion: [0, 0, 0, 1],
  scale: [1, 1, 1],
};

export const initialState: Readonly<AlignmentState> = Object.freeze({
  activeStep: undefined,
  completedSteps: [],
  elementToAlignTransform: undefined,
  cloudToAlign: undefined,
  alignmentArea: undefined,
  isAlignmentToolMounted: false,
  isBusy: false,
  isScaleEnabled: false,
  isScaling: false,
  isAlignmentCompleted: false,
});

const alignmentSlice = createSlice({
  initialState,
  name: "alignment",

  reducers: {
    /**
     * Initialize the alignmentSlice for an alignment only workflow.
     * Keep the Project Selection step hidden and set the initial step to alignment
     *
     * @param state The current state
     */
    initAlignment(state) {
      state.isAlignmentToolMounted = true;
      state.activeStep = CloudToSheetAlignmentStepNames.alignIn2d;
    },

    /**
     * Set the alignment step
     *
     * @param state The current state
     * @param action The payload containing the alignment step name
     */
    setAlignmentStep(
      state,
      action: PayloadAction<CloudToSheetAlignmentStepNames>,
    ) {
      state.activeStep = action.payload;
    },

    /**
     * Mark the currently active step as completed/finished
     *
     * @param state The current state
     */
    completeActiveStep(state) {
      if (state.activeStep) {
        state.completedSteps = uniq([
          ...state.completedSteps,
          state.activeStep,
        ]);
      }
    },

    /**
     * Set the cached transform of the element to align
     *
     * @param state The current state
     * @param action The payload containing the updated transform
     */
    setElementToAlignTransform(
      state,
      action: PayloadAction<AlignmentTransform | undefined>,
    ) {
      state.elementToAlignTransform = action.payload;
    },

    /**
     * Sets the point cloud the user wants to align
     *
     * @param state The current state
     * @param action The new point cloud GUID
     */
    setCloudToAlign(state, action: PayloadAction<GUID | undefined>) {
      state.cloudToAlign = action.payload;
    },

    /**
     * Set the the Area/FloorPlan, which will be used to align with CAD or Point Cloud
     *
     * @param state The current state
     * @param action The GUID of selected area
     */
    setAlignmentArea(state, action: PayloadAction<GUID | undefined>) {
      state.alignmentArea = action.payload;
    },

    /**
     * Change the busy state of the alignment tool to trigger full page modal spinner
     *
     * @param state The current state
     * @param action The new isBusy state
     */
    setIsAlignmentToolBusy(state, action: PayloadAction<boolean>) {
      state.isBusy = action.payload;
    },

    /**
     * Change the enable state for allowing scale changes during the alignment
     *
     * @param state The current state
     * @param action The new isScaleEnabled state
     */
    setIsScaleEnabled(state, action: PayloadAction<boolean>) {
      state.isScaleEnabled = action.payload;
    },

    /**
     * Change the isScaling state during the alignment
     *
     * @param state The current state
     * @param action The new isScaling state
     */
    setIsScaling(state, action: PayloadAction<boolean>) {
      state.isScaling = action.payload;
    },

    /**
     * Change the isAlignmentCompleted state after alignment is finished
     *
     * @param state The current state
     * @param action The new isAlignmentCompleted state
     */
    setIsAlignmentCompleted(state, action: PayloadAction<boolean>) {
      state.isAlignmentCompleted = action.payload;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(clearStore, () => initialState);
  },
});

export const {
  initAlignment,
  completeActiveStep,
  setAlignmentStep,
  setElementToAlignTransform,
  setCloudToAlign,
  setAlignmentArea,
  setIsAlignmentToolBusy,
  setIsScaleEnabled,
  setIsScaling,
  setIsAlignmentCompleted,
} = alignmentSlice.actions;

export const alignmentReducer = alignmentSlice.reducer;
