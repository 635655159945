import { useAppDispatch, useAppSelector } from "@/store/store-hooks";
import {
  FaroSvgIcon,
  FaroTooltip,
  Toolbar,
  ToolButton,
} from "@faro-lotv/flat-ui";
import { useCallback } from "react";
import { ReactComponent as DeleteConnectionSvg } from "../icons/registration-edge-delete.svg";
import { ReactComponent as AddConnectionSvg } from "../icons/registration-edge-new.svg";
import { selectEditMode } from "../store/data-preparation-ui/data-preparation-ui-selectors";
import {
  enableDefaultEditMode,
  RegistrationEditMode,
  setEditMode,
} from "../store/data-preparation-ui/data-preparation-ui-slice";

/** @returns Toolbar where the user can select which edit mode to use. */
export function EditModeToolbar(): JSX.Element | null {
  const dispatch = useAppDispatch();
  const editMode = useAppSelector(selectEditMode);

  /** Toggle the given edit mode on or off. */
  const toggleEditMode = useCallback(
    (mode: RegistrationEditMode) => {
      if (editMode === mode) {
        // If clicking on the already enabled edit mode, switch to the default instead
        dispatch(enableDefaultEditMode());
      } else {
        dispatch(setEditMode(mode));
      }
    },
    [dispatch, editMode],
  );

  if (!editMode) {
    // Only show the toolbar if editing is enabled
    return null;
  }

  return (
    <Toolbar>
      <FaroTooltip title="Add connection" placement="left">
        <ToolButton
          onClick={() => toggleEditMode(RegistrationEditMode.addConnection)}
          selected={editMode === RegistrationEditMode.addConnection}
        >
          <FaroSvgIcon source={AddConnectionSvg} aria-label="Add connection" />
        </ToolButton>
      </FaroTooltip>

      <FaroTooltip title="Delete connection" placement="left">
        <ToolButton
          onClick={() => toggleEditMode(RegistrationEditMode.deleteConnection)}
          selected={editMode === RegistrationEditMode.deleteConnection}
        >
          <FaroSvgIcon
            source={DeleteConnectionSvg}
            aria-label="Delete connection"
          />
        </ToolButton>
      </FaroTooltip>
    </Toolbar>
  );
}
