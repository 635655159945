import { EventType } from "@/analytics/analytics-events";
import {
  getBcfIntegrationTypeForIElementType,
  getExternalAnnotationProviderName,
} from "@/components/ui/annotations/external-annotation-utils";
import { DeleteElementDialogContent } from "@/components/ui/delete-element-dialog-content";
import { useAppDispatch } from "@/store/store-hooks";
import { useDialog, useToast } from "@faro-lotv/flat-ui";
import { Analytics } from "@faro-lotv/foreign-observers";
import {
  ExternalMarkupIElement,
  IElementMarkup,
  isIElementExternalMarkup,
} from "@faro-lotv/ielement-types";
import { removeIElement } from "@faro-lotv/project-source";
import {
  ApiResponseError,
  createMutationDeleteElement,
  getMutationErrorMessages,
  useApiClientContext,
} from "@faro-lotv/service-wires";
import { useCallback, useState } from "react";

export type UseDeleteAnnotationReturn = {
  /** Function to call when the annotation needs to be removed */
  removeAnnotation(): Promise<void>;

  /** True while the mutation is in progress */
  isRemovingAnnotation: boolean;
};

/**
 * Return a function to remove an annotation and a flag to know when the project is mutating
 *
 * @param annotationToDelete of the annotation to remove
 * @returns the function to call to remove the annotation and a flag to check for project mutations
 */
export function useDeleteAnnotation(
  annotationToDelete: IElementMarkup | ExternalMarkupIElement,
): UseDeleteAnnotationReturn {
  const { projectApiClient } = useApiClientContext();
  const { createDialog } = useDialog();
  const [isRemovingAnnotation, setIsRemoving] = useState(false);
  const { openToast } = useToast();
  const dispatch = useAppDispatch();
  const externalAnnotationProviderName = isIElementExternalMarkup(
    annotationToDelete,
  )
    ? getExternalAnnotationProviderName(
        getBcfIntegrationTypeForIElementType(annotationToDelete.type),
      )
    : undefined;
  const externalAnnotationWarningMessage = `The corresponding annotation saved on ${externalAnnotationProviderName} will not be deleted.`;

  const removeAnnotation = useCallback(async () => {
    const shouldRemove = await createDialog({
      title: "Delete Element?",
      confirmText: "Delete",
      content: (
        <DeleteElementDialogContent
          name="the annotation"
          warningMessage={
            externalAnnotationProviderName
              ? externalAnnotationWarningMessage
              : undefined
          }
          dark
        />
      ),
      variant: "danger",
      dark: true,
    });

    if (!shouldRemove) {
      Analytics.track(EventType.cancelAnnotationDeletion);
      return;
    }

    Analytics.track(EventType.confirmAnnotationDeletion);

    // Preemptive hide the annotation
    setIsRemoving(true);
    try {
      await projectApiClient.applyMutations([
        createMutationDeleteElement(annotationToDelete.id),
      ]);
      dispatch(removeIElement(annotationToDelete.id));
    } catch (error) {
      const messages =
        error instanceof ApiResponseError
          ? getMutationErrorMessages(error)
          : [JSON.stringify(error)];
      openToast({
        title: "Removal Failed",
        message: (
          <>
            {messages.map((mess) => (
              <>
                {mess}
                <br />
              </>
            ))}
          </>
        ),
      });
      // Show again if removal failed
      setIsRemoving(false);
    }
  }, [
    createDialog,
    externalAnnotationProviderName,
    externalAnnotationWarningMessage,
    projectApiClient,
    annotationToDelete.id,
    dispatch,
    openToast,
  ]);

  return {
    isRemovingAnnotation,
    removeAnnotation,
  };
}
