import { OrientedBoundingBox } from "@/components/r3f/utils/oriented-bounding-box";
import { DepthBufferReader, FboContainer } from "@faro-lotv/lotv";
import { useThree } from "@react-three/fiber";
import { MutableRefObject, useCallback, useEffect, useState } from "react";
import { Vector3 } from "three";

const FRUSTUM_DEPTH_FRACTION = 0.25;
const FRUSTUM_INSIDE_BOX_FRACTION = 0.6;

type AutoClipBoxFnc = () => void;

/**
 *
 * @param fboContainerRef Reference to the object that contains the depth buffer
 * @param onClipBoxCreated Callback called when the clipping box is created
 * @returns A function to create a new clipping box in the scene, conveniently placed and sized to cover the geometry currently rendered.
 */
export function useAutoClipBox(
  fboContainerRef: MutableRefObject<FboContainer | null>,
  onClipBoxCreated: (obb: OrientedBoundingBox) => void,
): AutoClipBoxFnc {
  const camera = useThree((s) => s.camera);

  const gl = useThree((s) => s.gl);

  const [depthBufferReader] = useState(() => new DepthBufferReader());

  useEffect(() => () => depthBufferReader.dispose(), [depthBufferReader]);

  const boxFromCenterAndCamera = useCallback(
    (center: Vector3) => {
      const centerNdc = center.clone();
      centerNdc.project(camera);
      const sz = (1.0 - Math.abs(centerNdc.y)) * FRUSTUM_INSIDE_BOX_FRACTION;
      // can't use Math.sign  below because zero is an undesired value
      const sign = centerNdc.y > 0 ? 1 : -1;
      const limit = centerNdc.clone();
      limit.y += sign * sz;
      limit.unproject(camera);
      const boxSize = limit.distanceTo(center) * Math.SQRT2;

      onClipBoxCreated({
        position: [center.x, center.y, center.z],
        size: [boxSize, boxSize, boxSize],
        rotation: [0, 0, 0, 1],
      });
    },
    [camera, onClipBoxCreated],
  );

  const result = useCallback(() => {
    // Initialize the bounding box center to be at a given distance along the camera focal axis.
    const center = new Vector3(
      0,
      0,
      -(camera.near + (camera.near + camera.far) * FRUSTUM_DEPTH_FRACTION),
    );
    center.applyMatrix4(camera.matrixWorld);

    if (!fboContainerRef.current) {
      boxFromCenterAndCamera(center);
      return;
    }

    const fbo = fboContainerRef.current.offscreenFbo;

    // Create a clipping box by sampling the depth buffer
    depthBufferReader.read(fbo.depthTexture, fbo.width, fbo.height, gl);

    const W = fbo.width;
    const H = fbo.height;
    const HW = Math.floor(W / 2);
    const HH = Math.floor(H / 2);
    const ar = W / H;

    // valid depth at the screen center?
    if (depthBufferReader.depthAt(HW, HH) < 1) {
      depthBufferReader.worldCoordinatesAt(HW, HH, camera, center);
      boxFromCenterAndCamera(center);
      return;
    }

    // sample screen with progressively bigger ellipses
    let radius = 4;
    while (radius * 2 < H - 4) {
      // compute how many samples are needed to roughly have a sample each four pixels,
      // not accounting for screen aspect ratio
      const samples = Math.floor((radius * Math.PI * 2) / 4);
      const angleStep = (2 * Math.PI) / samples;
      for (let sample = 0; sample < samples; ++sample) {
        const angle = angleStep * sample;
        const x = HW + Math.round(ar * radius * Math.cos(angle));
        const y = HH + Math.round(radius * Math.sin(angle));
        if (depthBufferReader.depthAt(x, y) < 1) {
          depthBufferReader.worldCoordinatesAt(x, y, camera, center);
          boxFromCenterAndCamera(center);
          return;
        }
      }
      radius += 4;
    }

    boxFromCenterAndCamera(center);
  }, [boxFromCenterAndCamera, gl, camera, depthBufferReader, fboContainerRef]);

  return result;
}
