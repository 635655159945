import { hasMaterial } from "@faro-lotv/lotv";
import { useEffect } from "react";
import { Material, Object3D, Plane, ShaderMaterial } from "three";

/**
 * Assigns the given clipping planes to the given object's material, whenever either changes.
 * On unmount, restores the object's material to no clipping planes.
 *
 * @param object A generic object to be affected by clipping planes
 * @param clippingPlanes The planes to be assigned to the object
 */
export function useAssignClippingPlanes(
  object: Object3D | null,
  clippingPlanes: Plane[] | undefined,
): void {
  useEffect(() => {
    if (object === null || !hasMaterial(object)) return;
    const { material } = object;
    const shaderMaterial = isShaderMaterial(material);
    const oldIntersection = object.material.clipIntersection;

    const oldClippingPlanes = material.clippingPlanes;
    material.clippingPlanes = clippingPlanes ?? [];
    material.clipIntersection = false;
    let oldClipping = false;
    if (shaderMaterial) {
      oldClipping = material.clipping;
      material.clipping = !!clippingPlanes && clippingPlanes.length > 0;
    }

    return () => {
      material.clippingPlanes = oldClippingPlanes;
      material.clipIntersection = oldIntersection;
      if (shaderMaterial) material.clipping = oldClipping;
    };
  }, [clippingPlanes, object]);
}

/**
 * A type guard that determines whether a generic threejs Material is an instance of ShaderMaterial.
 * ShaderMaterials must be treated slightly differently from e.g. 'PointsMaterial',
 * since the 'clipping' property must be set to true for the clipping planes to have any effect.
 *
 * @param m the input material
 * @returns true iff the given material is an instance of ShaderMaterial or inherits from it.
 */
function isShaderMaterial(m: Material): m is ShaderMaterial {
  return m instanceof ShaderMaterial;
}
