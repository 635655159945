import { computeSplitScreenAlignment } from "@/alignment-tool/utils/compute-split-screen-alignment";
import { useActiveCameras } from "@/components/common/view-runtime-context";
import { SheetModeControls } from "@/components/r3f/controls/sheet-mode-controls";
import { PointCloudSimpleSubscene } from "@/components/r3f/effects/point-cloud-simple-subscene";
import { PointCloudRenderer } from "@/components/r3f/renderers/pointcloud-renderer";
import { SheetRenderer } from "@/components/r3f/renderers/sheet-renderer";
import {
  centerOrthoCamera,
  useCenterCameraOnPlaceholders,
} from "@/hooks/use-center-camera-on-placeholders";
import { useObjectBoundingBox } from "@/hooks/use-object-bounding-box";
import { PointCloudObject, SheetObject } from "@/object-cache";
import {
  selectAnchorPositionsForSheetToCloudAlignment,
  selectCrossSectionEnabled,
  selectSheetToCloudAlignmentSheetElevation,
} from "@/store/modes/sheet-to-cloud-alignment-mode-selectors";
import {
  setMovingElementAnchor1ForAlignment,
  setMovingElementAnchor2ForAlignment,
  setReferenceElementAnchor1ForAlignment,
  setReferenceElementAnchor2ForAlignment,
  setSheetToCloudIncrementalTransform,
} from "@/store/modes/sheet-to-cloud-alignment-mode-slice";
import { useAppDispatch, useAppSelector } from "@/store/store-hooks";
import { isObjPointCloudPoint } from "@/types/threejs-type-guards";
import {
  AnchorPairPlacement,
  CopyToScreenPass,
  DesaturatePass,
  EffectPipeline,
  EffectPipelineWithSubScenes,
  FilteredRenderPass,
  View,
  useNonExhaustiveEffect,
} from "@faro-lotv/app-component-toolbox";
import { assert } from "@faro-lotv/foundation";
import { IElementImg360 } from "@faro-lotv/ielement-types";
import { useThree } from "@react-three/fiber";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Vector3Tuple } from "three";
import {
  useCenterCameraOnBoundingBoxIn2D,
  useClippingPlanesAtCloudElevation,
  useCloudForAlignmentView,
  useNewOrthographicCamera,
} from "../alignment-modes-commons/align-to-cad-utils";
import { useOverlayElements } from "../overlay-elements-context";

type AlignSheetToCloudSceneProps = {
  /** The sheet to be aligned to the reference cloud */
  sheetObject: SheetObject;

  /** The reference cloud object for alignment */
  cloudObject: PointCloudObject;
};

/** @returns the split-screen scene for align sheet to cloud in 2d */
export function AlignSheetToCloudSplitScene({
  sheetObject,
  cloudObject,
}: AlignSheetToCloudSceneProps): JSX.Element {
  const { firstScreen, secondScreen } = useOverlayElements();
  const background = useThree((s) => s.scene.background);
  // first camera is for top or left screen, second is for bottom or right screen
  const firstCamera = useNewOrthographicCamera();
  const secondCamera = useNewOrthographicCamera();

  const sheetElevation = useAppSelector(
    selectSheetToCloudAlignmentSheetElevation,
  );

  const sheetCenteringData = useCenterCameraOnPlaceholders({
    sheetElement: sheetObject.iElement,
    placeholders: new Array<IElementImg360>(),
    viewAspectRatio: firstScreen
      ? firstScreen.clientWidth / firstScreen.clientHeight
      : 1,
  });

  const cloudBox = useObjectBoundingBox(cloudObject, cloudObject.iElement.id);
  assert(cloudBox, "unable to compute bounding box for cloud");

  const cloudCenteringData = useCenterCameraOnBoundingBoxIn2D(
    cloudBox,
    secondScreen ? secondScreen.clientWidth / secondScreen.clientHeight : 1,
  );

  useNonExhaustiveEffect(() => {
    centerOrthoCamera(firstCamera, sheetCenteringData);
    centerOrthoCamera(secondCamera, cloudCenteringData);
  }, []);

  const cameras = useMemo(
    () => [firstCamera, secondCamera],
    [firstCamera, secondCamera],
  );

  useActiveCameras(cameras);

  const isCrossSectionEnabled = useAppSelector(selectCrossSectionEnabled);
  const clippingPlanes = useClippingPlanesAtCloudElevation(
    sheetElevation,
    isCrossSectionEnabled,
  );

  useCloudForAlignmentView(cloudObject, clippingPlanes);

  const [controlsEnabled, setControlsEnabled] = useState(true);

  const dispatch = useAppDispatch();
  const alignmentAnchorPositions = useAppSelector(
    selectAnchorPositionsForSheetToCloudAlignment,
  );

  const changeSheetAnchor1Position = useCallback(
    (position: Vector3Tuple) => {
      dispatch(setMovingElementAnchor1ForAlignment(position));
    },
    [dispatch],
  );
  const changeSheetAnchor2Position = useCallback(
    (position: Vector3Tuple) => {
      dispatch(setMovingElementAnchor2ForAlignment(position));
    },
    [dispatch],
  );
  const changeCloudAnchor1Position = useCallback(
    (position: Vector3Tuple) => {
      dispatch(setReferenceElementAnchor1ForAlignment(position));
    },
    [dispatch],
  );
  const changeCloudAnchor2Position = useCallback(
    (position: Vector3Tuple) => {
      dispatch(setReferenceElementAnchor2ForAlignment(position));
    },
    [dispatch],
  );

  // compute split screen alignment when there is change in anchor positions
  useEffect(() => {
    const alignmentTransform = computeSplitScreenAlignment(
      alignmentAnchorPositions,
      true,
    );
    if (alignmentTransform) {
      dispatch(setSheetToCloudIncrementalTransform(alignmentTransform));
    }
  }, [alignmentAnchorPositions, dispatch]);

  return (
    <>
      <View
        camera={firstCamera}
        trackingElement={firstScreen}
        background={background}
        hasSeparateScene
      >
        <AnchorPairPlacement
          onPointerDown={() => setControlsEnabled(false)}
          onPointerUp={() => setControlsEnabled(true)}
          anchor1Position={alignmentAnchorPositions.movingElementAnchor1}
          anchor2Position={alignmentAnchorPositions.movingElementAnchor2}
          changeAnchor1Position={changeSheetAnchor1Position}
          changeAnchor2Position={changeSheetAnchor2Position}
        >
          <SheetRenderer sheet={sheetObject} />
        </AnchorPairPlacement>
        <EffectPipeline>
          <FilteredRenderPass
            filter={(o) => o.name === sheetObject.iElement.id}
          />
          <DesaturatePass />
          <FilteredRenderPass
            filter={(o) => o.name !== sheetObject.iElement.id}
            clear={false}
            clearDepth={false}
          />
          <CopyToScreenPass />
        </EffectPipeline>
        <SheetModeControls
          camera={firstCamera}
          referencePlaneHeight={sheetElevation}
          enabled={controlsEnabled}
        />
      </View>
      <View
        camera={secondCamera}
        trackingElement={secondScreen}
        background={background}
        hasSeparateScene
      >
        <ambientLight intensity={1.0} />
        <AnchorPairPlacement
          onPointerDown={() => setControlsEnabled(false)}
          onPointerUp={() => setControlsEnabled(true)}
          anchor1Position={alignmentAnchorPositions.referenceElementAnchor1}
          anchor2Position={alignmentAnchorPositions.referenceElementAnchor2}
          changeAnchor1Position={changeCloudAnchor1Position}
          changeAnchor2Position={changeCloudAnchor2Position}
        >
          <PointCloudRenderer pointCloud={cloudObject} />
        </AnchorPairPlacement>
        <EffectPipelineWithSubScenes>
          <PointCloudSimpleSubscene pointCloud={cloudObject} />
          <FilteredRenderPass
            filter={(o) => !isObjPointCloudPoint(o)}
            clear={false}
            clearDepth={false}
          />
          <CopyToScreenPass />
        </EffectPipelineWithSubScenes>
        <SheetModeControls
          camera={secondCamera}
          referencePlaneHeight={sheetElevation}
          enabled={controlsEnabled}
        />
      </View>
    </>
  );
}
