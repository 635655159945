// Reference document for all the events in viewer - https://faro01.atlassian.net/wiki/spaces/AN/pages/3378971889/Sphere+Viewer+Amplitude+Events

import { PointCloudRenderingProfile } from "@/components/common/rendering-settings-context";
import { ResponsiveCardVariant } from "@/components/ui/annotation-panel/annotation-list-card/responsive-card-variant";
import { AnnotationSortingOptions } from "@/components/ui/annotation-panel/sort-menu-button";
import { QualityStatus } from "@/registration-tools/utils/metrics";
import { MeasurementUnits } from "@faro-lotv/app-component-toolbox";
import { IElementType, IElementTypeHint } from "@faro-lotv/ielement-types";
import { PointCloudType } from "@faro-lotv/project-source";
import { ProjectAccessLevel } from "@faro-lotv/service-wires";

export enum EventType {
  openProject = "open project",
  openSheetView = "open sheet view",
  openModel3dView = "open 3dview",
  selectViaProjectOverview = "select via project overview",
  selectLocation = "select location",
  selectCapture = "select capture",
  inspectCaptureLocation = "inspect capture location",
  turnSplitscreenOn = "turn splitscreen on",
  turnSplitscreenOff = "turn splitscreen off",
  turnSplitscreenLockOn = "turn splitscreen lock on",
  turnSplitscreenLockOff = "turn splitscreen lock off",
  turnDesaturationOn = "turn desaturation on",
  turnDesaturationOff = "turn desaturation off",
  switchPointCloudStream = "switch point cloud stream",

  // Data Organization events
  editElement = "edit element",
  moveDataSetToSheet = "move data set to sheet",

  // Annotation events
  hoverAnnotationTitle = "hover annotation title",
  expandAnnotationDetails = "expand annotation details",
  closeAnnotationDetails = "close annotation details",
  createAnnotationShareLink = "create annotation share link",
  gotoAnnotation = "goto annotation",
  openAnnotationDetailsContextMenu = "open annotation details context menu",
  editAnnotation = "edit annotation",
  deleteAnnotation = "delete annotation",
  confirmAnnotationDeletion = "confirm annotation deletion",
  cancelAnnotationDeletion = "cancel annotation deletion",
  triggerAnnotationCreationTool = "trigger annotation creation tool",
  createAnnotation = "create annotation",
  cancelAnnotationCreation = "cancel annotation creation",
  cancelAnnotationEditing = "cancel annotation editing",
  saveAnnotation = "save annotation",
  openAnnotationList = "open annotation list",
  filterAnnotationList = "filter annotation list",
  sortAnnotationList = "sort annotation list",
  changeAnnotationCardLayout = "change annotation card layout",

  // Measurement events
  enableMeasurementMode = "enable measurement mode",
  disableMeasurementMode = "disable measurement mode",
  completeMeasurement = "complete measurement",
  deleteMeasurement = "delete measurement",
  selectMeasurement = "select measurement",
  copyMeasurementToClipboard = "copy measurement to clipboard",
  toggleUnitOfMeasure = "toggle unit of measure",

  // PointCloud Management events
  selectPointCloudFile = "select point-cloud file",
  uploadPointCloud = "upload point-cloud",
  cancelUploadViaTaskBar = "cancel upload via task-bar",
  cancelUploadViaProjectTree = "cancel upload via project-tree",
  downloadPointCloud = "download point-cloud",
  alignPointCloud = "align point-cloud",
  deletePointCloud = "delete point-cloud",
  closePageWhileUploading = "close page while uploading",
  closePageWhileBackendProcessing = "close page while backend processing",
  openProgressOverview = "open progress-overview",

  // Export Tool events
  startExportTool = "start export tool",
  exitExportTool = "exit export tool",
  exportPointCloud = "export point-cloud",
  exportAutoClip = "export tool autoclipping",
  exportResetClip = "export tool reset clipping",

  // PointCloud Export (to be removed in https://faro01.atlassian.net/browse/SWEB-5550)
  startPointCloudExport = "start point-cloud export",
  cancelPointCloudExport = "cancel point-cloud export",

  // Orthophoto Export
  // https://faro01.atlassian.net/wiki/spaces/AN/pages/4193648670/Export+Tool+Events
  generateOrthophoto = "generate orthophoto",
  cancelOrthophoto = "cancel orthophoto",

  // Create Area and Volume events
  startCreateAreaTool = "start create area tool",
  createNewArea = "create new area",

  // CreateArea events
  setAreaName = "set area name",
  setFloorImage = "set floor-image",
  changeFloorImage = "change floor-image",

  // SetScale events
  openAreaScaleTool = "open area-scale tool",
  cancelAreaScaleWarning = "cancel area-scale warning",
  confirmAreaScaleWarning = "confirm area-scale warning",
  adjustAreaScaleLine = "adjust area-scale line",
  removeAreaScaleLine = "remove area-scale line",
  editAreaScaleValue = "edit area-scale value",
  setAreaScaleValue = "set area-scale value",
  setAreaScaleUnit = "set area-scale unit",
  applyAreaScale = "apply area-scale",
  discardAreaScale = "discard area-scale",

  // DeepLink events
  copyDeepLink = "copy deep-link",
  closeDeepLink = "close deep-link",

  // Pairwise Registration events
  startPairwiseAutomaticRegistrationTask = "start pairwise automatic registration task",
  cancelPairwiseAutomaticRegistrationTask = "cancel pairwise automatic registration task",
  startPairwiseRegistrationWorkflow = "start pairwise registration workflow",
  abortPairwiseRegistrationWorkflow = "abort pairwise registration workflow",
  finishPairwiseRegistrationWorkflow = "finish pairwise registration workflow",
  showPairwiseRegistrationReport = "show pairwise registration report",
  resetPairwiseRegistrationCamera = "reset pairwise registration camera",
  resetPairwiseRegistrationTransformation = "reset pairwise registration transformation",
  changePairwiseRegistrationView = "change pairwise registration view",

  // Attachments events
  addAnnotationAttachments = "add annotation attachment",
  openAnnotationAttachment = "open annotation attachment",
  deleteAnnotationAttachment = "delete annotation attachment",
  confirmDeleteAnnotationAttachment = "confirm annotation attachment deletion",
  cancelDeleteAnnotationAttachment = "cancel annotation attachment deletion",

  // Viewing Settings - Visibility Settings Menu
  toggleViewSettingsMenu = "toggle view settings menu",
  toggleWaypointsVisibility = "toggle waypoints visibility",
  toggleWaypointLabelsVisibility = "toggle waypoint labels visibility",
  toggleAnnotationsVisibility = "toggle annotations visibility",
  toggleMeasurementsVisibility = "toggle measurements visibility",
  toggleTrajectoriesVisibility = "toggle trajectories visibility",
  toggleAnalysesVisibility = "toggle analyses visibility",
  changeObjectVisibility = "change object visibility",
  openObjectVisibilityHelp = "open object visibility help",
  toggleWaypointColoring = "toggle waypoint coloring",
  toggleWaypointHeight = "toggle waypoint height",
  setPointCloudRenderingProfile = "set point cloud rendering profile",

  // Viewing Settings - Transparency/opacity sliders
  toggleOpacityPanel = "toggle opacity panel",
  changeMapOpacity = "change map opacity",
  changeCadOpacity = "change cad opacity",
  changePointcloudOpacity = "change pointcloud opacity",

  // VideoMode path correction events
  startVideoModeEditor = "start videomode editor",
  selectVideomodeEditorAlignmentTool = "select videomode editor alignment tool",
  selectVideoModeEditorTrajectoryTool = "select videomode editor trajectory tool",
  undoVideoModeEditorChanges = "undo videomode editor changes",
  redoVideoModeEditorChanges = "redo videomode editor changes",
  resetVideoModeEditorChanges = "reset videomode editor changes",
  saveVideoModeEditorChanges = "save videomode editor changes",
  discardVideoModeEditorChanges = "discard videomode editor changes",

  // Clipping box events
  showClippingBox = "show clipping box",
  hideClippingBox = "hide clipping box",
  editClippingBox = "edit clipping box",
  applyAutoClippingBox = "apply auto clipping box",
  resetClippingBox = "reset clipping box",

  // Tag management events
  openTagManagement = "open tag management",
  createNewTag = "create new tag",
  addTag = "add tag",
  removeTag = "remove tag",

  // Tag filtering
  toggleTagFilterMenu = "toggle tag filter menu",
  toggleTag = "toggle tag",
  clearSelectedTags = "clear selected tags",

  // Multi-layer menu
  toggleMultiLayerMenu = "toggle multi-layer menu",
  changeActiveLayer = "change active layer",

  // Colored cloud analysis events
  // https://faro01.atlassian.net/wiki/spaces/AN/pages/4317839382/Colored+cloud+analysis+events
  openColoredAnalysisTool = "open colored analysis tool",
  closeColoredAnalysisTool = "close colored analysis tool",
  closeColoredAnalysisLoop = "close colored analysis loop",
  openColoredAnalysisSubmenu = "open colored analysis submenu",
  hideColoredAnalysis = "hide colored analysis",
  showColoredAnalysis = "show colored analysis",
  saveColoredAnalysis = "save colored analysis",
  deleteColoredAnalysis = "delete colored analysis",
  generateColoredAnalysisDeeplink = "generate colored analysis deeplink",
  selectColoredAnalysisColorScale = "select colored analysis color scale",
  selectColoredAnalysisTolerance = "select colored analysis tolerance",
  selectColoredAnalysisRange = "select colored analysis range",
  toggleVisibilityOfColoredAnalysisReferencePlane = "toggle visibility of colored analysis reference plane",
  selectColoredAnalysisPlaneFitMethod = "select colored analysis plane fit method",
  openColoredAnalysisLabelCreationMode = "open colored analysis label creation mode",
  createColoredAnalysisLabel = "create colored analysis label",
  changeColoredAnalysisUnits = "change colored analysis units",
}

/**
 * Properties of openModel3dView which tracks the type in which the user is viewing the models
 */
export enum OpenModel3dViewProperties {
  scan = "scan",
  design = "design",
  pointcloud = "pointcloud",
}

/**
 * Properties of selectViaProjectOverview which tracks the item that has been clicked from the project overview
 */
export enum SelectViaProjectOverviewProperties {
  sheet = "sheet",
  location = "location",
  capture = "capture",
}

/**
 * Properties of location selection tracking which contains the information on where the event originated from
 */
export enum SelectLocationProperties {
  minimap = "minimap",
  sheetView = "sheetView",
  panoView = "panoView",
  projectView = "projectView",
  model3dView = "3dView",
}

/**
 * Properties of select capture tracking which contains the information on where the event originated from
 * i.e from where the 360 image or a capture was selected
 */
export enum SelectCaptureProperties {
  projectOverview = "projectOverview",
  dropdown = "dropdown",
  splitscreen = "splitscreen",
}

/**
 * Properties of inspectCaptureLocation which tracks the type in which the user is viewing the 360s
 */
export enum InspectCaptureLocationProperties {
  panoView = "panoView",
  pointcloud = "pointcloud",
}

/**
 * Properties for the openProject event
 */
export type OpenProjectEventProperties = {
  /** Indicator if the project was empty when opened */
  isEmpty: boolean;

  /** What mode is being open if a custom deep link is used */
  mode: string | "default";
};

/**
 * Properties for the selectPointCloudFile event
 */
export type SelectPointCloudFileEventProperties = {
  /** Size of the file in bytes */
  fileSize: number;

  /** File extension */
  extension: string;
};

/**
 * Properties for the uploadPointCloud event
 */
export type UploadPointCloudEventProperties =
  SelectPointCloudFileEventProperties & {
    /** Number of Point Clouds already existing on the floor */
    numberOfExistingPCs: number;

    /** Indicator if the user changed the name of the PC before upload */
    nameChanged: boolean;

    /** Indicator if the user changed the time point of the PC before upload */
    timePointChanged: boolean;

    /** Indicator if the user changed the preselected floor of the PC before upload */
    floorChanged: boolean;
  };

/**
 * Properties for the alignPointCloud event
 */
export type AlignPointCloudEventProperties = {
  /** Which UI was used to start the alignment tool */
  via: "context menu" | "align button";

  /** Cumber of PCs that are already aligned to the floor */
  numberOfAlignedPCs: number;

  /** Indicating if the user re-aligns a PC */
  alreadyAligned: boolean;
};

/**
 * Properties for the createArea event
 */
export type CreateAreaEventProperties = {
  /** Which sheet image was chosen to upload */
  via: "default-grid" | "custom-image";

  /** File extension */
  extension: string;
};

/** Properties for the setAreaScaleUnit event */
export type SetAreaScaleUnitProperties = {
  /** The measurement unit selected by the user */
  unit: MeasurementUnits;
};

/** Properties to capture for deep link events */
export type DeepLinkProperties = {
  /** Name of the mode the deep link will target */
  mode: string;

  /** Access level of the linked project  */
  access: ProjectAccessLevel;
};

/** Properties for the switchPointCloudStream event */
export type SwitchPointCloudStreamEventProperties = {
  /** The type of the selected point cloud stream */
  pointCloudType: PointCloudType;
};

/** Properties for the showPairwiseRegistrationReport event */
export type ShowPairwiseRegistrationReportEventProperties = {
  /** The quality of the registration as displayed in the report */
  registrationQuality: QualityStatus;
};

/** Properties for the resetPairwiseRegistrationTransformation event */
export type ResetPairwiseRegistrationTransformationEventProperties = {
  /** If the point cloud was moved before / did the reset actually move the point cloud? */
  movedBefore: boolean;
};

/** Properties for the createAnnotationShareLink event */
export type CreateAnnotationShareLinkEventProperties = {
  /** Which annotation view variant the share link was created from */
  via: "collapsed" | "title" | "small" | "full" | "annotation list";
};

/** Properties for the createAnnotation event */
export type CreateAnnotationEventProperties = {
  /** Which shape the created annotation has */
  shape: "point" | "rectangle" | "measurement";
};

/** Properties for the cancelAnnotationCreation event */
export type CancelAnnotationCreationEventProperties = {
  /** How the creation of an annotation was cancelled */
  via: "dialog" | "escape key";
};

/** Properties for the saveAnnotation event */
export type SaveAnnotationEventProperties = {
  /** Whether this annotation is new or not */
  isNew: boolean;

  /** Whether this annotation has been assigned */
  assigneeSet: boolean;

  /** Whether this annotation has been assigned to the current user */
  assignToCurrentUser: boolean;

  /** Whether this annotation has a status */
  statusSet: boolean;

  /** Whether this annotation has a due date */
  dateSet: boolean;

  /** Whether this annotation has a description */
  descriptionSet: boolean;

  /** The number of new attachments when saving the annotation */
  numberOfAttachmentsAdded: number;

  /** Number of tags added or created with the annotation */
  tagsAdded: number;

  /** Number of tags removed from the annotation */
  tagsRemoved: number;
};

/** Properties for the filterAnnotationList event */
export type FilterAnnotationListEventProperties = {
  /** true when the user used the text field */
  hasSearchFilter: boolean;

  /** true when the user selected at least one user to filter by */
  hasUserFilter: boolean;

  /** true when the user selected at least one tag to filter by */
  hasTagsFilter: boolean;

  /** true when the user selected at least one status to filter by */
  hasStatusFilter: boolean;

  /** true when the user selected a time range to filter by */
  hasDueDateFilter: boolean;
};

/** Properties for the sortAnnotationList event */
export type SortAnnotationListEventProperties = {
  /** the sorting method used */
  sortBy: AnnotationSortingOptions;
};

/** Properties for the changeAnnotationCardLayout event */
export type ChangeAnnotationCardLayoutEventProperties = {
  /** the variant used for the layout */
  variant: ResponsiveCardVariant;
};

/** Properties for the completeMeasurement event */
export type CompleteMeasurementEventProperties = {
  /** Whether the created measurement is a closed loop */
  isClosed: boolean;

  /** Number of points used for this measurement */
  numberOfPoints: number;
};

/** Properties for the copyMeasurementToClipboard event */
export type CopyMeasurementToClipboardEventProperties = {
  /** Which UI was used for copying the measurements */
  via: "action bar" | "annotation dialog";
};

/** Enumerates lost of sources from where measurement units can be changed */
export enum ToggleUnitOfMeasureActionSource {
  measureToolbar = "measure toolbar",
  colorAnalysisToolbar = "color analysis toolbar",
}

/** Properties for the toggleUnitOfMeasure event */
export type ToggleUnitOfMeasureEventProperties = {
  /** Which unit system the user switched to */
  newValue: "imperial" | "metric";

  /** reports from where units were changed */
  via: ToggleUnitOfMeasureActionSource;
};

/** Properties for the closeColoredAnalysisLoop event */
export type CloseColoredAnalysisLoopEventProperties = {
  /** Whether the created analysis polygon was closed by click on first point */
  isCompletedByClickFirstPoint: boolean;

  /** Number of points in analysis polygon */
  numberOfPoints: number;
};

/** Properties for the openColoredAnalysisSubmenu event */
export type OpenColoredAnalysisSubmenuEventProperties = {
  /** Which submenu was open */
  newValue: "change color scale" | "change reference plane";
};

/** Properties for the selectColoredAnalysisColorScale event */
export type SelectColoredAnalysisColorScaleEventProperties = {
  /** Which color scale the user switched to */
  newValue: string;
};

/** Properties for the selectColoredAnalysisPlaneFitMethod event */
export type SelectColoredAnalysisPlaneFitMethodEventProperties = {
  /** Which fit method the user switched to */
  newValue: string;
};

/** Properties for the selectColoredAnalysisTolerance event */
export type SelectColoredAnalysisToleranceEventProperties = {
  /** Which tolerance user set */
  newTolerance: number;
};

/** Properties for the selectColoredAnalysisRange event */
export type SelectColoredAnalysisRangeEventProperties = {
  /** Range distance set by the user */
  newRange: number;
};

/** Properties for the toggleAnalysisReferencePlaneVisibility event */
export type ToggleAnalysisReferencePlaneVisibilityProperties = {
  /** The visibility of the object after switching */
  visible: boolean;
};

/** Properties for the openAnnotationDetailsContextMenu event */
export type OpenAnnotationDetailsContextMenuEventProperties = {
  /** Which annotation view variant the context menu was opened in */
  via: "hidden" | "collapsed" | "title" | "small" | "full" | "annotation list";
};

/** Properties for the editAnnotation event */
export type EditAnnotationEventProperties = {
  via: "3d scene" | "annotation list";
};

/** Properties for the deleteAnnotation event */
export type DeleteAnnotationEventProperties = {
  via: "3d scene" | "annotation list";
};

/** Properties for the deleteMeasurement event */
export type DeleteMeasurementEventProperties = {
  /** How the measurement was deleted */
  via: "action bar" | "delete key";
};

/** Properties for the addAnnotationAttachments event*/
export type AddAnnotationAttachmentsEventProperties = {
  /** Whether the file was added by dragging and dropping  */
  viaDrag: boolean;
  /** The attachment file type */
  fileType: string;
};

/** Properties for the openAnnotationAttachment event*/
export type OpenAnnotationAttachmentEventProperties = {
  /** The attachment file type */
  fileType: string;
};

/** Properties for the deleteAnnotationAttachment event*/
export type DeleteAnnotationAttachmentEventProperties = {
  /** The attachment file type */
  fileType: string;
  /* Whether the attachment is a new one */
  isNewAttachment: boolean;
};

/** Properties for the toggleViewSettingsMenu event */
export type ToggleViewSettingsMenuProperties = {
  /** True if the user is opening the menu */
  opening: boolean;
};

/** Properties for the toggleWaypointVisibility event */
export type ToggleWaypointsVisibilityProperties = {
  /** The visibility of the object after switching */
  visible: boolean;
};

/** Properties for the toggleAnnotationsVisibility event */
export type ToggleAnnotationsVisibilityProperties =
  ToggleWaypointsVisibilityProperties;

/** Properties for the toggleMeasurementsVisibility event */
export type ToggleMeasurementsVisibilityProperties =
  ToggleWaypointsVisibilityProperties;

/** Properties for the toggleTrajectoriesVisibility event */
export type ToggleTrajectoriesVisibilityProperties =
  ToggleWaypointsVisibilityProperties;

/** Properties for the changeObjectVisibility event */
export type ChangeObjectVisibilityProperties = {
  /** The object visibility value after switching */
  value: "nearby" | "all";
};

/** Properties for the toggleOpacityPanel event*/
export type ToggleOpacityPanelEventProperties = {
  /** The value of the map opacity when the panel is toggled */
  currentMapOpacity: number;

  /** The value of the CAD opacity when the panel is toggled */
  currentCadOpacity: number;

  /** The value of the point cloud opacity when the panel is toggled */
  currentPointCloudOpacity: number;

  /** True if the panel has been opened on toggle */
  isOpened: boolean;
};

/** Properties for the setPointCloudRenderingProfile event */
export type SetPointCloudRenderingProfileProperties = {
  /** The point cloud rendering profile just set */
  value: PointCloudRenderingProfile;
};

type ChangeOpacityEventProperties = {
  /** Value of the opacity when the user releases the slider */
  newValue: number;

  /** How long the user has spent dragging the slider, in seconds */
  timeSpent: number;
};

/** Properties for the changeMapOpacity event*/
export type ChangeMapOpacityEventProperties = ChangeOpacityEventProperties;

/** Properties for the changeCadOpacity event*/
export type ChangeCadOpacityEventProperties = ChangeOpacityEventProperties;

/** Properties for the changePointcloudOpacity event*/
export type ChangePointcloudOpacityEventProperties =
  ChangeOpacityEventProperties;

/** Properties for the openTagManagement event */
export type OpenTagManagementProperties = {
  /** Where the tag management dialog has been opened from */
  via: "project overview" | "annotation";
};

/** Properties for the addTag event */
export type AddTagProperties = {
  /** Number of tags added */
  tagsAdded: number;

  /** What type of object a tag has been added to */
  target: "annotation";
};

/** Properties for the removeTag event */
export type RemoveTagProperties = {
  /** Number of tags removed */
  tagsRemoved: number;

  /** What type of object a tag has been removed from */
  target: "annotation";
};

/** Properties for the toggleTagFilterMenu event */
export type ToggleTagFilterMenuProperties = {
  /** True if the user is opening the menu */
  opening: boolean;
};

/** Properties for the toggleTag event */
export type ToggleTagProperties = {
  /** True if the user has enabled the tag */
  enabling: boolean;

  /** Number of now selected tags */
  numberOfSelectedTags: number;
};

/** Properties for the toggleMultiLayerMenu event */
export type ToggleMultiLayerMenuProperties = {
  /** True if the user is opening the menu */
  opening: boolean;
};

/** Properties for the changeActiveLayer event */
export type ChangeActiveLayerProperties = {
  /** Type of the layer the user sets as active */
  type: string;
};

/** Properties for the toggleWaypointColoring event */
export type ToggleWaypointColoringProperties = {
  /** True if the user has enabled the waypoint coloring */
  enabling: boolean;
};

/** Properties for the toggleWaypointHeight event */
export type ToggleWaypointHeightProperties = {
  /** True if the user has enabled the option to show waypoints on the floor */
  enabling: boolean;
};

export type EditElementProperties = {
  /** Type of the element that was edited */
  elementType: `${IElementType}` | string;

  /** Type hint of the element that was edited */
  elementTypeHint?: `${IElementTypeHint}` | string;

  /** Whether the user has edited the name of the element */
  hasEditedName: boolean;

  /** Whether the user has edited the time point of the element */
  hasEditedTimePoint: boolean;
};

/** Properties for the moveDataSetToSheet event */
export type MoveDataSetToSheetProperties = {
  /** Type of the element that was moved */
  elementType: `${IElementType}` | string;

  /** Type hint of the element that was moved */
  elementTypeHint?: `${IElementTypeHint}` | string;
};

/** Properties for the exportPointCloud event */
export type ExportPointCloudProperties = {
  /** Whether the user has set a custom name for the exported file */
  hasEditedName: boolean;

  /** The point cloud format for the export (Eg. cpe, e57) [as they depend on the backend we do not have a fixed list in the client] */
  format: string;

  /** The desired custom point cloud density if user defined (expressed as the min distance in meters between points) */
  density?: number;
};

/** Properties for the createNewArea event */
export type CreateAreaToolProperties = {
  /** Whether the user has edited the name of the area to create */
  hasEditedName: boolean;

  /** The value of the create another area check box */
  createAnotherArea: boolean;
};

/** Properties for the gotoAnnotation event */
export type GotoAnnotationProperties = {
  /** From what UI component the goto action was triggered */
  via: "3d scene" | "annotation list";
};

/** Properties for the  exportAutoClip and exportResetClip events */
export type ExportToolClippingProperties = {
  /** From what type of exports these events were triggered */
  via: "point cloud" | "orthophoto";
};
