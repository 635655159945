import { RuntimeConfig } from "@/runtime-config";
import { RootState } from "@/store/store";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { uniqWith } from "es-toolkit";
import { FeatureFlag, previewFeatures } from "./features";
export { Features } from "./features";

/**
 * A feature flag with an optional value.
 */
type EnabledFeature = {
  /** FeatureFlag of the feature being enabled. */
  featureFlag: FeatureFlag;
  /** Optional value associated with the feature. */
  value: string | null;
};

type FeaturesState = {
  /**
   * List of all the enabled features with optional value.
   */
  enabledFeatures: EnabledFeature[];
};

const initialState: Readonly<FeaturesState> = Object.freeze({
  enabledFeatures: [],
});

const featuresSlice = createSlice({
  initialState,
  name: "features",
  reducers: {
    /**
     * Initialize the list of enabled features from the current runtime config
     *
     * @param state of this slice
     * @param action with the runtimeConfig as a payload
     */
    initFeatures(
      state,
      action: PayloadAction<Pick<RuntimeConfig, "enablePreviewFeatures">>,
    ) {
      if (action.payload.enablePreviewFeatures) {
        // convert previewFeatures() to an array of EnabledFeature
        const featuresWithValue = previewFeatures().map((featureFlag) => ({
          featureFlag,
          value: null,
        }));
        // Making sure that features are not added multiple times
        state.enabledFeatures = uniqWith(
          [...state.enabledFeatures, ...featuresWithValue],
          (a, b) => a.featureFlag === b.featureFlag,
        );
      } else {
        state.enabledFeatures.splice(0);
      }
    },

    /**
     * Enable a feature with associated optional value.
     * If already enabled, change the associated value.
     *
     * @param state of this slice
     * @param action what feature to toggle
     */
    enableFeature(state, action: PayloadAction<EnabledFeature>) {
      // search in already enabled features
      const existingFeature = state.enabledFeatures.find(
        (feature) => feature.featureFlag === action.payload.featureFlag,
      );
      if (existingFeature !== undefined) {
        // update already enabled feature
        existingFeature.value = action.payload.value;
        return;
      }
      // not found = add this new feature
      state.enabledFeatures.push(action.payload);
    },
  },
});

export const { initFeatures, enableFeature } = featuresSlice.actions;

export const featuresReducer = featuresSlice.reducer;

/**
 * @param feature the feature to test
 * @returns true if the feature is enabled
 */
export function selectHasFeature(feature: FeatureFlag) {
  return (state: RootState): boolean =>
    state.features.enabledFeatures.some(
      (enabledFeature) => enabledFeature.featureFlag === feature,
    );
}

/**
 * @param feature the feature to test
 * @returns the optional value (string or null) associated with the feature if enabled;
 * undefined if the feature is not enabled
 */
export function selectGetFeatureValueIfEnabled(feature: FeatureFlag) {
  return (state: RootState) =>
    state.features.enabledFeatures.find(
      (oneFeature) => oneFeature.featureFlag === feature,
    )?.value;
}

/**
 * @param state the root application state
 * @returns true if any preview feature is enabled
 */
export function selectHasAnyFeatureEnabled(state: RootState): boolean {
  return state.features.enabledFeatures.length > 0;
}
