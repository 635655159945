import { useCached3DObjectIfReady } from "@/object-cache";
import { isPanoObject } from "@/object-cache-type-guard";
import { useAppSelector } from "@/store/store-hooks";
import { useEffect, useMemo, useState } from "react";
import { selectIsPanoWithDepth } from "./walk-scene-selectors";
import { WalkSceneActiveElement } from "./walk-types";

type IsPanoWithDepthAndReady = {
  isPanoWithDepth: boolean;
  isDepthReady: boolean;
};

/**
 * @returns A object sepcifying if the element is a pano with depth and if the depth is ready
 * @param element The element that must be checked
 */
export function useIsPanoWithDepthAndReady(
  element: WalkSceneActiveElement | undefined,
): IsPanoWithDepthAndReady {
  const isPanoWithDepth = useAppSelector(selectIsPanoWithDepth(element));
  const panoObject = useCached3DObjectIfReady(element);
  const [isDepthReady, setIsDepthReady] = useState(false);

  useEffect(() => {
    if (
      panoObject instanceof Error ||
      !isPanoObject(panoObject) ||
      !isPanoWithDepth
    ) {
      return;
    }
    if (panoObject.depths) {
      setIsDepthReady(true);
      return;
    }

    setIsDepthReady(false);
    const event = panoObject.depthsChanged.on(() => {
      setIsDepthReady(true);
    });
    return () => {
      event.dispose();
    };
  }, [isPanoWithDepth, panoObject]);

  return useMemo(
    () => ({
      isPanoWithDepth,
      isDepthReady,
    }),
    [isPanoWithDepth, isDepthReady],
  );
}
