import { Features, selectHasFeature } from "@/store/features/features-slice";
import { useAppSelector } from "@/store/store-hooks";
import { selectHasWritePermission } from "@/store/user-selectors";
import { useLocalStorage } from "@faro-lotv/app-component-toolbox";
import {
  FaroPopover,
  FaroPopoverProps,
  ToolGroup,
  Toolbar,
} from "@faro-lotv/flat-ui";
import { keyframes } from "@mui/material";
import { useState } from "react";
import { NewFeatureBadge } from "./canvas-toolbar";
import { MultiLayerTool } from "./multi-layer-tool";
import { TagsFilterTool } from "./tags-filter-tool";
import { ViewOptionsTool } from "./view-options-tool";

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

/** @returns a toolbar that contains the tools to manage view settings */
export function ViewSettingsToolbar(): JSX.Element | null {
  const [toolbarRef, setToolbarRef] = useState<HTMLDivElement | null>(null);
  const [buttonRef, setButtonRef] = useState<HTMLButtonElement | null>(null);

  const [isNewFeatureAddLayerToArea, setIsNewFeatureAddLayerToArea] =
    useLocalStorage("newFeatureAddLayerToArea", true);

  const [isMultiLayerMenuOpen, setIsMultiLayerMenuOpen] = useState(false);
  const canAddLayer = useAppSelector(selectHasFeature(Features.AlignLayer));
  const hasWritePermission = useAppSelector(selectHasWritePermission);

  return (
    <Toolbar
      ref={setToolbarRef}
      sx={{ alignSelf: "flex-end", overflow: "visible" }}
    >
      <ToolGroup>
        <ViewOptionsTool anchorEl={toolbarRef} />
        <TagsFilterTool anchorEl={toolbarRef} />
        <MultiLayerTool
          anchorEl={toolbarRef}
          setButtonRef={setButtonRef}
          setMultiLayerToolVisible={setIsMultiLayerMenuOpen}
        />
        {isNewFeatureAddLayerToArea &&
          !isMultiLayerMenuOpen &&
          canAddLayer &&
          hasWritePermission && (
            <NewFeatureMultiLayer
              anchorEl={buttonRef}
              onClose={() => {
                setIsNewFeatureAddLayerToArea(false);
              }}
            />
          )}
      </ToolGroup>
    </Toolbar>
  );
}

type NewFeatureMultiLayerProps = Pick<FaroPopoverProps, "anchorEl" | "onClose">;

/**
 * Display the popover for the new feature multi-layer tool.
 *
 * @returns the component to show the popover for the new feature multi-layer tool.
 */
export function NewFeatureMultiLayer({
  anchorEl,
  onClose,
}: NewFeatureMultiLayerProps): JSX.Element | null {
  return anchorEl ? (
    <FaroPopover
      dark
      open
      anchorEl={anchorEl}
      onClose={onClose}
      closeOnClickOutside={false}
      title=<>
        Import new layers &nbsp;&nbsp;
        <NewFeatureBadge />
      </>
      description="Add new sheets to the active area"
      actions={[{ children: "Got it", onClick: onClose }]}
      sx={{
        // Show popover after slight delay
        animationName: `${fadeIn}`,
        animationDuration: "0.5s",
        animationDelay: "1s",
        animationFillMode: "backwards",
      }}
      placement="left"
    />
  ) : null;
}
