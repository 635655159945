import { CircularProgress } from "@faro-lotv/flat-ui";
import { RegistrationState } from "@faro-lotv/service-wires";
import { Box } from "@mui/material";

type DataPreparationLoadingSpinnerProps = {
  /** The current state of the revision. */
  revisionState: RegistrationState;
};

/** @returns A loading spinner while the registration is in progress. */
export function DataPreparationLoadingSpinner({
  revisionState,
}: DataPreparationLoadingSpinnerProps): JSX.Element | null {
  if (revisionState !== RegistrationState.cloudRegistrationStarted) {
    return null;
  }

  return (
    <Box
      component="div"
      sx={{
        position: "absolute",
        top: "50%",
        left: "50%",
        pointerEvents: "none",
      }}
    >
      <CircularProgress
        sx={{
          // For proper centering
          translate: "-50% -50%",
        }}
        size={60}
      />
    </Box>
  );
}
