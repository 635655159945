import { Upload3dDataDropHandler } from "@/components/common/upload-3d-data-drop-handler";
import { AppColorBar } from "@/components/r3f/utils/app-color-bar";
import { DataSessionsDropDown } from "@/components/ui/data-sessions-dropdown";
import { ToolsHelpBanners } from "@/components/ui/help-banners/tools-help-banners";
import { useMinimapHeight } from "@/hooks/use-minimap-height";
import { useCurrentArea, useCurrentScene } from "@/modes/mode-data-context";
import { setActiveElement } from "@/store/selections-slice";
import { useAppDispatch } from "@/store/store-hooks";
import { neutral } from "@faro-lotv/flat-ui";
import { Box, Stack } from "@mui/material";
import { useMemo, useState } from "react";
import { ImportData } from "./import-data/import-data";

/**
 * @returns the Overlay for SheetMode
 */
export function SheetOverlay(): JSX.Element {
  const dispatch = useAppDispatch();
  const { dataSessions2d, dataSessions5d } = useCurrentArea();
  const { referenceElement } = useCurrentScene();

  const options = useMemo(
    () => [...dataSessions2d, ...dataSessions5d],
    [dataSessions2d, dataSessions5d],
  );

  const [container, setContainer] = useState<HTMLDivElement | null>(null);
  const height = useMinimapHeight({ container });

  return (
    <Upload3dDataDropHandler>
      <Stack
        direction="column"
        justifyContent="space-between"
        sx={{ height: "100%" }}
        ref={setContainer}
      >
        <Stack direction="column">
          <Stack direction="row" justifyContent="space-between">
            <Stack direction="row" gap={1.25}>
              <DataSessionsDropDown
                datasets={options}
                referenceElement={referenceElement}
                onDataSessionChanged={(id) => dispatch(setActiveElement(id))}
              />
            </Stack>
            <ImportData />
          </Stack>
          <ToolsHelpBanners />
        </Stack>
        <Stack
          direction="row"
          sx={{
            height,
          }}
        >
          <Box component="div" flex={1} />
          <AppColorBar
            sx={{
              position: "relative",
              p: "4px",
              backgroundColor: neutral[999],
              borderWidth: "1px",
              borderColor: `${neutral[0]}33`,
              borderRadius: "4px",
            }}
          />
        </Stack>
      </Stack>
    </Upload3dDataDropHandler>
  );
}
