import { FaroText, TranslateVar } from "@faro-lotv/flat-ui";
import { BcfServicesIntegrationType } from "@faro-lotv/service-wires";
import { getExternalAnnotationProviderName } from "./external-annotation-utils";

/**
 * Displays an error message indicating that the session with the external annotation provider might have expired.
 *
 * @param bcfIntegrationType - The type of BCF services integration, or undefined if not available.
 * @returns A JSX element containing the error message.
 */
export function ExternalAnnotationError({
  bcfIntegrationType,
}: {
  bcfIntegrationType?: BcfServicesIntegrationType;
}): JSX.Element {
  return (
    <FaroText variant="bodyM">
      Your session with{" "}
      {bcfIntegrationType ? (
        <TranslateVar name="providerName">
          {getExternalAnnotationProviderName(bcfIntegrationType)}
        </TranslateVar>
      ) : (
        "external annotation provider"
      )}{" "}
      might have expired. To continue, please refresh the connection through the
      workspace integration settings in the dashboard.
    </FaroText>
  );
}
