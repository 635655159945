import { Step, StepLabel, Stepper } from "@mui/material";
import { Box, Stack } from "@mui/system";
import { ReactNode, useCallback, useMemo, useState } from "react";
import { FaroButton } from "../button/faro-button";
import { blue, neutral } from "../colors";
import { FaroText } from "../text/faro-text/faro-text";
import { FaroStepIcon } from "./faro-step-icon";

export type FaroStep<Key = string> = {
  /** A unique identifier for the step. */
  key: Key;

  /** The label to show to the user. */
  label: ReactNode;

  /** Whether the user is allowed to proceed to the next step. */
  allowNext?(): boolean;

  /** Whether the user is allowed to go back to the previous step. */
  allowBack?(): boolean;

  /** An action to execute before proceeding to the next step. */
  onBeforeNext?(): Promise<void>;

  /** An action to execute before going back to the previous step. */
  onBeforeBack?(): Promise<void>;
};

export type FaroStepperProps<Key = string> = {
  /** The steps to step through. */
  steps: Array<FaroStep<Key>>;

  /** The key of the step which is currently active. */
  activeStepKey: Key;

  /** The action to execute when the active step changes. */
  onStepChange(newStep: FaroStep<Key>): void;

  /** if provided button "Next" will stay visible on last step with provided text instead of "Next" */
  lastStepButtonText?: string;

  /** if provided and set to true do not display numbers of steps */
  hideStepNumbers?: boolean;

  /** An action to execute when completing last step. */
  onLastStepButtonClicked?(): void;
};

/** @returns A progress indicator for multiple steps through a workflow. */
export function FaroStepper<Key = string>({
  steps,
  activeStepKey,
  onStepChange,
  lastStepButtonText,
  hideStepNumbers = false,
  onLastStepButtonClicked,
}: FaroStepperProps<Key>): JSX.Element {
  const activeStepIndex = useMemo(
    () => steps.findIndex((step) => step.key === activeStepKey),
    [steps, activeStepKey],
  );
  const activeStep = steps[activeStepIndex];

  const allowNext = activeStep.allowNext ? activeStep.allowNext() : true;
  const allowBack = activeStep.allowBack ? activeStep.allowBack() : true;

  const [isLoadingBack, setIsLoadingBack] = useState(false);
  const [isLoadingNext, setIsLoadingNext] = useState(false);

  const onBack = useCallback(async () => {
    setIsLoadingBack(true);

    try {
      await activeStep.onBeforeBack?.();
      onStepChange(steps[activeStepIndex - 1]);
    } catch {
      /* empty */
    }

    setIsLoadingBack(false);
  }, [activeStep, activeStepIndex, onStepChange, steps]);

  const onNext = useCallback(async () => {
    if (
      onLastStepButtonClicked &&
      lastStepButtonText &&
      activeStepIndex === steps.length - 1
    ) {
      // if it's action on last step, only onLastStepButtonClicked should be executed
      onLastStepButtonClicked();
      return;
    }

    setIsLoadingNext(true);

    try {
      await activeStep.onBeforeNext?.();
      onStepChange(steps[activeStepIndex + 1]);
    } catch {
      /* empty */
    }

    setIsLoadingNext(false);
  }, [
    activeStep,
    activeStepIndex,
    lastStepButtonText,
    onLastStepButtonClicked,
    onStepChange,
    steps,
  ]);

  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      sx={{ width: "100%", p: 1, backgroundColor: neutral[50] }}
    >
      {/* Placeholder to center the stepper */}
      <Box component="div" flexBasis={180} flexGrow={1} flexShrink={1} />

      <Stepper
        activeStep={activeStepIndex}
        sx={{
          flexGrow: 0.5,
          flexShrink: 0,
          justifyContent: steps.length === 1 ? "center" : undefined,
        }}
      >
        {steps.map((step, index) => (
          <Step key={`${step.key}`}>
            <StepLabel
              StepIconComponent={
                hideStepNumbers ? NonVisibleStepperIcon : FaroStepIcon
              }
            >
              <FaroText
                variant="heading16"
                color={
                  index === activeStepIndex && steps.length > 1
                    ? blue[500]
                    : neutral[800]
                }
                sx={{ opacity: index > activeStepIndex ? 0.5 : 1 }}
              >
                {step.label}
              </FaroText>
            </StepLabel>
          </Step>
        ))}
      </Stepper>

      <Stack
        direction="row"
        justifyContent="flex-end"
        flexBasis={180}
        flexGrow={1}
        flexShrink={0.1}
      >
        {activeStepIndex > 0 && (
          <FaroButton
            variant="ghost"
            disabled={!allowBack}
            isLoading={isLoadingBack}
            sx={{ width: "content" }}
            onClick={onBack}
          >
            Back
          </FaroButton>
        )}
        {(activeStepIndex < steps.length - 1 || lastStepButtonText) && (
          <FaroButton
            variant="ghost"
            disabled={!allowNext}
            isLoading={isLoadingNext}
            sx={{ width: "content" }}
            onClick={onNext}
          >
            {lastStepButtonText && activeStepIndex === steps.length - 1
              ? lastStepButtonText
              : "Next"}
          </FaroButton>
        )}
      </Stack>
    </Stack>
  );
}

/**
 * @returns non-visible icon for stepper
 */
function NonVisibleStepperIcon(): JSX.Element {
  return (
    <Stack
      sx={{
        width: "0px",
        height: "0px",
      }}
    />
  );
}
