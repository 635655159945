import { Upload3dDataDropHandler } from "@/components/common/upload-3d-data-drop-handler";
import { DataSessionsDropDown } from "@/components/ui/data-sessions-dropdown";
import { ToolsHelpBanners } from "@/components/ui/help-banners/tools-help-banners";
import { OverviewModeSceneFilterToggle } from "@/components/ui/overview-mode-scene-filter-toggle";
import { SceneContextMenu } from "@/components/ui/scene-context-menu";
import { useCurrentArea, useCurrentScene } from "@/modes/mode-data-context";
import { ImportData } from "@/modes/sheet-mode/import-data/import-data";
import { useWalkOverlayResponsiveLayout } from "@/modes/walk-mode/walk-overlay-responsive-layout";
import { changeMode } from "@/store/mode-slice";
import { setActiveElement } from "@/store/selections-slice";
import { useAppDispatch, useAppSelector } from "@/store/store-hooks";
import { selectActiveTool } from "@/store/ui/ui-selectors";
import { ToolName } from "@/store/ui/ui-slice";
import { Stack } from "@mui/material";
import { useEffect, useMemo } from "react";

/**
 * @returns the Overlay for OverviewMode
 */
export function OverviewOverlay(): JSX.Element | null {
  const dispatch = useAppDispatch();

  const activeTool = useAppSelector(selectActiveTool);

  // Get data from store
  const { dataSessions3d, dataSessions5d } = useCurrentArea();
  const { main, referenceElement, cad } = useCurrentScene();

  const options = useMemo(
    () => [...dataSessions3d, ...dataSessions5d],
    [dataSessions3d, dataSessions5d],
  );

  useEffect(() => {
    if (activeTool === ToolName.export) {
      dispatch(changeMode("export"));
    }
  }, [dispatch, activeTool]);

  const { buttonLayout, overlayRef } = useWalkOverlayResponsiveLayout();

  const model = main && referenceElement ? main : cad;

  return (
    <Upload3dDataDropHandler>
      <Stack direction="column" gap={2}>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          flexWrap="wrap"
          // Use gap instead of spacing prop so it works correctly with flex-wrapped elements
          gap={1}
          ref={overlayRef}
        >
          {/* divs are used here for a stable layout */}
          <div>
            <Stack direction="row" gap={1.25}>
              <DataSessionsDropDown
                datasets={options}
                referenceElement={referenceElement}
                onDataSessionChanged={(id) => dispatch(setActiveElement(id))}
              />
            </Stack>
          </div>
          <div>
            {model && (
              <OverviewModeSceneFilterToggle
                model={model}
                layout={buttonLayout}
              />
            )}
          </div>
          <ImportData />
        </Stack>
        <ToolsHelpBanners />
      </Stack>
      <SceneContextMenu />
    </Upload3dDataDropHandler>
  );
}
