import { AutodeskIcon, ProcoreIcon } from "@faro-lotv/flat-ui";
import { BcfServicesIntegrationType } from "@faro-lotv/service-wires";
import { Theme } from "@mui/material";
import { SxProps } from "@mui/system";

type ExternalAnnotationProviderLogoProps = {
  /** The type of the external annotation provider */
  annotationType: BcfServicesIntegrationType;
  /** Optional ARIA label for accessibility */
  ariaLabel?: string;
  /** Optional styling properties */
  sx?: SxProps<Theme>;
};

/**
 * @returns the logo component for the given external annotation provider type.
 */
export function ExternalAnnotationProviderLogo({
  annotationType,
  sx,
  ariaLabel,
}: ExternalAnnotationProviderLogoProps): JSX.Element {
  switch (annotationType) {
    case BcfServicesIntegrationType.autodeskAccIssues:
    case BcfServicesIntegrationType.autodeskAccRfis:
    case BcfServicesIntegrationType.autodeskBim360Issues:
    case BcfServicesIntegrationType.autodeskBim360Rfis:
      return AutodeskIcon({ sx, "aria-label": ariaLabel });
    case BcfServicesIntegrationType.procoreObservations:
    case BcfServicesIntegrationType.procoreRfis:
      return ProcoreIcon({ sx, "aria-label": ariaLabel });
  }
}
