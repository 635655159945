import { ComponentsToDisplay } from "@/store/measurement-tool-slice";
import { SupportedUnitsOfMeasure } from "@faro-lotv/ielement-types";
import { HtmlProps } from "@react-three/drei/web/Html";
import { MutableRefObject, useMemo } from "react";
import { Material, Matrix3, Matrix4, Vector3 } from "three";
import { computeMeasurementSegments } from "./measure-utils";
import { TwoPointMeasureSegment } from "./two-point-segment-renderer";

type MultiSegmentRendererProps = {
  /** The list of points describing the polyline local to the reference system defined by the `worldMatrix` */
  points: Vector3[];
  /** A flag specifying if the polyline is closed */
  isClosed: boolean;
  /** A flag specifying if the measurement is the current active one */
  isActive: boolean;
  /** A ref to the parent HTML element to which all labels will be attached */
  labelContainer: MutableRefObject<HTMLElement>;
  /** The HTML property for the pointer events on the HTML labels */
  labelsPointerEvents: HtmlProps["pointerEvents"];
  /** The unit of measure used to display the measurement */
  unitOfMeasure: SupportedUnitsOfMeasure;
  /** The world matrix of the whole measurement */
  worldMatrix: Matrix4;
  /** A flag for making dashed lines */
  dashed?: boolean;
  /** Whether depth testing should be used to render the segment */
  depthTest?: Material["depthTest"];
  /**
   * Callback providing center position of the segment when a label is clicked
   *
   * @param position of the segment center
   * @param firstPointIndex index of the first point of the segment
   */
  onClick?(position: Vector3, firstPointIndex: number): void;
  /** True if the label should be rendered */
  isLabelVisible: boolean;
  /** Component to display for 2-points line */
  componentsToDisplay?: ComponentsToDisplay;
};

/** @returns A component to render a polyline that, optionally, could be closed, where each segment have a label showing the length */
export function MultiSegmentRenderer({
  points,
  isClosed,
  isActive,
  labelContainer,
  labelsPointerEvents,
  unitOfMeasure,
  worldMatrix,
  dashed,
  depthTest,
  onClick,
  isLabelVisible,
  componentsToDisplay,
}: MultiSegmentRendererProps): JSX.Element {
  // Matrix to convert from points local coordinates system to world
  const matrix = useMemo(
    () => new Matrix3().setFromMatrix4(worldMatrix),
    [worldMatrix],
  );

  const listSegments = useMemo(
    () =>
      computeMeasurementSegments({
        points,
        isClosed,
        componentsToDisplay,
        matrix,
      }),
    [points, isClosed, componentsToDisplay, matrix],
  );

  return (
    <>
      {listSegments.map((segment, i) => (
        <TwoPointMeasureSegment
          key={i}
          main
          start={segment.start}
          end={segment.end}
          length={segment.length}
          index={0}
          live={false}
          visible
          isMeasurementActive={isActive}
          isLabelActive={isActive}
          labelContainer={labelContainer}
          labelsPointerEvents={labelsPointerEvents}
          unitOfMeasure={unitOfMeasure}
          onClick={() => onClick?.(segment.labelPosition, i)}
          dashed={dashed}
          depthTest={depthTest}
          labelPosition={segment.labelPosition}
          prefix={segment.prefix}
          isLabelVisible={isLabelVisible}
        />
      ))}
    </>
  );
}
