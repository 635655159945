import { AlignmentTransform } from "@/alignment-tool/store/alignment-slice";
import { TwoPointsPairsAlignmentAnchorPositions } from "@/alignment-tool/utils/compute-split-screen-alignment";
import {
  AlignmentViewLayout,
  SplitDirection,
} from "@/store/modes/alignment-ui-types";
import { GUID } from "@faro-lotv/foundation";
import { clearStore } from "@faro-lotv/project-source";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { Vector3Tuple } from "three";

/** State managing data exchange during layer-to-layer alignment session */
type LayerToLayerAlignmentToolState = {
  /**
   * Aligned sheet ID used during current alignment session
   * It's defined only within life cycle of layer-to-layer alignment workflow
   * At the end of each alignment cycle it must be reset to undefined to prevent reusing it in next session of alignment
   */
  alignedLayerId?: GUID;

  /**
   * Sheet ID used as reference layer during current alignment session.
   * It's defined only within life cycle of layer-to-layer alignment workflow
   * At the end of each alignment cycle it must be reset to undefined to prevent reusing it in next session of alignment
   */
  referenceLayerId?: GUID;

  /**
   * Incremental transform of the between layers. Translations are in meters.
   * At the end of each alignment cycle it must be reset to undefined to prevent reusing it in next session of alignment
   */
  incrementalTransform?: AlignmentTransform;

  /** anchor positions defined in split screen alignment */
  alignmentAnchorPositions: TwoPointsPairsAlignmentAnchorPositions;

  /** sheet to sheet alignment screen layout */
  alignmentLayout: AlignmentViewLayout;

  /** the alignment screen split direction */
  splitDirection: SplitDirection;
};

const initialState: LayerToLayerAlignmentToolState = {
  alignmentAnchorPositions: {},
  alignmentLayout: AlignmentViewLayout.splitScreen,
  splitDirection: SplitDirection.horizontalSplit,
};

const layerToLayerAlignmentModeSlice = createSlice({
  initialState,
  name: "layerToLayerAlignmentMode",
  reducers: {
    setAlignedLayerId(state, action: PayloadAction<string>) {
      state.alignedLayerId = action.payload;
    },

    setReferenceLayerId(state, action: PayloadAction<GUID>) {
      state.referenceLayerId = action.payload;
    },

    setLayerToLayerIncrementalTransform(
      state,
      action: PayloadAction<AlignmentTransform | undefined>,
    ) {
      state.incrementalTransform = action.payload;
    },

    setMovingElementAnchor1ForLayerToLayerAlignment(
      state,
      action: PayloadAction<Vector3Tuple | undefined>,
    ) {
      state.alignmentAnchorPositions.movingElementAnchor1 = action.payload;
    },

    setMovingElementAnchor2ForLayerToLayerAlignment(
      state,
      action: PayloadAction<Vector3Tuple | undefined>,
    ) {
      state.alignmentAnchorPositions.movingElementAnchor2 = action.payload;
    },

    setReferenceElementAnchor1ForLayerToLayerAlignment(
      state,
      action: PayloadAction<Vector3Tuple | undefined>,
    ) {
      state.alignmentAnchorPositions.referenceElementAnchor1 = action.payload;
    },

    setReferenceElementAnchor2ForLayerToLayerAlignment(
      state,
      action: PayloadAction<Vector3Tuple | undefined>,
    ) {
      state.alignmentAnchorPositions.referenceElementAnchor2 = action.payload;
    },

    resetLayerToLayerAlignment(state) {
      state.incrementalTransform = undefined;
      state.alignmentAnchorPositions = {};
      state.alignmentLayout = AlignmentViewLayout.splitScreen;
      state.splitDirection = SplitDirection.horizontalSplit;
    },

    setLayerToLayerAlignmentLayout(
      state,
      action: PayloadAction<AlignmentViewLayout>,
    ) {
      state.alignmentLayout = action.payload;
    },

    setLayerToLayerAlignmentSplitDirection(
      state,
      action: PayloadAction<SplitDirection>,
    ) {
      state.splitDirection = action.payload;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(clearStore, () => initialState);
  },
});

export const {
  setAlignedLayerId,
  setReferenceLayerId,
  resetLayerToLayerAlignment,
  setLayerToLayerIncrementalTransform,
  setMovingElementAnchor1ForLayerToLayerAlignment,
  setMovingElementAnchor2ForLayerToLayerAlignment,
  setReferenceElementAnchor1ForLayerToLayerAlignment,
  setReferenceElementAnchor2ForLayerToLayerAlignment,
  setLayerToLayerAlignmentSplitDirection,
  setLayerToLayerAlignmentLayout,
} = layerToLayerAlignmentModeSlice.actions;

export const layerToLayerAlignmentModeReducer =
  layerToLayerAlignmentModeSlice.reducer;
