import { WalkSceneActiveElement } from "@/modes/walk-mode/walk-types";
import { RootState } from "@/store/store";
import {
  useAppDispatch,
  useAppSelector,
  useAppStore,
} from "@/store/store-hooks";
import { FaroText, cyan, neutral } from "@faro-lotv/flat-ui";
import { GUID, removeExtension } from "@faro-lotv/foundation";
import {
  IElemLinkCommand,
  IElement,
  isIElemLinkCommand,
  isIElementAreaSection,
  isIElementImg360,
  isIElementWithFileUri,
} from "@faro-lotv/ielement-types";
import {
  addIElements,
  isIElementVideoModeCopy,
  selectAncestor,
  selectChildDepthFirst,
  selectIElement,
} from "@faro-lotv/project-source";
import { useApiClientContext } from "@faro-lotv/service-wires";
import { Link } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { AnnotationWrapper } from "../annotation-wrapper";
import {
  AnnotationProps,
  isIElementLinkToExternal,
  isIElementWalkActiveElement,
} from "../annotations-types";

/** @returns A renderer for an annotation element */
export function GeneralLinkAnnotationRenderer({
  iElement,
  worldTransform,
  onTargetElementChanged,
}: AnnotationProps): JSX.Element {
  const linkToExternal = useAppSelector(
    selectChildDepthFirst(iElement, isIElementLinkToExternal),
  );
  const linkToIElement = useAppSelector(
    selectChildDepthFirst(iElement, isIElemLinkCommand),
  );
  if (linkToIElement) {
    return (
      <NavigationAnnotation
        iElement={iElement}
        linkToIElement={linkToIElement}
        worldTransform={worldTransform}
        onTargetElementChanged={onTargetElementChanged}
      />
    );
  }

  return (
    <AnnotationWrapper iElement={iElement} worldTransform={worldTransform}>
      {linkToExternal?.uri && (
        <Link href={linkToExternal.uri} target="_blank" color={cyan[500]}>
          <FaroText variant="hyperLink" noWrap>
            {isIElementWithFileUri(linkToExternal) && linkToExternal.fileName
              ? removeExtension(linkToExternal.fileName)
              : linkToExternal.uri}
          </FaroText>
        </Link>
      )}
    </AnnotationWrapper>
  );
}

interface NavigationAnnotationProps extends AnnotationProps {
  /** An iElement which links to another element in the project */
  linkToIElement: IElemLinkCommand;
}

/**
 * @returns An annotation element that links to another element in the project
 */
function NavigationAnnotation({
  iElement,
  linkToIElement,
  worldTransform,
  onTargetElementChanged,
}: NavigationAnnotationProps): JSX.Element {
  const targetElement = useTargetElement(linkToIElement.target_Id);
  const loadTarget = useLoadTarget(targetElement);
  return (
    <AnnotationWrapper
      iElement={iElement}
      worldTransform={worldTransform}
      onClick={async () => {
        const target = await loadTarget();
        if (target) {
          onTargetElementChanged?.(target);
        }
      }}
    >
      <FaroText variant="buttonM" color={neutral[0]} noWrap>
        Go to:{" "}
        {targetElement?.name ??
          // Since HB adds "Go to" to the name of the annotation iElement by default, it is removed here to not have duplicate text
          linkToIElement.name.replace(/go to /i, "")}
      </FaroText>
    </AnnotationWrapper>
  );
}

/**
 * @returns The element pointed by the input id, if it exists. Load it from the backend if it's missing.
 * @param id The id of the element we need to load
 */
function useTargetElement(id: GUID | undefined): IElement | undefined {
  const element = useAppSelector(selectIElement(id));
  const [targetElement, setTargetElement] = useState(element);

  const dispatch = useAppDispatch();
  const { projectApiClient } = useApiClientContext();
  useEffect(() => {
    if (targetElement) return;

    async function loadElement(): Promise<void> {
      if (!id) return;

      const elements = await projectApiClient.getAllIElements({
        ids: [id],
      });

      dispatch(addIElements(elements));
      setTargetElement(elements.find((e) => e.id === id));
    }
    loadElement();
  }, [dispatch, id, projectApiClient, targetElement]);

  return targetElement;
}

/**
 * The signature of the function returned by the useLoadTarget hook
 */
type LoadTargetFunction = () => Promise<WalkSceneActiveElement | undefined>;

/**
 * @returns A function to compute the target element for the input element. Load from the
 * backend the missing elements in the tree, if needed.
 * @param element The element whose target should be computed
 */
function useLoadTarget(element: IElement | undefined): LoadTargetFunction {
  const { projectApiClient } = useApiClientContext();
  const store = useAppStore();
  const dispatch = useAppDispatch();

  return useCallback(async () => {
    if (!element) return;
    const target = selectTargetElement(element)(store.getState());
    if (target) {
      return target;
    }

    const ancestorsPromise = projectApiClient.getAllIElements({
      descendantIds: [element.id],
    });
    const descendantsPromise = projectApiClient.getAllIElements({
      ancestorIds: [element.id],
    });

    const [descendants, ancestors] = await Promise.all([
      descendantsPromise,
      ancestorsPromise,
    ]);
    dispatch(addIElements([...descendants, ...ancestors]));
    return selectTargetElement(element)(store.getState());
  }, [dispatch, element, projectApiClient, store]);
}

/**
 * @returns the targetElement of a link annotation, an img360 if the target element is a video mode copy
 * otherwise returns a room section.
 * @param iElement iElement which contains the information of the linked element
 */
function selectTargetElement(iElement?: IElement) {
  return (state: RootState) => {
    // Check if the target element is a video mode copy
    const videoModeCopy = selectChildDepthFirst(
      iElement,
      isIElementVideoModeCopy,
    )(state);

    if (videoModeCopy) {
      const area = selectAncestor(videoModeCopy, isIElementAreaSection)(state);
      const targetElement360 = selectChildDepthFirst(
        area,
        (iElement) =>
          isIElementImg360(iElement) && iElement.targetId === videoModeCopy.id,
      )(state);

      if (targetElement360 && isIElementWalkActiveElement(targetElement360)) {
        return targetElement360;
      }
    }

    return selectChildDepthFirst(iElement, isIElementWalkActiveElement)(state);
  };
}
