import { FaroText, TextField } from "@faro-lotv/flat-ui";
import {
  FormControlLabel,
  InputBaseComponentProps,
  InputLabel,
  Stack,
  Switch,
} from "@mui/material";
import { clamp } from "es-toolkit";

type ControlSwitchProps = {
  /** If `true`, the switch is checked */
  checked: boolean;
  /** Label displayed next to the switch */
  label: string;
};

type RegistrationParameterFieldProps = {
  /** Label displayed on top of the field */
  label: string;
  /** The value of the field */
  value?: number;
  /** Props applied to the Input element */
  inputProps?: InputBaseComponentProps;
  /** Props for an optional switch that controls whether the field accepts input */
  controlSwitchProps?: ControlSwitchProps;
  /** A callback fired when the control switch value changed */
  onControlSwitchValueChanged?(value: boolean): void;
  /** A callback fired when the field value changed */
  onValueChanged?(value: number): void;
};

/**
 * @returns a field that accepts user input to control a parameter
 */
export function RegistrationParameterField({
  label,
  value,
  inputProps,
  controlSwitchProps,
  onControlSwitchValueChanged,
  onValueChanged,
}: RegistrationParameterFieldProps): JSX.Element {
  return (
    <Stack direction="column">
      <InputLabel>{label}</InputLabel>
      <Stack direction="row">
        {value !== undefined && (
          <TextField
            sx={{ maxWidth: "10em" }}
            type="number"
            text={value.toString()}
            inputProps={inputProps}
            disabled={controlSwitchProps?.checked}
            onTextChanged={(text: string) => {
              let parsedNumber = parseFloat(text);
              if (inputProps) {
                parsedNumber = clamp(
                  parsedNumber,
                  inputProps.min,
                  inputProps.max,
                );
              }
              onValueChanged?.(parsedNumber);
            }}
          />
        )}
        {controlSwitchProps && (
          <FormControlLabel
            control={
              <Switch
                checked={controlSwitchProps.checked}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  onControlSwitchValueChanged?.(event.target.checked);
                }}
                color="primary"
              />
            }
            label={
              <FaroText variant="bodyL">{controlSwitchProps.label}</FaroText>
            }
            sx={{ marginLeft: 1 }}
          />
        )}
      </Stack>
    </Stack>
  );
}
