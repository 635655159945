import { useState } from "react";
import { CopyShader, ShaderPass } from "three-stdlib";
import { attachPass } from "../attach-utils";

/** @returns A pass that renders the result of a composer to the screen */
export function CopyToScreenPass(): JSX.Element {
  const [pass] = useState(() => new ShaderPass(CopyShader));
  return (
    <primitive name="CopyToScreenPass" object={pass} attach={attachPass} />
  );
}
