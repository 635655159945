import { useAppSelector } from "@/store/store-hooks";
import { HelpBanner } from "@faro-lotv/flat-ui";
import { Box } from "@mui/system";
import {
  selectIsAnyEditModeEnabled,
  selectSelectedEntityId,
} from "../store/data-preparation-ui/data-preparation-ui-selectors";

/** @returns a help banner to explain the data preparation page */
export function DataPreparationHelpBanner(): JSX.Element | null {
  const helpMessage = useHelpMessage();

  if (!helpMessage) return null;

  return (
    <Box
      component="div"
      display="flex"
      justifyContent="center"
      sx={{ position: "absolute", top: 0, zIndex: 1, width: "100%" }}
    >
      <HelpBanner
        sx={{
          display: "inline-block",
          margin: 1,
        }}
      >
        {helpMessage}
      </HelpBanner>
    </Box>
  );
}

/** @returns The help message to show to the user, or `undefined` if no help should be shown. */
function useHelpMessage(): string | undefined {
  const isEditing = useAppSelector(selectIsAnyEditModeEnabled);
  const isEntitySelected = !!useAppSelector(selectSelectedEntityId);

  if (!isEditing) return undefined;

  return isEntitySelected
    ? "Confirm & run a refinement after aligning all scans"
    : "Select a scan or cluster to move from the list";
}
