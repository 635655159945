import {
  LabelAnchored,
  LabelsScreenPositionsComputer,
  TOOLBAR_HEIGHT,
  TOOLBAR_WIDTH,
} from "@/components/r3f/utils/labels-screen-positions-computer";
import { getCoordinateStringZUp } from "@/components/ui/scene-context-menu";
import { ComponentsToDisplay } from "@/store/measurement-tool-slice";
import {
  humanReadableArea,
  humanReadableDistance,
  parseVector3,
} from "@faro-lotv/app-component-toolbox";
import { SupportedUnitsOfMeasure } from "@faro-lotv/ielement-types";
import {
  CameraMonitor,
  areaOfClosedPolygon,
  polygonPerimeter,
} from "@faro-lotv/lotv";
import { Vector3 as Vector3Prop, useFrame } from "@react-three/fiber";
import { useEffect, useMemo, useRef, useState } from "react";
import { Matrix3, Vector2, Vector3 } from "three";
import { BIG_HANDLER_SIZE } from "./measure-constants";
import { Measurement, SegmentData } from "./measure-types";

// Cached value used for internal computations of the computeMeasurement function
// to not re-allocate them at every call as this function can be used in the useFrame hot loop
const P1 = new Vector3();
const P2 = new Vector3();
const REFERENCE = new Vector3();

/** String describing the components of a distance in user units. All values are absolute/unsigned. */
export type MeasurementToDisplay = {
  /** Vertical component (for 2-point polygon only)*/
  verticalDistance?: string;
  /** Horizontal component */
  horizontalDistance?: string;
  /** Area (for closest polygon only) */
  area?: string;
  /** Cumulative 3D length. For close polygon, this is also the perimeter. */
  fullLength: string;
  /** absolute/unsigned value of x component in user's CS/Z-up  (for 2-point polygon only)*/
  x?: string;
  /** absolute/unsigned value of y component in user's CS/Z-up (for 2-point polygon only)*/
  y?: string;
  /** absolute/unsigned value of z component in user's CS/Z-up (for 2-point polygon only)*/
  z?: string;
};

/**
 * Evaluate the various info to report to the user for a specific measurement and eventually return them formatted in user units.
 * The formatting is done with the humanReadableDistance and humanReadableArea functions.
 *
 * @param points points defining the measurement
 * @param isClosed true if the polygon is closed = there is a segment from last to first point
 * @param unitOfMeasure units to be used for display
 * @returns All applicable formatted components and properties of the polygons.
 *
 * DESIGN NOTES
 * This function returns verticalDistance and horizontalDistance only when input list of points has length 2.
 * It returns area only when isClosed is true.
 * fullLength is always returned, but it could be reported as a perimeter when isClosed is true.
 * The strings being returned are formatted with humanReadableDistance (or humanReadableArea for area).
 */
export function computeMeasurementToDisplay(
  points: Vector3[],
  isClosed: boolean,
  unitOfMeasure: SupportedUnitsOfMeasure,
): MeasurementToDisplay {
  // compute vertical and horizontal components only for 2-point polygons
  const measureFor2Points =
    points.length === 2
      ? computeMeasurementInWorld(points[0], points[1], new Vector3())
      : undefined;
  return {
    verticalDistance: measureFor2Points
      ? humanReadableDistance(
          measureFor2Points.components.vertical.length,
          unitOfMeasure,
        )
      : undefined,
    horizontalDistance: measureFor2Points
      ? humanReadableDistance(
          measureFor2Points.components.horizontal.length,
          unitOfMeasure,
        )
      : undefined,
    area: isClosed
      ? humanReadableArea(areaOfClosedPolygon(points).area, unitOfMeasure)
      : undefined,
    fullLength: humanReadableDistance(
      polygonPerimeter(points, isClosed),
      unitOfMeasure,
    ),
    x: measureFor2Points
      ? humanReadableDistance(
          measureFor2Points.xyzValues.x.length,
          unitOfMeasure,
        )
      : undefined,
    y: measureFor2Points
      ? humanReadableDistance(
          measureFor2Points.xyzValues.y.length,
          unitOfMeasure,
        )
      : undefined,
    z: measureFor2Points
      ? humanReadableDistance(
          measureFor2Points.xyzValues.z.length,
          unitOfMeasure,
        )
      : undefined,
  };
}

/**
 * Compute the Measurement structure with all the components from a pair of points and a reference position
 *
 * @param from starting point of the measurement
 * @param to ending point of the measurement
 * @param reference reference position for this measurement
 * @returns the computed Measurement object (all coordinates in same system as input)
 */
export function computeMeasurementInWorld(
  from: Vector3Prop,
  to: Vector3Prop,
  reference: Vector3Prop,
): Measurement {
  const p1 = parseVector3(from, P1);
  const p2 = parseVector3(to, P2);
  const ref = parseVector3(reference, REFERENCE);

  // Compute one SegmentData from both extremities
  function computeSegmentData(
    prefix: string | undefined,
    p1: Vector3,
    p2: Vector3,
    labelPosition: Vector3,
  ): SegmentData {
    return {
      prefix,
      start: new Vector3(p1.x, p1.y, p1.z),
      end: new Vector3(p2.x, p2.y, p2.z),
      length: p1.distanceTo(p2),
      visible: true,
      labelPosition,
    };
  }

  // Compute the Measurement segments data
  return {
    referencePosition: ref.clone(),
    main: computeSegmentData(undefined, p1, p2, ref.clone()),
    components: {
      horizontal: computeSegmentData(
        "↔",
        new Vector3(p1.x, p2.y, p1.z),
        new Vector3(p2.x, p2.y, p2.z),
        new Vector3(ref.x, p2.y, ref.z),
      ),
      vertical: computeSegmentData(
        "↕",
        new Vector3(p1.x, p1.y, p1.z),
        new Vector3(p1.x, p2.y, p1.z),
        new Vector3(p1.x, ref.y, p1.z),
      ),
    },
    xyzValues: {
      // convert from internal Y-up to displayed Z-up
      x: computeSegmentData(
        "X",
        new Vector3(p1.x, p2.y, p2.z),
        new Vector3(p2.x, p2.y, p2.z),
        new Vector3(ref.x, p2.y, p2.z),
      ),
      y: computeSegmentData(
        "Y",
        new Vector3(p1.x, p2.y, p1.z),
        new Vector3(p1.x, p2.y, p2.z),
        new Vector3(p1.x, p2.y, ref.z),
      ),
      // z should component should be the same as vertical
      z: computeSegmentData(
        "Z",
        new Vector3(p1.x, p1.y, p1.z),
        new Vector3(p1.x, p2.y, p1.z),
        new Vector3(p1.x, ref.y, p1.z),
      ),
    },
  };
}

type ComputeMeasurementSegmentsParameters = {
  /** The list of points describing the polyline local to the reference system defined by `matrix` transformation */
  points: Vector3[];
  /** A flag specifying if the polyline is closed */
  isClosed: boolean;
  /** Component to display for 2-points line */
  componentsToDisplay?: ComponentsToDisplay;
  /** Transformation to be applied to the points to be in world coordinates system */
  matrix: Matrix3;
};

// Constant Vector3 used to avoid re-allocating it at each call of the function
const TEMP_VEC3 = new Vector3();

/**
 * @param params description of the measurement to display
 * @returns the list of SegmentData to display for the given measurement
 */
export function computeMeasurementSegments(
  params: ComputeMeasurementSegmentsParameters,
): SegmentData[] {
  let listSegments: SegmentData[] = [];
  if (
    params.points.length !== 2 ||
    params.componentsToDisplay === undefined ||
    params.componentsToDisplay === ComponentsToDisplay.single3d
  ) {
    // get one single measurement (for 3D distance) for each segment in the polyline
    listSegments = params.points.flatMap((p1, i) => {
      if (!params.isClosed && i === params.points.length - 1) return [];
      const nextIndex = (i + 1) % params.points.length;
      const p2 = params.points[nextIndex];
      return [
        {
          start: p1,
          end: p2,
          length: TEMP_VEC3.subVectors(p2, p1)
            .applyMatrix3(params.matrix)
            .length(),
          labelPosition: new Vector3().addVectors(p1, p2).multiplyScalar(0.5),
          visible: true,
        },
      ];
    });
  } else {
    // get 2 measurements (one for each components) for the single line
    const p1 = params.points[0];
    const p2 = params.points[1];
    const labelPosition = new Vector3().addVectors(p1, p2).multiplyScalar(0.5);
    const measurementsInWorld = computeMeasurementInWorld(
      p1,
      p2,
      labelPosition,
    );
    if (
      params.componentsToDisplay === ComponentsToDisplay.heightAndHorizontal
    ) {
      listSegments = [
        measurementsInWorld.components.vertical,
        measurementsInWorld.components.horizontal,
      ];
    } else {
      listSegments = [
        measurementsInWorld.xyzValues.x,
        measurementsInWorld.xyzValues.y,
        measurementsInWorld.xyzValues.z,
      ];
    }
  }

  return listSegments;
}

/**
 * Compute the Measurement structure with all the components from a pair of points and a reference position
 *
 * @param from starting point of the measurement
 * @param to ending point of the measurement
 * @param reference reference position for this measurement
 * @returns the computed Measurement object (all coordinates are with respect to ref)
 */
export function computeMeasurement(
  from: Vector3Prop,
  to: Vector3Prop,
  reference: Vector3Prop,
): Measurement {
  const measurement = computeMeasurementInWorld(from, to, reference);
  const ref = parseVector3(reference, REFERENCE);
  const components = [
    measurement.main,
    measurement.components.horizontal,
    measurement.components.vertical,
  ];
  components.forEach((component) => {
    component.start.sub(ref);
    component.end.sub(ref);
    component.labelPosition.sub(ref);
  });
  return measurement;
}

/**
 * This coefficient has been agreed with the designers and has the purpose
 * of showing the distance components only when their length is significant,
 * i.e. greater than 4 cm by default. The main distance is shown when is longer than zero.
 */
const MIN_SNAP_THRESHOLD = 0.04;
/** Components shorter than this fraction of the measurement length, will be hidden. */
const MIN_SNAP_THRESHOLD_FRACTION = 0.05;
/** Max value that the snap threshold may have. */
const MAX_SNAP_THRESHOLD = 0.1;

/**
 * Update the visibility of a measurement components based on a snap threshold
 *
 * @param measurement the measurement to update
 */
export function updateComponentsVisibility(measurement: Measurement): void {
  const snapDistance = Math.max(
    MIN_SNAP_THRESHOLD,
    Math.min(
      measurement.main.length * MIN_SNAP_THRESHOLD_FRACTION,
      MAX_SNAP_THRESHOLD,
    ),
  );
  measurement.main.visible = measurement.main.length > 0;
  Object.values(measurement.components).forEach(
    (component) => (component.visible = component.length > snapDistance),
  );

  // If only one component is visible, then hide all three
  // because it means that the distance is almost aligned with one of the three axes.
  const componentsVisible = Object.values(measurement.components).filter(
    (component) => component.visible,
  ).length;
  if (componentsVisible < 2) {
    Object.values(measurement.components).forEach(
      (component) => (component.visible = false),
    );
  }
}

/**
 * Compute the top most measurement point based on the camera position
 *
 * @param measurement the measurement to analyze
 * @returns the top most point based on the camera position
 */
export function useCameraSpaceTopMostPoint(
  measurement?: Measurement,
): Vector3 | undefined {
  const [topMostPoint, setTopMostPoint] = useState<Vector3>();

  // Private state used only to optimize the useFrame memory management
  // to not re-allocate objects each frame
  const [topPoint] = useState(new Vector3());
  const [testPoint] = useState(new Vector3());

  // Minimum squared distance required for two points to be considered different
  const minSquaredDistance = 1e-6;

  // Compute top most measurement line end based on current camera position
  useFrame(({ camera }) => {
    if (!measurement) return;
    topPoint
      .copy(measurement.main.start)
      .add(measurement.referencePosition)
      .project(camera);

    // List of other candidate points
    const others = [
      measurement.main.end,
      measurement.components.horizontal.end,
    ];

    // Find highest point in camera space
    for (const point of others) {
      testPoint.copy(point).add(measurement.referencePosition).project(camera);
      if (testPoint.y > topPoint.y) {
        topPoint.copy(testPoint);
      }
    }
    topPoint.unproject(camera).sub(measurement.referencePosition);

    if (
      !topMostPoint ||
      topPoint.distanceToSquared(topMostPoint) > minSquaredDistance
    ) {
      setTopMostPoint(topPoint.clone());
    }
  }, 0);
  return topMostPoint;
}

/**
 * @returns an array of the visibility of the 4 segments of a measurement
 * @param measurement to query for the visibility information
 */
function getSegmentsVisibility(
  measurement: Measurement,
): [boolean, boolean, boolean] {
  return [
    measurement.main.visible,
    measurement.components.horizontal.visible,
    measurement.components.vertical.visible,
  ];
}

/**
 * @returns an array of the desired positions for the segments label
 * @param measurement to query for the visibility information
 * @param array of pre-allocated vectors to not recreate them every frame
 */
function getSegmentsLabelPositions(
  measurement: Measurement,
  array: [Vector3, Vector3, Vector3],
): [Vector3, Vector3, Vector3] {
  array[0]
    .copy(measurement.main.labelPosition)
    .add(measurement.referencePosition);
  array[1]
    .copy(measurement.components.horizontal.labelPosition)
    .add(measurement.referencePosition);
  array[2]
    .copy(measurement.components.vertical.labelPosition)
    .add(measurement.referencePosition);
  return array;
}

/** Pre allocated Vector3 array to optimize segments label computation each frame */
const TEMP_ARRAY: [Vector3, Vector3, Vector3] = [
  new Vector3(),
  new Vector3(),
  new Vector3(),
];

/** Pre allocated Vector3 for real time checks of the measurement end point world position */
const TEMP_END_POINT = new Vector3();

/**
 * Initialize and connect a LabelsScreenPositionsComputer for a measurement
 *
 * @param measurement to position the labels for
 * @param labelContainer HTMLElement that will contain the labels
 * @param isLiveEditing true if the user is live editing this measurement
 * @param toolbarPosition position of the toolbar if visible
 * @returns the LabelsScreenPositionsComputer instance to use to position the labels
 */
export function useLabelScreenPositionComputer(
  measurement: Measurement | undefined,
  labelContainer: HTMLElement,
  isLiveEditing: boolean,
  toolbarPosition?: Vector3,
): LabelsScreenPositionsComputer {
  // This object is responsible to guarantee that measurement labels never overlap the mouse position
  // and never overlap each other.
  const labelsScreenPositionsComputer = useMemo(
    // There are only three segments to account for: distance, horizontal, and vertical component.
    () => new LabelsScreenPositionsComputer(3),
    [],
  );

  const screenSize = useRef(
    new Vector2(labelContainer.clientWidth, labelContainer.clientHeight),
  );

  useEffect(() => {
    if (!measurement) return;
    labelsScreenPositionsComputer.setDirty();
    labelsScreenPositionsComputer.setSegmentsVisible(
      getSegmentsVisibility(measurement),
    );
  }, [labelsScreenPositionsComputer, measurement]);

  const cameraMonitor = useMemo(() => new CameraMonitor(), []);

  // At each frame, the component checks whether the label screen positions must be
  // recomputed, either because the 3D position changed or because the camera changed.
  useFrame(({ camera }, delta) => {
    cameraMonitor.checkCameraMovement(camera, delta);
    const screenResized =
      labelContainer.clientWidth !== screenSize.current.x ||
      labelContainer.clientHeight !== screenSize.current.y;
    screenSize.current.set(
      labelContainer.clientWidth,
      labelContainer.clientHeight,
    );
    // At each frame: if the camera moved, or the screen resized, or
    // the distance start and end points changed, the labels' screen
    // positions are recomputed.
    if (
      measurement &&
      (cameraMonitor.cameraMoving ||
        labelsScreenPositionsComputer.dirty ||
        screenResized)
    ) {
      if (isLiveEditing) {
        labelsScreenPositionsComputer.setCollider(
          TEMP_END_POINT.copy(measurement.main.end).add(
            measurement.referencePosition,
          ),
          BIG_HANDLER_SIZE * 2,
          BIG_HANDLER_SIZE * 2,
          LabelAnchored.Center,
        );
      } else if (toolbarPosition) {
        labelsScreenPositionsComputer.setCollider(
          toolbarPosition,
          TOOLBAR_WIDTH,
          TOOLBAR_HEIGHT,
          LabelAnchored.BottomRight,
        );
      } else {
        labelsScreenPositionsComputer.resetCollider();
      }
      labelsScreenPositionsComputer.compute(
        getSegmentsLabelPositions(measurement, TEMP_ARRAY),
        camera,
        {
          width: labelContainer.clientWidth,
          height: labelContainer.clientHeight,
        },
      );
    }
    // here the useFrame priority is deliberately set to 0, otherwise
    // the measure labels flicker a lot while rotating the camera.
  }, 0);

  return labelsScreenPositionsComputer;
}

/**
 * Update the clipboard with a text representation of a measurement
 *
 * @param measurement to copy to the clipboard
 * @param unitOfMeasure the unit of measure to use for the measurement
 */
export function copyMeasurementToClipboard(
  measurement: Measurement,
  unitOfMeasure: SupportedUnitsOfMeasure,
): void {
  // Text with the correct format and lines to copy to the clipboard.
  const textToCopy = `Distance: ${humanReadableDistance(
    measurement.main.length,
    unitOfMeasure,
  )}

Horizontal component: ${humanReadableDistance(
    measurement.components.horizontal.length,
    unitOfMeasure,
  )}

Vertical component: ${humanReadableDistance(
    measurement.components.vertical.length,
    unitOfMeasure,
  )}`;

  // Copy the text to the clipboard.
  navigator.clipboard.writeText(textToCopy);
}

/**
 * Define which type of contents should be reported for a measurement:
 * "all" to copy everything
 * "coordinates" to copy the coordinates of all points in the measurement
 * "distance" to copy only the distance of the segment
 */
export type MeasurementDescriptionContents = "all" | "coordinates" | "distance";

export type ComputeMultiPointMeasurementDescriptionParameters = {
  /** points of the measurement in world coordinates system */
  points: Vector3[];
  /** true if the measurement is a closed loop */
  isClosed: boolean;
  /** unitOfMeasure to use to format the numbers */
  unitOfMeasure: SupportedUnitsOfMeasure;
  /** Define which contents should be copied */
  contents: MeasurementDescriptionContents;
  /** index of the first point of the segment (ignored if contents === "all") */
  firstPointIndex?: number;
  /** components to display for 2-points measurement */
  componentsToDisplay?: ComponentsToDisplay;
};

/**
 * @param params description of the measurement and what to copy in the description
 * @returns a string description of the measurement (used for clipboard copy)
 */
export function computeMultiPointMeasurementDescription({
  contents,
  points,
  isClosed,
  firstPointIndex,
  unitOfMeasure,
  componentsToDisplay,
}: ComputeMultiPointMeasurementDescriptionParameters): string {
  switch (contents) {
    case "all":
      return computeMultiPointMeasurementDescriptionAll(
        points,
        isClosed,
        unitOfMeasure,
      );

    case "distance": {
      if (firstPointIndex === undefined) {
        // This case is not supposed to happen, but be safe by returning the full description.
        return computeMultiPointMeasurementDescriptionAll(
          points,
          isClosed,
          unitOfMeasure,
        );
      }
      const segmentsData = computeMeasurementSegments({
        points,
        isClosed,
        componentsToDisplay,
        // points are already in world cs
        matrix: new Matrix3(),
      });
      return `${segmentsData[firstPointIndex].prefix ? `${segmentsData[firstPointIndex].prefix}\u00A0` : ""}${humanReadableDistance(segmentsData[firstPointIndex].length, unitOfMeasure)}`;
    }
    case "coordinates": {
      const contents = points
        .map((point) => getCoordinateStringZUp([point.x, point.y, point.z]))
        .join("\n");
      if (isClosed) {
        // add the first point at the end when the polygon is closed
        return `${contents}\n${getCoordinateStringZUp([points[0].x, points[0].y, points[0].z])}`;
      }
      return contents;
    }
  }

  // should not reach this point until someone changed `contents` enum definition
}

/**
 * @param points of the measurement
 * @param isClosed true if the measurement is a closed loop
 * @param unitOfMeasure to use to format the numbers
 * @returns a detailed/complete string description of the measurement (used for clipboard copy)
 */
function computeMultiPointMeasurementDescriptionAll(
  points: Vector3[],
  isClosed: boolean,
  unitOfMeasure: SupportedUnitsOfMeasure,
): string {
  const numSegments = isClosed ? points.length : points.length - 1;

  // Compute the header line (Eg: "3 points polygon" or "4 points poly-line")
  const header =
    points.length === 2
      ? undefined
      : `${numSegments} segments ${isClosed ? "polygon" : "poly-line"}`;
  // Compute the length line (Eg: "Perimeter: 4 m" or "Full Length: 4m")
  let lengthType;
  if (isClosed) {
    lengthType = "Perimeter";
  } else if (points.length === 2) {
    lengthType = "3D Distance";
  } else {
    lengthType = "Full Length";
  }

  const components = computeMeasurementToDisplay(
    points,
    isClosed,
    unitOfMeasure,
  );

  const verticalDistance = components.verticalDistance
    ? `Vertical Distance: ${components.verticalDistance}`
    : undefined;
  const horizontalDistance = components.horizontalDistance
    ? `Horizontal Distance: ${components.horizontalDistance}`
    : undefined;
  const perimeter = `${lengthType}: ${components.fullLength}`;
  const area = components.area ? `Area: ${components.area}` : undefined;

  // Compute a list of line, one for each segment, with a first line saying Segments
  // Eg:
  // Segments
  // 1: 5 m
  // 2: 3.4 m
  const segments =
    points.length === 2
      ? undefined
      : points.reduce((segmentString, current, index, points) => {
          // Skip closing segment if the measure is not closed
          if (index === points.length - 1 && !isClosed) {
            return segmentString;
          }

          const nextIndex = (index + 1) % points.length;
          const next = points[nextIndex];
          const segmentLength = current.distanceTo(next);
          return `${segmentString}\n${index + 1}: ${humanReadableDistance(segmentLength, unitOfMeasure)}`;
        }, "Segments");

  const x =
    components.x === undefined ? undefined : `X Distance: ${components.x}`;
  const y =
    components.y === undefined ? undefined : `Y Distance: ${components.y}`;
  const z =
    components.z === undefined ? undefined : `Z Distance: ${components.z}`;

  return [
    header,
    perimeter,
    horizontalDistance,
    verticalDistance,
    area,
    segments,
    x,
    y,
    z,
  ]
    .filter(Boolean)
    .join("\n");
}
