import { selectIsIdInFilteredDisjunctGroups } from "@/data-preparation-tool/store/revision-selectors";
import { OverlayBadgeData } from "@/hooks/use-overlay-bagde";
import { useAppSelector } from "@/store/store-hooks";
import {
  ExclamationMarkCircleFillIcon,
  FolderSmallIcon,
  ScanClusterIcon,
  ScanIcon,
} from "@faro-lotv/flat-ui";
import {
  CaptureTreeEntity,
  CaptureTreeEntityType,
  isCaptureTreeEntityRevision,
  isRevisionScanEntity,
} from "@faro-lotv/service-wires";
import { Badge } from "@mui/material";
import { SxProps } from "@mui/system";

interface EntityIconProps {
  /** entity to show the icon for */
  entity: CaptureTreeEntity;

  /** styles to apply to the icon */
  sx: SxProps;
}

/** @returns icon for a capture tree entity */
export function EntityIcon({ entity, sx }: EntityIconProps): JSX.Element {
  const isEntityDisjunct = useAppSelector((state) =>
    isCaptureTreeEntityRevision(entity) && isRevisionScanEntity(entity)
      ? selectIsIdInFilteredDisjunctGroups(state, entity.id)
      : false,
  );

  if (isCaptureTreeEntityRevision(entity) && isRevisionScanEntity(entity)) {
    // TODO: we need to refactor this logic with their approapriate selector where possible
    // ticket number https://faro01.atlassian.net/browse/NRT-1586
    const overlayBadgeData: OverlayBadgeData = {
      badgeContent: (
        <ExclamationMarkCircleFillIcon
          sx={{ fontSize: "1em", color: "warning.main" }}
        />
      ),
      badgeBackgroundColor: "default",
      propagateToParent: false,
    };

    return (
      <ScanEntityIcon
        overlayBadgeData={overlayBadgeData}
        sx={sx}
        badgeVisible={isEntityDisjunct}
      />
    );
  }

  if (entity.type === CaptureTreeEntityType.cluster) {
    return <ScanClusterIcon sx={sx} />;
  }

  // Show a folder as a fallback
  return <FolderSmallIcon sx={sx} />;
}

type ScanEntityIconProps = {
  /** styles to apply to the icon */
  sx: SxProps;

  /** data for the overlay badge */
  overlayBadgeData?: OverlayBadgeData;

  badgeVisible: boolean;
};

/** @returns the label component used, for each node, in the tree with the node's icon, name and context menu */
function ScanEntityIcon({
  sx,
  overlayBadgeData,
  badgeVisible,
}: ScanEntityIconProps): JSX.Element {
  return (
    <Badge
      badgeContent={overlayBadgeData?.badgeContent}
      color={
        overlayBadgeData ? overlayBadgeData.badgeBackgroundColor : "warning"
      }
      overlap="circular"
      invisible={!badgeVisible}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
    >
      <ScanIcon sx={{ ...sx, fontSize: "1.125em" }} />
    </Badge>
  );
}
