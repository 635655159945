import { selectBackgroundTask } from "@/store/background-tasks/background-tasks-selector";
import { useAppSelector } from "@/store/store-hooks";
import { BackgroundTask } from "@/utils/background-tasks";
import {
  CloudIcon,
  FaroIconButton,
  FaroIconButtonProps,
} from "@faro-lotv/flat-ui";
import { isBackgroundTaskActive } from "@faro-lotv/service-wires";
import { Badge, CircularProgress, Stack } from "@mui/material";
import { forwardRef } from "react";
import { ProcessInfoSource } from "./progress-overview-selectors";

type CloudActivityMenuButtonProps = Omit<FaroIconButtonProps, "children"> & {
  /** List of all the processes happening in the app */
  processes: ProcessInfoSource[];

  /** True to show a badge to attract user attention */
  showBadge: boolean;
};

/** @returns the button to open the cloud activity menu that can show the progress of one task */
export const CloudActivityMenuButton = forwardRef<
  HTMLButtonElement,
  CloudActivityMenuButtonProps
>(function CloudActivityMenuButton(
  { showBadge, processes, ...rest },
  ref,
): JSX.Element {
  // Search for the tracked task that is furthest away from completion
  const task = useAppSelector((state) =>
    processes
      .filter((u) => u.type === "Task" || u.type === "Export")
      .map(({ id }) => selectBackgroundTask(id)(state))
      .filter(
        (task): task is BackgroundTask =>
          !!task && isBackgroundTaskActive(task.state),
      )
      .sort((a, b) => a.progress - b.progress)
      .at(0),
  );

  return (
    <FaroIconButton aria-label="open cloud activity menu" ref={ref} {...rest}>
      <Stack
        alignItems="center"
        justifyContent="center"
        sx={{
          transform: "scale(1.25)",
        }}
      >
        {task && (
          <CircularProgress
            variant={task.progress > 0 ? "determinate" : "indeterminate"}
            value={task.progress}
            size="100%"
          />
        )}
        <Badge
          sx={{
            position: "absolute",
            "& .MuiBadge-badge": {
              border: "1px solid white",
              height: "6px",
              width: "6px",
            },
          }}
          variant="dot"
          color="secondary"
          overlap="circular"
          invisible={!showBadge}
        >
          <CloudIcon sx={{ width: "100%", height: "100%", p: "10%" }} />
        </Badge>
      </Stack>
    </FaroIconButton>
  );
});
