import { createAreaAlignmentMutations } from "@/alignment-tool/project-alignment-mutations";
import { IDENTITY } from "@/alignment-tool/store/alignment-slice";
import {
  alignmentTransformToMatrix4,
  matrix4ToAlignmentTransform,
} from "@/alignment-tool/utils/alignment-transform";
import { useCurrentProjectApiClient } from "@/components/common/project-provider/project-loading-context";
import { updateProject } from "@/components/common/project-provider/update-project";
import { ModalSpinner } from "@/components/ui/modal-spinner";
import { useErrorHandlers } from "@/errors/components/error-handling-context";
import { changeMode } from "@/store/mode-slice";
import { AlignmentViewLayout } from "@/store/modes/alignment-ui-types";
import {
  selectIncrementalCadTransform,
  selectSheetElevation,
  selectSheetToCadAlignmentLayout,
  selectSheetToCadAlignmentStep,
} from "@/store/modes/sheet-to-cad-alignment-mode-selectors";
import {
  resetSheetToCadAlignment,
  setClippingBoxEnabled,
  setSheetToCadAlignmentLayout,
  SheetToCadAlignmentStep,
} from "@/store/modes/sheet-to-cad-alignment-mode-slice";
import { setActiveElement } from "@/store/selections-slice";
import {
  useAppDispatch,
  useAppSelector,
  useAppStore,
} from "@/store/store-hooks";
import { useBoxControlsContext } from "@/utils/box-controls-context";
import { selectIElementWorldMatrix4 } from "@/utils/transform-conversion-parsed";
import { useNonExhaustiveEffect } from "@faro-lotv/app-component-toolbox";
import { neutral, useToast } from "@faro-lotv/flat-ui";
import { isIElementAreaSection } from "@faro-lotv/ielement-types";
import {
  selectAncestor,
  selectIElementProjectApiLocalPose,
} from "@faro-lotv/project-source";
import { Stack } from "@mui/material";
import { useCallback, useState } from "react";
import { AlignmentViewLayoutToggle } from "../alignment-modes-commons/alignment-view-layout-toggle";
import { useSheetSelectedForAlignment } from "../mode-data-context";
import { SheetToCadCrossSectionToggle } from "./sheet-to-cad-alignment-cross-section-toggle";
import { SheetToCadAlignmentElevation } from "./sheet-to-cad-alignment-elevation";
import { SheetToCadAlignmentProgressBar } from "./sheet-to-cad-alignment-progress-bar";
import { SheetToCadAlignmentSplitScreen } from "./sheet-to-cad-alignment-split-screen";

/** @returns The overlay for the sheet to CAD alignment mode */
export function SheetToCadAlignmentModeOverlay(): JSX.Element {
  const [showSpinner, setShowSpinner] = useState(false);
  const { openToast } = useToast();

  const store = useAppStore();
  const dispatch = useAppDispatch();
  const client = useCurrentProjectApiClient();

  const step = useAppSelector(selectSheetToCadAlignmentStep);

  const alignmentLayout = useAppSelector(selectSheetToCadAlignmentLayout);

  const incrementalTransform = useAppSelector(selectIncrementalCadTransform);
  const sheetElevation = useAppSelector(selectSheetElevation);
  const sheet = useSheetSelectedForAlignment("sheetToCad");
  const sectionArea = useAppSelector(
    selectAncestor(sheet, isIElementAreaSection),
  );
  if (!sectionArea) {
    throw new Error("Section Area not found for selected sheet.");
  }

  const { clippingPlanes, setClippingPlanes } = useBoxControlsContext();

  // store original clippingPlanes when entering to sheet-to-cad alignment mode and restoring original value back at return
  useNonExhaustiveEffect(() => {
    const originalClippingPlanes = clippingPlanes;
    setClippingPlanes(undefined);

    return () => {
      setClippingPlanes(originalClippingPlanes);
      dispatch(setClippingBoxEnabled(false));
    };
  }, []);

  const { handleErrorWithToast } = useErrorHandlers();

  const sectionAreaWorldMatrix = useAppSelector(
    selectIElementWorldMatrix4(sectionArea.id),
  );
  const sectionAreaWorldTransform = matrix4ToAlignmentTransform(
    sectionAreaWorldMatrix,
  );

  const sheetWorldMatrix = useAppSelector(selectIElementWorldMatrix4(sheet.id));
  const sheetWorldTransform = matrix4ToAlignmentTransform(sheetWorldMatrix);

  const applyAreaMutation = useCallback(async () => {
    setShowSpinner(true);
    let transform = incrementalTransform ?? IDENTITY;

    // from UI we've obtained incremental sheet-to-cad transform (only changes from current alignment session)
    // we need multiply the incremental matrix with original, to obtain absolute values in W.C.S
    const sheetToCadMatrix = alignmentTransformToMatrix4(transform);
    transform = matrix4ToAlignmentTransform(
      sheetToCadMatrix.multiply(sectionAreaWorldMatrix),
    );

    // We are computing the delta elevation to apply to the section area
    const finalSheetElevation =
      sheetElevation ?? sheetWorldTransform.position[1];
    transform.position[1] =
      finalSheetElevation +
      sectionAreaWorldTransform.position[1] -
      sheetWorldTransform.position[1];

    const localTransform = selectIElementProjectApiLocalPose(
      sectionArea,
      alignmentTransformToMatrix4(transform),
    )(store.getState());

    try {
      await client.applyMutations(
        createAreaAlignmentMutations(sectionArea, {
          position: [
            localTransform.pos.x,
            localTransform.pos.y,
            localTransform.pos.z,
          ],
          quaternion: [
            localTransform.rot.x,
            localTransform.rot.y,
            localTransform.rot.z,
            localTransform.rot.w,
          ],
          scale: [
            localTransform.scale.x,
            localTransform.scale.y,
            localTransform.scale.z,
          ],
        }),
      );
    } catch (error) {
      handleErrorWithToast({
        title: "Failed to save new alignment",
        error,
      });

      setShowSpinner(false);

      return;
    }

    // Fetch the changed section area sub-tree and update the local copy of the project
    // that new alignment will be used without reloading whole project
    dispatch(
      updateProject({
        projectApi: client,
        iElementQuery: {
          // Refresh the area node to get new transform
          ancestorIds: [sectionArea.id],
        },
      }),
    );

    // at the end of alignment cycle reset temporary data to prevent reusing it in the next session of alignment
    dispatch(resetSheetToCadAlignment());

    // after new alignment applied user will be redirected to 3D view in "overview" mode
    // force sheet to be new active area to immediately show results of alignment
    dispatch(setActiveElement(sheet.id));

    setShowSpinner(false);
    openToast({
      title: "Alignment completed",
      variant: "success",
    });

    // after alignment force switch to overview mode as most convenient to validate alignment result in main 3D view
    dispatch(changeMode("overview"));
  }, [
    incrementalTransform,
    sectionAreaWorldMatrix,
    sheetElevation,
    sheetWorldTransform.position,
    sectionAreaWorldTransform.position,
    sectionArea,
    store,
    dispatch,
    sheet.id,
    openToast,
    client,
    handleErrorWithToast,
  ]);

  const changeAlignmentScreenLayout = useCallback(
    (value: AlignmentViewLayout) =>
      dispatch(setSheetToCadAlignmentLayout(value)),
    [dispatch],
  );

  const alignmentViewLayout = useAppSelector(selectSheetToCadAlignmentLayout);

  return (
    <>
      <ModalSpinner
        sx={{ color: neutral[0], zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={showSpinner}
      />
      <Stack
        sx={{
          width: "100%",
          height: "100%",
        }}
      >
        <SheetToCadAlignmentProgressBar apply={applyAreaMutation} />

        {step === SheetToCadAlignmentStep.setElevation && (
          <SheetToCadAlignmentElevation />
        )}
        {step === SheetToCadAlignmentStep.alignSheet &&
          alignmentLayout === AlignmentViewLayout.overlay && (
            <SheetToCadCrossSectionToggle />
          )}
        {step === SheetToCadAlignmentStep.alignSheet && (
          <>
            {alignmentLayout === AlignmentViewLayout.splitScreen && (
              <SheetToCadAlignmentSplitScreen />
            )}

            <AlignmentViewLayoutToggle
              alignmentLayout={alignmentViewLayout}
              changeAlignmentScreenLayout={changeAlignmentScreenLayout}
            />
          </>
        )}
      </Stack>
    </>
  );
}
