import { WayPointTarget } from "@/components/r3f/renderers/odometry-paths/walk-paths-renderer";
import { CurrentScene } from "@/modes/mode-selectors";
import { selectBestWayPointForElement } from "@/modes/walk-mode/animations/pano-to-model";
import { WalkSceneActiveElement } from "@/modes/walk-mode/walk-types";
import {
  selectShouldSyncCamerasRotation,
  setCompareElementId,
  setSyncCamerasRotation,
} from "@/store/modes/walk-mode-slice";
import { setActiveElement } from "@/store/selections-slice";
import { AppDispatch } from "@/store/store";
import {
  useAppDispatch,
  useAppSelector,
  useAppStore,
} from "@/store/store-hooks";
import { SceneFilter } from "@/types/scene-filter";
import { selectPanoCameraTransform } from "@/utils/camera-transform";
import { useNonExhaustiveEffect } from "@faro-lotv/app-component-toolbox";
import { useToast } from "@faro-lotv/flat-ui";
import {
  isIElementImg360,
  isIElementModel3dStream,
} from "@faro-lotv/ielement-types";
import { DEFAULT_MAX_PERSP_FOV } from "@faro-lotv/lotv";
import { selectHasValidPose } from "@faro-lotv/project-source";
import { Camera, Quaternion, Vector3 as Vector3Prop } from "@react-three/fiber";
import { useCallback, useEffect, useState } from "react";
import { PerspectiveCamera } from "three";

export type SyncSplitSceneCamerasProps = {
  /** information about waypoint selected in one of the screens  */
  target: WayPointTarget;

  // flag indicating from which screen waypoint change is initiated
  // (if true - from left/main screen, if false - from right/compare screen)  */
  isMainScreen: boolean;

  /** app dispatch to manage redux   */
  dispatch: AppDispatch;

  /** if true the right and left cameras will move together to the same selected waypoint  */
  shouldSyncCamerasOnWaypoint: boolean;

  /** if true the right and left cameras will rotate together  */
  shouldSyncCamerasRotation: boolean;
};

/** elements required to sync two cameras */
export type SyncCamerasElements = {
  /** position of left camera in split screen  */
  leftCameraPosition?: Vector3Prop;

  /** position of right camera in split screen  */
  rightCameraPosition?: Vector3Prop;

  /** Change position of left camera in split screen */
  setLeftCameraPosition(pos: Vector3Prop | undefined): void;

  /** Change position of right camera in split screen */
  setRightCameraPosition(pos: Vector3Prop | undefined): void;

  /** Quaternion of left camera in split screen  */
  leftCameraQuaternion?: Quaternion;

  /** Quaternion of right camera in split screen  */
  rightCameraQuaternion?: Quaternion;

  /** Change Quaternion of left camera in split screen */
  setLeftCameraQuaternion(rot: Quaternion | undefined): void;

  /** Change Quaternion of right camera in split screen */
  setRightCameraQuaternion(rot: Quaternion | undefined): void;

  /** flag indicating that there is ongoing animation in left screen (when it's === "true")  */
  waitForAutoLockLeft: boolean;

  /** flag indicating that there is ongoing animation in right screen (when it's === "true")  */
  waitForAutoLockRight: boolean;

  /** change flag indicating that there is ongoing animation in left screen  */
  setWaitForAutoLockLeft(w: boolean): void;

  /** change flag indicating that there is ongoing animation in right screen  */
  setWaitForAutoLockRight(w: boolean): void;

  /** method performing sync logic  */
  syncSplitSceneCameras(p: SyncSplitSceneCamerasProps): void;

  /** method managing camera movement  */
  onCameraMoved(): void;

  /** callback managing passing camera info from one side of split screen to another */
  moveSyncCamerasToNewTarget(
    target: WayPointTarget,
    isMainScreen: boolean,
  ): void;
};

/**
 * @param leftCamera - left/main camera
 * @param rightCamera - right/compare camera
 * @param mainScene - main scene info (left screen)
 * @param compareScene - compare scene info (right screen)
 * @param mainSceneFilter - main scene filter
 * @param compareSceneFilter - compare scene filter
 * @param mainWalkElement - The main element to be rendered on left scene
 * @param compareWalkElement - The element to render on the right scene as comparison element
 * @param shouldSyncCamerasOnWaypoint - if true the right and left cameras will move together to the same selected waypoint
 * @param shouldSyncCamerasRotation - iif true the right and left cameras will rotate together
 * @returns states related to synchronization of  two cameras in split screen mode
 */
export function useSyncCameras(
  leftCamera: Camera,
  rightCamera: Camera,
  mainScene: CurrentScene,
  compareScene: CurrentScene,
  mainSceneFilter: SceneFilter,
  compareSceneFilter: SceneFilter,
  mainWalkElement: WalkSceneActiveElement,
  compareWalkElement: WalkSceneActiveElement,
  shouldSyncCamerasOnWaypoint: boolean,
  shouldSyncCamerasRotation: boolean,
): SyncCamerasElements {
  const store = useAppStore();
  const [waitForAutoLockLeft, setWaitForAutoLockLeft] = useState(false);
  const [waitForAutoLockRight, setWaitForAutoLockRight] = useState(false);

  const [leftCameraPosition, setLeftCameraPosition] = useState<Vector3Prop>();
  const [rightCameraPosition, setRightCameraPosition] = useState<Vector3Prop>();
  const [leftCameraQuaternion, setLeftCameraQuaternion] = useState<
    Quaternion | undefined
  >(undefined);
  const [rightCameraQuaternion, setRightCameraQuaternion] = useState<
    Quaternion | undefined
  >(undefined);

  const [isLockRequired, setLockRequired] = useState(false);
  const dispatch = useAppDispatch();
  const { openToast } = useToast();

  const isRotationLockEnabled = useAppSelector(selectShouldSyncCamerasRotation);

  // Disable the syncing of the cameras when the camera moves when changing element
  // this callback called every time when camera position in any of both screen changed as
  // result of user interaction or animation
  const onCameraMoved = useCallback(() => {
    if (
      !shouldSyncCamerasOnWaypoint &&
      shouldSyncCamerasRotation &&
      (mainSceneFilter === SceneFilter.Pano ||
        compareSceneFilter === SceneFilter.Pano)
    ) {
      // Show the toast only if cameras syncing rotation was set to true
      openToast({ title: "Views unlocked", variant: "info" });
      dispatch(setSyncCamerasRotation(false));
    }
  }, [
    shouldSyncCamerasOnWaypoint,
    shouldSyncCamerasRotation,
    mainSceneFilter,
    compareSceneFilter,
    openToast,
    dispatch,
  ]);

  // Disable the syncing of the cameras when the camera moves in the scene (e.g. click on floor of pointcloud)
  useNonExhaustiveEffect(() => {
    // Both positions will be undefined when entering split-mode, therefore check them to not show the toast
    if (!!leftCameraPosition || !!rightCameraPosition) {
      onCameraMoved();
    }
  }, [leftCameraPosition, rightCameraPosition]);

  /**
   * performing lock of both cameras in split screen only when
   * lock is required (selected target has orientation) and animation in both views are finished
   */
  useEffect(() => {
    if (isLockRequired && !waitForAutoLockLeft && !waitForAutoLockRight) {
      dispatch(setSyncCamerasRotation(true));
      setLockRequired(false);
    }
  }, [dispatch, isLockRequired, waitForAutoLockLeft, waitForAutoLockRight]);

  // manages update of FOV when entering to split camera mode and restoring original value back at return to Walk mode
  useEffect(() => {
    if (
      !(leftCamera instanceof PerspectiveCamera) ||
      !(rightCamera instanceof PerspectiveCamera)
    ) {
      return;
    }

    const originalFov = leftCamera.fov;

    // Zoom out at the maximum level when entering split screen
    const defaultSplitViewFov = DEFAULT_MAX_PERSP_FOV;
    leftCamera.fov = defaultSplitViewFov;
    rightCamera.fov = defaultSplitViewFov;

    return () => {
      leftCamera.fov = originalFov;
      rightCamera.fov = originalFov;
    };
  }, [leftCamera, rightCamera]);

  /**
   *  lock screens at opening if they can be locked automatically
   */
  useNonExhaustiveEffect(() => {
    if (
      isIElementImg360(mainScene.activeElement) &&
      selectHasValidPose(mainScene.activeElement)(store.getState()) &&
      !!mainScene.activeElement.isRotationAccurate &&
      isIElementImg360(compareScene.activeElement) &&
      selectHasValidPose(compareScene.activeElement)(store.getState()) &&
      !!compareScene.activeElement.isRotationAccurate
    ) {
      dispatch(setSyncCamerasRotation(true));
    }

    return () => {
      // always unlock when leaving split screen view
      dispatch(setSyncCamerasRotation(false));
    };
  }, []);

  /**
   * Correctly set the initial position of the left camera
   * when the component is mounted the first time
   */
  useNonExhaustiveEffect(() => {
    if (isIElementImg360(mainWalkElement)) {
      // The camera does not necessarily already have to be at the correct position in pano mode
      // e.g. when the user enters splitscreen during camera animations
      const transform = selectPanoCameraTransform(
        mainWalkElement,
        mainScene.sheet,
      )(store.getState());
      leftCamera.position.fromArray(transform.position);
    }
  }, []);

  /**
   * This useNonExhaustiveEffect intended trigger animation only when mainWalkElement changed outside
   * of the scene (e.g. from timeseries selector dropdown).
   * All other dependencies should not trigger animation.
   */
  useNonExhaustiveEffect(() => {
    if (
      isIElementImg360(mainWalkElement) &&
      mainWalkElement !== mainScene.activeElement &&
      (mainSceneFilter !== SceneFilter.Pano ||
        compareSceneFilter !== SceneFilter.Pano)
    ) {
      syncSplitSceneCameras({
        target: selectBestWayPointForElement(
          mainWalkElement,
          leftCamera,
          mainScene.sheet,
          shouldSyncCamerasOnWaypoint,
        )(store.getState()),
        isMainScreen: true,
        dispatch,
        shouldSyncCamerasOnWaypoint,
        shouldSyncCamerasRotation,
      });
    }
  }, [mainWalkElement]);

  /**
   * This useNonExhaustiveEffect intended trigger animation only when compareWalkElement changed outside
   * of the scene (e.g. from timeseries selector dropdown).
   * All other dependencies should not trigger animation.
   */
  useNonExhaustiveEffect(() => {
    if (
      isIElementImg360(compareWalkElement) &&
      compareWalkElement !== compareScene.activeElement &&
      (mainSceneFilter !== SceneFilter.Pano ||
        compareSceneFilter !== SceneFilter.Pano)
    ) {
      syncSplitSceneCameras({
        target: selectBestWayPointForElement(
          compareWalkElement,
          rightCamera,
          compareScene.sheet,
          shouldSyncCamerasOnWaypoint,
        )(store.getState()),
        isMainScreen: false,
        dispatch,
        shouldSyncCamerasOnWaypoint,
        shouldSyncCamerasRotation,
      });
    }
  }, [compareWalkElement]);

  /**
   *  function managing passing camera info from one side of split screen to another
   *
   * @param p parameters required for synchronization of the screens
   */
  const syncSplitSceneCameras = useCallback(
    (p: SyncSplitSceneCamerasProps) => {
      if (!isIElementModel3dStream(p.target.targetElement)) {
        // CAD managed by its own slice using setActiveCad

        if (p.shouldSyncCamerasOnWaypoint) {
          if (mainSceneFilter === SceneFilter.Pano) {
            p.dispatch(setActiveElement(p.target.targetElement.id));
          }
          if (compareSceneFilter === SceneFilter.Pano) {
            p.dispatch(setCompareElementId(p.target.targetElement.id));
          }
        } else if (p.isMainScreen) {
          if (mainSceneFilter === SceneFilter.Pano) {
            p.dispatch(setActiveElement(p.target.targetElement.id));
          }
        } else if (compareSceneFilter === SceneFilter.Pano) {
          p.dispatch(setCompareElementId(p.target.targetElement.id));
        }
      }

      if (p.shouldSyncCamerasOnWaypoint) {
        setLeftCameraPosition(p.target.position);
        setRightCameraPosition(p.target.position);

        setLeftCameraQuaternion(p.target.quaternion);
        setRightCameraQuaternion(p.target.quaternion);
      } else {
        setLeftCameraQuaternion(undefined);
        setRightCameraQuaternion(undefined);
      }

      if (p.shouldSyncCamerasOnWaypoint) {
        if (
          isIElementImg360(p.target.targetElement) &&
          selectHasValidPose(p.target.targetElement)(store.getState()) &&
          !!p.target.targetElement.isRotationAccurate
        ) {
          setLockRequired(true);
          setWaitForAutoLockLeft(true);
          setWaitForAutoLockRight(true);
        } else {
          if (
            isRotationLockEnabled &&
            (mainSceneFilter === SceneFilter.Pano ||
              compareSceneFilter === SceneFilter.Pano)
          ) {
            p.dispatch(setSyncCamerasRotation(false));
            openToast({ title: "Views unlocked", variant: "info" });
          }

          setLockRequired(false);
          setWaitForAutoLockLeft(false);
          setWaitForAutoLockRight(false);
        }
      }
    },
    [
      compareSceneFilter,
      isRotationLockEnabled,
      mainSceneFilter,
      openToast,
      store,
    ],
  );

  /**
   *  callback managing applying camera info on each side of split
   *
   * @param target - information about waypoint, which is selected to triggered switch
   * @param isMainScreen -   flag indicating from which screen waypoint change is triggered
   * if true - from left/main screen, if false - from right/compare screen.
   * flag required for correctly set active element in only one screen when follow option is disabled
   */
  const moveSyncCamerasToNewTarget = useCallback(
    (target: WayPointTarget, isMainScreen: boolean) => {
      syncSplitSceneCameras({
        target,
        isMainScreen,
        dispatch,
        shouldSyncCamerasOnWaypoint,
        shouldSyncCamerasRotation,
      });
    },
    [
      dispatch,
      shouldSyncCamerasOnWaypoint,
      shouldSyncCamerasRotation,
      syncSplitSceneCameras,
    ],
  );

  return {
    leftCameraPosition,
    setLeftCameraPosition,
    rightCameraPosition,
    setRightCameraPosition,
    leftCameraQuaternion,
    setLeftCameraQuaternion,
    rightCameraQuaternion,
    setRightCameraQuaternion,
    waitForAutoLockLeft,
    setWaitForAutoLockLeft,
    waitForAutoLockRight,
    setWaitForAutoLockRight,
    syncSplitSceneCameras,
    onCameraMoved,
    moveSyncCamerasToNewTarget,
  };
}
