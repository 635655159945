import { useCallback, useRef, useState } from "react";
import { LanguageCodes } from "../../types/localize-types";
import { FaroIconButton } from "../icon-button/faro-icon-button";
import { FeedbackIcon, HelpCenterIcon, HelpIcon, VideoIcon } from "../icons";
import {
  FaroMenu,
  FaroMenuItem,
  FaroMenuProps,
  FaroMenuSection,
} from "../menu";

/**
 * Exports the name of all the names displayed in the help center.
 * Useful to identify which link was clicked and track it.
 */
export type HelpCenterMenuLinkNames =
  | "help-center"
  | "videos"
  | "send-feedback";

interface HelpCenterMenuProps extends Pick<FaroMenuProps, "anchorOrigin"> {
  /** The language to open the help center [NOTE for now the help desk contains only english articles on Sphere XG] */
  helpCenterLanguages?: LanguageCodes;

  /** Optional callback when the user clicks to open the help center */
  onOpenHelpCenter?(): void;

  /** Optional callback when the user clicks to close the help center */
  onCloseHelpCenter?(): void;

  /** Optional callback when the user clicks on any help link */
  onOpenHelpLink?(link: HelpCenterMenuLinkNames): void;

  /** Optional callback when the user clicks on Send Feedback */
  sendFeedback?(): void;
}

/**
 * Renders a menu component that provides access to various resources, including the Help Center, walkthrough videos, and a feedback option.
 *
 * @returns JSX.Element The rendered menu component.
 */
export function HelpCenterMenu({
  helpCenterLanguages = LanguageCodes.English,
  sendFeedback,
  onOpenHelpCenter,
  onCloseHelpCenter,
  onOpenHelpLink,
  anchorOrigin = { horizontal: "left", vertical: "bottom" },
}: HelpCenterMenuProps): JSX.Element {
  const ref = useRef<HTMLButtonElement>(null);
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);

  /**
   * Closes the help center menu.
   */
  const closeMenu = useCallback(() => {
    onCloseHelpCenter?.();
    setIsMenuOpen(false);
  }, [onCloseHelpCenter, setIsMenuOpen]);

  /**
   * Opens the Sphere XG Help Center documentation in a new browser tab.
   *
   * This method constructs the URL for the Help Center documentation based on the selected language
   * and then opens it in a new tab. After opening the Help Center, it closes the current menu.
   */
  const openHelpCenter = useCallback(() => {
    onOpenHelpLink?.("help-center");
    const url = `https://help.holobuilder.com/${helpCenterLanguages}/collections/9864205-sphere-xg`;

    window.open(url, "_blank");
    setIsMenuOpen(false);
  }, [helpCenterLanguages, onOpenHelpLink]);

  /**
   * Opens the Sphere-XG walkthrough videos page in a new browser tab.
   *
   * This method is designed to be triggered when a user clicks a specific button or menu item.
   * It constructs the URL for the Sphere-XG walkthrough videos on the HoloBuilder website and
   * opens it in a new browser tab.
   */
  const openWalkthroughVideo = useCallback(() => {
    onOpenHelpLink?.("videos");
    const url = "https://www.holobuilder.com/sphere-xg-walkthrough-videos/";

    window.open(url, "_blank");
    setIsMenuOpen(false);
  }, [onOpenHelpLink]);

  /**
   * Triggers the app defined send feedback workflow
   */
  const triggerSendFeedback = useCallback(() => {
    if (!sendFeedback) return;
    onOpenHelpLink?.("send-feedback");
    sendFeedback();
    setIsMenuOpen(false);
  }, [onOpenHelpLink, sendFeedback]);

  return (
    <>
      <FaroIconButton
        aria-label="expand help center menu"
        ref={ref}
        onClick={() => {
          onOpenHelpCenter?.();
          setIsMenuOpen(true);
        }}
        color={isMenuOpen ? "primary.main" : "secondary.main"}
        pressed={isMenuOpen}
      >
        <HelpIcon
          sx={{
            width: "24px",
            height: "24px",
          }}
        />
      </FaroIconButton>
      <FaroMenu
        open={isMenuOpen}
        anchorEl={ref.current}
        onClose={() => closeMenu()}
        anchorOrigin={anchorOrigin}
      >
        <FaroMenuSection>Resource center</FaroMenuSection>

        <FaroMenuItem
          label="Help Center"
          Icon={HelpCenterIcon}
          onClick={openHelpCenter}
        />
        <FaroMenuItem
          label="Videos"
          Icon={VideoIcon}
          onClick={openWalkthroughVideo}
        />

        {sendFeedback && (
          <FaroMenuItem
            label="Send feedback"
            Icon={FeedbackIcon}
            onClick={triggerSendFeedback}
          />
        )}
      </FaroMenu>
    </>
  );
}
