import {
  BaseSelectProps,
  OutlinedSelectProps,
  SxProps,
  Theme,
} from "@mui/material";
import { ReactNode } from "react";
import { InputLabelTagProps } from "../input/input-label-tag";

export type DropdownProps = Omit<OutlinedSelectProps, "placeholder"> &
  Omit<BaseSelectProps<string>, "placeholder"> &
  Pick<InputLabelTagProps, "tag"> & {
    /** styling properties */
    sx?: SxProps<Theme>;

    /** Placeholder text for the dropdown */
    placeholder?: ReactNode;

    /** Options for the dropdown */
    options: DropdownOption[];

    /**
     * Should have the full width of the parent
     *
     * @default true
     */
    fullWidth?: boolean;

    /** True if the dropdown has to be in dark mode */
    dark?: boolean;

    /**
     * True if the dropdown contents should be capitalized
     *
     * @default true
     */
    shouldCapitalize?: boolean;

    /**
     * Optional help text shown below the input
     */
    helpText?: ReactNode;
  };

/** Elements that can be displayed in the dropdown */
export type DropdownOption = Option | DividerOption;

export interface Option {
  /** A unique key of the given option */
  key: string;

  /** Value of the option */
  value: string;

  /** Label to display for the option */
  label: ReactNode;

  /** The secondary text of the option */
  secondaryText?: ReactNode;

  /** True if the option is disabled */
  isDisabled?: boolean;

  /** If true, the element is a non-selectable label */
  isHeader?: boolean;
}

export type DividerOption = {
  /** If true, the element is a non-selectable divider */
  isDivider: true;
};

/**
 * @returns true if the DropdownOption is a normal Option
 * @param option the DropdownOption to check
 */
export function isOption(option: DropdownOption): option is Option {
  return "key" in option && "value" in option && "label" in option;
}

/**
 * @returns true if the DropdownOption is a DividerOption
 * @param option the DropdownOption to check
 */
export function isDivider(option: DropdownOption): option is DividerOption {
  return "isDivider" in option && option.isDivider;
}
