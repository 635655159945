import { Object3DProps } from "@react-three/fiber";
import { useCallback, useEffect, useMemo } from "react";
import { Camera, Event, Object3D, Quaternion, Vector3 } from "three";
import { TransformControls as ThreeTransformControls } from "three-stdlib";
import { useThreeEventTarget } from "../hooks";

export type TransformControlsProps = Object3DProps & {
  /** Object to attach to the controls. */
  object: Object3D;

  /** Camera of the rendered scene. */
  camera: Camera;

  /** Wether or not it's possible to interact with the controls. */
  enabled?: boolean;

  /** The current transformation axis. */
  axis?: string | null;

  /** The Element used to listen for mouse / touch events. */
  domElement?: HTMLElement;

  /** The current transformation mode. */
  mode?: "translate" | "rotate" | "scale";

  /**
   * By default, 3D objects are continuously translated.
   * If you set this property to a numeric value (world units),
   * you can define in which steps the 3D object should be translated.
   */
  translationSnap?: number | null;

  /**
   * By default, 3D objects are continuously rotated.
   * If you set this property to a numeric value (radians),
   * you can define in which steps the 3D object should be rotated.
   */
  rotationSnap?: number | null;

  /**
   * By default, 3D objects are continuously scaled.
   * If you set this property to a numeric value (world units),
   * you can define in which steps the 3D object should be scaled.
   */
  scaleSnap?: number | null;

  /**
   * Defines in which coordinate space transformations should be performed.
   * Possible values are "world" and "local".
   */
  space?: "world" | "local";

  /** The size of the helper UI (axes/planes).*/
  size?: number;

  /** Whether or not the x-axis helper should be visible.  */
  showX?: boolean;

  /** Whether or not the y-axis helper should be visible.  */
  showY?: boolean;

  /** Whether or not the z-axis helper should be visible. */
  showZ?: boolean;

  /** Function to call onMouseDown. */
  onMouseDown?(e?: Event): void;

  /** Function to call onMouseUp. */
  onMouseUp?(e?: Event): void;

  /** Callback for when the transform changes. */
  onTransformChanged?(
    position: Vector3,
    rotation: Quaternion,
    scale: Vector3,
  ): void;
};

/**
 * @returns A wrapper component for the TransformControls.
 */
export function TransformControls({
  object,
  camera,
  domElement,
  onMouseDown,
  onMouseUp,
  onTransformChanged,
  ...props
}: TransformControlsProps): JSX.Element {
  const eventTarget = useThreeEventTarget(domElement);

  // Create a new instance of the TransformControls from threejs.
  const controls = useMemo(() => {
    const tControls = new ThreeTransformControls(camera, eventTarget);
    // We have to override the clone function of the controls because
    // ThreeJS default implementation is broken: it calls the constructor without
    // arguments, throwing errors since the attached domElement cannot be undefined
    tControls.clone = (recursive?: boolean | undefined) => {
      const cloned = new ThreeTransformControls(camera, eventTarget);
      cloned.copy(tControls, recursive);
      return cloned;
    };
    return tControls;
  }, [camera, eventTarget]);

  useEffect(() => {
    // Attach the object to the transform controls.
    controls.attach(object);

    // Detach the object from the controls and dispose all
    // events
    return () => {
      controls.detach();
      controls.dispose();
    };
  }, [controls, object]);

  useEffect(() => {
    // Be sure to update the visibility of the elements of the controls
    controls.updateMatrixWorld();
  }, [controls]);

  // Create callbacks for onMouseDown and onMouseUp
  const onMouseDownCbk = useCallback(() => onMouseDown?.(), [onMouseDown]);
  const onMouseUpCbk = useCallback(() => onMouseUp?.(), [onMouseUp]);

  const onChangeCbk = useCallback(() => {
    // Get current position, rotation and scale of the object.
    const position = object.getWorldPosition(new Vector3());
    const rotation = object.getWorldQuaternion(new Quaternion());
    const scale = object.getWorldScale(new Vector3());

    onTransformChanged?.(position, rotation, scale);
  }, [object, onTransformChanged]);

  useEffect(() => {
    // Attach the event listeners to the controls.
    controls.addEventListener("mouseDown", onMouseDownCbk);
    controls.addEventListener("mouseUp", onMouseUpCbk);
    controls.addEventListener("change", onChangeCbk);

    return () => {
      // Detach the event listeners.
      controls.removeEventListener("mouseDown", onMouseDownCbk);
      controls.removeEventListener("mouseUp", onMouseUpCbk);
      controls.removeEventListener("change", onChangeCbk);
    };
  }, [controls, onChangeCbk, onMouseDownCbk, onMouseUpCbk]);

  return <primitive object={controls} {...props} />;
}
