import { useAppSelector } from "@/store/store-hooks";
import { getSheetCenter, getSheetSize } from "@faro-lotv/app-component-toolbox";
import { IElementGenericImgSheet } from "@faro-lotv/ielement-types";
import { memberWithPrivateData } from "@faro-lotv/lotv";
import { selectIElementWorldTransform } from "@faro-lotv/project-source";
import { useMemo } from "react";
import { Box3, Matrix4, Vector3, Vector3Tuple } from "three";

/**
 * @param sheet to compute the corners for
 * @returns the 4 interesting corners for the sheet
 */
export function useSheetCorners(
  sheet: IElementGenericImgSheet | undefined,
): Vector3Tuple[] {
  const { scale, worldMatrix } = useAppSelector(
    selectIElementWorldTransform(sheet?.id),
  );

  return useMemo(() => {
    if (!sheet) return [];

    const { width, height } = getSheetSize(sheet, scale);
    const halfWidth = width / 2;
    const halfHeight = height / 2;
    const center = getSheetCenter(
      sheet,
      new Matrix4().fromArray(worldMatrix),
    ).toArray();
    return [
      [center[0] - halfWidth, center[1], center[2] + halfHeight],
      [center[0] + halfWidth, center[1], center[2] + halfHeight],
      [center[0] - halfWidth, center[1], center[2] - halfHeight],
      [center[0] + halfWidth, center[1], center[2] - halfHeight],
    ];
  }, [scale, sheet, worldMatrix]);
}

/**
 *
 * @param sheetCorners The four corners of a sheet
 * @param box The bounding box to add the corners to
 * @returns The argument box with the added corners
 */
export const sheetCorners2Box = memberWithPrivateData(() => {
  const point = new Vector3();

  return (sheetCorners: Vector3Tuple[], box = new Box3()): Box3 => {
    sheetCorners.forEach((p) => box.expandByPoint(point.set(p[0], p[1], p[2])));
    return box;
  };
});
