import { RootState } from "@/store/store";
import { GUID } from "@faro-lotv/foundation";

/**
 * @param state  Current state
 * @returns ID of element to be aligned in current session of Alignment Wizard
 */
export function selectWizardElementToAlignId(
  state: RootState,
): GUID | undefined {
  return state.alignmentWizardMode.elementToAlignId;
}

/**
 * @param state  Current state
 * @returns ID of element to be used as a reference in current session of Alignment Wizard
 */
export function selectWizardReferenceElementId(
  state: RootState,
): GUID | undefined {
  return state.alignmentWizardMode.referenceElementId;
}

/**
 * @param state  Current state
 * @returns ID of area to which new layer should be uploaded and aligned (used for sheet-to-sheet alignment)
 */
export function selectLayerAreaElementId(state: RootState): GUID | undefined {
  return state.alignmentWizardMode.layerAreaElementId;
}
