import { selectClippingBoxEnabledForCadAlignment } from "@/store/modes/sheet-to-cad-alignment-mode-selectors";
import { setClippingBoxEnabled } from "@/store/modes/sheet-to-cad-alignment-mode-slice";
import { useAppDispatch, useAppSelector } from "@/store/store-hooks";
import { ViewDiv } from "@faro-lotv/app-component-toolbox";
import {
  ClippingBoxIcon,
  ToolButton,
  ToolGroup,
  neutral,
} from "@faro-lotv/flat-ui";
import { Box, Stack, Toolbar, Tooltip } from "@mui/material";
import { useOverlayElements, useOverlayRef } from "../overlay-elements-context";
import { SheetToCadAlignmentSetElevationPanel } from "./sheet-to-cad-alignment-set-elevation-panel";

/**
 * SheetToCadAlignmentElevation provides a view for interacting with a 3D model
 * It also renders a panel for setting elevation in a sheet-to-CAD alignment tool and a button to enable/disable a clipping box.
 *
 * @returns A layout with a panel to set elevation and a 3D model view with a toolbar
 * for interacting with the model.
 */
export function SheetToCadAlignmentElevation(): JSX.Element {
  const dispatch = useAppDispatch();

  const { setSingleScreen } = useOverlayElements();
  const singleScreenRef = useOverlayRef(setSingleScreen);

  const isClippingBoxEnabled = useAppSelector(
    selectClippingBoxEnabledForCadAlignment,
  );

  return (
    <Stack direction="row" sx={{ height: "100%" }}>
      <SheetToCadAlignmentSetElevationPanel />

      <ViewDiv
        eventDivRef={singleScreenRef}
        sx={{
          height: "100%",
          width: "100%",
        }}
      >
        <Box
          component="div"
          sx={{
            position: "absolute",
            height: "100%",
            width: "50px",
            right: "5px",
            alignContent: "center",
          }}
        >
          <Toolbar
            sx={{
              right: "25px",
            }}
          >
            <ToolGroup>
              <Tooltip title="Clip 3D model" placement="left">
                <ToolButton
                  sx={{ backgroundColor: neutral[999] }}
                  selected={isClippingBoxEnabled}
                  onClick={() => {
                    dispatch(setClippingBoxEnabled(!isClippingBoxEnabled));
                  }}
                >
                  <ClippingBoxIcon sx={{ width: "24px", height: "24px" }} />
                </ToolButton>
              </Tooltip>
            </ToolGroup>
          </Toolbar>
        </Box>
      </ViewDiv>
    </Stack>
  );
}
