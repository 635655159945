import { Avatar, Box, Stack, SxProps, Theme } from "@mui/material";
import { CSSProperties, ReactNode, useMemo } from "react";
import { NO_TRANSLATE_CLASS } from "../../types";
import { UserDisplayInfo } from "../../types/user-display-info";
import { neutral } from "../colors";
import { getUserInitials } from "./user-avatar-utils";

export enum UserAvatarSizes {
  /** When avatar size should be extra small */
  xs = "xs",

  /** When avatar size should be small */
  s = "s",

  /** When avatar size should be medium */
  m = "m",

  /** When avatar size should be large */
  l = "l",
}

/** Display options for avatar if the name and email should be shown */
export enum UserAvatarDisplayOptions {
  /** Only name will be shown beside the avatar */
  nameOnly = "nameOnly",

  /** Both name and email will be shown beside the avatar */
  nameAndEmail = "nameAndEmail",
}

export type UserAvatarProps = {
  /** Info about the user used to generate an avatar */
  userDisplayInfo?: UserDisplayInfo;

  /**
   * Size of the user avatar
   *
   * @default m
   */
  size?: `${UserAvatarSizes}`;

  /**
   * Optional icon to be shown inside the avatar. If provided, this
   * property will be used even if "initials" was provided, but it will be ignored if the "src" attribute
   * was provided.
   */
  icon?: ReactNode;

  /** Optional style to apply to the icon. Only used if the icon prop is used */
  iconStyle?: SxProps<Theme>;

  /**
   * If true shows the white rim between the avatar and the blue wrapper
   *
   * @default true
   */
  shouldShowWhiteRim?: boolean;

  /**
   * If true then a blue gradient border is shown around the avatar
   *
   * @default false
   */
  shouldShowGradientBorder?: boolean;

  /**
   * If true, the component will be displayed in dark mode
   *
   * @default false
   */
  dark?: boolean;

  /** Display option for the avatar */
  displayOption?: `${UserAvatarDisplayOptions}`;
};

/**
 * @returns The avatar for a user.
 * Contains the users image if present,
 * Falls back to the users initials if available,
 * Falls back to the first two letters of the users email if there is one,
 * Falls back to the default avatar otherwise.
 */
export function UserAvatar({
  userDisplayInfo,
  size = "m",
  icon,
  iconStyle,
  shouldShowGradientBorder = false,
  shouldShowWhiteRim = true,
  dark = false,
  displayOption,
}: UserAvatarProps): JSX.Element {
  const { profileImageUrl } = userDisplayInfo ?? {};

  let name = "";
  if (displayOption && userDisplayInfo) {
    name =
      userDisplayInfo.name ??
      `${userDisplayInfo.firstName} ${userDisplayInfo.lastName}`;

    if (displayOption === UserAvatarDisplayOptions.nameAndEmail) {
      name = `${name} (${userDisplayInfo.email})`;
    }
  }

  // Contains style based on the avatar size
  const sizeStyles = USER_AVATAR_STYLE_MAP[size];

  const initials = useMemo(
    () => getUserInitials(userDisplayInfo),
    [userDisplayInfo],
  );

  return (
    <Stack
      className={NO_TRANSLATE_CLASS}
      component="span"
      direction="row"
      mx={0.25}
      gap={name ? 1 : 0}
      alignItems="center"
      width="fit-content"
      height="fit-content"
    >
      <Box
        component="span"
        style={{
          width: sizeStyles.wrapperSize,
          height: sizeStyles.wrapperSize,
          borderRadius: "50px",
          background: shouldShowGradientBorder
            ? "transparent linear-gradient(159deg, #009CDE 0%, #3455DB 38%, #3354D8 52%, #2E53CE 64%, #2650BD 74%, #1C4CA5 84%, #0E4787 93%, #01426A 100%) 0% 0% no-repeat padding-box"
            : "none",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Avatar
          sx={{
            bgcolor: dark ? neutral[900] : neutral[400],
            width: shouldShowGradientBorder
              ? sizeStyles.avatarSize
              : sizeStyles.wrapperSize,
            height: shouldShowGradientBorder
              ? sizeStyles.avatarSize
              : sizeStyles.wrapperSize,
            fontSize: sizeStyles.avatarFontSize,
            fontWeight: sizeStyles.avatarFontWeight,
            letterSpacing: "0.6px",
            outline: shouldShowWhiteRim ? "2px solid white" : "none",
          }}
          src={profileImageUrl}
          alt={initials}
          component="span"
        >
          {/* Show preferably icon if provided */}
          {icon && (
            <Box component="span" sx={iconStyle}>
              {icon}
            </Box>
          )}

          {/* Only show initials if icon is not provided.*/}
          {!icon && initials}
        </Avatar>
      </Box>

      {/* eslint-disable-next-line @typescript-eslint/no-unnecessary-condition -- FIXME */}
      {name ?? ""}
    </Stack>
  );
}

type CustomSizeStyles = {
  // Style to apply to the Avatar component and its parent Box
  avatarSize: CSSProperties["width"];
  avatarFontSize: CSSProperties["fontSize"];
  avatarFontWeight: CSSProperties["fontWeight"];

  // Size to apply to the root wrapper
  wrapperSize: CSSProperties["width"];
};

// Specific style for each button size as defined in the FARO Design System
export const USER_AVATAR_STYLE_MAP: Record<UserAvatarSizes, CustomSizeStyles> =
  {
    [UserAvatarSizes.xs]: {
      avatarFontSize: "10px",
      avatarFontWeight: "400",
      avatarSize: "18px",
      wrapperSize: "24px",
    },
    [UserAvatarSizes.s]: {
      avatarFontSize: "12px",
      avatarFontWeight: "400",
      avatarSize: "28px",
      wrapperSize: "36px",
    },
    [UserAvatarSizes.m]: {
      avatarFontSize: "16px",
      avatarFontWeight: "400",
      avatarSize: "40px",
      wrapperSize: "48px",
    },
    [UserAvatarSizes.l]: {
      avatarFontSize: "22px",
      avatarFontWeight: "500",
      avatarSize: "52px",
      wrapperSize: "60px",
    },
  };
