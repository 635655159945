import { EventType } from "@/analytics/analytics-events";
import { useAnnotationPermissions } from "@/hooks/use-annotation-permissions";
import { selectAllCadModels } from "@/store/cad/cad-selectors";
import {
  selectActiveCadId,
  selectActiveCadLoadingError,
  setActiveCad,
} from "@/store/cad/cad-slice";
import { Features } from "@/store/features/features";
import { selectHasFeature } from "@/store/features/features-slice";
import { useAppDispatch, useAppSelector } from "@/store/store-hooks";
import { selectCanReadCAD } from "@/store/subscriptions/subscriptions-selectors";
import {
  Dropdown,
  TreeData,
  selectProjectTree,
  useBreakpointMdUp,
} from "@faro-lotv/app-component-toolbox";
import { Alert, NoTranslate } from "@faro-lotv/flat-ui";
import { Analytics } from "@faro-lotv/foreign-observers";
import { TabContext, TabList } from "@mui/lab";
import { Box, Stack, Tab, Typography } from "@mui/material";
import { isEqual } from "es-toolkit";
import {
  PropsWithChildren,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { AnnotationPanel } from "./annotation-panel/annotation-panel";
import { AreaNavigationPanel } from "./area-navigation-panel";
import { CadModelTree } from "./tree/cad-model-tree/cad-model-tree";
import { useCadModelTreeData } from "./tree/cad-model-tree/cad-model-tree-data";
import { CaptureTree } from "./tree/capture-tree/capture-tree";
import { CaptureTreeNode } from "./tree/capture-tree/capture-tree-node";
import { TreeWrapper } from "./tree/tree-wrapper";

/**
 * UI component including the data sessions tree either alone, or with the CAD model tree in a separate tab.
 *
 * @returns JSX.Element UI component displaying the tab "Capture" and the tab "Model" and their contents.
 */
export function ProjectView(): JSX.Element {
  const showCadTab = useAppSelector(selectCanReadCAD);
  const { canReadAnnotations: showAnnotationTab } = useAnnotationPermissions();
  const tree = useAppSelector(selectProjectTree);

  const canShowAreaNavigation = useAppSelector(
    selectHasFeature(Features.AreaNavigation),
  );

  if (showCadTab || showAnnotationTab) {
    return (
      <TabbedProjectView
        tree={tree}
        showCadTab={showCadTab}
        showAnnotationTab={showAnnotationTab}
      />
    );
  }

  // Display the legacy UI if the area navigation feature is disabled
  return canShowAreaNavigation ? (
    <AreaNavigationPanel />
  ) : (
    <TreeWrapper>
      <CaptureTree tree={tree}>{CaptureTreeNode}</CaptureTree>
    </TreeWrapper>
  );
}

// List of tabs
enum TabType {
  /** 3d/2d project captures */
  capture = "capture",

  /** Cad model structure */
  model = "model",

  /** Project annotations */
  annotations = "annotations",
}

type TabbedProjectViewProps = {
  /** True to show the cad tab */
  showCadTab: boolean;

  /** True to show the annotation tab */
  showAnnotationTab: boolean;

  /** The project content tree */
  tree: TreeData[];
};

/**
 * Display the tabs with both the "Capture" (list of data sessions) and "Model" (list of CADs) tree views.
 *
 * @returns UI component including 2 tabs and their contents
 */
function TabbedProjectView({
  showCadTab,
  showAnnotationTab,
  tree,
}: TabbedProjectViewProps): JSX.Element {
  // Active tab
  const [activeTab, setActiveTab] = useState<TabType>(TabType.capture);

  const handleTabChange = useCallback((_: unknown, newValue: TabType): void => {
    setActiveTab(newValue);
  }, []);

  const canShowAreaNavigation = useAppSelector(
    selectHasFeature(Features.AreaNavigation),
  );

  return (
    <Stack component="div" flexGrow="1" sx={{ overflow: "auto" }}>
      <TabContext value={activeTab.toString()}>
        <Box component="div">
          <TabList onChange={handleTabChange}>
            <Tab label="Capture" value={TabType.capture} />
            {showCadTab && <Tab label="3D Model" value={TabType.model} />}
            {showAnnotationTab && (
              <Tab
                label="Annotations"
                value={TabType.annotations}
                onClick={() => Analytics.track(EventType.openAnnotationList)}
              />
            )}
          </TabList>
        </Box>
      </TabContext>

      <TabPanel visible={activeTab === TabType.capture}>
        {canShowAreaNavigation ? (
          <AreaNavigationPanel />
        ) : (
          <TreeWrapper>
            <CaptureTree tree={tree}>{CaptureTreeNode}</CaptureTree>
          </TreeWrapper>
        )}
      </TabPanel>

      {showCadTab && (
        <TabPanel visible={activeTab === TabType.model}>
          <CadPanel />
        </TabPanel>
      )}

      {showAnnotationTab && (
        <TabPanel visible={activeTab === TabType.annotations}>
          <AnnotationPanel />
        </TabPanel>
      )}
    </Stack>
  );
}

type TabPanelProps = PropsWithChildren<{
  visible: boolean;
}>;

function TabPanel({ children, visible }: TabPanelProps): JSX.Element {
  const isMdUp = useBreakpointMdUp();

  return (
    <Stack
      sx={{
        overflow: "auto",
        flexGrow: 1,
        flexDirection: "column",
        ...(isMdUp ? { mt: 2, ml: 2.5 } : { mt: 1 }),
        display: visible ? undefined : "none",
      }}
    >
      {children}
    </Stack>
  );
}

function CadPanel(): JSX.Element {
  const dispatch = useAppDispatch();

  const currentCadId = useAppSelector(selectActiveCadId);
  const cadModels = useAppSelector(selectAllCadModels, isEqual);

  // sort models in alphabetical order of names
  const sortedCadModels = useMemo(
    () => cadModels.sort((a, b) => a.name.localeCompare(b.name)),
    [cadModels],
  );

  const cadSelectorOptions = sortedCadModels.map((cad) => ({
    key: cad.id,
    value: cad.id,
    label: <NoTranslate>{cad.name}</NoTranslate>,
  }));

  // if there is no active CAD yet, take first one in the lost and set it as default active CAD
  useEffect(() => {
    if (!currentCadId && sortedCadModels.length) {
      dispatch(setActiveCad(sortedCadModels[0].id));
    }
  }, [currentCadId, dispatch, sortedCadModels]);

  const modelTreeData = useCadModelTreeData();
  const activeCadLoadingError = useAppSelector(selectActiveCadLoadingError);

  return (
    <>
      {activeCadLoadingError && (
        <Alert title={activeCadLoadingError} variant="warning" sx={{ mb: 3 }} />
      )}

      <Dropdown
        label="Active model"
        options={cadSelectorOptions}
        sx={{ height: 35 }}
        value={currentCadId ?? ""}
        shouldCapitalize={false}
        onChange={(e) => {
          const selCad = cadSelectorOptions.find(
            (el) => el.value === e.target.value,
          );
          if (selCad) {
            dispatch(setActiveCad(selCad.key));
          }
        }}
      />

      {typeof modelTreeData === "string" ? (
        <Typography>{modelTreeData}</Typography>
      ) : (
        <TreeWrapper>
          <CadModelTree modelTreeData={modelTreeData} />
        </TreeWrapper>
      )}
    </>
  );
}
