import { selectSelectedExportModeTab } from "@/store/modes/export-mode-selectors";
import { setSelectedExportModeTab } from "@/store/modes/export-mode-slice";
import { useAppDispatch, useAppSelector } from "@/store/store-hooks";
import { FaroText, neutral } from "@faro-lotv/flat-ui";
import { TabContext, TabPanel } from "@mui/lab";
import { Drawer, SxProps, Tab, Tabs } from "@mui/material";
import { useState } from "react";
import { ExportModeTab } from "./export-mode-types";
import { OrthophotoExportForm } from "./orthophoto-export/orthophoto-export-form";
import { PointCloudExportForm } from "./point-cloud-export/point-cloud-export-form";
import { usePointCloudFormats } from "./point-cloud-export/use-point-cloud-formats";

/** Common styles for the tab panels */
const TAB_PANEL_SX: SxProps = {
  p: 0,
  height: "100%",
};

/** @returns the drawer for the export mode */
export function ExportModeDrawer(): JSX.Element {
  const dispatch = useAppDispatch();

  const selectedTab = useAppSelector(selectSelectedExportModeTab);

  const [isExportInProgress, setIsExportInProgress] = useState(false);

  const supportedPointCloudFormats = usePointCloudFormats();

  return (
    <Drawer
      variant="persistent"
      open
      sx={{
        "& .MuiDrawer-paper": {
          position: "unset",
          width: 300,
          backgroundColor: neutral[50],
          p: 1.5,
          gap: 3,
        },
      }}
    >
      <FaroText variant="heading16">Export settings</FaroText>

      <TabContext value={selectedTab}>
        <Tabs
          value={selectedTab}
          onChange={(_, tab) => dispatch(setSelectedExportModeTab(tab))}
        >
          <Tab
            label="Point cloud"
            value={ExportModeTab.pointCloud}
            aria-label="export point cloud tab"
            disabled={isExportInProgress}
          />
          <Tab
            label="Orthophoto"
            value={ExportModeTab.orthophoto}
            aria-label="export orthophoto tab"
            disabled={isExportInProgress}
          />
        </Tabs>
        <TabPanel value={ExportModeTab.pointCloud} sx={TAB_PANEL_SX}>
          <PointCloudExportForm
            formats={supportedPointCloudFormats}
            isExportInProgress={isExportInProgress}
            onChangeIsExportInProgress={setIsExportInProgress}
          />
        </TabPanel>

        <TabPanel value={ExportModeTab.orthophoto} sx={TAB_PANEL_SX}>
          <OrthophotoExportForm />
        </TabPanel>
      </TabContext>
    </Drawer>
  );
}
