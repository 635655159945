import { useUpdateProjectMetadata } from "@/components/common/project-provider/use-project-metadata";
import { LanguageSelector } from "@/components/ui/language-selector";
import { ToolBreadcrumbs } from "@/components/ui/tool-breadcrumbs";
import { useOpenAccountAndSecurity } from "@/hooks/use-open-account-and-security";
import {
  RegistrationContextProvider,
  useRegistrationContext,
} from "@/registration-tools/common/registration-context";
import { runtimeConfig } from "@/runtime-config";
import { Features, selectHasFeature } from "@/store/features/features-slice";
import { useAppSelector } from "@/store/store-hooks";
import {
  RegisterMultiCloudDataSetTask,
  SuccessfullyCompleted,
} from "@/utils/background-tasks";
import { redirectToViewer } from "@/utils/redirects";
import {
  FaroDialog,
  HeaderBar,
  useTypedEvent,
} from "@faro-lotv/app-component-toolbox";
import { assert } from "@faro-lotv/foundation";
import { useAuthContext } from "@faro-lotv/gate-keepers";
import { IElementSectionDataSession } from "@faro-lotv/ielement-types";
import { GenericUserInfo, useApiClientContext } from "@faro-lotv/service-wires";
import { Stack } from "@mui/material";
import { isEqual } from "es-toolkit";
import { useEffect, useState } from "react";
import { useAlignmentOverlay } from "../common/alignment-overlay-context";
import { MultiRegistrationSideBar } from "../common/multi-registration-side-bar";
import { MultiCloudRegistrationReportView } from "../common/registration-report/multicloud-registration-report";
import { selectDefaultThresholdSet } from "../common/registration-report/registration-thresholds";
import {
  ThresholdSetProvider,
  useThresholdSetContext,
} from "../common/registration-report/threshold-set-context";
import {
  selectAllMainPointCloudsFromUnmergedDataSessions,
  selectMainPointCloudFromDataSession,
} from "../common/select-main-point-clouds";
import { QualityStatus } from "../utils/metrics";
import { determineReportQuality } from "../utils/multi-registration-report";
import { InspectAndPublishBar } from "./inspect-and-publish-bar";
import { InspectAndPublishCanvas } from "./inspect-and-publish-canvas";
import { InspectAndPublishQuickHelp } from "./inspect-and-publish-quick-help";

export type InspectAndPublishUiProps = {
  /** The currently logged-in user. */
  currentUser?: GenericUserInfo;

  /** The data sessions that the user is viewing. */
  dataSessions: IElementSectionDataSession[];

  /**
   * Optional, enables redirect to the dashboard after the workflow finishes if not undefined.
   * If not undefined, the string is used to call redirectToDashboardProjectPage which allows
   * to define the target tab of the project details in the dashboard.
   * If undefined the Viewer will be opened after the registration is saved or the merge is started.
   */
  dashboardRedirect?: string;

  /** The registration task that the results are being displayed of. */
  registrationTask?: SuccessfullyCompleted<RegisterMultiCloudDataSetTask>;
};

/**
 * @returns UI with canvas and utility bars for the inspect & publish workflow.
 */
export function InspectAndPublishUi({
  currentUser,
  dataSessions,
  dashboardRedirect,
  registrationTask,
}: InspectAndPublishUiProps): JSX.Element {
  const { requestLogin, logout } = useAuthContext();

  const openAccountAndSecurity = useOpenAccountAndSecurity();

  const [isQuickHelpOpen, setIsQuickHelpOpen] = useState(false);

  const pointClouds = useAppSelector(
    selectAllMainPointCloudsFromUnmergedDataSessions(dataSessions),
    isEqual,
  );

  const {
    registrationApiClient: registrationClient,
    projectApiClient: projectApi,
  } = useApiClientContext();
  assert(
    registrationClient,
    "The multi-cloud registration can only be used when the registration URL is defined",
  );

  // Non-blocking: Load project meta-data, e.g. to get the project name for the breadcrumbs
  useUpdateProjectMetadata(projectApi.projectId);

  const defaultThresholdSet = useAppSelector(
    selectDefaultThresholdSet(pointClouds),
  );

  const hasVisualRegistrationFeature = useAppSelector(
    selectHasFeature(Features.VisualRegistration),
  );

  const [isVisualRegistrationEnabled, setIsVisualRegistrationEnabled] =
    useState(false);
  const [selectedDataSession, setSelectedDataSession] =
    useState<IElementSectionDataSession>(dataSessions[0]);

  const selectedPointCloud = useAppSelector(
    selectMainPointCloudFromDataSession(selectedDataSession),
  );

  const showLanguageSelector = useAppSelector(
    selectHasFeature(Features.Localize),
  );

  return (
    <Stack
      sx={{
        height: "100%",
        width: "100%",
        overflow: "hidden",
      }}
    >
      <HeaderBar
        showProfileButton
        content={
          <ToolBreadcrumbs
            toolName="Inspect and Publish"
            onRedirectToViewerClicked={() => {
              redirectToViewer(projectApi.projectId);
            }}
          />
        }
        userMenuContent={showLanguageSelector ? <LanguageSelector /> : null}
        userDisplayInfo={currentUser}
        links={runtimeConfig.externalLinks}
        onLogInClick={requestLogin}
        onLogOutClick={logout}
        onAccountAndSecurityClick={openAccountAndSecurity}
        isQuickHelpOpen={isQuickHelpOpen}
        onQuickHelpClick={() => {
          setIsQuickHelpOpen(!isQuickHelpOpen);
        }}
      />
      <InspectAndPublishBar
        dataSessions={dataSessions}
        dashboardRedirect={dashboardRedirect}
        updatedLocalIElementPoses={
          registrationTask?.result.updatedLocalIElementPoses
        }
      />
      <Stack
        sx={{
          height: "inherit",
          width: "100%",
          overflow: "hidden",
          position: "relative",
        }}
        direction="row"
      >
        {hasVisualRegistrationFeature && (
          <MultiRegistrationSideBar
            dataSessions={dataSessions}
            selectedDataSession={selectedDataSession}
            onSelectDataSession={setSelectedDataSession}
            onToggleVisualRegistration={() =>
              setIsVisualRegistrationEnabled(!isVisualRegistrationEnabled)
            }
            isVisualRegistrationEnabled={isVisualRegistrationEnabled}
          />
        )}
        <RegistrationContextProvider>
          <ThresholdSetProvider defaultThresholdSet={defaultThresholdSet}>
            <DatasetRegistrationReportDialog
              registrationTask={registrationTask}
            />
          </ThresholdSetProvider>
          <InspectAndPublishCanvas
            pointClouds={pointClouds}
            selectedPointCloud={
              isVisualRegistrationEnabled ? selectedPointCloud : undefined
            }
            registrationTask={registrationTask}
          />
        </RegistrationContextProvider>
        {/* Drawer does not resize canvas*/}
        {isQuickHelpOpen && (
          <InspectAndPublishQuickHelp
            onClose={() => {
              setIsQuickHelpOpen(false);
            }}
          />
        )}
      </Stack>
    </Stack>
  );
}

type MultiRegistrationReportDialogProps = {
  /** The task to display the result of. */
  registrationTask?: RegisterMultiCloudDataSetTask;
};

/**
 * @returns Dialog to display the latest registration report for the dataset.
 *  The dialog is opened when the user clicks on the metric button in the toolbar.
 */
function DatasetRegistrationReportDialog({
  registrationTask,
}: MultiRegistrationReportDialogProps): JSX.Element | null {
  const { showRegistrationResultsDialog } = useAlignmentOverlay();
  const report = registrationTask?.result;
  const [isDialogVisible, setIsDialogVisible] = useState(false);

  useTypedEvent(showRegistrationResultsDialog, () => setIsDialogVisible(true));

  const { setRegistrationQuality } = useRegistrationContext();
  const { thresholdSet } = useThresholdSetContext();

  // Update the current registration quality
  // This currently also enables / disables the button to show the registration report
  useEffect(() => {
    setRegistrationQuality(
      report
        ? determineReportQuality(report, thresholdSet)
        : QualityStatus.UNKNOWN,
    );
  }, [report, setRegistrationQuality, thresholdSet]);

  if (!report) return null;

  return (
    <FaroDialog
      title="Registration Report"
      open={isDialogVisible}
      onClose={() => setIsDialogVisible(false)}
      size="l"
    >
      <MultiCloudRegistrationReportView {...report} />
    </FaroDialog>
  );
}
