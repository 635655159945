import { InvalidConfigurationError } from "@faro-lotv/foundation";
import { Camera, FloatType, NearestFilter, RedFormat, WebGLRenderTarget, WebGLRenderer } from "three";
import { FullScreenQuad, Pass } from "three-stdlib";
import { FastSsaoMaterial } from "../Materials/FastSsaoMaterial";
import { SsBlur1DMaterial } from "../Materials/SsBlur1DMaterial";
import { SsComposeMaterial } from "../Materials/SsComposeMaterial";

/**
 * A ThreeJS effect pass to apply Fast SSAO.
 * This SSAO technique is very fast as it needs much less texel lookups than the classic SSAO.
 * It is therefore suitable to be run in more complex effect pipelines on low-performance GPUs.
 * It provides plausible high-radius ambient shadows, missing a little bit on the very small valleys
 * of the model.
 */
export class FastSsaoPass extends Pass {
	#fssaoMaterial = new FastSsaoMaterial();
	#fssaoBlurMaterial = new SsBlur1DMaterial();
	#fssaoComposeMaterial = new SsComposeMaterial();
	#fsQuad: FullScreenQuad;
	#width = 4;
	#height = 4;
	#aoFbo: WebGLRenderTarget;
	#aoBlurFbo: WebGLRenderTarget;

	/**
	 *
	 * @returns A newly created FBO with only color texture with one channel
	 */
	#createAoFbo(): WebGLRenderTarget {
		const fbo = new WebGLRenderTarget(this.#width, this.#height);
		fbo.texture.format = RedFormat;
		fbo.texture.type = FloatType;
		fbo.texture.minFilter = NearestFilter;
		fbo.texture.magFilter = NearestFilter;
		fbo.texture.generateMipmaps = false;
		fbo.texture.name = "AOfbo";
		fbo.stencilBuffer = false;
		fbo.depthBuffer = false;
		return fbo;
	}

	/**
	 * Constructs a new instance of Fast SSAO pass.
	 *
	 * @param camera The scenes camera to grab camera matrices.
	 */
	constructor(public camera: Camera) {
		super();
		this.#fsQuad = new FullScreenQuad(this.#fssaoMaterial);
		this.#aoFbo = this.#createAoFbo();
		this.#aoBlurFbo = this.#createAoFbo();
		this.#fssaoBlurMaterial.uniforms.uLargeKernel.value = true;
	}

	/**
	 * Resizing aux FBOs if needed.
	 *
	 * @param width FBO resolution width
	 * @param height FBO resolution height
	 */
	private resizeFboOnNeed(width: number, height: number): void {
		if (width !== this.#width || height !== this.#height) {
			this.#width = width;
			this.#height = height;
			this.#aoFbo.dispose();
			this.#aoBlurFbo.dispose();
			this.#aoFbo = this.#createAoFbo();
			this.#aoBlurFbo = this.#createAoFbo();
		}
	}

	/**
	 * Dispose all internal resources for this object
	 */
	dispose(): void {
		this.#aoFbo.dispose();
		this.#aoBlurFbo.dispose();
		this.#fssaoMaterial.dispose();
		this.#fssaoBlurMaterial.dispose();
		this.#fssaoComposeMaterial.dispose();
		this.#fsQuad.dispose();
	}

	/**
	 * Renders Fast SSAO
	 *
	 * @param renderer renderer used to render the effect
	 * @param writeBuffer Buffer to write by renderer
	 * @param readBuffer Buffer to read the textures by renderer
	 */
	render(renderer: WebGLRenderer, writeBuffer: WebGLRenderTarget, readBuffer: WebGLRenderTarget): void {
		// eslint-disable-next-line @typescript-eslint/no-unnecessary-condition -- FIXME
		if (!readBuffer.depthTexture) {
			throw new InvalidConfigurationError("Fast SSAO pass requires a depth texture in the composer FBO");
		}

		const oldAutoClear = renderer.autoClear;
		renderer.autoClear = true;

		this.resizeFboOnNeed(readBuffer.width, readBuffer.height);

		// computing ambient occlusion factor from the depth texture, and saving it to this.aoFbo.texture
		this.#fsQuad.material = this.#fssaoMaterial;
		this.#fssaoMaterial.uniforms.uDepthTex.value = readBuffer.depthTexture;
		this.#fssaoMaterial.uniforms.uInvPMatrix.value = this.camera.projectionMatrixInverse;
		this.#fssaoMaterial.uniformsNeedUpdate = true;
		renderer.setRenderTarget(this.#aoFbo);
		this.#fsQuad.render(renderer);

		// Blurring the ambient occlusion factor, and saving it to this.aoBlurFbo.texture
		this.#fssaoBlurMaterial.uniforms.uSingleChannelTex.value = this.#aoFbo.texture;
		this.#fssaoBlurMaterial.uniforms.uDepthTex.value = readBuffer.depthTexture;
		this.#fssaoBlurMaterial.uniforms.uProjectionMatrixInverse.value.copy(this.camera.projectionMatrixInverse);
		this.#fssaoBlurMaterial.uniforms.uRadius.value = this.#fssaoMaterial.uniforms.uRadius.value;
		this.#fssaoBlurMaterial.uniformsNeedUpdate = true;
		this.#fsQuad.material = this.#fssaoBlurMaterial;
		renderer.setRenderTarget(this.#aoBlurFbo);
		this.#fsQuad.render(renderer);

		// Composing the final result by multiplying the original color by the occlusion factor for each pixel,
		// and simply copying the depth.
		this.#fssaoComposeMaterial.uniforms.uColorTex.value = readBuffer.texture;
		this.#fssaoComposeMaterial.uniforms.uDepthTex.value = readBuffer.depthTexture;
		this.#fssaoComposeMaterial.uniforms.uEffectTex.value = this.#aoBlurFbo.texture;
		this.#fssaoComposeMaterial.uniformsNeedUpdate = true;
		this.#fsQuad.material = this.#fssaoComposeMaterial;
		renderer.setRenderTarget(this.renderToScreen ? null : writeBuffer);
		this.#fsQuad.render(renderer);

		renderer.autoClear = oldAutoClear;
	}

	/** @returns the radius of AO shading */
	get radius(): number {
		return this.#fssaoMaterial.radius;
	}

	/** Sets the radius of AO shading */
	set radius(r: number) {
		this.#fssaoMaterial.radius = r;
	}

	/** @returns the strength of the AO shading */
	get strength(): number {
		return this.#fssaoMaterial.strengthFactor;
	}

	/** Sets the strength of the AO shading */
	set strength(s: number) {
		this.#fssaoMaterial.strengthFactor = s;
	}

	/**
	 * @returns the angle bias value used to avoid noise close to zero when computing
	 * the dot product dot(originToSample, normal).
	 */
	get angleBias(): number {
		return this.#fssaoMaterial.angleBias;
	}

	/** Sets the angle bias value */
	set angleBias(a: number) {
		this.#fssaoMaterial.angleBias = a;
	}

	/** @returns whether to use a large blur kernel */
	get useLargeBlurKernel(): boolean {
		return this.#fssaoBlurMaterial.uniforms.uLargeKernel.value;
	}

	/** Sets whether to use a large blur kernel */
	set useLargeBlurKernel(u: boolean) {
		this.#fssaoBlurMaterial.uniforms.uLargeKernel.value = u;
	}
}
