import { alignmentWizardModeReducer } from "./alignment-wizard-mode-slice";
import { cloudToCadAlignmentModeReducer } from "./cloud-to-cad-alignment-mode-slice";
import { exportModeReducer } from "./export-mode-slice";
import { layerToLayerAlignmentModeReducer } from "./layer-to-layer-alignment-mode-slice";
import { overviewModeReducer } from "./overview-mode-slice";
import { sheetToCadAlignmentModeReducer } from "./sheet-to-cad-alignment-mode-slice";
import { sheetToCloudAlignmentModeReducer } from "./sheet-to-cloud-alignment-mode-slice";
import { walkModeReducer } from "./walk-mode-slice";

export const modesReducers = {
  walkMode: walkModeReducer,
  overviewMode: overviewModeReducer,
  sheetToCadAlignmentMode: sheetToCadAlignmentModeReducer,
  cloudToCadAlignmentMode: cloudToCadAlignmentModeReducer,
  alignmentWizardMode: alignmentWizardModeReducer,
  sheetToCloudAlignmentMode: sheetToCloudAlignmentModeReducer,
  layerToLayerAlignmentMode: layerToLayerAlignmentModeReducer,
  exportMode: exportModeReducer,
};
