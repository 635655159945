import { SplitDirection } from "@/store/modes/alignment-ui-types";
import { useAppDispatch } from "@/store/store-hooks";
import { neutral, ViewDiv } from "@faro-lotv/app-component-toolbox";
import { IElement } from "@faro-lotv/ielement-types";
import { Stack } from "@mui/material";
import { useSplitDirection } from "../alignment-modes-commons/align-to-cad-utils";
import { CrossSectionToggleButton } from "../alignment-modes-commons/cross-section-toggle-button";
import { ScreenSplitDirectionToggle } from "../alignment-modes-commons/screen-split-direction-toggle";
import { useOverlayElements, useOverlayRef } from "../overlay-elements-context";
import { AlignmentElementLabel, LabelType } from "./alignment-element-label";

/**
 * Cross section button properties in split screen
 * A cross section button is used to enable/disable cad/cloud cross section clipping planes for tomographic view.
 */
export interface CrossSectionProps {
  /** if cross section is enabled */
  enabled: boolean;

  /** elevation where cross section will apply */
  elevation: number;

  /** callback function to toggle cross section state */
  onToggleCrossSection(): void;
}

type SplitScreenOverlayProps = {
  /** aligned Element which is rendered */
  alignedElement?: IElement;

  /** reference Element which is rendered */
  referenceElement?: IElement;

  /** The split screen split direction */
  splitDirection: SplitDirection;

  /** callback function to change split direction */
  onChangeSplitDirection?(): void;

  /**
   * optional cross section button properties in the first screen
   * If defined, the cross section button will be displayed in the first screen.
   * If not defined, there will not be cross section button.
   */
  firstScreenCrossSection?: CrossSectionProps;

  /**
   * optional cross section button properties in the second screen
   * If defined, the cross section button will be displayed in the second screen.
   * If not defined, there will not be cross section button.
   */
  secondScreenCrossSection?: CrossSectionProps;
};

/**
 * @returns Renderer of split screen overlay for alignment mode.
 *
 * This component sets up a side-by-side view with two `ViewDiv` components, each managed by `useOverlayRef`
 * to handle references for interactions. It occupies the full viewport using MUI's `Stack` component.
 *
 * For developers:
 * - `useOverlayElements` retrieves the context to manage screen overlays.
 * - `useOverlayRef` creates and registers the references for the two screens.
 * - Use `ViewDiv` to utilize full-screen space for content.
 *
 * For end users:
 * - Provides a seamless side-by-side comparison of point cloud data and CAD models.
 * - Facilitates accurate alignment with full viewport visibility and interaction.
 * - Users can manipulate and view both data sources simultaneously for effective comparison.
 */
export function AlignmentModeSplitScreenOverlay({
  alignedElement,
  referenceElement,
  splitDirection,
  onChangeSplitDirection,
  firstScreenCrossSection,
  secondScreenCrossSection,
}: SplitScreenOverlayProps): JSX.Element {
  const dispatch = useAppDispatch();
  const { setFirstScreen, setSecondScreen } = useOverlayElements();
  const firstScreenRef = useOverlayRef(setFirstScreen);
  const secondScreenRef = useOverlayRef(setSecondScreen);

  const { width, height } = useSplitDirection(splitDirection);

  return (
    <Stack
      direction={
        splitDirection === SplitDirection.horizontalSplit ? "row" : "column"
      }
      justifyItems="stretch"
      sx={{
        width: "100%",
        height: "100%",
      }}
    >
      <ViewDiv
        eventDivRef={firstScreenRef}
        sx={{
          height: { height },
          width: { width },
          flexGrow: 1,
          overflow: "hidden",
          border: `1px solid ${neutral[100]}`,
        }}
      >
        {firstScreenCrossSection && (
          <CrossSectionToggleButton
            isEnabled={firstScreenCrossSection.enabled}
            elevation={firstScreenCrossSection.elevation}
            onToggleCrossSections={() => {
              dispatch(firstScreenCrossSection.onToggleCrossSection);
            }}
          />
        )}

        {alignedElement && (
          <AlignmentElementLabel
            element={alignedElement}
            labelType={LabelType.alignedElement}
            sx={{
              top: "10px",
              left: "30px",
            }}
          />
        )}
      </ViewDiv>

      <ViewDiv
        eventDivRef={secondScreenRef}
        sx={{
          height: { height },
          width: { width },
          flexGrow: 1,
          overflow: "hidden",
          border: `1px solid ${neutral[100]}`,
        }}
      >
        {secondScreenCrossSection && (
          <CrossSectionToggleButton
            isEnabled={secondScreenCrossSection.enabled}
            elevation={secondScreenCrossSection.elevation}
            onToggleCrossSections={() => {
              dispatch(secondScreenCrossSection.onToggleCrossSection);
            }}
          />
        )}
        {referenceElement && (
          <AlignmentElementLabel
            element={referenceElement}
            labelType={LabelType.referenceElement}
            sx={{
              top: "10px",
              left: "30px",
            }}
          />
        )}
      </ViewDiv>
      {onChangeSplitDirection && (
        <ScreenSplitDirectionToggle
          splitDirection={splitDirection}
          onClick={onChangeSplitDirection}
        />
      )}
    </Stack>
  );
}
