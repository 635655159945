import { neutral } from "@faro-lotv/flat-ui";
import { Stack } from "@mui/material";
import { AlignmentWizardReferenceSelector } from "./align-wizard-reference-tree";

/**
 * @returns control to select reference element in Alignment Wizard
 */
export function ReferenceSelectorTool(): JSX.Element {
  return (
    <Stack
      direction="column"
      spacing={2}
      sx={{
        border: "1px",
        borderColor: neutral[200],
        borderLeftStyle: "solid",
        borderRightStyle: "solid",
        px: 1,
        pt: 1,
        backgroundColor: neutral[0],
        position: "absolute",
        height: "auto",
        width: "250px",
      }}
    >
      <AlignmentWizardReferenceSelector />
    </Stack>
  );
}
