import { CameraCenterData } from "@/hooks/use-center-camera-on-placeholders";
import { sheetCorners2Box, useSheetCorners } from "@/hooks/use-sheet-corners";
import { CadModelObject, PointCloudObject, SheetObject } from "@/object-cache";
import { useMultiCloudPointBudgetManager } from "@/registration-tools/common/rendering/use-multi-cloud-point-budget-manager";
import { usePointCloudMaterials } from "@/registration-tools/common/rendering/use-point-cloud-materials";
import { SplitDirection } from "@/store/modes/alignment-ui-types";
import { useAppSelector } from "@/store/store-hooks";
import {
  computeOrthoFrustumFromBox,
  green,
  selectIElementWorldTransform,
} from "@faro-lotv/app-component-toolbox";
import { useThree } from "@react-three/fiber";
import { useEffect, useLayoutEffect, useMemo, useState } from "react";
import {
  Box3,
  Euler,
  Matrix4,
  OrthographicCamera,
  PerspectiveCamera,
  Plane,
  Quaternion,
  Vector3,
} from "three";

/**
 * Sets the CAD material's parameters to values that are suitable for tomographic rendering.
 * Then set it back to what it was when we leave the mode.
 *
 * @param cadModel the CAD model to set the material of
 */
export function useCadTomographicMaterial(cadModel: CadModelObject): void {
  useEffect(() => {
    cadModel.setTomographic(true);

    return () => {
      cadModel.setTomographic(false);
    };
  }, [cadModel]);
}

/**
 * Sets the point cloud material's parameters to values that are suitable for tomographic rendering.
 * Then set it back to what it was when we leave the mode.
 *
 * @param pointCloud the point cloud object to set the material of
 * @param clippingPlanes the clipping planes that will apply to point cloud for tomographic view
 */
export function useCloudForAlignmentView(
  pointCloud: PointCloudObject,
  clippingPlanes: Plane[],
): void {
  usePointCloudMaterials({
    pointClouds: [pointCloud],
    colors: [green[600]],
    clippingPlanes,
  });

  useMultiCloudPointBudgetManager([pointCloud]);
}

// For the purpose of tomographic view and camera position in 2d alignment view, a
// rough 2D layout of a floor at certain elevation is defined using the CAD model
// geometry between the y range of (elevation - 0.1m, elevation + 1m).
const ELEVATION_RANGE_MIN = -0.1;
const ELEVATION_RANGE_MAX = 2.0;

/**
 * Get local clipping planes of CAD model at a certain floor elevation, so only selected floor is
 * visible in the 2D alignment view.
 *
 * The clipping planes need to be defined in the local transform of the CAD model, due to
 * the `TwoPointAlignment` tool squashs the CAD model in Y axis, the planes in world transform
 * will not work for `TwoPointAlignment`.
 *
 * @param cadModel The CAD model to be clipped at the elevation
 * @param elevation The given elevation in world coordinate to clip the CAD model between a defined range
 * @param isCrossSectionEnabled true to enable the clipping planes, false otherwise
 * @returns local clipping planes for the cad model at given elevation
 */
export function useClippingPlanesAtModelElevation(
  cadModel: CadModelObject,
  elevation: number | undefined,
  isCrossSectionEnabled: boolean,
): Plane[] {
  const { worldMatrix } = useAppSelector(
    selectIElementWorldTransform(cadModel.iElement.id),
  );

  return useMemo(() => {
    if (elevation === undefined || !isCrossSectionEnabled) return [];

    const modelPositionMatrix = new Matrix4().setPosition(cadModel.position);
    const worldMatrixInvert = new Matrix4()
      .fromArray(worldMatrix)
      .multiply(modelPositionMatrix)
      .invert();

    // set local clipping planes
    const plane1 = new Plane(
      new Vector3(0, 1, 0),
      -(elevation + ELEVATION_RANGE_MIN),
    ).applyMatrix4(worldMatrixInvert);
    const plane2 = new Plane(
      new Vector3(0, -1, 0),
      elevation + ELEVATION_RANGE_MAX,
    ).applyMatrix4(worldMatrixInvert);
    return [plane1, plane2];
  }, [cadModel.position, elevation, isCrossSectionEnabled, worldMatrix]);
}

/**
 * Get clipping planes in world coordinate for point cloud at a certain floor elevation, so only selected floor is
 * visible in the 2D alignment view.
 *
 * @param elevation The given elevation in world coordinate to clip the cloud between a defined range
 * @param isCrossSectionEnabled true to enable the clipping planes, false otherwise
 * @returns clipping planes in world coordinate for the point cloud at given elevation
 */
export function useClippingPlanesAtCloudElevation(
  elevation: number | undefined,
  isCrossSectionEnabled: boolean,
): Plane[] {
  return useMemo(() => {
    if (elevation === undefined || !isCrossSectionEnabled) return [];

    // set clipping planes in world coordinate space
    const plane1 = new Plane(
      new Vector3(0, 1, 0),
      -(elevation + ELEVATION_RANGE_MIN),
    );
    const plane2 = new Plane(
      new Vector3(0, -1, 0),
      elevation + ELEVATION_RANGE_MAX,
    );

    return [plane1, plane2];
  }, [elevation, isCrossSectionEnabled]);
}

/**
 * Center the camera on the CAD model, so the CAD model is visible in the 2D alignment view.
 *
 * @param cadModel The CAD model to center the camera on
 * @param cadBox The cad model bounding box in world
 * @param viewElement The element defines the viewport
 * @returns The data needed to center the camera
 */
export function useCenterCameraOnCadModel(
  cadModel: CadModelObject,
  cadBox: Box3,
  viewElement: HTMLDivElement,
): CameraCenterData {
  const aspectRatio = viewElement.clientWidth / viewElement.clientHeight;

  const quaternion = useRotationAroundGravity(cadModel);

  return useMemo(() => {
    const frustum = computeOrthoFrustumFromBox(cadBox, aspectRatio);
    const position = frustum.bboxCenter.clone();
    // make sure camera is always above the CAD model
    position.y += cadBox.getSize(new Vector3()).y;

    return {
      position,
      frustum,
      quaternion,
    };
  }, [quaternion, aspectRatio, cadBox]);
}

/**
 * Create a new orthographic camera.
 *
 * @returns A new orthographic camera. The camera's manual property is set to true,
 *  so R3F does not change it when resizing the canvas.
 */
export function useNewOrthographicCamera(): OrthographicCamera {
  const [camera] = useState<OrthographicCamera>(() => {
    const o = new OrthographicCamera();
    // If the manual property is not set or is false,
    // R3F automatically changes the camera when resizing the canvas
    Object.assign(o, { manual: true });
    return o;
  });

  return camera;
}

/**
 * Set the camera in the current state of the scene.
 *
 * @param camera The camera to set in the current state
 */
export function useCameraInCurrentScene(
  camera: OrthographicCamera | PerspectiveCamera,
): void {
  const set = useThree((s) => s.set);
  useLayoutEffect(() => {
    set({ camera });
  }, [camera, set]);
}

/**
 * Get rotation quaternion around Y axis in world from the POSE of the cad model
 *
 * Assume the rotation in POSE is achieved by euler rotations in the order of Y->Z->X.
 * The extracted quaternion around Y can be applied to the level plane placeholder so that
 * it can be rendered in the same z/x orientation as the cad model (in cad model coordinate).
 *
 * @param cadModel The given CAD model
 * @returns The rotation quaternion around gravity axis (world Y)
 */
export function useRotationAroundGravity(cadModel: CadModelObject): Quaternion {
  const { quaternion } = useAppSelector(
    selectIElementWorldTransform(cadModel.iElement.id),
  );

  return useMemo(() => {
    const euler = new Euler().setFromQuaternion(
      new Quaternion().fromArray(quaternion),
      "YZX",
    );

    return new Quaternion()
      .setFromAxisAngle(new Vector3(0, 1, 0), euler.y)
      .multiply(new Quaternion(-Math.SQRT1_2, 0, 0, Math.SQRT1_2));
  }, [quaternion]);
}

/**
 * Create a new Perspective camera.
 *
 * @returns A new Perspective camera.
 */
export function useNewPerspectiveCamera(): PerspectiveCamera {
  return useMemo<PerspectiveCamera>(() => new PerspectiveCamera(), []);
}

/**
 * Center the camera on given bounding box, so the 3d object within the bounding box is visible in the 2D alignment view.
 *
 * @param box The given bounding box in world
 * @param aspectRatio aspect ratio of the view element
 * @returns The data needed to center the camera
 */
export function useCenterCameraOnBoundingBoxIn2D(
  box: Box3,
  aspectRatio: number,
): CameraCenterData {
  return useMemo(() => {
    // rotate the camera to look at the cloud from the top
    const cameraQuaternion = new Quaternion().setFromAxisAngle(
      new Vector3(1, 0, 0),
      -Math.PI * 0.5,
    );

    const cameraFrustum = computeOrthoFrustumFromBox(box, aspectRatio);
    const position = cameraFrustum.bboxCenter.clone();
    // make sure camera is always above the bounding box
    position.y += box.getSize(new Vector3()).y;

    return {
      position,
      frustum: cameraFrustum,
      quaternion: cameraQuaternion,
    };
  }, [aspectRatio, box]);
}

/**
 * get width and height of split screen for given split direction
 *
 * @param splitDirection the given split screen split direction
 * @returns The data needed width and height
 */
export function useSplitDirection(splitDirection: SplitDirection): {
  width: string;
  height: string;
} {
  return useMemo(() => {
    const width =
      splitDirection === SplitDirection.horizontalSplit ? "50%" : "100%";
    const height =
      splitDirection === SplitDirection.verticalSplit ? "50%" : "100%";
    return { width, height };
  }, [splitDirection]);
}

/**
 * get bounding box of given sheet object from sheet corners.
 *
 * @param sheetObject the given sheet object
 * @returns the bounding box.
 */
export function useSheetBoundingBox(sheetObject: SheetObject): Box3 {
  const sheetCorners = useSheetCorners(sheetObject.iElement);

  return useMemo(() => sheetCorners2Box(sheetCorners), [sheetCorners]);
}
