import {
  GUID,
  validateNotNullishObject,
  validateOfType,
} from "@faro-lotv/foundation";
import { clearStore } from "@faro-lotv/project-source";
import {
  CaptureTreeEntityRevision,
  RegistrationEdgeRevision,
  RegistrationMetrics,
  RegistrationRevision,
  RevisionScanEntity,
  validateRegistrationMetrics,
} from "@faro-lotv/service-wires";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { isEdgeEqual } from "../utils/edge-utils";
import { clearUserEdits } from "./data-preparation-actions";
import {
  EntityTransformOverrides,
  RevisionTransformCache,
  generateRevisionTransformCache,
  updateRevisionTransformCache,
} from "./revision-transform-cache";

export type EntityMap = Record<GUID, CaptureTreeEntityRevision | undefined>;

/** The data we expect to be contained in a registration edge on the capture tree. */
export type RegistrationEdgeMetricsData = {
  metrics: RegistrationMetrics;
};

/**
 * @param data The data to validate
 * @returns true if the data is a valid `RegistrationEdgeMetricsData` object, else false.
 */
export function isRegistrationEdgeMetricsData(
  data: unknown,
): data is RegistrationEdgeMetricsData {
  return (
    validateNotNullishObject<RegistrationEdgeMetricsData>(
      data,
      "RegistrationEdgeData",
    ) && validateOfType(data, "metrics", validateRegistrationMetrics)
  );
}

export type EdgesMap = Record<GUID, RegistrationEdgeRevision | undefined>;

/**
 * A map from an entity ID to its visibility value.
 * `undefined` is to be treated as `true`, i.e. entities are visible by default.
 */
export type VisibilityMap = Record<GUID, boolean | undefined>;

type RevisionState = {
  /** The information about registration revision */
  registrationRevision: RegistrationRevision | undefined;

  /** The disjunct groups of the registration if any */
  disjunctGroups: GUID[][];

  /** A map from IDs to the corresponding entity in the revision. */
  entityMap: EntityMap;

  /** A map from IDs to the corresponding registration edge in the revision. */
  edgesMap: EdgesMap;

  /** The cached world transforms of the entities. */
  transformCache: RevisionTransformCache;

  /** The transform overrides to use for the transform calculation */
  transformOverrides: EntityTransformOverrides;

  /**
   * A map from an entity ID to its visibility value.
   * `undefined` is to be treated as `true`, i.e. entities are visible by default.
   */
  visibilityMap: VisibilityMap;
};

export const initialState: Readonly<RevisionState> = Object.freeze({
  registrationRevision: undefined,
  disjunctGroups: [],
  entityMap: {},
  edgesMap: {},
  transformCache: {},
  transformOverrides: {},
  visibilityMap: {},
});

const revisionSlice = createSlice({
  initialState,
  name: "revision",

  reducers: {
    /**
     * @param state The current application state
     * @param action The revision information to add to the store.
     */
    setRegistrationRevision(
      state,
      action: PayloadAction<RegistrationRevision>,
    ) {
      state.registrationRevision = action.payload;
    },

    /**
     * @param state The current application state
     * @param action The revision entities to add to the store.
     */
    addEntities(state, action: PayloadAction<CaptureTreeEntityRevision[]>) {
      // Create a new map, as the store should not be mutated directly
      const newMap: EntityMap = {
        // Add old entries
        ...state.entityMap,
      };

      // Add new entries
      action.payload.forEach((entity) => {
        newMap[entity.id] = entity;
      });

      // Re-generate the world transform cache
      const transformCache = generateRevisionTransformCache(
        newMap,
        state.transformOverrides,
      );

      state.entityMap = newMap;
      state.transformCache = transformCache;
    },

    /**
     * @param state The current application state
     * @param action The disjunct groups GUIDs to add to the store.
     */
    setDisjunctGroups(state, action: PayloadAction<GUID[][]>) {
      state.disjunctGroups = action.payload;
    },

    /**
     * @param state The current application state
     * @param action The revision edges to add to the store.
     */
    addRevisionEdges(state, action: PayloadAction<RegistrationEdgeRevision[]>) {
      for (const newEdge of action.payload) {
        // If an "equivalent" edge already exist, we need to reuse its ID to properly update the backend
        const oldEdge: RegistrationEdgeRevision | undefined = Object.values(
          state.edgesMap,
        ).find((edge) => edge && isEdgeEqual(edge, newEdge));
        const id = oldEdge?.id ?? newEdge.id;

        // Add the new edge
        state.edgesMap[id] = { ...newEdge, id };
      }
    },

    /**
     * @param state The current application state
     * @param action The IDs of the edges to delete from the store.
     */
    removeRevisionEdges(state, action: PayloadAction<GUID[]>) {
      for (const id of action.payload) {
        delete state.edgesMap[id];
      }
    },

    /**
     * Adding a transform override will override the local transform of an entity.
     * This will update the cached world position of the entity and all its children.
     *
     * @param state the current application state
     * @param action the transform override to add
     */
    addEntityTransformOverride(
      state,
      action: PayloadAction<{
        id: GUID;
        localTransform: RevisionScanEntity["pose"];
      }>,
    ) {
      state.transformOverrides[action.payload.id] =
        action.payload.localTransform;

      updateRevisionTransformCache(
        state.transformCache,
        state.entityMap,
        action.payload.id,
        state.transformOverrides,
      );
    },

    /**
     * Removes a transform override from the store, reverting it to its original transform from the revision.
     *
     * @param state the current application state
     * @param action the entity to remove the transform override for
     */
    removeEntityTransformOverride(state, action: PayloadAction<GUID>) {
      delete state.transformOverrides[action.payload];

      updateRevisionTransformCache(
        state.transformCache,
        state.entityMap,
        action.payload,
        state.transformOverrides,
      );
    },

    setEntityVisibility(
      state,
      action: PayloadAction<{
        id: GUID;
        isVisible: boolean;
      }>,
    ) {
      const { id, isVisible } = action.payload;

      if (isVisible) {
        // Default is true
        delete state.visibilityMap[id];
      } else {
        state.visibilityMap[id] = false;
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(clearStore, () => initialState);

    builder.addCase(clearUserEdits, (state) => {
      state.transformOverrides = {};

      state.transformCache = generateRevisionTransformCache(
        state.entityMap,
        state.transformOverrides,
      );
    });
  },
});

export const {
  setRegistrationRevision,
  addEntities,
  setDisjunctGroups,
  addRevisionEdges,
  removeRevisionEdges,
  addEntityTransformOverride,
  removeEntityTransformOverride,
  setEntityVisibility,
} = revisionSlice.actions;

export const revisionReducer = revisionSlice.reducer;
