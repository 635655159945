import { matrix4ToAlignmentTransform } from "@/alignment-tool/utils/alignment-transform";
import { selectActiveCadModel } from "@/store/cad/cad-slice";
import { SplitDirection } from "@/store/modes/alignment-ui-types";
import {
  selectCrossSectionEnabledForTomographicView,
  selectSheetElevation,
  selectSheetToCadAlignmentSplitDirection,
} from "@/store/modes/sheet-to-cad-alignment-mode-selectors";
import {
  setCrossSectionEnabled,
  setSheetToCadAlignmentSplitDirection,
} from "@/store/modes/sheet-to-cad-alignment-mode-slice";
import { useAppDispatch, useAppSelector } from "@/store/store-hooks";
import { selectIElementWorldMatrix4 } from "@/utils/transform-conversion-parsed";
import { isIElementAreaSection } from "@faro-lotv/ielement-types";
import { selectAncestor } from "@faro-lotv/project-source";
import { useCallback, useMemo } from "react";
import {
  AlignmentModeSplitScreenOverlay,
  CrossSectionProps,
} from "../alignment-modes-commons/alignment-mode-split-screen-overlay";
import { useSheetSelectedForAlignment } from "../mode-data-context";

/** @returns The sheet to cad alignment split screen */
export function SheetToCadAlignmentSplitScreen(): JSX.Element {
  const dispatch = useAppDispatch();
  const splitDirection = useAppSelector(
    selectSheetToCadAlignmentSplitDirection,
  );

  // callback function for changing split-screen direction
  const changeSplitDirection = useCallback(() => {
    const newDirection =
      splitDirection === SplitDirection.horizontalSplit
        ? SplitDirection.verticalSplit
        : SplitDirection.horizontalSplit;
    dispatch(setSheetToCadAlignmentSplitDirection(newDirection));
  }, [dispatch, splitDirection]);

  const isCrossSectionEnabled = useAppSelector(
    selectCrossSectionEnabledForTomographicView,
  );
  const sheetElevation = useAppSelector(selectSheetElevation);
  const sheet = useSheetSelectedForAlignment("sheetToCad");
  const area = useAppSelector(selectAncestor(sheet, isIElementAreaSection));

  const sheetMatrix = useAppSelector(selectIElementWorldMatrix4(sheet.id));
  const activeCad = useAppSelector(selectActiveCadModel);

  const cadCrossSectionProps: CrossSectionProps = useMemo(() => {
    const sheetTransform = matrix4ToAlignmentTransform(sheetMatrix);
    const elevation = sheetElevation ?? sheetTransform.position[1];
    return {
      enabled: isCrossSectionEnabled,
      elevation,
      onToggleCrossSection: () => {
        dispatch(setCrossSectionEnabled(!isCrossSectionEnabled));
      },
    };
  }, [dispatch, isCrossSectionEnabled, sheetElevation, sheetMatrix]);

  return (
    <AlignmentModeSplitScreenOverlay
      splitDirection={splitDirection}
      onChangeSplitDirection={changeSplitDirection}
      secondScreenCrossSection={cadCrossSectionProps}
      alignedElement={area}
      referenceElement={activeCad}
    />
  );
}
