import { GUID } from "@faro-lotv/foundation";
import { clearStore } from "@faro-lotv/project-source";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";

/** State managing data exchange during alignment Wizard session */
type AlignmentWizardToolState = {
  /** id of element which should be aligned; value is undefined when aligned element is not yet selected by user */
  elementToAlignId?: GUID;

  /** id of element which should used as reference for alignment; value is undefined when reference element is not yet selected by user */
  referenceElementId?: GUID;

  /**
   * id of area to which new layer should be uploaded and aligned
   * Please note that this value is used exclusively by sheet-to-sheet alignment
   * to pass area info for new layer from context menu to useCreateAreaLogic
   * This value is not used by internal logic of alignment wizard. It placed
   * here opportunistically to avoid creation new slice just for one variable
   *
   * Value is undefined outside of "add new layer" workflow
   */
  layerAreaElementId?: GUID;
};

const initialState: AlignmentWizardToolState = {};

const alignmentWizardModeSlice = createSlice({
  initialState,
  name: "alignmentWizardMode",
  reducers: {
    setWizardElementToAlignId(state, action: PayloadAction<GUID>) {
      state.elementToAlignId = action.payload;
    },

    setWizardReferenceElementId(state, action: PayloadAction<GUID>) {
      state.referenceElementId = action.payload;
    },

    setLayerAreaElementId(state, action: PayloadAction<GUID>) {
      state.layerAreaElementId = action.payload;
    },

    resetLayerAreaElementId(state) {
      state.layerAreaElementId = undefined;
    },

    resetWizardReferenceElementId(state) {
      state.referenceElementId = undefined;
    },

    resetAlignmentWizard(state) {
      state.elementToAlignId = undefined;
      state.referenceElementId = undefined;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(clearStore, () => initialState);
  },
});

export const {
  setWizardElementToAlignId,
  setWizardReferenceElementId,
  setLayerAreaElementId,
  resetLayerAreaElementId,
  resetWizardReferenceElementId,
  resetAlignmentWizard,
} = alignmentWizardModeSlice.actions;

export const alignmentWizardModeReducer = alignmentWizardModeSlice.reducer;
