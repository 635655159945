import { AlignmentTransform } from "@/alignment-tool/store/alignment-slice";
import { TwoPointsPairsAlignmentAnchorPositions } from "@/alignment-tool/utils/compute-split-screen-alignment";
import {
  AlignmentViewLayout,
  SplitDirection,
} from "@/store/modes/alignment-ui-types";
import { RootState } from "@/store/store";
import { GUID } from "@faro-lotv/foundation";
import { SheetToCloudAlignmentStep } from "./sheet-to-cloud-alignment-mode-slice";

/**
 * @returns the current sheet-to-cloud alignment step
 */
export function selectSheetToCloudAlignmentStep({
  sheetToCloudAlignmentMode,
}: RootState): SheetToCloudAlignmentStep {
  return sheetToCloudAlignmentMode.step;
}

/**
 * @param state  Current state
 * @returns ID of cloud used in current session of sheet-to-cloud alignment
 */
export function selectCloudForSheetToCloudAlignment(
  state: RootState,
): GUID | undefined {
  return state.sheetToCloudAlignmentMode.referenceCloudId;
}

/**
 * @param state  Current state
 * @returns ID of sheet used in current session of sheet-to-cloud alignment
 */
export function selectSheetForSheetToCloudAlignment(
  state: RootState,
): GUID | undefined {
  return state.sheetToCloudAlignmentMode.sheetIdToAlignWithCloud;
}

/**
 * @returns the current sheet-to-cloud alignment sheet elevation
 */
export function selectSheetToCloudAlignmentSheetElevation({
  sheetToCloudAlignmentMode,
}: RootState): number {
  return sheetToCloudAlignmentMode.sheetElevation;
}

/**
 * @returns the current sheet-to-cloud alignment isClippingBoxEnabled
 */
export function selectClippingBoxEnabled({
  sheetToCloudAlignmentMode,
}: RootState): boolean {
  return sheetToCloudAlignmentMode.isClippingBoxEnabled;
}

/**
 * @returns the current flag isCrossSectionEnabled for sheet-to-cloud alignment
 */
export function selectCrossSectionEnabled({
  sheetToCloudAlignmentMode,
}: RootState): boolean {
  return sheetToCloudAlignmentMode.isCrossSectionEnabled;
}

/**
 * @param state Current state
 * @returns The index of the current active sheet-to-cloud alignment step
 */

/**
 * @param state  Current state
 * @returns  anchor positions in split screen for sheet-to-cloud alignment
 */
export function selectAnchorPositionsForSheetToCloudAlignment(
  state: RootState,
): TwoPointsPairsAlignmentAnchorPositions {
  return state.sheetToCloudAlignmentMode.alignmentAnchorPositions;
}

/**
 * @param state  Current state
 * @returns incremental sheet-to-cloud transform in CS of previous pose or undefined if position/scale was not modified
 */
export function selectIncrementalSheetTransform(
  state: RootState,
): AlignmentTransform | undefined {
  return state.sheetToCloudAlignmentMode.incrementalTransform;
}

/**
 * @returns the current sheet-to-cloud alignment screen layout
 */
export function selectSheetToCloudAlignmentLayout({
  sheetToCloudAlignmentMode,
}: RootState): AlignmentViewLayout {
  return sheetToCloudAlignmentMode.alignmentLayout;
}

/**
 * @returns the current alignment screen split direction
 */
export function selectSheetToCloudAlignmentSplitDirection({
  sheetToCloudAlignmentMode,
}: RootState): SplitDirection {
  return sheetToCloudAlignmentMode.splitDirection;
}
