import { useAppDispatch, useAppSelector } from "@/store/store-hooks";
import { GUID } from "@faro-lotv/foundation";
import {
  RegistrationRevision,
  useApiClientContext,
} from "@faro-lotv/service-wires";
import { useEffect, useState } from "react";
import { selectRegistrationRevision } from "../store/revision-selectors";
import { setRegistrationRevision } from "../store/revision-slice";

/**
 * @param revisionId The ID of the revision to load.
 * @returns The revision with the given ID.
 *  While the revision is loading, `undefined` is returned.
 *  If the revision does not exist, an error is thrown.
 */
export function useLoadRevision(
  revisionId: GUID,
): RegistrationRevision | undefined {
  const dispatch = useAppDispatch();
  const { projectApiClient } = useApiClientContext();

  const revision = useAppSelector(selectRegistrationRevision);
  const [error, setError] = useState<Error>();

  useEffect(() => {
    const abortController = new AbortController();
    setError(undefined);

    projectApiClient
      .getRegistrationRevision(revisionId, abortController.signal)
      .then((registrationRevision) => {
        dispatch(setRegistrationRevision(registrationRevision));
      })
      .catch((error) => {
        if (abortController.signal.aborted) {
          // Ignore errors from unmounting
          return;
        }
        setError(error);
      });

    return () => {
      abortController.abort("useRevision hook unmounted");
    };
  }, [projectApiClient, revisionId, dispatch]);

  if (error) {
    throw error;
  }

  return revision;
}
