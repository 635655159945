import { GUID } from "@faro-lotv/foundation";
import { clearStore } from "@faro-lotv/project-source";
import { IElementInVolume } from "@faro-lotv/service-wires";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { AreaContentType } from "./area-contents-types";

/** Unique id to reference area contents of the "entire project" */
export const ENTIRE_PROJECT_KEY = "entire-project";

/** Keys for which area contents can be stored. Can be either an area's id, or the "entire project" */
export type AreaContentsKey = GUID | typeof ENTIRE_PROJECT_KEY;

/** A record holding an area's loaded contents by content type */
export type AreaContents = Partial<Record<AreaContentType, IElementInVolume[]>>;

export type AreaContentsState = {
  /** A record of all loaded area contents and the contents of the entire project */
  areaContents: Record<AreaContentsKey, AreaContents | undefined>;
};

export const AREA_CONTENTS_INITIAL_STATE: AreaContentsState = {
  areaContents: {},
};

const areaContentsSlice = createSlice({
  initialState: AREA_CONTENTS_INITIAL_STATE,
  name: "areaContents",
  reducers: {
    /**
     * Overwrites an area's contents for an AreaContentType.
     * Passing the ENTIRE_PROJECT_KEY as the areaId will set the area contents for the "entire project".
     *
     * @param state current state
     * @param action action payload
     */
    setAreaContents(
      state,
      action: PayloadAction<{
        areaContentsKey: AreaContentsKey;
        type: AreaContentType;
        elements: IElementInVolume[];
      }>,
    ) {
      const { areaContentsKey, type, elements } = action.payload;

      let areaRecord = state.areaContents[areaContentsKey];

      if (!areaRecord) {
        areaRecord = state.areaContents[areaContentsKey] = {};
      }

      areaRecord[type] = elements;
    },

    /**
     * Removes all loaded area contents except for the given area id.
     * Useful if other area contents might have changed, but the current state should be updated without unmounting the current data.
     * Passing the ENTIRE_PROJECT_KEY invalidate all but the "entire project" contents.
     *
     * @param state current state
     * @param action action payload
     */
    invalidateContents(
      state,
      action: PayloadAction<{
        except: AreaContentsKey;
      }>,
    ) {
      const keptContents = state.areaContents[action.payload.except];

      state.areaContents = {
        [action.payload.except]: keptContents,
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(clearStore, () => AREA_CONTENTS_INITIAL_STATE);
  },
});

export const { setAreaContents, invalidateContents } =
  areaContentsSlice.actions;

export const areaContentsReducer = areaContentsSlice.reducer;
