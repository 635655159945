import {
  EventType,
  ExportToolClippingProperties,
} from "@/analytics/analytics-events";
import { selectSelectedExportModeTab } from "@/store/modes/export-mode-selectors";
import { useAppSelector } from "@/store/store-hooks";
import { useBoxControlsContext } from "@/utils/box-controls-context";
import {
  AutoClipCurrentViewIcon,
  FaroTooltip,
  ResetIcon,
  Toolbar,
  ToolButton,
  ToolGroup,
} from "@faro-lotv/flat-ui";
import { Analytics } from "@faro-lotv/foreign-observers";
import { Stack } from "@mui/material";
import { useMemo } from "react";

/**
 * @returns the overlay for the export mode
 */
export function ExportModeOverlay(): JSX.Element {
  const { autoBoxEvent, resetBoxEvent } = useBoxControlsContext();

  // Setup properties for analytics events
  const selectedTab = useAppSelector(selectSelectedExportModeTab);
  const via = useMemo<ExportToolClippingProperties["via"]>(() => {
    if (selectedTab === "pointCloud") {
      return "point cloud";
    }
    return "orthophoto";
  }, [selectedTab]);

  return (
    <Stack
      // Use row-reverse to make the reset button appear on the right
      direction="row-reverse"
      justifyContent="space-between"
      sx={{
        height: "100%",
        width: "100%",
      }}
    >
      <Stack justifyContent="center">
        <Toolbar>
          <ToolGroup>
            <FaroTooltip
              title="Reset clipping box"
              placement="left"
              aria-label="reset clipping box"
            >
              <ToolButton
                onClick={() => {
                  Analytics.track<ExportToolClippingProperties>(
                    EventType.exportResetClip,
                    {
                      via,
                    },
                  );
                  resetBoxEvent.emit();
                }}
              >
                <ResetIcon sx={{ width: "24px", height: "24px" }} />
              </ToolButton>
            </FaroTooltip>
            <FaroTooltip title="Auto-clip to current view">
              <ToolButton
                onClick={() => {
                  Analytics.track<ExportToolClippingProperties>(
                    EventType.exportAutoClip,
                    {
                      via,
                    },
                  );
                  autoBoxEvent.emit();
                }}
              >
                <AutoClipCurrentViewIcon />
              </ToolButton>
            </FaroTooltip>
          </ToolGroup>
        </Toolbar>
      </Stack>
    </Stack>
  );
}
