import {
  CreateAnnotationEventProperties,
  EventType,
} from "@/analytics/analytics-events";
import {
  EmbeddedToolbar,
  EmbeddedToolbarButton,
} from "@/components/ui/embedded-toolbar";
import { useAnnotationPermissions } from "@/hooks/use-annotation-permissions";
import { ComponentsToDisplay } from "@/store/measurement-tool-slice";
import {
  ANNOTATION_ZINDEX_RANGE_MAP,
  AnnotationIcon,
  Checkmark2Icon,
  CoordinatesIcon,
  CopyIcon,
  Data3dIcon,
  DeleteIcon,
  MeasurePointIcon,
  MeasureShowAxesIcon,
  neutral,
} from "@faro-lotv/app-component-toolbox";
import { Analytics } from "@faro-lotv/foreign-observers";
import { SupportedUnitsOfMeasure } from "@faro-lotv/ielement-types";
import { Stack, SvgIconProps, Tooltip, Typography } from "@mui/material";
import {
  MutableRefObject,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Vector3 } from "three";
import { AppAwareHtml } from "../app-aware-html";
import { MEASURE_ANIMATION_LENGTH } from "./measure-constants";
import { MeasurementDescriptionContents } from "./measure-utils";

/** Time (ms) that the copied message box will be shown for, after which it will stop rendering */
const COPIED_POPUP_LIFE_TIME = 2000;

// Max value for z-index, since the measurements' labels created with Html from drei use a computed z-index
// that could be very big and would occlude the message.
const MAX_ZINDEX_VALUE = 2147483647;

export type CopyToClipboardParameters = {
  /** Which contents should be copied to the clipboard  */
  contents: MeasurementDescriptionContents;
  /** index of the first point of the segment (if contents !== "all") */
  firstPointIndex?: number;
};

declare type MeasureActionBarProp = {
  /** 3D point to anchor the html component to. */
  anchorPoint: Vector3;

  /** Is this measurement active. */
  isActive: boolean;

  /** The parent that the label should have in the html DOM */
  parentRef: MutableRefObject<HTMLElement>;

  /** Current selected unit of measure used to display the distances */
  unitOfMeasure: SupportedUnitsOfMeasure;

  /** Which components are displayed in a measurement. Undefined is not a measurement. */
  componentsToDisplay?: ComponentsToDisplay;

  /** Function to call when the create annotation button is clicked  */
  onCreateAnnotation?(): void;

  /** Function to call when one of the button of the "copy to clipboard" menu is clicked. */
  onCopyToClipboard(contentsToCopy: CopyToClipboardParameters): void;

  /** Function to call when the delete measurement button is clicked. */
  onDeleteMeasurement?(): void;

  /** Function to toggle between the available unit of measures */
  onToggleUnitOfMeasure(): void;

  /** Function to change the type of components to display */
  onChangeMeasurementComponentsToDisplay?(
    componentsToDisplay: ComponentsToDisplay,
  ): void;

  /**
   * Index of the first point of the segment associated wit this label;
   * undefined for the area label (i.e. not associated with a segment)
   */
  firstPointIndex?: number;
};

/**
 * @returns an action bar with an option to copy the measurements and an option to delete the measurement.
 */
export function MeasureActionBar({
  anchorPoint,
  isActive,
  parentRef,
  unitOfMeasure,
  componentsToDisplay,
  onCreateAnnotation,
  onCopyToClipboard,
  onDeleteMeasurement,
  onToggleUnitOfMeasure,
  onChangeMeasurementComponentsToDisplay,
  firstPointIndex,
}: MeasureActionBarProp): JSX.Element | null {
  const buttonSize = "36px";

  const { canWriteAnnotations } = useAnnotationPermissions();
  const toolTipForCreateAnnotation = useMemo(() => {
    if (!canWriteAnnotations) {
      return "Annotation creation is only available for Project Editors";
    }
    return "Create annotation";
  }, [canWriteAnnotations]);

  // Check to know if the copied to clipboard message is rendered or not
  const [isCopiedPopupShown, setIsCopiedPopupShown] = useState(false);

  const clipboardBtnRef = useRef<HTMLButtonElement>(null);

  // defined which submenu is active if any (undefined = no active submenu)
  const [activeSubMenu, setActiveSubMenu] = useState<
    undefined | "component" | "clipboard"
  >();

  const pointerEvents = isActive ? "auto" : "none";

  // reset isSubMenuActive to false whenever isActive is false
  useEffect(() => {
    if (!isActive && activeSubMenu) {
      setActiveSubMenu(undefined);
    }
  }, [isActive, activeSubMenu]);

  // perform the copy to clipboard action
  const copyToClipboard = useCallback(
    (contentsToCopy: CopyToClipboardParameters) => {
      setActiveSubMenu(undefined);
      setIsCopiedPopupShown(true);

      // Set timeout to close the copied to clipboard message
      setTimeout(() => setIsCopiedPopupShown(false), COPIED_POPUP_LIFE_TIME);

      onCopyToClipboard(contentsToCopy);
    },
    [setActiveSubMenu, setIsCopiedPopupShown, onCopyToClipboard],
  );

  // This state will control the tooltip for the Copy To Clipboard button.
  const [copyToClipboardTooltipOpen, setCopyToClipboardTooltipOpen] =
    useState(false);

  return (
    <>
      <AppAwareHtml
        portal={parentRef}
        position={anchorPoint}
        zIndexRange={ANNOTATION_ZINDEX_RANGE_MAP.toolbar}
        eps={-1}
        style={{
          pointerEvents,
          // Add offset to put the container on top of measurement label
          transform: "translate(-50% , -150% )",
        }}
      >
        <EmbeddedToolbar
          isActive={isActive}
          sx={{
            transition: `opacity ${MEASURE_ANIMATION_LENGTH}s linear`,
            backgroundColor: neutral[999],
            borderRadius: "6px",
          }}
        >
          <Tooltip title={toolTipForCreateAnnotation} placement="top">
            <EmbeddedToolbarButton
              aria-label="create annotation"
              buttonSize={buttonSize}
              value="Create annotation"
              disabled={!canWriteAnnotations}
              onClick={() => {
                setActiveSubMenu(undefined);
                if (onCreateAnnotation) {
                  Analytics.track<CreateAnnotationEventProperties>(
                    EventType.createAnnotation,
                    { shape: "measurement" },
                  );

                  onCreateAnnotation();
                }
              }}
            >
              <AnnotationIcon />
            </EmbeddedToolbarButton>
          </Tooltip>
          <Tooltip title="Change unit" placement="top">
            <EmbeddedToolbarButton
              aria-label="change unit"
              buttonSize={buttonSize}
              value="Change Unit"
              onClick={() => {
                setActiveSubMenu(undefined);
                onToggleUnitOfMeasure();
              }}
            >
              <Typography>{unitOfMeasure === "metric" ? "m" : "ft"}</Typography>
            </EmbeddedToolbarButton>
          </Tooltip>
          <Tooltip
            title={isCopiedPopupShown ? <CopiedTooltip /> : "Copy to clipboard"}
            PopperProps={{
              sx: {
                zIndex: MAX_ZINDEX_VALUE,
              },
            }}
            placement="top"
            open={copyToClipboardTooltipOpen || isCopiedPopupShown}
            onOpen={() => setCopyToClipboardTooltipOpen(true)}
            onClose={() => setCopyToClipboardTooltipOpen(false)}
          >
            <EmbeddedToolbarButton
              aria-label="copy to clipboard"
              buttonSize={buttonSize}
              value="Copy to clipboard"
              ref={clipboardBtnRef}
              onClick={() => {
                setActiveSubMenu(
                  activeSubMenu === "clipboard" ? undefined : "clipboard",
                );
              }}
            >
              <CopyIcon />
            </EmbeddedToolbarButton>
          </Tooltip>
          <ToggleComponentsButton
            componentsToDisplay={componentsToDisplay}
            onChangeMeasurementComponentsToDisplay={
              onChangeMeasurementComponentsToDisplay
            }
            onClick={() =>
              setActiveSubMenu(
                activeSubMenu === "component" ? undefined : "component",
              )
            }
            buttonSize={buttonSize}
          />
          <Tooltip
            title="Delete"
            placement="top"
            PopperProps={{
              sx: {
                zIndex: MAX_ZINDEX_VALUE,
              },
            }}
          >
            <EmbeddedToolbarButton
              aria-label="delete measurement"
              buttonSize={buttonSize}
              value="Delete"
              onClick={() => {
                setActiveSubMenu(undefined);
                onDeleteMeasurement?.();
              }}
            >
              <DeleteIcon sx={{ color: neutral[0] }} />
            </EmbeddedToolbarButton>
          </Tooltip>
        </EmbeddedToolbar>
      </AppAwareHtml>
      {isActive && activeSubMenu === "component" && (
        // This is the submenu for changing the components displayed in the measurement
        <AppAwareHtml
          portal={parentRef}
          position={anchorPoint}
          zIndexRange={ANNOTATION_ZINDEX_RANGE_MAP.toolbar}
          eps={-1}
          style={{
            pointerEvents,
            // Add offset to put the container on top of measurement label
            transform: "translate(+50% , -13% )",
          }}
        >
          <EmbeddedToolbar
            isActive={!!activeSubMenu}
            vertical
            sx={{
              transition: `opacity ${MEASURE_ANIMATION_LENGTH}s linear`,
              backgroundColor: neutral[999],
              borderRadius: "6px",
            }}
          >
            <Tooltip
              title="Display 3D distance"
              PopperProps={{
                sx: {
                  zIndex: MAX_ZINDEX_VALUE,
                },
              }}
              placement="right"
            >
              <EmbeddedToolbarButton
                aria-label="3d-components"
                buttonSize={buttonSize}
                vertical
                value="3D"
                onClick={() => {
                  if (onChangeMeasurementComponentsToDisplay) {
                    onChangeMeasurementComponentsToDisplay(
                      ComponentsToDisplay.single3d,
                    );
                  }
                  setActiveSubMenu(undefined);
                }}
              >
                <Data3dIcon sx={{ color: neutral[0] }} />
              </EmbeddedToolbarButton>
            </Tooltip>
            <Tooltip
              title="Display vertical and horizontal distances"
              PopperProps={{
                sx: {
                  zIndex: MAX_ZINDEX_VALUE,
                },
              }}
              placement="right"
            >
              <EmbeddedToolbarButton
                aria-label="horizontal-vertical-components"
                buttonSize={buttonSize}
                vertical
                value="HorizontalVertical"
                onClick={() => {
                  if (onChangeMeasurementComponentsToDisplay) {
                    onChangeMeasurementComponentsToDisplay(
                      ComponentsToDisplay.heightAndHorizontal,
                    );
                  }
                  setActiveSubMenu(undefined);
                }}
              >
                <MeasureShowAxesIcon sx={{ color: neutral[0] }} />
              </EmbeddedToolbarButton>
            </Tooltip>
            <Tooltip
              title="Display distances along x, y, and z axes"
              PopperProps={{
                sx: {
                  zIndex: MAX_ZINDEX_VALUE,
                },
              }}
              placement="right"
            >
              <EmbeddedToolbarButton
                aria-label="xyz-components"
                buttonSize={buttonSize}
                vertical
                value="XYZ"
                onClick={() => {
                  if (onChangeMeasurementComponentsToDisplay) {
                    onChangeMeasurementComponentsToDisplay(
                      ComponentsToDisplay.xyz,
                    );
                    setActiveSubMenu(undefined);
                  }
                }}
              >
                <CoordinatesIcon sx={{ color: neutral[0] }} />
              </EmbeddedToolbarButton>
            </Tooltip>
          </EmbeddedToolbar>
        </AppAwareHtml>
      )}
      {isActive && activeSubMenu === "clipboard" && (
        <CopyToClipboardSubMenu
          parentRef={parentRef}
          anchorPoint={anchorPoint}
          buttonSize={buttonSize}
          copyToClipboard={copyToClipboard}
          firstPointIndex={firstPointIndex}
        />
      )}
    </>
  );
}

type CopyToClipboardSubMenuProps = {
  /** The parent that the label should have in the html DOM */
  parentRef: MutableRefObject<HTMLElement>;

  /** 3D point to anchor the html component to. */
  anchorPoint: Vector3;

  /** Size of the buttons */
  buttonSize: string;

  /** Callback to be called when user clicked on one of the menu */
  copyToClipboard(contentsToCopy: CopyToClipboardParameters): void;

  /** index of the first point for this segment */
  firstPointIndex?: number;
};

/**
 * @returns the submenu for copying the measurement to the clipboard.
 */
function CopyToClipboardSubMenu({
  parentRef,
  anchorPoint,
  buttonSize,
  copyToClipboard,
  firstPointIndex,
}: CopyToClipboardSubMenuProps): JSX.Element {
  return (
    <AppAwareHtml
      portal={parentRef}
      position={anchorPoint}
      zIndexRange={ANNOTATION_ZINDEX_RANGE_MAP.toolbar}
      eps={-1}
      style={{
        // Add offset to put the container on top of measurement label
        transform: "translate(-10% , -13% )",
      }}
    >
      <EmbeddedToolbar
        isActive={true}
        vertical
        sx={{
          transition: `opacity ${MEASURE_ANIMATION_LENGTH}s linear`,
          backgroundColor: neutral[999],
          borderRadius: "6px",
        }}
        alignItems="stretch"
      >
        <CopyClipboardButton
          label="Copy distance"
          ariaLabel="copy-distance"
          value="CopyDistance"
          tooltip={
            firstPointIndex === undefined
              ? "Copying distance is only available for individual segments"
              : "Copy the distance displayed for this segment"
          }
          Icon={MeasureShowAxesIcon}
          onClick={() => {
            copyToClipboard({
              contents: "distance",
              firstPointIndex,
            });
          }}
          buttonSize={buttonSize}
          disabled={firstPointIndex === undefined}
        />
        <CopyClipboardButton
          label="Copy coordinates"
          ariaLabel="copy-coordinates"
          value="CopyCoordinates"
          tooltip="Copy the coordinates of all points"
          Icon={MeasurePointIcon}
          onClick={() => {
            copyToClipboard({
              contents: "coordinates",
            });
          }}
          buttonSize={buttonSize}
        />
        <CopyClipboardButton
          ariaLabel="copy-all"
          value="CopyAll"
          label="Copy all"
          tooltip="Copy the measurements and the coordinates of all segments"
          Icon={CopyIcon}
          onClick={() => {
            copyToClipboard({ contents: "all" });
          }}
          buttonSize={buttonSize}
        />
      </EmbeddedToolbar>
    </AppAwareHtml>
  );
}

/**
 * @returns the tooltip message after the user copies the measurement to the clipboard.
 */
function CopiedTooltip(): JSX.Element {
  return (
    <Stack direction="row" gap={1}>
      <Checkmark2Icon sx={{ width: "20px", height: "20px" }} />

      <Typography color="gray100" fontSize="14px">
        Copied to clipboard
      </Typography>
    </Stack>
  );
}

/** Parameters for ToggleComponentsButton component. */
declare type ToggleComponentsButtonProps = Pick<
  MeasureActionBarProp,
  "componentsToDisplay" | "onChangeMeasurementComponentsToDisplay"
> & {
  /** Size of the button */
  buttonSize: string;
  /** Called whenever the user click on the button */
  onClick(): void;
};

/**
 * @returns the button for toggling between displaying 3D and components distances.
 */
function ToggleComponentsButton({
  componentsToDisplay,
  onChangeMeasurementComponentsToDisplay,
  onClick,
  buttonSize,
}: ToggleComponentsButtonProps): JSX.Element {
  const tooltipForToggleMeasurementComponents = useMemo(() => {
    if (onChangeMeasurementComponentsToDisplay) {
      return "Change the components displayed for this measurement";
    }

    return "Displayed components can only be changed for 2-point measurements";
  }, [onChangeMeasurementComponentsToDisplay]);

  return (
    <Tooltip
      title={tooltipForToggleMeasurementComponents}
      placement="top"
      PopperProps={{
        sx: {
          zIndex: MAX_ZINDEX_VALUE,
        },
      }}
    >
      <EmbeddedToolbarButton
        aria-label="toggle measurements"
        buttonSize={buttonSize}
        value="Toggle measurements"
        onClick={() => {
          onClick();
        }}
        disabled={!onChangeMeasurementComponentsToDisplay}
      >
        {componentsToDisplay === ComponentsToDisplay.single3d && (
          <Data3dIcon sx={{ color: neutral[0] }} />
        )}
        {componentsToDisplay === ComponentsToDisplay.heightAndHorizontal && (
          <MeasureShowAxesIcon sx={{ color: neutral[0] }} />
        )}
        {componentsToDisplay === ComponentsToDisplay.xyz && (
          <CoordinatesIcon sx={{ color: neutral[0] }} />
        )}
      </EmbeddedToolbarButton>
    </Tooltip>
  );
}

type CopyClipboardButtonProps = {
  /** Text displayed in the button */
  label: string;
  /** Value associated with the button */
  value: string;
  /** Aria-label for the button */
  ariaLabel: string;
  /** Message for the tooltip */
  tooltip: string;
  /** Callback called when user clicked on the button */
  onClick(): void;
  /** Icon component to be displayed before the label */
  Icon(props: SvgIconProps): JSX.Element;
  /** Size of the buttons */
  buttonSize: string;
  /** True if te user should not be able to click on the button */
  disabled?: boolean;
};

/**
 * @returns one button in the "Copy to Clipboard" submenu
 */
function CopyClipboardButton({
  value,
  label,
  ariaLabel,
  tooltip,
  onClick,
  Icon,
  buttonSize,
  disabled,
}: CopyClipboardButtonProps): JSX.Element {
  return (
    <Tooltip
      title={tooltip}
      PopperProps={{
        sx: {
          zIndex: MAX_ZINDEX_VALUE,
        },
      }}
      placement="right"
    >
      <EmbeddedToolbarButton
        aria-label={ariaLabel}
        buttonSize={buttonSize}
        fullWidth
        value={value}
        onClick={onClick}
        vertical
        sx={{
          whiteSpace: "nowrap",
          width: "100%",
          justifyContent: "left",
        }}
        size="small"
        disabled={disabled}
      >
        <Icon
          sx={{
            width: 16,
            height: 16,
          }}
        />{" "}
        {label}
      </EmbeddedToolbarButton>
    </Tooltip>
  );
}
