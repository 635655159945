import { Stack, StackProps } from "@mui/material";

/**
 * @returns A `Stack` element which does not capture any pointer events.
 *          It allows you to click "through" the stack to elements behind it.
 */
export function ClickThroughStack({ sx, ...props }: StackProps): JSX.Element {
  return (
    <Stack
      component="div"
      sx={[
        {
          // Prevent this element from capturing pointer events, but allow children to do so
          pointerEvents: "none",
          "& > *": {
            pointerEvents: "auto",
          },
        },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
      {...props}
    />
  );
}
