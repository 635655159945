import { humanReadableDistance } from "@faro-lotv/app-component-toolbox";
import { SupportedUnitsOfMeasure } from "@faro-lotv/ielement-types";
import { useMemo } from "react";
import { TextLabel, TextLabelProps } from "../text-label";

type MeasureLabelProps = Omit<TextLabelProps, "text"> & {
  /** The the number to show in the label representing the length. */
  distance: number;
  /** Optional units symbol.*/
  unitOfMeasure: SupportedUnitsOfMeasure;
};

/**
 * @returns a Html label that displays a measurement and moves in the 3D scene along with a given 3D position.
 */
export function MeasureLabel({
  index,
  visible,
  position,
  parentRef,
  distance: distanceInMeters,
  symbol,
  unitOfMeasure,
  labelsScreenPositionsComputer,
  active,
  onClick,
  onPointerDown,
  onContextMenu,
  transparent,
  pointerEvents,
  placement,
}: MeasureLabelProps): JSX.Element | null {
  const distance = useMemo(
    () => humanReadableDistance(distanceInMeters, unitOfMeasure),
    [distanceInMeters, unitOfMeasure],
  );

  return (
    <TextLabel
      index={index}
      visible={visible}
      position={position}
      parentRef={parentRef}
      text={distance}
      symbol={symbol}
      labelsScreenPositionsComputer={labelsScreenPositionsComputer}
      active={active}
      onClick={onClick}
      transparent={transparent}
      pointerEvents={pointerEvents}
      placement={placement}
      onPointerDown={onPointerDown}
      onContextMenu={onContextMenu}
    />
  );
}
