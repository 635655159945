import { HorizontalResizeContainer } from "@/components/ui/resize-container";
import { useAppSelector } from "@/store/store-hooks";
import {
  ExclamationMarkCircleFillIcon,
  FaroText,
  neutral,
  NoTranslate,
  yellow,
} from "@faro-lotv/flat-ui";
import { selectProjectName } from "@faro-lotv/project-source";
import { CaptureTreeEntityRevision } from "@faro-lotv/service-wires";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Stack,
} from "@mui/material";
import { useMemo } from "react";
import {
  selectFilteredDisjunctGroups,
  selectRevisionEntities,
  selectScanTypeLabel,
} from "../store/revision-selectors";
import { ScanTree } from "./scan-tree/scan-tree";

/** @returns Sidebar to use throughout the data preparation tool. */
export function DataPreparationSidebar(): JSX.Element {
  const projectName = useAppSelector(selectProjectName) ?? "Project";
  const scanTypeLabel = useAppSelector(selectScanTypeLabel);

  return (
    <ScanTreeSidebar projectName={projectName} dataTitle={scanTypeLabel} />
  );
}

type ScanTreeSidebarProps = {
  /** The name of the project containing the revision. */
  projectName: string;

  /** The title for the data being shown to the user. */
  dataTitle: string;
};

/** @returns a sidebar with containing a ScanTree with customizable title and action buttons */
function ScanTreeSidebar({
  projectName,
  dataTitle,
}: ScanTreeSidebarProps): JSX.Element {
  const entities = useAppSelector(selectRevisionEntities);

  return (
    <HorizontalResizeContainer
      initialWidth={320}
      minWidth={200}
      maxWidth={500}
      handleSide="right"
    >
      <Stack
        justifyContent="space-between"
        flexShrink={0}
        sx={{
          width: "100%",
          height: "100%",
          px: 1.5,
          py: 3,
        }}
      >
        <Stack gap={2} height="100%">
          <Stack gap={1.5}>
            <NoTranslate>
              <FaroText variant="heading16">{projectName}</FaroText>
            </NoTranslate>
            <FaroText variant="heading14">{dataTitle}</FaroText>
          </Stack>

          <ScanTree entities={entities} />
        </Stack>
        <IssuesPanel entities={entities} />
      </Stack>
    </HorizontalResizeContainer>
  );
}

/**
 * @param entities The entities to use in search of the issues in the IssuesPanel.
 */
type IssuesPanelProps = {
  entities: CaptureTreeEntityRevision[];
};

/** @returns a panel displaying the issues that exist with the scans */
function IssuesPanel({ entities }: IssuesPanelProps): JSX.Element | null {
  const disjunctGroups = useAppSelector(selectFilteredDisjunctGroups);
  const entityNames = useMemo(
    () => getNamesFromEntitiesAndGroups(entities, disjunctGroups),
    [entities, disjunctGroups],
  );

  if (entityNames.length === 0) {
    return null;
  }

  return (
    <Stack gap={2} height="25%">
      <Stack gap={1.5}>
        <Accordion
          // Styled to match the design of the `RegistrationCard` component
          sx={{
            width: "100%",
            background: yellow[50],
            boxShadow: "none",
            borderRadius: 0.5,
            border: "solid rgba(0, 0, 0, 0.12) 1px",
            overflow: "hidden",
          }}
          expanded={true}
        >
          <AccordionSummary aria-controls="panel-content">
            <ExclamationMarkCircleFillIcon sx={{ color: "warning.main" }} />
            <FaroText variant="heading16" sx={{ ml: 1 }}>
              {entityNames.length} Issues
            </FaroText>
          </AccordionSummary>

          <AccordionDetails sx={{ maxHeight: 200, overflow: "auto" }}>
            <FaroText variant="heading12" color={neutral[500]}>
              Unconnected Scans
            </FaroText>
            <Stack gap={1} sx={{ mt: 2 }}>
              {entityNames.map((entityName, index) => (
                <FaroText key={index} variant="bodyM">
                  {entityName}
                </FaroText>
              ))}
            </Stack>
          </AccordionDetails>
        </Accordion>
      </Stack>
    </Stack>
  );
}

/**
 * @param entities The entities to extract names from.
 * @param disjunctGroups The disjunct groups to extract names from.
 * @returns An array of names from the entities and disjunct groups.
 */
function getNamesFromEntitiesAndGroups(
  entities: CaptureTreeEntityRevision[],
  disjunctGroups: string[][] | undefined,
): string[] {
  if (!disjunctGroups) return [];

  const entityMap = new Map(entities.map((entity) => [entity.id, entity.name]));
  const rootName = entities.find((entity) => !entity.parentId)?.name;

  return entities
    .filter(
      (entity) => entity.parentId && disjunctGroups.flat().includes(entity.id),
    )
    .map((entity) => {
      // we know that the parent exists because we filtered out the root
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const parentName = entityMap.get(entity.parentId!);
      return parentName && parentName !== rootName
        ? `${parentName} - ${entity.name}`
        : entity.name;
    });
}
