import { useAppSelector } from "@/store/store-hooks";
import { selectIElementFloorPosition } from "@/utils/camera-transform";
import {
  isSingleScanSection,
  roundedCalculatePosition,
  selectAncestor,
  selectIElement,
  selectIElementWorldPosition,
} from "@faro-lotv/app-component-toolbox";
import {
  IElement,
  IElementImg360,
  IElementType,
  IElementTypeHint,
  isIElementGenericDataset,
  isIElementSection,
  isIElementTimeseries,
  isIElementWithTypeAndHint,
} from "@faro-lotv/ielement-types";
import { Box, useTheme } from "@mui/material";
import { isEqual } from "es-toolkit";
import { useCallback, useEffect, useRef, useState } from "react";
import { Vector3, Vector3Tuple } from "three";
import { AppAwareHtml } from "../renderers/app-aware-html";
import { PlaceholderPreviewBase } from "./placeholder-preview-base";

/** The offset that needs to be applies for popper from its anchor element in px */
const POPPER_OFFSET = 24;

/**
 * @param placeholder - waypoint placeholder
 * @returns element to identify waypoint name for different types of scans (E57, Focus scan, Orbis)
 */
export function useWaypointReference(
  placeholder: IElementImg360 | undefined,
): IElement | undefined {
  let referenceElement: IElement | undefined = placeholder;
  const refElementParent = useAppSelector(
    selectIElement(referenceElement?.parentId),
  );

  // name to be displayed should be name of room section, which is parent of time series
  const placeholderTimeSeries = useAppSelector(
    selectAncestor(placeholder, isIElementTimeseries),
  );

  // Use the parent section for name/date if the 360s is not part of a path
  const placeholderSection = useAppSelector(
    selectAncestor(placeholderTimeSeries, isIElementSection),
  );

  if (
    refElementParent &&
    (isIElementWithTypeAndHint(
      refElementParent,
      IElementType.section,
      IElementTypeHint.structuredE57,
    ) ||
      isSingleScanSection(refElementParent))
  ) {
    referenceElement = refElementParent;
  } else {
    referenceElement =
      placeholder?.typeHint === IElementTypeHint.odometryPath
        ? placeholder
        : placeholderSection;
  }

  return referenceElement;
}

/** Add an offset to the popper to position it just below the placeholder. */
const POPPER_MODIFIERS = [
  {
    name: "offset",
    options: {
      offset: [0, POPPER_OFFSET],
    },
  },
];

type PlaceholderPreviewProps = {
  /** The placeholder Img360 which is currently being hovered */
  placeholder?: IElementImg360;

  /** Position at which the placeholder preview should appear */
  position?: Vector3;
};

/**
 * @returns  A dialog that displays a preview of an Img360 placeholder on hover,
 * while handling warm-up and cool-down transitions. The warm-up transition
 * introduces a delay before showing the preview window upon hovering,
 * ensuring a smooth and deliberate interaction. The cool-down transition
 * introduces a delay before hiding the preview window after moving the
 * pointer away, preventing flickering or abrupt disappearance.
 *
 * These transitions contribute to a better user experience by providing a
 * subtle delay for previewing and dismissing the content, reducing visual
 * noise and creating a more polished interaction.
 */
export function PlaceholderPreview({
  placeholder,
  position: customPosition,
}: PlaceholderPreviewProps): JSX.Element | null {
  const theme = useTheme();

  /**
   * The duration used for both fade in/out of window as well as the warm up and cool down time to make the window visible/invisible
   * i.e handles the delay between pointer hover and the popup visibility both while hovering in (warm-up) and hovering out (cool-down)
   */
  const transitionDuration = theme.transitions.duration.shorter;

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [isHovering, setIsHovering] = useState(false);
  const [cachedPlaceholder, setCachedPlaceholder] = useState<IElementImg360>();
  const [cachedPosition, setCachedPosition] = useState<
    Vector3 | Vector3Tuple
  >();

  const referenceElement = useWaypointReference(cachedPlaceholder);
  const parentDataset = useAppSelector(
    selectAncestor(placeholder, isIElementGenericDataset),
  );

  const position = useAppSelector(
    (state) =>
      // use the floor position to avoid the placeholder being placed out of view in (video mode) paths with large vertical components
      cachedPlaceholder
        ? selectIElementFloorPosition(cachedPlaceholder)(state)
        : undefined,
    isEqual,
  );

  const placeholderPosition = useAppSelector(
    selectIElementWorldPosition(placeholder?.id),
  );

  useEffect(() => {
    setCachedPosition(customPosition ?? position);
  }, [customPosition, position]);

  const onPointerInDebounced = useRef<ReturnType<typeof setTimeout>>();
  const onPointerIn = useCallback(() => {
    clearTimeout(onPointerInDebounced.current);

    onPointerInDebounced.current = setTimeout(() => {
      setIsHovering(true);
      setCachedPlaceholder(placeholder);
    }, transitionDuration);
  }, [placeholder, transitionDuration]);

  const onPointerOutDebounced = useRef<ReturnType<typeof setTimeout>>();
  const onPointerOut = useCallback(() => {
    clearTimeout(onPointerOutDebounced.current);

    // isHovering is made false so that the fade out animation can start
    setIsHovering(false);

    onPointerOutDebounced.current = setTimeout(() => {
      setCachedPlaceholder(undefined);
      setCachedPosition(undefined);
    }, transitionDuration);
  }, [transitionDuration]);

  useEffect(() => {
    if (placeholder) {
      onPointerIn();
    } else if (cachedPlaceholder) {
      onPointerOut();
    } else {
      // The user has hovered over the placeholder too quickly, so cancel the onPointerInDebounced
      clearTimeout(onPointerInDebounced.current);
    }
  }, [cachedPlaceholder, onPointerIn, onPointerOut, placeholder]);

  if (!cachedPlaceholder || !cachedPosition || !referenceElement) return null;

  const imageUri =
    cachedPlaceholder.thumbnailUri ??
    JSON.parse(cachedPlaceholder.json1x1)?.sources[0] ??
    cachedPlaceholder.uri;

  return (
    <AppAwareHtml
      position={cachedPosition}
      calculatePosition={roundedCalculatePosition}
      style={{
        backdropFilter: "blur(4px) brightness(40%)",
        borderRadius: 6,
      }}
    >
      {/* anchor element where the popper attaches to */}
      <Box component="div" ref={setAnchorEl} />
      {anchorEl && (
        <PlaceholderPreviewBase
          name={referenceElement.name}
          imageUri={imageUri}
          createdAt={placeholder?.createdAt}
          isVisible={isHovering}
          anchorEl={anchorEl}
          datasetName={parentDataset?.name}
          coordinates={parentDataset ? placeholderPosition : undefined}
          popperModifiers={POPPER_MODIFIERS}
        />
      )}
    </AppAwareHtml>
  );
}
