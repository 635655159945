import { useCurrentProjectApiClient } from "@/components/common/project-provider/project-loading-context";
import { updateProject } from "@/components/common/project-provider/update-project";
import { useErrorHandlers } from "@/errors/components/error-handling-context";
import { useAppDispatch, useAppStore } from "@/store/store-hooks";
import { selectIElementWorldMatrix4 } from "@/utils/transform-conversion-parsed";
import {
  FaroDialog,
  FaroRadio,
  FaroRadioGroup,
  FaroText,
  selectIElement,
  selectIElementProjectApiLocalPose,
  selectIElementWorldTransform,
  useToast,
} from "@faro-lotv/app-component-toolbox";
import { assert, GUID } from "@faro-lotv/foundation";
import { isIElementBimModelSection } from "@faro-lotv/ielement-types";
import {
  createMutationSetElementPosition,
  createMutationSetElementRotation,
  createMutationSetElementScale,
} from "@faro-lotv/service-wires";
import { FormControlLabel, Stack, Typography } from "@mui/material";
import { useCallback, useRef, useState } from "react";
import { Matrix4 } from "three";

export type TransformCadDialogProps = {
  /* true to display the dialog, false to keep it hidden */
  open: boolean;

  /** GUID of the Model being edited */
  idIElementModel3dStream: GUID;

  /** callback to be called when user close/cancel the dialog */
  onClose(): void;
};

/**
 * @returns component displaying a dialog asking user to apply a transformation on the CAD
 */
export function TransformCadDialog({
  open,
  idIElementModel3dStream,
  onClose,
}: TransformCadDialogProps): JSX.Element | null {
  const store = useAppStore();
  const projectApi = useCurrentProjectApiClient();
  const { handleErrorWithToast } = useErrorHandlers();
  const { openToast } = useToast();
  const dispatch = useAppDispatch();
  const [isConfirmDisabled, setConfirmButtonDisabled] = useState(true);
  const [transformInProgress, setTransformInProgress] = useState(false);

  // Which transformation to apply to Cad
  const userChoice = useRef<TransformationType>();

  // Called when user pressed Apply button in Transform Model dialog
  const applyTransformationToCad = useCallback(async (): Promise<void> => {
    const model3DStreamElement = selectIElement(idIElementModel3dStream)(
      store.getState(),
    );
    assert(model3DStreamElement, "Invalid CAD stream");
    const bimModelSectionElement = selectIElement(
      model3DStreamElement.parentId,
    )(store.getState());
    assert(
      bimModelSectionElement &&
        isIElementBimModelSection(bimModelSectionElement),
      "Invalid 3D Model Element in applyTransformationToCad",
    );

    setTransformInProgress(true);

    // get current CAD transformation
    const streamWorldMatrix = selectIElementWorldMatrix4(
      idIElementModel3dStream,
    )(store.getState());

    // Compute the new transformation to apply to the CAD
    function getNewCadInWorld(): Matrix4 | undefined | false {
      switch (userChoice.current) {
        case TransformationType.rotateX:
          return streamWorldMatrix.multiply(
            new Matrix4().makeRotationX(Math.PI / 2),
          );
        case TransformationType.rotateY:
          return streamWorldMatrix.multiply(
            new Matrix4().makeRotationY(Math.PI / 2),
          );
        case TransformationType.rotateZ:
          return streamWorldMatrix.multiply(
            new Matrix4().makeRotationZ(Math.PI / 2),
          );
        case TransformationType.reset:
          return undefined;
        default:
          return false;
      }
    }
    const newCadInWorld = getNewCadInWorld();
    const cadParent = selectIElement(bimModelSectionElement.parentId)(
      store.getState(),
    );
    const cadParentWorldTransform = selectIElementWorldTransform(cadParent?.id)(
      store.getState(),
    );

    // the mutation will combine the invert optional transformation of the CAD`s parent
    const mutationTransform = newCadInWorld
      ? selectIElementProjectApiLocalPose(
          bimModelSectionElement,
          new Matrix4()
            .fromArray(cadParentWorldTransform.worldMatrix)
            .invert()
            .multiply(newCadInWorld),
        )(store.getState())
      : undefined;

    // apply the mutation updating the CAD transformation
    try {
      const mutations = [
        createMutationSetElementPosition(
          bimModelSectionElement.id,
          mutationTransform ? mutationTransform.pos : { x: 0, y: 0, z: 0 },
        ),
        createMutationSetElementRotation(
          bimModelSectionElement.id,
          mutationTransform
            ? mutationTransform.rot
            : { x: 0, y: 0, z: 0, w: 1 },
        ),
        createMutationSetElementScale(bimModelSectionElement.id, {
          x: 1,
          y: 1,
          z: 1,
        }),
      ];

      await projectApi.applyMutations(mutations);

      // Update the IElement tree
      await dispatch(
        updateProject({
          projectApi,
          iElementQuery: {
            // We only need to fetch the subtree starting from the modified element
            ancestorIds: [bimModelSectionElement.id],
          },
        }),
      );

      openToast({
        title: "CAD transformation successfully applied",
        variant: "success",
      });
    } catch (error) {
      handleErrorWithToast({
        title: "Failed to save new CAD transformation",
        error,
      });
    }

    setTransformInProgress(false);
  }, [
    handleErrorWithToast,
    projectApi,
    store,
    openToast,
    dispatch,
    idIElementModel3dStream,
  ]);

  return (
    <FaroDialog
      title="Rotate Model"
      open={open}
      onConfirm={applyTransformationToCad}
      onCancel={onClose}
      isConfirmDisabled={isConfirmDisabled}
      dark
      confirmText="Apply"
      showXButton
      showSpinner={transformInProgress}
    >
      <Stack gap={3}>
        <Typography>Select the rotation to apply to the model</Typography>
        <FaroRadioGroup
          onChange={(v) => {
            userChoice.current = stringToTransformationType(v.target.value);
            setConfirmButtonDisabled(false);
          }}
        >
          <FormControlLabel
            value="rotateZ"
            control={<FaroRadio dark />}
            label={
              <FaroText variant="bodyL" dark>
                Rotate +90° around East/X axis
              </FaroText>
            }
            aria-label="rotateZ"
            sx={{ m: 0 }}
          />
          <FormControlLabel
            value="rotateX"
            control={<FaroRadio dark />}
            label={
              <FaroText variant="bodyL" dark>
                Rotate +90° around North/Y axis
              </FaroText>
            }
            aria-label="rotateX"
            sx={{ m: 0 }}
          />
          <FormControlLabel
            value="rotateY"
            control={<FaroRadio dark />}
            label={
              <FaroText variant="bodyL" dark>
                Rotate +90° around Vertical/Z axis
              </FaroText>
            }
            aria-label="rotateY"
            sx={{ m: 0 }}
          />
          <FormControlLabel
            value="reset"
            control={<FaroRadio dark />}
            label={
              <FaroText variant="bodyL" dark>
                Reset to identity
              </FaroText>
            }
            aria-label="reset"
            sx={{ m: 0 }}
          />
        </FaroRadioGroup>
      </Stack>
    </FaroDialog>
  );
}

// List of possible transformations.
// Take note that the CS store in the IElement is not the same one shown to the user
// (e.g. Z is up from user point of view, but Y is up in the IElement)
enum TransformationType {
  rotateX = "rotateX",
  rotateY = "rotateY",
  rotateZ = "rotateZ",
  reset = "reset",
}

// Convert the string associated with one of the radio button to the associated TransformationType
function stringToTransformationType(s: string): TransformationType | undefined {
  switch (s) {
    case TransformationType.rotateX:
      return TransformationType.rotateX;
    case TransformationType.rotateY:
      return TransformationType.rotateY;
    case TransformationType.rotateZ:
      return TransformationType.rotateZ;
    case TransformationType.reset:
      return TransformationType.reset;
  }
}
