import { useCancelUpload } from "@/components/common/file-upload-context/use-cancel-upload";
import { isSupportedCADFileExtension } from "@/components/common/point-cloud-file-upload-context/cad-upload-utils";
import { FileUploadTask } from "@/utils/background-tasks";
import { ElementIconType } from "../../icons";
import {
  BackgroundTaskCardProps,
  ProgressCardLayout,
} from "../layouts/progress-card-layout";

/** @returns the upload menu card to track a PointCloud upload */
export function UploadTaskCard({
  task,
}: BackgroundTaskCardProps<FileUploadTask>): JSX.Element {
  const cancelUpload = useCancelUpload();

  const icon = isSupportedCADFileExtension(task.metadata.filename)
    ? ElementIconType.CadModel
    : ElementIconType.PointCloudIcon;

  return (
    <ProgressCardLayout
      name={task.metadata.filename}
      upload={task}
      icon={icon}
      startTime={task.createdAt}
      menu={[
        {
          name: "Cancel import",
          callback: () => cancelUpload(task.id),
          color: "error",
        },
      ]}
    />
  );
}
