import { Mode } from "@/modes/mode";
import { changeMode } from "@/store/mode-slice";
import { LayerToLayerAlignmentModeOverlay } from "./layer-to-layer-alignment-mode-overlay";
import { LayerToLayerAlignmentModeScene } from "./layer-to-layer-alignment-mode-scene";

export const layerToLayerAlignmentMode: Mode = {
  name: "layerToLayerAlignment",
  ModeScene: LayerToLayerAlignmentModeScene,
  ModeOverlay: LayerToLayerAlignmentModeOverlay,
  hasCustomCanvasStyle: true,
  exclusive: {
    title: "Align layer to layer",
    onBack({ dispatch }) {
      dispatch(changeMode("start"));

      return Promise.resolve();
    },
  },
};
