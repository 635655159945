import { ModeNames } from "@/modes/mode";
import { userControlledFeatures } from "@/store/features/features";
import { GUID } from "@faro-lotv/foundation";
import { isPlainObject } from "es-toolkit";

/**
 * A deep link is a collection of parameters obtain from the project url to request a specific view from the viewer
 *
 * A set of parameters, to be considered a valid deep link, need to define at least the **id** or the **lookAtId**
 */
export type DeepLink = Record<string, string | undefined> & {
  /** The id of the active element, required for a valid DeepLink if lookAtId is not defined */
  id?: GUID;

  /** Optionally, the ID of an element to look at, distinct from the active element. */
  lookAtId?: GUID;

  /**
   * The current mode, if available. A deep link URL may not encode a specific mode, letting
   * the application compute the best mode to visualize from active element or from the lookAt element
   */
  mode?: ModeNames;

  /** The id of the active cad if available */
  cadId?: GUID;
};

/** A deep link that refers to a valid state of the application within the current project */
export type ValidDeepLink = Omit<DeepLink, "mode"> & {
  /** In a valid deep link, the mode is always defined */
  mode: ModeNames;
};

/**
 *
 * @param o The argument objec
 * @returns Whether o is a valid deep link
 */
export function isValidDeepLink(o: unknown): o is ValidDeepLink {
  return isDeepLink(o) && !!o.mode && typeof o.mode === "string";
}

function isDeepLink(o: unknown): o is DeepLink {
  if (!isPlainObject(o)) {
    return false;
  }
  const state: Partial<DeepLink> = o;
  return (
    (!!state.id && typeof state.id === "string") ||
    (!!state.lookAtId && typeof state.lookAtId === "string")
  );
}

/**
 * Encode the current app state into a base64 string
 *
 * @param state The current state of the app
 * @param keys The object containing the url search parameters
 * @returns A base64 string encoding the app state
 */
export function encodeDeepLink(
  state: DeepLink,
  keys: URLSearchParams,
): URLSearchParams {
  for (const [key, val] of Object.entries(state)) {
    if (val) {
      keys.append(key, val);
    }
  }
  return keys;
}

/**
 * Decodes the input URL keys into an app state. Removes from the input URL the keys that formed a deep link.
 *
 * @param keys The url search parameters we need to extract for the deep link
 * @returns The app state if the string was a valid encoded state
 */
export function decodeDeepLink(
  keys: URLSearchParams,
): DeepLink | Error | undefined {
  // Ignore feature flags from the url query parameters used for deep links
  const featureFlags = userControlledFeatures().map((s) =>
    s.toLocaleLowerCase(),
  );
  const entries = Array.from(keys.entries()).filter(
    ([key]) => !featureFlags.includes(key.toLocaleLowerCase()),
  );

  if (entries.length === 0) return;
  const data = Object.fromEntries(entries);
  if (!isDeepLink(data)) {
    return new Error(
      "Deep link does not contain the minimum required set of properties",
    );
  }
  return data;
}

/**
 * @param state The current state of the app
 * @returns the full URL with the "link" search parameter set
 */
export function getFullDeepLinkURL(state: DeepLink): URL {
  // Current URL without search parameters
  const url = new URL(window.location.origin + window.location.pathname);

  // This will populate the searchParameters of the url with all the data required to generate a deep link
  encodeDeepLink(state, url.searchParams);

  return url;
}
