import { useFileUploader } from "@/components/common/file-upload-context/use-file-uploader";
import { UploadElementType } from "@/components/common/point-cloud-file-upload-context/use-upload-element";
import { updateProject } from "@/components/common/project-provider/update-project";
import { store } from "@/store/store";
import { useAppDispatch, useAppSelector } from "@/store/store-hooks";
import { selectCurrentUser } from "@/store/user-selectors";
import { VolumeInfo } from "@/utils/volume-utils";
import { parseImageFromFile, resizeImage } from "@faro-lotv/flat-ui";
import { assert, generateGUID, GUID } from "@faro-lotv/foundation";
import {
  IElement,
  IElementType,
  IElementTypeHint,
  isIElementWithTypeAndHint,
} from "@faro-lotv/ielement-types";
import {
  computeReferenceSystemProperties,
  IElementWithPose,
  selectChildDepthFirst,
  selectIElementProjectApiLocalPose,
  selectProjectId,
  selectRootIElement,
} from "@faro-lotv/project-source";
import {
  createMutationAddArea,
  createMutationAddVolume,
  createMutationSetAreaWorldPose,
  mutationAddImgSheet,
  useApiClientContext,
} from "@faro-lotv/service-wires";
import { useCallback } from "react";
import { Matrix4, Quaternion, Vector3 } from "three";

type CreateAreaAndVolumeParams = {
  /** Name that will be assigned to the area and sheet */
  areaName: string;
  /** Image file that will be used for the sheet */
  file: File;
  /** Optional volume of the area */
  volume?: VolumeInfo;
};

type CreateAreaAndVolumeCallback = ({
  areaName,
  file,
  volume,
}: CreateAreaAndVolumeParams) => Promise<GUID | undefined>;

/**
 * Hook that creates an area, a volume and a sheet in the project
 *
 * @returns a function that creates the area and the volume.
 */
export function useCreateAreaAndVolume(): CreateAreaAndVolumeCallback {
  const dispatch = useAppDispatch();
  const user = useAppSelector(selectCurrentUser);
  const root = useAppSelector(selectRootIElement);
  const projectId = useAppSelector(selectProjectId);

  const uploadFile = useFileUploader();
  const { coreApiClient, projectApiClient } = useApiClientContext();

  const slideContainer = useAppSelector(
    selectChildDepthFirst(root, (iElement: IElement) =>
      isIElementWithTypeAndHint(
        iElement,
        IElementType.group,
        IElementTypeHint.slideContainer,
      ),
    ),
  );

  // This will create a new area in the project
  // update its pose and then
  // create an overview image and a volume
  return useCallback(
    async ({
      areaName,
      file,
      volume,
    }: CreateAreaAndVolumeParams): Promise<GUID | undefined> => {
      if (!projectId || !slideContainer) return;

      // Create a new area in the project
      const areaId = generateGUID();
      // Compute the local pose of the area in the ProjectAPI reference system
      const area: IElementWithPose = {
        id: areaId,
        parentId: slideContainer.id,
        type: IElementType.section,
        typeHint: IElementTypeHint.area,
      };
      const areaLocalPose = new Matrix4().compose(
        new Vector3(
          volume?.position?.x ?? 0,
          volume?.position?.y ?? 0,
          volume?.position?.z ?? 0,
        ),
        new Quaternion(
          volume?.rotation?.x ?? 0,
          volume?.rotation?.y ?? 0,
          volume?.rotation?.z ?? 0,
          volume?.rotation?.w ?? 1,
        ),
        new Vector3(1, 1, 1),
      );
      const areaLocalTransform = selectIElementProjectApiLocalPose(
        area,
        areaLocalPose,
      )(store.getState());

      // Compute the local pose of the sheet in the ProjectAPI reference system
      const sheet: IElementWithPose = {
        id: generateGUID(),
        parentId: area.id,
        type: IElementType.imgSheet,
        typeHint: IElementTypeHint.area,
      };
      // Since the sheet geometry is a square with side 1 on the XY plane with the minimum at the origin,
      // the local pose will be a -90° rotation around X, a scale based on the volume size and
      // a translation to put the center of the sheet at the origin
      const sheetLocalPose = new Matrix4()
        .compose(
          new Vector3(
            -(volume?.size?.x ?? 0) / 2,
            -(volume?.size?.y ?? 0) / 2,
            (volume?.size?.z ?? 0) / 2,
          ),
          new Quaternion(),
          new Vector3(
            volume?.size ? volume.size.x / 100 : 1,
            1,
            volume?.size ? volume.size.x / 100 : 1,
          ),
        )
        .multiply(
          new Matrix4().makeRotationFromQuaternion(
            new Quaternion(0, -Math.SQRT1_2, Math.SQRT1_2, 0),
          ),
        );
      const sheetLocalTransform = selectIElementProjectApiLocalPose(
        sheet,
        sheetLocalPose,
        [
          {
            ...area,
            pose: { ...areaLocalTransform, gps: null, isWorldRot: false },
          },
        ],
      )(store.getState());

      // Get the width and height of the image
      const { width: imageWidth, height: imageHeight } =
        await parseImageFromFile(file);

      // Resize the image to a thumbnail
      const thumbnailFile = await resizeImage(file);

      const [{ imageUrl, md5 }, { thumbnailUrl }] = await Promise.all([
        // Upload the image file to the backend
        // Wait for the upload to complete
        new Promise<{
          imageUrl: string;
          md5: string;
        }>((resolve, reject) => {
          uploadFile({
            file,
            uploadElementType: UploadElementType.none,
            projectId,
            coreApiClient,
            onUploadCompleted: (_, imageUrl, md5) => resolve({ imageUrl, md5 }),
            onUploadFailed: reject,
            silent: true,
          });
        }),

        // Upload the thumbnail file to the backend
        // Wait for the upload to complete
        new Promise<{ thumbnailUrl: string }>((resolve, reject) => {
          uploadFile({
            file: thumbnailFile,
            uploadElementType: UploadElementType.none,
            projectId,
            coreApiClient,
            onUploadCompleted: (_, thumbnailUrl) => resolve({ thumbnailUrl }),
            onUploadFailed: reject,
            silent: true,
          });
        }),
      ]);

      // Make sure the uploads were successful
      assert(
        imageUrl && md5 && thumbnailUrl,
        "Could not upload the image file",
      );

      // Apply the mutations to create the area, the volume and the sheet
      await projectApiClient.applyMutations([
        createMutationAddArea({
          id: areaId,
          rootId: slideContainer.rootId,
          groupId: slideContainer.id,
          name: areaName,
        }),
        mutationAddImgSheet({
          rootId: slideContainer.rootId,
          sectionId: areaId,
          name: areaName,
          fileName: file.name,
          fileSize: file.size,
          md5Hash: md5,
          uri: imageUrl,
          thumbnailUri: thumbnailUrl,
          pixelWidth: imageWidth,
          pixelHeight: imageHeight,
          refCoordSystemMatrix: computeReferenceSystemProperties(
            { type: IElementType.imgSheet, typeHint: IElementTypeHint.area },
            false,
          ),
          pose: {
            pos: sheetLocalTransform.pos,
            scale: sheetLocalTransform.scale,
          },
        }),
        // Add the volume and set the area pose using it, if it was provided
        ...(volume
          ? [
              createMutationSetAreaWorldPose(
                areaId,
                1,
                areaLocalTransform.rot,
                areaLocalTransform.pos,
              ),
              createMutationAddVolume({
                areaId,
                rootId: slideContainer.rootId,
                size: volume.size ?? { x: 1, y: 1, z: 1 },
                userId: user?.id,
              }),
            ]
          : []),
      ]);

      // Fetch the changed sub-tree and update the local copy of the project
      await dispatch(
        updateProject({
          projectApi: projectApiClient,
          iElementQuery: {
            ancestorIds: [slideContainer.id],
          },
        }),
      );

      return areaId;
    },
    [
      projectId,
      slideContainer,
      uploadFile,
      coreApiClient,
      projectApiClient,
      user?.id,
      dispatch,
    ],
  );
}
