import { FaroStep, FaroStepper, neutral } from "@faro-lotv/flat-ui";
import { Stack } from "@mui/system";
import { Key, useCallback, useState } from "react";

interface AlignmentStepperProps {
  /** The steps to step through. */
  steps: Array<FaroStep<Key>>;

  /** if provided button "Next" will stay visible on last step with provided text instead of "Next" */
  lastStepButtonText?: string;

  /** if provided and set to true do not display numbers of steps */
  hideStepNumbers?: boolean;

  /** An action to execute when completing last step. */
  onLastStepButtonClicked?(): void;
}

/**
 * @returns Wrapper around FaroStepper to provide common functionality and rendering style for all alignments
 *  Allows user to see where there are currently in the alignment process, and switch between those steps
 */
export function AlignmentStepper({
  steps,
  lastStepButtonText,
  hideStepNumbers,
  onLastStepButtonClicked,
}: AlignmentStepperProps): JSX.Element {
  const [activeStepKey, setActiveStepKey] = useState(steps[0].key);

  const handleStepChange = useCallback((step: FaroStep) => {
    setActiveStepKey(step.key);
  }, []);

  return (
    <Stack
      direction="row"
      alignItems="center"
      sx={{
        p: 1,
        backgroundColor: neutral[100],
        zIndex: 1,
      }}
    >
      <FaroStepper
        steps={steps}
        activeStepKey={activeStepKey}
        onStepChange={handleStepChange}
        lastStepButtonText={lastStepButtonText}
        hideStepNumbers={hideStepNumbers}
        onLastStepButtonClicked={onLastStepButtonClicked}
      />
    </Stack>
  );
}
