import { SplitDirection } from "@/store/modes/alignment-ui-types";
import { SplitscreenStartIcon } from "@faro-lotv/flat-ui";
import { ToggleButton, Tooltip } from "@mui/material";

type ScreenSplitDirectionToggleProps = {
  /** screen split direction */
  splitDirection: SplitDirection;

  /** callback function when button is clicked */
  onClick(): void;
};

/**
 * @returns split screen toggle between vertical and horizontal split
 */
export function ScreenSplitDirectionToggle({
  splitDirection,
  onClick,
}: ScreenSplitDirectionToggleProps): JSX.Element {
  const tooltip =
    splitDirection === SplitDirection.verticalSplit
      ? "Split horizontally"
      : "Split vertically";

  return (
    <Tooltip title={tooltip}>
      <ToggleButton
        value="splitscreen-direction"
        selected={false}
        onClick={onClick}
        sx={{
          width: 5,
          height: 10,
          position: "absolute",
          right: 20,
          top: "55%",
          transform: "translate(0, -50%)",
        }}
      >
        <SplitscreenStartIcon
          sx={{
            width: "18px",
            marginRight: "1px",
            marginLeft: "1px",
            ["&:hover"]: {
              color: "white",
            },
            transform:
              splitDirection === SplitDirection.horizontalSplit
                ? "rotate(90deg)"
                : "none",
          }}
        />
      </ToggleButton>
    </Tooltip>
  );
}
