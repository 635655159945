import { useCurrentProjectApiClient } from "@/components/common/project-provider/project-loading-context";
import { ModalSpinner } from "@/components/ui/modal-spinner";
import { changeMode } from "@/store/mode-slice";
import { selectAlignedLayerForLayerToLayerAlignment } from "@/store/modes/layer-to-layer-alignment-mode-selectors";
import { resetLayerToLayerAlignment } from "@/store/modes/layer-to-layer-alignment-mode-slice";
import { setActiveElement } from "@/store/selections-slice";
import { useAppDispatch, useAppSelector } from "@/store/store-hooks";
import { selectAncestor } from "@faro-lotv/app-component-toolbox";
import { neutral, useToast } from "@faro-lotv/flat-ui";
import { isIElementAreaSection } from "@faro-lotv/ielement-types";
import {
  fetchProjectIElements,
  selectIElement,
} from "@faro-lotv/project-source";
import { Stack } from "@mui/system";
import { useCallback, useState } from "react";
import { LayerToLayerAlignmentProgressBar } from "./layer-to-layer-alignment-progress-bar";
import { LayerToLayerAlignmentSplitScreen } from "./layer-to-layer-alignment-split-screen";

/** @returns The overlay for layer-to-layer alignment mode */
export function LayerToLayerAlignmentModeOverlay(): JSX.Element {
  const { openToast } = useToast();

  const [showSpinner, setShowSpinner] = useState(false);

  const dispatch = useAppDispatch();
  const client = useCurrentProjectApiClient();

  const alignedLayerId = useAppSelector(
    selectAlignedLayerForLayerToLayerAlignment,
  );
  const alignedLayer = useAppSelector(selectIElement(alignedLayerId));

  const sectionArea = useAppSelector(
    selectAncestor(alignedLayer, isIElementAreaSection),
  );

  if (!sectionArea) {
    throw new Error("Section Area not found for selected aligned sheet.");
  }

  const applyLayerMutation = useCallback(() => {
    setShowSpinner(true);

    // TODO:  computation of new transform and applying mutation
    // will be done in https://faro01.atlassian.net/browse/CADBIM-1082

    // Fetch the changed section area sub-tree and update the local copy of the project
    // that new alignment will be used without reloading whole project
    dispatch(
      fetchProjectIElements({
        fetcher: async () =>
          // Refresh the area node to get new transform
          await client.getAllIElements({
            ancestorIds: [sectionArea.id],
          }),
      }),
    );

    // at the end of alignment cycle reset temporary data to prevent reusing it in the next session of alignment
    dispatch(resetLayerToLayerAlignment());

    // after new alignment applied user will be redirected to 3D view in "overview" mode
    // force sheet to be new active area to immediately show results of alignment
    dispatch(setActiveElement(alignedLayerId));

    setShowSpinner(false);
    openToast({
      title: "Alignment completed",
      variant: "success",
    });

    // after alignment force switch to 2D mode as most convenient to validate alignment result
    dispatch(changeMode("sheet"));
  }, [sectionArea, dispatch, alignedLayerId, openToast, client]);

  return (
    <>
      <ModalSpinner
        sx={{ color: neutral[0], zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={showSpinner}
      />
      <Stack
        sx={{
          width: "100%",
          height: "100%",
        }}
      >
        <LayerToLayerAlignmentProgressBar apply={applyLayerMutation} />
        <LayerToLayerAlignmentSplitScreen />
      </Stack>
    </>
  );
}
