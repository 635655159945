import {
  GapFillingOptions,
  PointSizeOptions,
  useRenderingSettings,
} from "@/components/common/rendering-settings-context";
import { PointCloudObject } from "@/object-cache";
import { useAppSelector } from "@/store/store-hooks";
import {
  EyeDomePass,
  GapFillPass,
  LodPointCloudRendererBase,
  selectIElementWorldTransform,
  useAssignClippingPlanes,
} from "@faro-lotv/app-component-toolbox";
import {
  AdaptivePointsMaterial,
  GapFillOptimizedFor,
  LodPointCloud,
} from "@faro-lotv/lotv";
import { EventHandlers } from "@react-three/fiber/dist/declarations/src/core/events";
import { useEffect } from "react";
import { Plane } from "three";

export type PointCloudProps = EventHandlers & {
  /** Object to render */
  pointCloud: PointCloudObject;

  /** Point cloud opacity */
  opacity?: number;

  /**
   * Whether the point cloud should answser to pointer events via raycasting
   *
   * @default true By default the point cloud handles pointer events via efficient raycasting on the loaded LOD nodes
   */
  raycastEnabled?: boolean;

  /** Optional clipping planes */
  clippingPlanes?: Plane[];
};

/**
 * @returns the PointCloud in the correct world position
 */
export function PointCloudRenderer({
  pointCloud,
  opacity = 1,
  raycastEnabled = true,
  clippingPlanes,
  ...rest
}: PointCloudProps): JSX.Element {
  const transform = useAppSelector(
    selectIElementWorldTransform(pointCloud.iElement.id),
  );

  useAssignClippingPlanes(pointCloud, clippingPlanes);

  const { pointSize } = useRenderingSettings();

  useEffect(() => {
    const mat = pointCloud.material;
    if (!(mat instanceof AdaptivePointsMaterial)) return;

    switch (pointSize) {
      case PointSizeOptions.Adaptive:
        mat.targetMinMaxSize = { minSize: 2, maxSize: 6 };
        break;
      case PointSizeOptions.AdaptiveSharper:
        mat.targetMinMaxSize = { minSize: 1, maxSize: 3 };
        break;
      case PointSizeOptions.FixedOne:
        mat.targetMinMaxSize = { minSize: 1, maxSize: 1 };
        break;
      case PointSizeOptions.FixedTwo:
        mat.targetMinMaxSize = { minSize: 2, maxSize: 2 };
        break;
    }
  }, [pointSize, pointCloud]);

  return (
    <group {...transform}>
      <LodPointCloudRendererBase
        pointCloud={pointCloud}
        opacity={opacity}
        raycastEnabled={raycastEnabled}
        {...rest}
      />
    </group>
  );
}

type PointCloudDefaultEffectsProps = {
  /** The PointCloud that will be rendered by these Effects */
  pointCloud: LodPointCloud | null;
};

/** @returns the set of default effects for PointClouds to use in an EffectPipeline */
export function PointCloudDefaultEffects({
  pointCloud,
}: PointCloudDefaultEffectsProps): JSX.Element {
  const { gapFilling, eyeDomeOn } = useRenderingSettings();

  const isMonochrome = pointCloud?.monochrome ?? false;

  return (
    <>
      <GapFillPass
        enabled={gapFilling !== GapFillingOptions.Disabled}
        optimizedFor={
          gapFilling === GapFillingOptions.Speed
            ? GapFillOptimizedFor.Speed
            : GapFillOptimizedFor.GapSize
        }
      />
      <EyeDomePass
        enabled={isMonochrome || eyeDomeOn}
        strength={isMonochrome ? 0.5 : 0.1}
        showBlackOutlines={eyeDomeOn}
      />
    </>
  );
}
