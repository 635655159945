import { useCurrentProjectApiClient } from "@/components/common/project-provider/project-loading-context";
import { updateProject } from "@/components/common/project-provider/update-project";
import { AppStore } from "@/store/store";
import { useAppDispatch, useAppStore } from "@/store/store-hooks";
import { FileUploadTask } from "@/utils/background-tasks";
import {
  selectChildDepthFirst,
  selectIElement,
  selectRootIElement,
  useToast,
} from "@faro-lotv/app-component-toolbox";
import {
  GUID,
  assert,
  generateGUID,
  getFileExtension,
} from "@faro-lotv/foundation";
import {
  IElement,
  IElementType,
  IElementTypeHint,
  MAX_NAME_LENGTH,
  isIElementWithTypeAndHint,
} from "@faro-lotv/ielement-types";
import { createMutationAddArea } from "@faro-lotv/service-wires";

/** Maximum allowed image height in pixels */
export const MAX_ALLOWED_IMG_HEIGHT = 8192;

/** Maximum allowed image width in pixels */
export const MAX_ALLOWED_IMG_WIDTH = 8192;

/**
 * @param text string to check the validity of
 * @returns the validation error, or undefined if the string is valid
 */
export function verifyInputName(text?: string): string | undefined {
  if (!text) {
    return "Sheet name is required";
  }
  if (text.length > MAX_NAME_LENGTH) {
    return `Sheet name must not exceed ${MAX_NAME_LENGTH} characters`;
  }
}

/** All supported Image Sheet file extensions.  */
export enum SupportedImgSheetFileExtensions {
  pdf = "pdf",
  png = "png",
  jpeg = "jpeg",
  jpg = "jpg",
}

/**
 * List of supported Image Sheet file extensions without the dots.
 */
export const ImgSheetFileExtensionsList = Object.values(
  SupportedImgSheetFileExtensions,
).map((ext) => `${ext}`);

/**
 * List of supported Image Sheet file extensions with the dots.
 * The dot is required in order to work with the accept property of the file input tag.
 */
export const ImgSheetFileExtensionsWithDotsList = Object.values(
  SupportedImgSheetFileExtensions,
).map((ext) => `.${ext}`);

/**
 * @param fileName name of the file to check the extension of
 * @returns if the name passed has a supported image sheet extension
 */
export function isSupportedImgSheetFileExtension(fileName: string): boolean {
  const extension = getFileExtension(fileName)?.toLowerCase() ?? "";

  return ImgSheetFileExtensionsList.includes(extension);
}

/**
 * @param task to check
 * @returns true if the task is an upload background task of an image sheet file
 */
export function isImageFileUploadTask(task: FileUploadTask): boolean {
  return isSupportedImgSheetFileExtension(task.metadata.filename);
}

/**
 * This function takes the name of the uploaded image from the Section Area.
 * This is possible because the area, when created, will have the ImgSheet name chosen by the user.
 *
 * @param task file upload task containing the Section Area element id.
 * @param store current app store
 * @returns the name of the uploaded sheet
 */
export function getUploadedImgSheetName(
  task: FileUploadTask,
  store: AppStore,
): string | undefined {
  const sectionArea = selectIElement(task.iElementId ?? "")(store.getState());
  return sectionArea?.name;
}

type CreateAreaFunction = (areaName: string) => Promise<GUID | undefined>;

/**
 * @returns a function to create the area section
 */
export function useCreateArea(): CreateAreaFunction {
  const { openToast } = useToast();
  const projectApi = useCurrentProjectApiClient();
  const appStore = useAppStore();
  const dispatch = useAppDispatch();

  const sectionAreaId = generateGUID();

  /**
   * This hook will create an Area Section if it doesn't exist in the project
   *
   * @returns the ID of the section area
   * @param areaName name to assign to the area that will be created
   */
  return async function createArea(
    areaName: string,
  ): Promise<GUID | undefined> {
    const root = selectRootIElement(appStore.getState());
    const sectionArea = selectIElement(sectionAreaId)(appStore.getState());

    if (!sectionArea) {
      // Get the SlideContainer parent of the area to create
      const slideContainer = selectChildDepthFirst(root, (iElement: IElement) =>
        isIElementWithTypeAndHint(
          iElement,
          IElementType.group,
          IElementTypeHint.slideContainer,
        ),
      )(appStore.getState());

      assert(
        slideContainer,
        "A SlideContainer must be present in the project in order to add an Area",
      );

      try {
        // Create a new area in the project
        await projectApi.applyMutations([
          createMutationAddArea({
            id: sectionAreaId,
            rootId: slideContainer.rootId,
            groupId: slideContainer.id,
            name: areaName,
          }),
        ]);
      } catch {
        openToast({
          title: "Upload of the sheet failed. Please, try again",
          variant: "error",
        });
        return;
      }

      // Fetch the changed sub-tree and update the local copy of the project
      await dispatch(
        updateProject({
          projectApi,
          iElementQuery: {
            // We need to refresh from the floor or the group that contains all CADs in the floor (if it's available)
            ancestorIds: [slideContainer.id],
          },
        }),
      );
    }

    return sectionAreaId;
  };
}
