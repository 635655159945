import { EventType } from "@/analytics/analytics-events";
import { selectIsPointCloudViewable } from "@/modes/mode-selectors";
import { selectActiveCadModel } from "@/store/cad/cad-slice";
import { selectModeName } from "@/store/mode-selectors";
import { changeMode } from "@/store/mode-slice";
import { selectActiveElement } from "@/store/selections-selectors";
import { setActiveElement } from "@/store/selections-slice";
import { AppDispatch, RootState } from "@/store/store";
import { SceneFilter } from "@/types/scene-filter";
import {
  selectAncestor,
  selectChildDepthFirst,
  selectIElement,
} from "@faro-lotv/app-component-toolbox";
import { Analytics } from "@faro-lotv/foreign-observers";
import {
  GUID,
  IElement,
  IElementGenericPointCloud,
  IElementType,
  isIElementAreaSection,
  isIElementGenericImgSheet,
  isIElementGenericPointCloud,
  isIElementGenericPointCloudStream,
  isIElementGenericStream,
  isIElementImg360,
  isIElementModel3dStream,
} from "@faro-lotv/ielement-types";
import { Typography } from "@mui/material";
import { CreateDialogFn } from "./action-types";

/**
 * @returns The scene filter for the input element
 * @param activeElement The element for which we want to know the type
 */
function selectWalkModeSceneFilter(activeElement: IElement | undefined) {
  return (state: RootState): SceneFilter | undefined => {
    if (!activeElement) {
      return;
    }
    if (selectChildDepthFirst(activeElement, isIElementImg360)(state)) {
      return SceneFilter.Pano;
    }
    if (
      selectChildDepthFirst(
        activeElement,
        isIElementGenericPointCloudStream,
      )(state)
    ) {
      return SceneFilter.PointCloud;
    }
    if (selectChildDepthFirst(activeElement, isIElementModel3dStream)(state)) {
      return SceneFilter.Cad;
    }
    // If we reach this point, we are in an empty floor
  };
}

/**
 * Check if a element is currently used in a 3d scene
 *
 * TODO: create generic way of exiting mode when require data for the mode is removed. https://faro01.atlassian.net/browse/SWEB-1334
 *
 * @param section the section containing the element to check
 * @param state the current app state
 * @returns true if the element is in use in the 3d view
 */
export function isElementInUse(
  section: IElement | undefined,
  state: RootState,
): boolean {
  const activeElement = selectActiveElement(state);

  const modeName = selectModeName(state);

  if (activeElement?.id === section?.id) {
    return true;
  }

  // Get the stream element contained in the passed section
  const sectionStream = selectChildDepthFirst(
    section,
    isIElementGenericStream,
  )(state);

  // Get the stream element from the active element
  const activeStream = selectChildDepthFirst(
    activeElement,
    isIElementGenericStream,
  )(state);

  // Check if the walk mode overlay element is the same as the section stream that we want to delete
  const walkOverlay = selectActiveCadModel(state);
  if (
    modeName === "walk" &&
    walkOverlay &&
    walkOverlay.id === sectionStream?.id
  ) {
    // The section stream is in use by the walk mode overlay element
    return true;
  }

  // Check if the stream element from the section we want to delete, is the same as the active stream from the active element.
  if (sectionStream !== activeStream) {
    // The section stream is not in use since it's not the same as the active stream
    return false;
  }

  if (
    modeName === "walk" &&
    selectWalkModeSceneFilter(activeElement)(state) !== SceneFilter.Pano
  ) {
    return true;
  }

  return modeName === "overview";
}

type RedirectToFloorScalingToolArgs = {
  /** Id of the element in the project tree. */
  elementID: GUID;

  /** Current state of the application. */
  state: RootState;

  /** Function to update the state. */
  dispatch: AppDispatch;

  /** An async function to create a dialog */
  createDialog: CreateDialogFn;
};

/**
 * Redirect to the Floor Scale tool, showing a confirmation dialog if the sheet
 * has already a scale
 */
export async function redirectToFloorScalingTool({
  elementID,
  state,
  createDialog,
  dispatch,
}: RedirectToFloorScalingToolArgs): Promise<void> {
  Analytics.track(EventType.openAreaScaleTool);

  // Be sure that the current element is a floor
  const element = selectIElement(elementID)(state);

  // If the element is a generic image sheet, find the area section
  const area = isIElementGenericImgSheet(element)
    ? selectAncestor(element, isIElementAreaSection)(state)
    : element;

  if (!area || !isIElementAreaSection(area)) {
    return;
  }

  // If the sheet has a scale defined, show a dialog
  if (area.pose?.scale) {
    const hasAccepted = await createDialog({
      title: "Set area scale",
      content: (
        <Typography>
          Changing the scale of the area will also affect previous alignments.
          <br />
          <br />
          Point cloud coordinate systems, their geolocations, and other elements
          will remain unchanged.
        </Typography>
      ),
      confirmText: "Set scale",
    });
    // Do nothing if the user cancelled
    if (!hasAccepted) {
      Analytics.track(EventType.cancelAreaScaleWarning);
      return;
    }
    Analytics.track(EventType.confirmAreaScaleWarning);
  }

  dispatch(setActiveElement(elementID));
  dispatch(changeMode("floorscale"));
}

/**
 * @returns the point cloud to download for a specific IElement if available
 * @param el node of the pointcloud IElement container
 * @param predicate to discriminate between multiple available point clouds
 */
export function selectDownloadablePointCloud(
  el?: IElement,
  predicate: (el: IElement) => boolean = () => true,
) {
  return (state: RootState): IElementGenericPointCloud | undefined =>
    selectChildDepthFirst(
      el,
      (el): el is IElementGenericPointCloud =>
        isIElementGenericPointCloud(el) &&
        el.type !== IElementType.pointCloudGeoSlam &&
        predicate(el) &&
        selectIsPointCloudViewable(el)(state),
    )(state);
}
