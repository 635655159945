import { RootState } from "@/store/store";
import { SceneFilter } from "@/types/scene-filter";
import { isIElementValidPointCloudStream } from "@/types/type-guards";
import {
  GUID,
  IElement,
  IElementGenericPointCloudStream,
  IElementImg360,
  IElementType,
  IElementTypeHint,
  isIElementGenericPointCloudStream,
  isIElementImg360,
  isIElementWithTypeAndHint,
} from "@faro-lotv/ielement-types";
import {
  isIElementVideoModeCopy,
  selectAllIElementsOfType,
  selectAncestor,
  selectChildDepthFirst,
  selectIElement,
  selectIElementWorldPosition,
} from "@faro-lotv/project-source";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { Vector3 } from "three";

/** State specific for the walk mode */
type WalkModeState = {
  /** Information on the compare(right) side of a split screen */
  compare: {
    /** True if the orientations of both left and right cameras should be locked (both are moving with same rotation) */
    shouldSyncCamerasRotation: boolean;

    /** True if the left and right camera should sync on the same waypoint (both will move to the same waypoint) */
    shouldSyncCamerasOnWaypoint: boolean;

    /** Id of the element to render on the compare screen */
    elementId: GUID | undefined;

    /** Type of filtering for compare element in split screen */
    compareSceneFilter: SceneFilter;

    /**
     * If true, when choosing the panorama image to render in the compare screen in split mode,
     * the intensity images will have priority over the colored images
     */
    compareShouldUseIntensityData: boolean;
  };

  panoIndexDuringSnakeAnimation?: number;

  /** Type of filtering for main element in walk mode */
  walkSceneFilter: SceneFilter;

  /** If true, when choosing the panorama image to render, the intensity images will have priority over the colored images */
  shouldUseIntensityData: boolean;
};

const initialState: WalkModeState = {
  compare: {
    shouldSyncCamerasRotation: false,
    shouldSyncCamerasOnWaypoint: true,
    elementId: undefined,
    compareSceneFilter: SceneFilter.Pano,
    compareShouldUseIntensityData: false,
  },
  panoIndexDuringSnakeAnimation: undefined,
  walkSceneFilter: SceneFilter.Pano,
  shouldUseIntensityData: false,
};

const walkModeSlice = createSlice({
  initialState,
  name: "walkmode",
  reducers: {
    setSyncCamerasRotation(state, action: PayloadAction<boolean>) {
      state.compare.shouldSyncCamerasRotation = action.payload;
    },
    setSyncCamerasOnWaypoint(state, action: PayloadAction<boolean>) {
      state.compare.shouldSyncCamerasOnWaypoint = action.payload;
    },
    setCompareElementId(state, action: PayloadAction<GUID>) {
      state.compare.elementId = action.payload;
    },
    setPanoIndexDuringSnakeAnimation(
      state,
      action: PayloadAction<number | undefined>,
    ) {
      state.panoIndexDuringSnakeAnimation = action.payload;
    },
    setWalkSceneFilter(state, action: PayloadAction<SceneFilter>) {
      state.walkSceneFilter = action.payload;
    },
    setCompareSceneFilter(state, action: PayloadAction<SceneFilter>) {
      state.compare.compareSceneFilter = action.payload;
    },
    setShouldUseIntensityData(state, action: PayloadAction<boolean>) {
      state.shouldUseIntensityData = action.payload;
    },
    setCompareShouldUseIntensityData(state, action: PayloadAction<boolean>) {
      state.compare.compareShouldUseIntensityData = action.payload;
    },
  },
});

export const {
  setCompareElementId,
  setSyncCamerasRotation,
  setSyncCamerasOnWaypoint,
  setPanoIndexDuringSnakeAnimation,
  setWalkSceneFilter,
  setCompareSceneFilter,
  setShouldUseIntensityData,
  setCompareShouldUseIntensityData,
} = walkModeSlice.actions;

export const walkModeReducer = walkModeSlice.reducer;

/**
 * @returns the selected scene type to display in walk mode
 */
export function selectWalkMainSceneType({ walkMode }: RootState): SceneFilter {
  return walkMode.walkSceneFilter;
}

/**
 * @returns the selected scene type to display in compare scene of split screen mode
 */
export function selectWalkCompareSceneFilter({
  walkMode,
}: RootState): SceneFilter {
  return walkMode.compare.compareSceneFilter;
}

/**
 * @returns the selected data type to display in walk mode
 */
export function selectPanoIndexDuringSnakeAnimation({
  walkMode,
}: RootState): number | undefined {
  return walkMode.panoIndexDuringSnakeAnimation;
}

/**
 * @returns true if the left and right camera should lock their rotation
 */
export function selectShouldSyncCamerasRotation({
  walkMode,
}: RootState): boolean {
  return walkMode.compare.shouldSyncCamerasRotation;
}

/**
 * @returns true if the left and right camera should move to the same waypoint
 */
export function selectShouldSyncCamerasOnWaypoint({
  walkMode,
}: RootState): boolean {
  return walkMode.compare.shouldSyncCamerasOnWaypoint;
}

/**
 * @returns true if the intensity panorama images should have priority over the colored ones
 */
export function selectShouldUseIntensityData({ walkMode }: RootState): boolean {
  return walkMode.shouldUseIntensityData;
}

/**
 * @returns true if the intensity panorama images should have priority over the colored ones for the compare view in split mode
 */
export function selectCompareShouldUseIntensityData({
  walkMode,
}: RootState): boolean {
  return walkMode.compare.compareShouldUseIntensityData;
}

/**
 * @param element active element to check (360, PC, section or timeseries)
 * @returns the 360 or PointCloud IElement to use to walk in the active element
 */
export function selectWalkTypeDefaultElement(element: IElement) {
  return (
    state: RootState,
  ): IElementImg360 | IElementGenericPointCloudStream | undefined =>
    selectChildDepthFirst(
      element,
      (el): el is IElementImg360 | IElementGenericPointCloudStream =>
        isIElementImg360(el) || isIElementValidPointCloudStream(el),
    )(state);
}

/**
 * @param state current app state
 * @returns the element selected as a comparison for split screen
 */
export function selectCompareElement(state: RootState): IElement | undefined {
  if (!state.walkMode.compare.elementId) return;
  return selectIElement(state.walkMode.compare.elementId)(state);
}

/**
 * Compute the best element to use as a comparison in split screen for the current element
 *
 * @param mainElement we want to use as a base for the comparison
 * @param cameraPosition the position of the camera to compute the closest panorama in space
 * @returns the best element to render in the right screen by default
 */
export function selectBestCompareElement(
  mainElement: IElement,
  cameraPosition?: Vector3,
) {
  return (state: RootState): IElement | undefined => {
    // Get the current area section
    const areaSection = selectAncestor(mainElement, (e) =>
      isIElementWithTypeAndHint(e, IElementType.section, IElementTypeHint.area),
    )(state);
    if (!areaSection) return mainElement;

    if (!mainElement.parentId) return mainElement;
    const parentElement = selectIElement(mainElement.parentId)(state);
    if (!parentElement) return mainElement;

    // Compute the time from the parent element
    const currentTime = Date.parse(parentElement.createdAt);
    const currentPosition =
      cameraPosition ??
      new Vector3().fromArray(
        selectIElementWorldPosition(mainElement.id)(state),
      );

    // If the main element is a 360, compute the best 360 in the same area section
    if (isIElementImg360(mainElement)) {
      const bestPano = selectBestPanoAtPositionAndTime(
        state,
        areaSection,
        mainElement,
        currentPosition,
        currentTime,
      );
      if (bestPano) {
        return bestPano;
      }
    } else if (isIElementGenericPointCloudStream(mainElement)) {
      // Get the list of point clouds on the same area section
      const pointClouds = selectAllIElementsOfType(
        isIElementGenericPointCloudStream,
        areaSection.id,
      )(state);

      let minElement:
        | IElementGenericPointCloudStream
        | IElementImg360
        | undefined = undefined;
      let minTimeDistance = Number.POSITIVE_INFINITY;
      for (const pc of pointClouds) {
        if (pc.id === mainElement.id || !pc.parentId) {
          continue;
        }
        const parent = selectIElement(pc.parentId)(state);
        if (!parent) continue;

        // Store the closest point cloud in time
        const pcTime = Date.parse(parent.createdAt);
        const distanceInTime = Math.abs(currentTime - pcTime);
        if (distanceInTime < minTimeDistance) {
          minTimeDistance = distanceInTime;
          minElement = pc;
        }
      }
      // If no point cloud has been found, compute the closest pano in space and time on the same area section
      minElement =
        minElement ??
        selectBestPanoAtPositionAndTime(
          state,
          areaSection,
          mainElement,
          currentPosition,
          currentTime,
        );
      if (minElement) {
        return minElement;
      }
    }
    // We did not find anything, return the element itself
    return mainElement;
  };
}

/** Temporary variable to avoid many memory allocations during the execution of the selector */
const TEMP_POS = new Vector3();

/** Maximum search radius in meters (squared) */
const SEARCH_RADIUS_SQ = 4;

/**
 * Compute the closest pano (in time and space) to the current position and time
 * on a floor
 *
 * @param state The app state
 * @param floor The floor which the 360s belong to
 * @param currentElement The current element taken into account
 * @param currentPosition The current 3D position
 * @param currentTime The current time
 * @returns The closest pano in time and space (if any)
 */
function selectBestPanoAtPositionAndTime(
  state: RootState,
  floor: IElement,
  currentElement: IElement,
  currentPosition: Vector3,
  currentTime: number,
): IElementImg360 | undefined {
  // Get all panos on the same floor
  const panos = selectAllIElementsOfType(isIElementImg360, floor.id)(state);

  let minPano: IElementImg360 | undefined = undefined;
  let minPanoSameTime: IElementImg360 | undefined = undefined;
  let minSpaceDistanceSameTime = Number.POSITIVE_INFINITY;
  let minSpaceDistance = Number.POSITIVE_INFINITY;
  let minTimeDistance = Number.POSITIVE_INFINITY;

  // For each pano on the floor
  for (const pano of panos) {
    // Do not take into account the current main element
    if (pano.id === currentElement.id) {
      continue;
    }

    if (!pano.parentId) {
      continue;
    }

    const panoParent = selectIElement(pano.parentId)(state);
    if (!panoParent) {
      continue;
    }

    // Ignore the 360s copied from videomode
    if (isIElementVideoModeCopy(panoParent)) {
      continue;
    }

    const panoPosition = TEMP_POS.fromArray(
      selectIElementWorldPosition(pano.id)(state),
    );

    // If the pano is more than 2 meters away, ignore it
    const distance = panoPosition.distanceToSquared(currentPosition);
    if (distance > SEARCH_RADIUS_SQ) {
      continue;
    }

    // Get the time of the pano from the parent, because, for example, on a videomode path, each pano has a different time
    const panoTime = Date.parse(panoParent.createdAt);

    // If the current pano and the one we are testing have the same
    // time (e.g. belongs to the same path), store it if it's closer in space
    if (panoTime === currentTime) {
      if (distance < minSpaceDistanceSameTime) {
        minPanoSameTime = pano;
        minSpaceDistanceSameTime = distance;
      }
    } else {
      const deltaTime = Math.abs(currentTime - panoTime);
      // If the pano tested is closer in time, or has the same
      // distance in time but it's closer in space, store it
      if (deltaTime < minTimeDistance) {
        minPano = pano;
        minSpaceDistance = distance;
        minTimeDistance = deltaTime;
      } else if (deltaTime === minTimeDistance && distance < minSpaceDistance) {
        minPano = pano;
        minSpaceDistance = distance;
        minTimeDistance = deltaTime;
      }
    }
  }

  // Return the closest pano in space that has the smallest time difference (but greater than 0) from the main element
  if (minPano) {
    return minPano;
  }

  // Return the closest pano in space that has the same time as the main element
  if (minPanoSameTime) {
    return minPanoSameTime;
  }
}
