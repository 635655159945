import {
  IElementBase,
  IElementType,
  IElementTypeHint,
} from "@faro-lotv/ielement-types";

/** All supported type of icons associated with elements */
export enum ElementIconType {
  DisabledIcon = 0,
  ClockIcon = 1,
  MapIcon = 2,
  PanoramaIcon = 3,
  FolderIcon = 4,
  LocationOnIcon = 5,
  PanoramaPhotosphereSelectIcon = 6,
  ImageIcon = 7,
  AccessTimeFilledIcon = 9,
  LinkIcon = 10,
  InfoIcon = 11,
  VolumeUpIcon = 12,
  AttachmentIcon = 13,
  SquareFootIcon = 14,
  StraightenIcon = 15,
  CloudIcon = 16,
  PointCloudIcon = 17,
  DeleteIcon = 18,
  DownloadIcon = 19,
  AlignToPointCloudIcon = 20,
  RegistrationInteractiveIcon = 21,
  ToolIcon = 22,
  VideoCameraIcon = 23,
  AlignToSheetIcon = 24,
  ResetScaleIcon = 25,
  DataSession = 26,
  DataSessionWithPano = 27,
  DataSessionWithTrajectory = 28,
  DataSessionVideoRecording = 29,
  CadModel = 30,
  Visible = 31,
  NonVisible = 32,
  AlignToModelIcon = 33,
  GeoReferenceIcon = 34,
  FindCheckmarkIcon = 35,
  WorldCoordinateIcon = 36,
  EditIcon = 37,
  MoveElement = 38,
  AlignIcon = 39,
  SheetNewIcon = 40,
  AreaIcon = 41,
}

/**
 * Compute the best icon for an IElement node
 *
 * @param elementType Type of the element
 * @param elementTypeHint Type hint of the element
 * @param childrenTypeHints List of type hints of the children elements
 * @param parentType Type of the element parent
 * @param parentTypeHint TypeHint of the element parent
 * @returns the icon to use for this element
 */
export function iconForElement(
  elementType: IElementBase["type"],
  elementTypeHint: IElementBase["typeHint"],
  childrenTypeHints?: Array<IElementBase["typeHint"]>,
  parentType?: IElementBase["type"],
  parentTypeHint?: IElementBase["typeHint"],
): ElementIconType {
  if (
    elementType === IElementType.section &&
    elementTypeHint === IElementTypeHint.dataSession
  ) {
    return findIconForSession(childrenTypeHints);
  }
  if (
    parentType === IElementType.section &&
    parentTypeHint === IElementTypeHint.dataSets
  ) {
    // Assume "element" is a data set in this case
    return findIconForSession([elementTypeHint]);
  }
  if (
    parentType === IElementType.timeSeries &&
    parentTypeHint === IElementTypeHint.room
  ) {
    return ElementIconType.ClockIcon;
  }
  if (elementType === IElementType.model3dStream) {
    return ElementIconType.CadModel;
  }

  switch (elementTypeHint) {
    case IElementTypeHint.area:
      return ElementIconType.AreaIcon;
    case IElementTypeHint.room:
      return ElementIconType.PanoramaIcon;
    case IElementTypeHint.dataSession:
      return ElementIconType.FolderIcon;
    case IElementTypeHint.odometryPath:
      return ElementIconType.ClockIcon;
    case IElementTypeHint.videoRecordings:
      return elementType === IElementType.section
        ? ElementIconType.DataSessionVideoRecording
        : ElementIconType.FolderIcon;
    case IElementTypeHint.bimModel:
      return elementType === IElementType.section
        ? ElementIconType.CadModel
        : ElementIconType.FolderIcon;
  }
  switch (elementType) {
    case IElementType.projectRoot:
    case IElementType.group:
      return ElementIconType.FolderIcon;
    case IElementType.section:
      return ElementIconType.LocationOnIcon;
    case IElementType.img360:
    case IElementType.imgCube:
      return ElementIconType.PanoramaPhotosphereSelectIcon;
    case IElementType.imgSheet:
    case IElementType.img2d:
      return ElementIconType.ImageIcon;
    case IElementType.model3d:
      return ElementIconType.CadModel;
    case IElementType.timeSeries:
      return ElementIconType.AccessTimeFilledIcon;
    case IElementType.iElemLink:
    case IElementType.urlLink:
      return ElementIconType.LinkIcon;
    case IElementType.markup:
      return ElementIconType.InfoIcon;
    case IElementType.audioAttachment:
      return ElementIconType.VolumeUpIcon;
    case IElementType.attachment:
      return ElementIconType.AttachmentIcon;
    case IElementType.measureWall:
      return ElementIconType.SquareFootIcon;
    case IElementType.measureLine:
      return ElementIconType.StraightenIcon;
    case IElementType.pCloud:
    case IElementType.pointCloudStream:
    case IElementType.pointCloudStreamWebShare:
      return ElementIconType.CloudIcon;
    default:
      return ElementIconType.SquareFootIcon;
  }
}
function findIconForSession(
  childrenTypeHints: Array<IElementBase["typeHint"]> | undefined,
): ElementIconType {
  if (childrenTypeHints?.includes(IElementTypeHint.dataSetGeoSlam)) {
    return ElementIconType.DataSessionWithTrajectory;
  } else if (childrenTypeHints?.includes(IElementTypeHint.dataSetVideoWalk)) {
    return ElementIconType.DataSessionVideoRecording;
  }

  return ElementIconType.DataSession;
}
