import { RootState } from "@/store/store";
import { WorkspaceIntegrationProviderId } from "@faro-lotv/ielement-types";
import { BcfServicesIntegrationType } from "@faro-lotv/service-wires";

/**
 * @returns The list of enabled workspace integrations
 * @param state current app state
 */
export function selectWorkspaceIntegrations(state: RootState): string[] {
  if (state.integrations.workspaceIntegrations) {
    return state.integrations.workspaceIntegrations.map(
      (integration) => integration.provider,
    );
  }
  return [];
}

/**
 * Represents the names of supported project integration providers.
 */
export enum ProjectIntegrationProviderName {
  autodeskBim360 = "BIM 360",
  autodeskAcc = "ACC",
  procore = "Procore",
}

/**
 * @returns true if the user can write data to the given integration
 * @param integration the integration to check
 */
export function selectIntegrationCanWriteData(
  integration: WorkspaceIntegrationProviderId,
) {
  return (state: RootState): boolean => {
    if (state.integrations.workspaceIntegrations === undefined) {
      return false;
    }

    const index = state.integrations.workspaceIntegrations.findIndex(
      (currentIntegration) => currentIntegration.provider === integration,
    );
    if (index !== -1) {
      return state.integrations.workspaceIntegrations[index].scopes.includes(
        "data:write",
      );
    }

    return false;
  };
}

/**
 * @returns The list of enabled project integrations
 * @param state current app state
 */
export function selectProjectIntegrations(
  state: RootState,
): ProjectIntegrationProviderName[] {
  // There are possible 4 cases in which should consider that an integration is disabled:
  // 1. The project integrations field (projectIntegrations) is undefined
  // 2. The project integrations field is defined but the specific integration field (e.g: procore.rfis) is undefined
  // 3. The project integrations field is defined, the specific integration field (e.g: procore.rfis) is null
  // 4. The project integrations field is defined, the specific integration field is defined but the bcfProjectId is null
  // Below we are checking that we are not in any of these cases before adding the integration to the list of enabled integrations
  const integrations: ProjectIntegrationProviderName[] = [];
  if (state.integrations.projectIntegrations) {
    if (
      !!state.integrations.projectIntegrations["autodesk-bim360.issues"]
        ?.bcfProjectId ||
      !!state.integrations.projectIntegrations["autodesk-bim360.rfis"]
        ?.bcfProjectId
    ) {
      integrations.push(ProjectIntegrationProviderName.autodeskBim360);
    }

    if (
      !!state.integrations.projectIntegrations["autodesk-acc.issues"]
        ?.bcfProjectId ||
      !!state.integrations.projectIntegrations["autodesk-acc.rfis"]
        ?.bcfProjectId
    ) {
      integrations.push(ProjectIntegrationProviderName.autodeskAcc);
    }

    if (
      !!state.integrations.projectIntegrations["procore.observations"]
        ?.bcfProjectId ||
      !!state.integrations.projectIntegrations["procore.rfis"]?.bcfProjectId
    ) {
      integrations.push(ProjectIntegrationProviderName.procore);
    }
  }
  return integrations;
}

/**
 * @returns The projectId for the given integration type or undefined if the integration type is not enabled
 * @param integration the integration type to get the projectId for
 * NOTE: This selector is using only the new structure of the project integrations ("autodesk-bim360.issues", etc.).
 *  So if an integration is enabled only in the old structure, it will return undefined.
 */
export function selectProjectIdForIntegrationType(
  integration: BcfServicesIntegrationType,
) {
  return (state: RootState): string | undefined =>
    state.integrations.projectIntegrations?.[integration]?.bcfProjectId;
}
