import { EventType } from "@/analytics/analytics-events";
import { useDeepLink } from "@/components/common/deep-link/deep-link-context";
import {
  PROJECT_OUTDATED_MESSAGE,
  useProjectStatus,
} from "@/hooks/use-project-status";
import {
  useCurrentAreaIfAvailable,
  useIsCurrentAreaLoading,
} from "@/modes/mode-data-context";
import { changeMode } from "@/store/mode-slice";
import { selectActiveArea } from "@/store/selections-selectors";
import { useAppDispatch, useAppSelector } from "@/store/store-hooks";
import {
  selectAreSubscriptionsAvailable,
  selectCanReadPointCloud,
} from "@/store/subscriptions/subscriptions-selectors";
import { useDashboardAddOnUrl } from "@/utils/redirects";
import { Banner, BannerProps, BannerVariants } from "@faro-lotv/flat-ui";
import { Analytics } from "@faro-lotv/foreign-observers";
import {
  GUID,
  IElementSection,
  isIElementPointCloudStream,
} from "@faro-lotv/ielement-types";
import {
  selectAreaCaptureTreeDataSetIds,
  selectIsFloorWithoutScale,
  selectIsSubtreeLoaded,
  selectLoadedIElements,
  selectProjectId,
} from "@faro-lotv/project-source";
import { ProjectStatus } from "@faro-lotv/service-wires";
import { useCallback, useEffect, useRef, useState } from "react";
import {
  compatibilityMessage,
  useDisableCaptureTreeAlignment,
} from "./tree/cad-model-tree/use-disable-capture-tree-alignment";

/** Context information passed to a banner to check if it should be visible */
type AppContextForBanner = {
  /** Id of the current active area */
  activeArea: IElementSection | undefined;
};

type BannerData = BannerProps & {
  /** Unique id for this banner, to not add the same banner multiple times */
  bannerId: GUID;

  /**
   * A function to check if the banner should be visible in a specific application state
   *
   * If not define the banner will always be considered to be showed
   *
   * @param store the current application state
   * @returns true if this banner should be visible in the current app state
   */
  shouldShow?(context: AppContextForBanner): boolean;
};

type AddBannerFunction = (banner: BannerData) => void;
type RemoveBannerFunction = (bannerId: GUID) => void;

/** @returns an unique AppBanner showing the most important message until it's dismissed */
export function AppBanner(): JSX.Element | null {
  const [banners, setBanners] = useState<BannerData[]>([]);

  /** Callback to add a banner to the stack of banners, keeping them ordered by importance */
  const addBanner = useCallback<AddBannerFunction>((banner) => {
    setBanners((banners) => {
      if (
        banners.find(({ bannerId }) => bannerId === banner.bannerId) !==
        undefined
      ) {
        return banners;
      }
      banners.push(banner);
      banners.sort(sortBanners);
      return banners;
    });
  }, []);

  /** Callback to remove the banner of given id from the stack */
  const removeBanner = useCallback<RemoveBannerFunction>((id) => {
    setBanners((banners) => banners.filter((banner) => banner.bannerId !== id));
  }, []);

  useCheckForOutdatedProject(addBanner);
  useCheckForMissingPCMBundle(addBanner);
  useCheckForUnscaledFloor(addBanner);
  useCheckForEmptyArea(addBanner, removeBanner);
  useCheckForValidDeepLink(addBanner);

  const activeArea = useAppSelector(selectActiveArea);

  const mostImportantBanner = banners.find(
    (banner) => banner.shouldShow?.({ activeArea }) ?? true,
  );

  /** Callback to remove the most important banner from the stack (dismissed by the user) */
  const popBanner = useCallback(() => {
    setBanners((banners) =>
      banners.filter((banner) => banner !== mostImportantBanner),
    );
  }, [mostImportantBanner]);

  if (!mostImportantBanner) return null;

  return <Banner {...mostImportantBanner} onClose={popBanner} />;
}

/**
 * @returns the ordering between two BannerProps to order first the most important one based on the variant
 * following the priority defines in the FARO design system
 */
function sortBanners(
  { variant: a }: BannerProps,
  { variant: b }: BannerProps,
): number {
  const BANNER_PRIORITIES: BannerVariants[] = [
    "error",
    "warning",
    "info",
    "success",
  ];
  return BANNER_PRIORITIES.indexOf(a) - BANNER_PRIORITIES.indexOf(b);
}

/**
 * Append a Banner if needed to inform the user the project is outdated
 *
 * @param addBanner function used to add a new banner
 */
function useCheckForOutdatedProject(addBanner: AddBannerFunction): void {
  const projectStatus = useProjectStatus();

  const projectId = useAppSelector(selectProjectId);
  const [checkedProject, setCheckedProject] = useState<GUID>();

  useEffect(() => {
    if (checkedProject === projectId) return;
    if (projectStatus === ProjectStatus.outdated) {
      setCheckedProject(projectId);
      addBanner({
        bannerId: `outdated-${projectId}`,
        variant: "warning",
        children: PROJECT_OUTDATED_MESSAGE,
      });
    }
  }, [addBanner, checkedProject, projectId, projectStatus]);
}

/**
 * Append a Banner if needed to inform the user the PointCloudManagement module is missing
 *
 * @param addBanner function used to add a new banner
 */
function useCheckForMissingPCMBundle(addBanner: AddBannerFunction): void {
  const areSubscriptionsAvailable = useAppSelector(
    selectAreSubscriptionsAvailable,
  );
  const isMissingPCMBundle = useAppSelector(
    (state) =>
      !selectCanReadPointCloud(state) &&
      selectLoadedIElements(state).some(isIElementPointCloudStream),
  );
  const addonUrl = useDashboardAddOnUrl();
  const projectId = useAppSelector(selectProjectId);

  const checkedProject = useRef<GUID>();

  useEffect(() => {
    if (!areSubscriptionsAvailable || checkedProject.current === projectId) {
      return;
    }
    if (isMissingPCMBundle) {
      checkedProject.current = projectId;
      addBanner({
        bannerId: `missing-pcm-${projectId}`,
        variant: "warning",
        title: "Add-on required",
        children:
          "This project has point clouds. To visualize them, please purchase the point cloud Management add-on.",
        action: {
          label: "Manage Add Ons",
          action: addonUrl,
        },
      });
    }
  }, [
    addBanner,
    addonUrl,
    areSubscriptionsAvailable,
    isMissingPCMBundle,
    projectId,
  ]);
}

/**
 * Append a Banner if needed to inform the user the current floor has no scale information
 *
 * @param addBanner function used to add a new banner
 */
function useCheckForUnscaledFloor(addBanner: AddBannerFunction): void {
  const activeArea = useAppSelector(selectActiveArea);
  const isFloorWithoutScale = useAppSelector(
    selectIsFloorWithoutScale(activeArea),
  );
  const dispatch = useAppDispatch();
  const [checkedFloors, setCheckedFloors] = useState<GUID[]>([]);

  const disableScaling = useDisableCaptureTreeAlignment(activeArea);

  // Add a banner if the area section was not already checked, has no scale and the scale tool is available
  useEffect(() => {
    if (!activeArea || checkedFloors.includes(activeArea.id)) {
      return;
    }

    if (isFloorWithoutScale) {
      setCheckedFloors((floors) => [...floors, activeArea.id]);
      addBanner({
        bannerId: `unscaled-${activeArea.id}`,
        variant: "warning",
        title: "Sheet scale not defined.",
        children: `Data alignment and visual representation may not be accurate. ${disableScaling ? compatibilityMessage : "Use the set scale tool in the three-dots menu."}`,
        action: disableScaling
          ? undefined
          : {
              label: "Set scale",
              action() {
                Analytics.track(EventType.openAreaScaleTool);
                dispatch(changeMode("floorscale"));
              },
            },
        shouldShow(context) {
          return context.activeArea?.id === activeArea.id;
        },
      });
    }
  }, [
    addBanner,
    checkedFloors,
    dispatch,
    activeArea,
    isFloorWithoutScale,
    disableScaling,
  ]);
}

/**
 * Append a Banner if needed to inform the user the current floor is empty and user can start adding data
 *
 * @param addBanner function used to add a new banner
 * @param removeBanner function used to remove a banner
 */
function useCheckForEmptyArea(
  addBanner: AddBannerFunction,
  removeBanner: RemoveBannerFunction,
): void {
  const activeArea = useCurrentAreaIfAvailable();
  const isAreaLoaded = useAppSelector(
    selectIsSubtreeLoaded(activeArea?.area?.id),
  );

  // This value also depends on the state of the loading of the datasets in the capture tree.
  // Since that loading is NOT blocking the application until it's finished, it might happen that
  // the banner code checking the datasets, is executed before the datasets are loaded.
  const isAreaDataLoading = useIsCurrentAreaLoading();

  const areaHasCaptureTreeDataSets = !!useAppSelector((state) =>
    selectAreaCaptureTreeDataSetIds(state, activeArea?.area?.id),
  ).length;

  const isAreaEmpty =
    !areaHasCaptureTreeDataSets &&
    activeArea?.dataSessions.length === 0 &&
    activeArea.roomsSections.length === 0;

  const [checkedAreas, setCheckedAreas] = useState<GUID[]>([]);

  // Add a banner if the area is empty
  useEffect(() => {
    const area = activeArea?.area;

    if (
      !area ||
      !isAreaLoaded ||
      isAreaDataLoading ||
      checkedAreas.includes(area.id)
    ) {
      return;
    }

    if (isAreaEmpty) {
      setCheckedAreas((areas) => [...areas, area.id]);
      addBanner({
        bannerId: `empty-area-${area.id}`,
        variant: "info",
        title: "Empty sheet",
        children:
          "Start adding data to your sheet by clicking on the Import Button or by using our JobWalk app or Stream one!",
      });
    } else if (checkedAreas.length > 0) {
      // Remove the banners for all the areas which are empty as the user has switched to an area with data
      checkedAreas.map((areaId) => removeBanner(`empty-area-${areaId}`));

      // Reset the checked areas which are empty in order to allow the banner to appear again if the user moved to an empty area later
      setCheckedAreas([]);
    }
  }, [
    addBanner,
    activeArea,
    isAreaLoaded,
    isAreaEmpty,
    checkedAreas,
    removeBanner,
    isAreaDataLoading,
  ]);
}

/**
 * Append a Banner if needed to inform the user the deep link used to open the app is not valid
 *
 * @param addBanner function used to add a new banner
 */
function useCheckForValidDeepLink(addBanner: AddBannerFunction): void {
  const isInvalidDeepLink = useDeepLink() instanceof Error;

  // Add a banner if the link is invalid
  useEffect(() => {
    if (isInvalidDeepLink) {
      addBanner({
        bannerId: "invalid-deep-link",
        variant: "warning",
        title: "Invalid Link",
        children:
          "The view you are trying to access does not exist or has been moved to another location",
      });
    }
  }, [addBanner, isInvalidDeepLink]);
}
