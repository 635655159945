import { ThreeEvent } from "@react-three/fiber";
import { useCallback, useRef } from "react";
import { Vector2 } from "three";

/** Number of pixel the mouse need to move between click and release to consider the interaction a drag */
const MOUSE_DRAG_PIXEL_THRESHOLD = 5;

type UseOnClickReturn = {
  /** Event to memorize the initial pointer down position */
  onPointerDown(ev: ThreeEvent<PointerEvent>): void;

  /** Click event to get the final pointer position and detect if it was a click or a drag */
  onClick(e: ThreeEvent<MouseEvent>): void;
};

/**
 * @returns a set of pointer down and click event handlers to use in a 3D scene to differentiate click from drag
 * @param onClickCallback the callback to call when a click is detected
 * @param shouldUseManhattanDistance if true, the distance between pointer down and up will be calculated using the Manhattan distance
 */
export function useOnClick(
  onClickCallback?: (e: ThreeEvent<MouseEvent>) => void,
  shouldUseManhattanDistance = false,
): UseOnClickReturn {
  const startPos = useRef<Vector2>();

  const onPointerDown = useCallback((ev: ThreeEvent<PointerEvent>) => {
    startPos.current = new Vector2(ev.clientX, ev.clientY);
  }, []);

  const onClick = useCallback(
    (e: ThreeEvent<MouseEvent>) => {
      if (startPos.current) {
        const distanceFromPointerDown = shouldUseManhattanDistance
          ? Math.abs(startPos.current.x - e.clientX) +
            Math.abs(startPos.current.y - e.clientY)
          : startPos.current.distanceTo(new Vector2(e.clientX, e.clientY));

        if (distanceFromPointerDown < MOUSE_DRAG_PIXEL_THRESHOLD) {
          onClickCallback?.(e);
        }
      }
    },
    [onClickCallback, shouldUseManhattanDistance],
  );

  return {
    onPointerDown,
    onClick,
  };
}
