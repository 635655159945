import { RootState } from "@/store/store";
import { useAppSelector } from "@/store/store-hooks";
import {
  IElement,
  IElementAreaSection,
  IElementTypeHint,
  isIElementAreaSection,
} from "@faro-lotv/ielement-types";
import {
  selectAncestor,
  selectAreaCaptureTreeDataSetIds,
  selectIElement,
} from "@faro-lotv/project-source";

/**
 * @returns true if the given element in the project is related to capture tree and can not be aligned in viewer
 * Alignment is disabled for two cases:
 *   1. the element is from capture tree
 *   2. element itself is area section, its associated capture tree data set contains any element from capture tree
 * @param element the element to check
 *
 * HOTFIX for https://faro01.atlassian.net/browse/SWEB-5087
 */
export function selectDisableCaptureTreeAlignment(
  element: IElement | undefined,
) {
  return (state: RootState): boolean => {
    if (!element) {
      return true;
    }

    if (
      isIElementAreaSection(element) &&
      selectAreaCaptureTreeRelated(element)(state)
    ) {
      return true;
    }
    return false;
  };
}

/**
 * @returns the hook version of selectDisableCaptureTreeAlignment
 * @param element the IElement to check if Alignment should be disabled
 *
 * HOTFIX for https://faro01.atlassian.net/browse/SWEB-5087
 */
export function useDisableCaptureTreeAlignment(
  element: IElement | undefined,
): boolean {
  return useAppSelector(selectDisableCaptureTreeAlignment(element));
}

export const compatibilityMessage =
  "Alignment/scaling is not yet available for SCENE data.";

/**
 * @param element the given IElement to check
 * @returns true if the given element is from the capture tree
 */
export function selectFromCaptureTree(element: IElement | undefined) {
  return (state: RootState): boolean =>
    !!selectAncestor(
      element,
      (el) => el.typeHint === IElementTypeHint.captureTree,
    )(state);
}

function selectAreaCaptureTreeRelated(area: IElementAreaSection) {
  return (state: RootState): boolean => {
    const ids = selectAreaCaptureTreeDataSetIds(state, area.id);
    return ids.some((value) => {
      const oneElement = selectIElement(value)(state);
      if (oneElement && selectFromCaptureTree(oneElement)(state)) {
        return true;
      }
      return false;
    });
  };
}
