/**
 * The layout type of the split screen in 2D alignment step
 */
export enum AlignmentViewLayout {
  splitScreen = "splitScreen",
  overlay = "overlay",
}

export enum SplitDirection {
  /** split screen into left screen and right screen */
  horizontalSplit = "horizontalSplit",

  /** split screen into top screen and bottom screen */
  verticalSplit = "verticalSplit",
}
