import { EventType } from "@/analytics/analytics-events";
import { ToSheetAnimation } from "@/components/r3f/animations/to-sheet-animation";
import { ModeTransitionProps } from "@/modes/mode";
import { useCurrentScene } from "@/modes/mode-data-context";
import { useAppSelector } from "@/store/store-hooks";
import {
  selectChildDepthFirst,
  selectChildrenDepthFirst,
  selectIElementWorldTransform,
} from "@faro-lotv/app-component-toolbox";
import { Analytics } from "@faro-lotv/foreign-observers";
import { assert } from "@faro-lotv/foundation";
import {
  isIElementGenericVideoRecording,
  isIElementImg360,
} from "@faro-lotv/ielement-types";
import { isEqual } from "es-toolkit";
import { useEffect, useMemo } from "react";
import { Vector3 } from "three";
import {
  PathAlignmentTool,
  usePathAlignmentContext,
  usePathAlignmentUndoRedo,
} from "./path-alignment-mode-context";

/**
 * @returns the transition to enter PathAlignmentMode
 * @param props - ModeTransitionProps required for transition between modes
 */
export function PathAlignmentModeTransition(
  props: ModeTransitionProps,
): JSX.Element {
  const { referenceElement, sheet } = useCurrentScene();
  const videoRecording = useAppSelector(
    selectChildDepthFirst(referenceElement, isIElementGenericVideoRecording, 1),
  );
  assert(
    sheet,
    "Path Alignment Mode requires a valid sheet image to align the video recording to",
  );
  assert(
    videoRecording,
    "Path Alignment Mode requires a data session with a video recording as the reference element",
  );

  const { clear } = usePathAlignmentUndoRedo();

  const panos = useAppSelector(
    selectChildrenDepthFirst(videoRecording, isIElementImg360),
    isEqual,
  );

  // Initial editing pose is the world pose of the entire path
  const initialPose = useAppSelector(
    selectIElementWorldTransform(videoRecording.id),
  ).worldMatrix;

  // Initialize the single points position with their local position inside the path
  const initialPositions = useMemo(
    () =>
      panos.map(
        (p) =>
          new Vector3(p.pose?.pos?.x, p.pose?.pos?.y, -(p.pose?.pos?.z ?? 0)),
      ),
    [panos],
  );

  const { activeTool, initialize } = usePathAlignmentContext();

  /** Initialize the store with the panos positions written in the project and a default state*/
  useEffect(() => {
    initialize(initialPositions, initialPose);
    // Reset undo state so we can't undo before the initialPositions
    clear();
  }, [initialPositions, clear, initialize, initialPose]);

  useEffect(() => {
    // Track initial tool that is active by default
    Analytics.track(
      activeTool === PathAlignmentTool.alignPath
        ? EventType.selectVideomodeEditorAlignmentTool
        : EventType.selectVideoModeEditorTrajectoryTool,
    );
  }, [activeTool]);

  return (
    <ToSheetAnimation sheetElement={sheet} placeholders={panos} {...props} />
  );
}
