import { GUID, isIElementGenericImgSheet } from "@faro-lotv/ielement-types";
import { selectIElement } from "@faro-lotv/project-source";
import { createSelector } from "@reduxjs/toolkit";
import { Measurement } from "./measurement-tool-slice";
import { RootState } from "./store";

/**
 *  @returns the list of all 3D measurements in current projects
 *  @param state The Root State
 */
export const selectAll3DMeasurements = createSelector(
  [
    (state: RootState) => state,
    (state: RootState) => state.measurementTool.measurements,
  ],
  (state, measurements: RootState["measurementTool"]["measurements"]) => {
    const measurements3D: Measurement[] = [];
    for (const key of Object.keys(measurements)) {
      const is2dImage = isIElementGenericImgSheet(selectIElement(key)(state));
      if (!is2dImage) {
        const dataArray = state.measurementTool.measurements[key];
        measurements3D.push(...dataArray);
      }
    }

    return measurements3D;
  },
);

/**
 *  @returns the list of measurements associated to an element
 *  @param id The id of the element which measurements we want to get
 */
export function selectMeasurements(id: GUID | GUID[] | undefined) {
  return (state: RootState): Measurement[] => {
    if (!id) return [];

    if (Array.isArray(id)) {
      return id.reduce((measurements: Measurement[], iElementId) => {
        if (iElementId in state.measurementTool.measurements) {
          measurements.push(...state.measurementTool.measurements[iElementId]);
        }
        return measurements;
      }, []);
    }

    return id in state.measurementTool.measurements
      ? state.measurementTool.measurements[id]
      : [];
  };
}

/**
 * @returns the id of the active measurement or undefined if none
 * @param root The Root State
 */
export function selectActiveMeasurement(
  root: RootState,
): Measurement | undefined {
  return root.measurementTool.activeMeasurement;
}

/**
 * @returns true if a measurement is currently being taken
 * @param root The Root State
 */
export function selectIsMeasurementBeingTaken(root: RootState): boolean {
  return root.measurementTool.isMeasurementBeingTaken;
}

/**
 *  @returns true if the measurement is a loop
 *  @param id The id of the element which contains the measurement in consideration
 *  @param measurementId The ID of the measurement
 */
export function selectIsMeasurementLoop(id: GUID, measurementId: GUID) {
  return (state: RootState) => {
    const measurements = state.measurementTool.measurements[id];
    return measurements.find((m) => m.id === measurementId)?.metadata.isLoop;
  };
}

/**
 *  @returns the components to be displayed for this measurement
 *  @param id The id of the element which contains the measurement in consideration
 *  @param measurementId The ID of the measurement
 */
export function selectMeasurementComponentsToDisplay(
  id: GUID,
  measurementId: GUID,
) {
  return (state: RootState) => {
    const measurements = state.measurementTool.measurements[id];
    return measurements.find((m) => m.id === measurementId)
      ?.componentsToDisplay;
  };
}

/**
 * @param state current app state
 * @returns the number of measurements taken in this browser session
 */
export function selectSessionMeasurements(state: RootState): number {
  return state.measurementTool.sessionMeasurements;
}
