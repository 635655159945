import { BackgroundTask } from "@/utils/background-tasks";
import { FaroText } from "@faro-lotv/flat-ui";
import { LinearProgress, Stack } from "@mui/material";
import { ReactNode } from "react";

type CardProgressProps = {
  /** Label for the progress notification */
  label: ReactNode;

  /** Task to show the progress of */
  task?: BackgroundTask | null;
};

/** @returns a label or a linear progress to notify a BackgroundTask state in the upload menu card */
export function CardProgress({
  task,
  label,
}: CardProgressProps): JSX.Element | null {
  if (!task) {
    return null;
  }

  return (
    <Stack>
      <LinearProgress
        value={task.progress}
        variant={task.progress > 0 ? "determinate" : "indeterminate"}
      />
      <FaroText variant="helpText" sx={{ fontSize: "0.625rem" }}>
        {label}
      </FaroText>
    </Stack>
  );
}
