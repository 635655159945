import { EventType } from "@/analytics/analytics-events";
import { SheetModeControls } from "@/components/r3f/controls/sheet-mode-controls";
import { DesaturationPipeline } from "@/components/r3f/renderers/desaturation-pipeline";
import { SheetRenderer } from "@/components/r3f/renderers/sheet-renderer";
import { useCurrentArea } from "@/modes/mode-data-context";
import { useCached3DObject } from "@/object-cache";
import { useAppSelector } from "@/store/store-hooks";
import { selectActiveTool } from "@/store/ui/ui-selectors";
import { ToolName } from "@/store/ui/ui-slice";
import {
  MeasurementUnits,
  selectChildDepthFirst,
  selectIElementWorldPosition,
  selectRootIElement,
  useReproportionCamera,
} from "@faro-lotv/app-component-toolbox";
import { useToast } from "@faro-lotv/flat-ui";
import { Analytics } from "@faro-lotv/foreign-observers";
import { assert } from "@faro-lotv/foundation";
import { isIElementGenericImgSheet } from "@faro-lotv/ielement-types";
import { useThree } from "@react-three/fiber";
import { useCallback, useEffect, useMemo, useState } from "react";
import { EditMeasure } from "./edit-measure";
import { useFloorScaleContext } from "./floor-scale-mode-context";
import { LineToolbar } from "./line-toolbar";
import { ReferenceLine } from "./reference-line";
import { useInteractionLogic } from "./use-interaction-logic";

/** @returns The 3D scene of the mode */
export function FloorScaleModeScene(): JSX.Element {
  const { area } = useCurrentArea();
  const activeSheet = useAppSelector(
    selectChildDepthFirst(area, isIElementGenericImgSheet),
  );
  assert(
    activeSheet,
    "A valid floor image is required for the floor scale tool",
  );

  const position = useAppSelector(selectIElementWorldPosition(activeSheet.id));
  const sheet = useCached3DObject(activeSheet);
  const camera = useThree((s) => s.camera);
  useReproportionCamera(camera);
  const activeTool = useAppSelector(selectActiveTool);

  // Real value of the line as defined by the user (in meters)
  const [distanceInMeters, setDistanceInMeters] = useState<number>();

  const root = useAppSelector(selectRootIElement);
  const [unitOfMeasure, setUnitOfMeasure] = useState<MeasurementUnits>(() => {
    if (root?.metaDataMap?.projectSettings?.unitSystem === "us") {
      return MeasurementUnits.feet;
    }
    return MeasurementUnits.meters;
  });

  const {
    setSheet,
    setArea,
    setMeasuredDistance,
    setUserDefinedDistance,
    setShouldShowHelpBanner,
  } = useFloorScaleContext();

  const onMeasurementCreated = useCallback(
    (distance: number) => {
      setMeasuredDistance(distance);
      setDistanceInMeters(distance);
      setIsEditMeasureOpen(true);
      setShouldShowHelpBanner(false);
    },
    [setMeasuredDistance, setShouldShowHelpBanner],
  );

  const resetDistance = useCallback(() => setDistanceInMeters(undefined), []);

  const {
    startPoint,
    endPoint,
    onPointerDown,
    onPointerUp,
    onPointerMove,
    isVertical,
    onMeasureRemoved,
    onUndoMeasureRemoval,
    controlsEnabled,
    isDraggingMeasureLine,
  } = useInteractionLogic({ onMeasurementCreated, resetDistance });

  // Everytime the distance changes, update the context
  useEffect(() => {
    if (distanceInMeters !== undefined) {
      setUserDefinedDistance(distanceInMeters);
    }
  }, [distanceInMeters, setUserDefinedDistance]);

  // Everytime the sheet changes, update the context
  useEffect(() => {
    setSheet(activeSheet);
  }, [activeSheet, setSheet]);

  // Everytime the area changes, update the context
  useEffect(() => {
    assert(area, "Expected an area to be defined");
    setArea(area);
  }, [area, setArea]);

  // Reset the boolean to check if the help banner should be shown,
  // So that the message is displayed every time the user enters the mode
  useEffect(
    () => () => {
      setShouldShowHelpBanner(true);
    },
    [setShouldShowHelpBanner],
  );

  const { openToast } = useToast();
  const removeMeasure = useCallback(() => {
    Analytics.track(EventType.removeAreaScaleLine);
    openToast({
      title: "Line to set the scale deleted",
      action: {
        label: "Undo",
        onClicked: onUndoMeasureRemoval,
      },
    });

    onMeasureRemoved();
  }, [openToast, onMeasureRemoved, onUndoMeasureRemoval]);

  const [isEditMeasureOpen, setIsEditMeasureOpen] = useState(false);
  const onMeasureAccepted = useCallback(
    (measure: number, unit: MeasurementUnits) => {
      setDistanceInMeters(measure);
      setUnitOfMeasure(unit);
      setIsEditMeasureOpen(false);
    },
    [],
  );

  // Callback executed when the user press the button to edit the measurement
  const editMeasure = useCallback(() => {
    Analytics.track(EventType.editAreaScaleValue);
    setIsEditMeasureOpen(true);
  }, []);

  // Anchor point where to place the embedded toolbar
  const anchorPoint = useMemo(() => {
    if (!startPoint || !endPoint) return;
    if (isVertical) {
      return startPoint.z < endPoint.z ? startPoint : endPoint;
    }
    const anchor = startPoint.x > endPoint.x ? startPoint : endPoint;
    return anchor.clone().setY(1);
  }, [endPoint, isVertical, startPoint]);

  return (
    <>
      <ReferenceLine start={startPoint} end={endPoint} />
      <SheetRenderer
        sheet={sheet}
        onPointerDown={onPointerDown}
        onPointerUp={onPointerUp}
        onPointerMove={onPointerMove}
      />
      <SheetModeControls
        camera={camera}
        referencePlaneHeight={position[1]}
        enabled={controlsEnabled}
      />
      {anchorPoint &&
        distanceInMeters &&
        !isEditMeasureOpen &&
        !isDraggingMeasureLine && (
          <LineToolbar
            anchor={anchorPoint}
            distanceInMeters={distanceInMeters}
            unit={unitOfMeasure}
            onRemoveMeasure={removeMeasure}
            onEditMeasure={editMeasure}
          />
        )}
      {isEditMeasureOpen && distanceInMeters && anchorPoint && (
        <EditMeasure
          anchor={anchorPoint}
          distanceInMeters={distanceInMeters}
          unit={unitOfMeasure}
          onMeasureAccepted={onMeasureAccepted}
        />
      )}
      <DesaturationPipeline
        id={activeSheet.id}
        enabled={activeTool === ToolName.desaturateSheet}
      />
    </>
  );
}
