import { useThree } from "@react-three/fiber";
import { useCallback, useEffect, useState } from "react";
import { CubeCamera, CubeTexture, Vector3, WebGLCubeRenderTarget } from "three";

export type EnvironmentMapOptions = {
  /** The size of the textures in the cubemap, default to 1k */
  size?: number;
};

type UpdateCallback = (position: Vector3) => void;

type EnvironmentMapReturn = {
  /** The generated environment map */
  envMap: CubeTexture;

  /** Function to update the environment map */
  updateEnvMap(position: Vector3): void;
};

/**
 * Hook to use an environment map, Eg a cube map generated by a scene
 *
 * @returns the environment cube texture and an update function to update the environment
 */
export function useEnvironmentMap({
  size = 1024,
}: EnvironmentMapOptions = {}): EnvironmentMapReturn {
  const camera = useThree((s) => s.camera);
  const gl = useThree((s) => s.gl);
  const scene = useThree((s) => s.scene);

  const [target] = useState(() => new WebGLCubeRenderTarget(size));

  const [cubeCamera] = useState(() => {
    const cubeCamera = new CubeCamera(camera.near, camera.far, target);
    cubeCamera.position.copy(camera.position);
    cubeCamera.update(gl, scene);
    return cubeCamera;
  });

  const updateEnvMap = useCallback<UpdateCallback>(
    (position) => {
      cubeCamera.position.copy(position);
      cubeCamera.update(gl, scene);
    },
    [cubeCamera, gl, scene],
  );

  useEffect(
    () => () => {
      target.texture.dispose();
      target.dispose();
    },
    [target],
  );

  return { envMap: target.texture, updateEnvMap };
}
