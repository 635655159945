import { PointCloudObject } from "@/object-cache";
import { useOnResize } from "@faro-lotv/app-component-toolbox";
import { DynamicLodCloudRendering } from "@faro-lotv/lotv";
import { usePerformanceMonitor } from "@react-three/drei";
import { useCallback, useEffect, useState } from "react";

/**
 * This hook can be called from the component defining a scene that includes a
 * LOD point cloud. It provides an object that adapts LOD point cloud rendering
 * to the measured performance, delivering high-quality rendering on performant
 * GPUs and high framerate with lower quality on low-end GPUs.
 *
 * @param pointcloud the LOD cloud to be rendered
 * @returns An object handling logic to adapt rendering to performance
 */
export function useLodCloudDynamicRenderer(
  pointcloud: PointCloudObject | null,
): DynamicLodCloudRendering {
  // dynamicRenderer is an object with logic on adaptive dynamic rendering.
  // It is passed as a property to the SubScene that is rendering the point
  // cloud with gap filling.
  const [dynamicRenderer] = useState<DynamicLodCloudRendering>(
    () => new DynamicLodCloudRendering(),
  );

  // Disposing correctly the dynamic render, to unregister it from the
  // point cloud signals
  useEffect(() => () => dynamicRenderer.dispose(), [dynamicRenderer]);

  useOnResize(() => dynamicRenderer.invalidate());

  // Whenever the 'pointcloud' prop changes, we reassign it to the 'dynamicRenderer' object.
  // In this way the dynamic renderer can listen to the lod cloud events and perform subsequent
  // logic. For example the dynamic renderer may invalidate the scene if new lod nodes were received,
  // or may set a given subsampling parameter to the point cloud if the camera is moving and we are on
  // low-performance settings.
  useEffect(() => {
    dynamicRenderer.lodCloud = pointcloud ?? undefined;
    return () => {
      // Assigning undefined below to disconnect from the fetcher's signals
      // and to revert any changed parameters to the cloud.
      dynamicRenderer.lodCloud = undefined;
    };
  }, [dynamicRenderer, pointcloud]);

  // Initially, we render the point cloud with high-quality settings, so fastSettings = false
  const [fastSettings, setFastSettings] = useState<boolean>(() => false);

  // we attach a callback to Drei's performance monitor: when the framerate is
  // insufficient, we switch to 'fast' rendering.
  const onDecline = useCallback(() => {
    if (!fastSettings) {
      setFastSettings(true);
      dynamicRenderer.dynamic = true;
    }
  }, [fastSettings, dynamicRenderer]);

  // If the framerate is good even on moving camera, we may switch back to high-quality rendering
  const onIncline = useCallback(() => {
    if (fastSettings && dynamicRenderer.cameraMoving) {
      setFastSettings(false);
      dynamicRenderer.dynamic = false;
    }
  }, [fastSettings, dynamicRenderer]);

  usePerformanceMonitor({ onDecline, onIncline });

  return dynamicRenderer;
}
