import { useElementFileUploadContext } from "@/components/common/point-cloud-file-upload-context/element-file-upload-context";
import {
  MAX_UPLOAD_CLOUD_FILE_SIZE_GB,
  useIsAppReadyForImport,
} from "@/components/common/point-cloud-file-upload-context/point-cloud-upload-utils";
import { ImportDataMenu } from "./import-data-menu";

/**
 * @returns Management of data imports. Shows a button that offers a menu to import different kinds of data.
 */
export function ImportData(): JSX.Element | null {
  const isAppReadyForImport = useIsAppReadyForImport();

  const { setPointCloudFile, setCadFile } = useElementFileUploadContext();

  if (!isAppReadyForImport) {
    return null;
  }

  return (
    <ImportDataMenu
      onPointCloudToUploadSelected={setPointCloudFile}
      onCADToUploadSelected={setCadFile}
      maxCloudFileSizeGB={MAX_UPLOAD_CLOUD_FILE_SIZE_GB}
      dark
    />
  );
}
