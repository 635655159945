import { ElementIconType } from "@/components/ui/icons";
import { isIElementGenericDataset } from "@faro-lotv/ielement-types";
import { selectAncestor } from "@faro-lotv/project-source";
import {
  compatibilityMessage,
  selectDisableCaptureTreeAlignment,
} from "../../cad-model-tree/use-disable-capture-tree-alignment";
import { ContextMenuAction, ContextMenuActionType } from "../action-types";
import { redirectToFloorScalingTool } from "../utils";

export const SCALE_FLOOR_ACTION: ContextMenuAction = {
  type: ContextMenuActionType.scaleFloor,
  label: "Set area scale",
  disabledMessageForNode(iElement, state) {
    if (selectDisableCaptureTreeAlignment(iElement)(state)) {
      return compatibilityMessage;
    }
    if (selectAncestor(iElement, isIElementGenericDataset)(state)) {
      return "Scale setting not available for datasets overview maps.";
    }
  },
  icon: ElementIconType.ResetScaleIcon,
  handler: redirectToFloorScalingTool,
};
