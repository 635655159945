import { useActiveCameras } from "@/components/common/view-runtime-context";
import { useCached3DObject } from "@/object-cache";
import {
  selectCloudAndCadAlignReference,
  selectCloudToCadAlignmentCloudElevation,
  selectCloudToCadAlignmentModelElevation,
  selectModelClippingBoxEnabled,
} from "@/store/modes/cloud-to-cad-alignment-mode-selectors";
import {
  AlignmentReference,
  setCloudElevationForCloudAlignment,
  setModelElevationForCloudAlignment,
} from "@/store/modes/cloud-to-cad-alignment-mode-slice";
import { useAppDispatch, useAppSelector } from "@/store/store-hooks";
import { View } from "@faro-lotv/app-component-toolbox";
import {
  IElementGenericPointCloudStream,
  IElementModel3dStream,
} from "@faro-lotv/ielement-types";
import { useThree } from "@react-three/fiber";
import { useCallback, useEffect, useMemo } from "react";
import { Box3 } from "three";
import { useNewPerspectiveCamera } from "../alignment-modes-commons/align-to-cad-utils";
import { CloudElevationScene } from "../alignment-modes-commons/cloud-elevation-scene";
import { ModelElevationScene } from "../alignment-modes-commons/model-elevation-scene";
import { useOverlayElements } from "../overlay-elements-context";

type CloudToCadElevationSceneProps = {
  /** The active cad model for alignment */
  activeCad: IElementModel3dStream;

  /** The bounding box of the cad model in world */
  cadBox: Box3;

  /** The point cloud to align with cad model */
  selectedCloud: IElementGenericPointCloudStream;
};

/** @returns the scene for setting elevations for the cloud to cad alignment*/
export function CloudToCadElevationScene({
  activeCad,
  cadBox,
  selectedCloud,
}: CloudToCadElevationSceneProps): JSX.Element | null {
  const { firstScreen, secondScreen } = useOverlayElements();

  const background = useThree((s) => s.scene.background);

  const cloudCamera = useNewPerspectiveCamera();
  const modelCamera = useNewPerspectiveCamera();

  const cameras = useMemo(
    () => [cloudCamera, modelCamera],
    [cloudCamera, modelCamera],
  );

  useActiveCameras(cameras);

  const cadModelObject = useCached3DObject(activeCad);
  const pointCloud = useCached3DObject(selectedCloud);

  const dispatch = useAppDispatch();

  const isModelClippingBoxEnabled = useAppSelector(
    selectModelClippingBoxEnabled,
  );

  const modelElevation = useAppSelector(
    selectCloudToCadAlignmentModelElevation,
  );

  const cloudElevation = useAppSelector(
    selectCloudToCadAlignmentCloudElevation,
  );

  const updateModelElevation = useCallback(
    (elevation: number) => {
      dispatch(setModelElevationForCloudAlignment(elevation));
    },
    [dispatch],
  );

  const updateCloudElevation = useCallback(
    (elevation: number) => {
      dispatch(setCloudElevationForCloudAlignment(elevation));
    },
    [dispatch],
  );

  const reference = useAppSelector(selectCloudAndCadAlignReference);

  // make sure default cursor is ued to start
  useEffect(() => {
    if (firstScreen && secondScreen) {
      const oldCursor1 = firstScreen.style.cursor;
      const oldCursor2 = secondScreen.style.cursor;
      firstScreen.style.cursor = "default";
      secondScreen.style.cursor = "default";
      return () => {
        firstScreen.style.cursor = oldCursor1;
        secondScreen.style.cursor = oldCursor2;
      };
    }
  }, [firstScreen, secondScreen]);

  return (
    firstScreen &&
    secondScreen && (
      <>
        <View
          camera={cloudCamera}
          trackingElement={
            reference === AlignmentReference.bimModel
              ? firstScreen
              : secondScreen
          }
          background={background}
          hasSeparateScene
        >
          <CloudElevationScene
            pointCloud={pointCloud}
            cloudElevation={cloudElevation}
            updateCloudElevation={updateCloudElevation}
            camera={cloudCamera}
          />
        </View>
        <View
          camera={modelCamera}
          trackingElement={
            reference === AlignmentReference.bimModel
              ? secondScreen
              : firstScreen
          }
          background={background}
          hasSeparateScene
        >
          <ModelElevationScene
            cadModelObject={cadModelObject}
            cadBox={cadBox}
            elevation={modelElevation}
            updateElevation={updateModelElevation}
            isClippingBoxEnabled={isModelClippingBoxEnabled}
            camera={modelCamera}
          />
        </View>
      </>
    )
  );
}
