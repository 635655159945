import { SplitDirection } from "@/store/modes/alignment-ui-types";
import {
  selectCrossSectionEnabled,
  selectSheetToCloudAlignmentSheetElevation,
  selectSheetToCloudAlignmentSplitDirection,
} from "@/store/modes/sheet-to-cloud-alignment-mode-selectors";
import {
  setCrossSectionEnabled,
  setSheetToCloudAlignmentSplitDirection,
} from "@/store/modes/sheet-to-cloud-alignment-mode-slice";
import { store } from "@/store/store";
import { useAppDispatch, useAppSelector } from "@/store/store-hooks";
import { assert } from "@faro-lotv/foundation";
import {
  isIElementSection,
  isIElementSectionDataSession,
} from "@faro-lotv/ielement-types";
import { selectAncestor, selectIElement } from "@faro-lotv/project-source";
import { useCallback, useMemo } from "react";
import {
  AlignmentModeSplitScreenOverlay,
  CrossSectionProps,
} from "../alignment-modes-commons/alignment-mode-split-screen-overlay";
import { useSheetSelectedForAlignment } from "../mode-data-context";
import { useCloudForSheetToCloudAlignment } from "./sheet-to-cloud-alignment-mode";

/** @returns The sheet to cloud alignment split screen overlay*/
export function SheetToCloudAlignmentSplitScreen(): JSX.Element {
  const dispatch = useAppDispatch();
  const splitDirection = useAppSelector(
    selectSheetToCloudAlignmentSplitDirection,
  );

  // callback function for changing split-screen direction
  const changeSplitDirection = useCallback(() => {
    const newDirection =
      splitDirection === SplitDirection.horizontalSplit
        ? SplitDirection.verticalSplit
        : SplitDirection.horizontalSplit;
    dispatch(setSheetToCloudAlignmentSplitDirection(newDirection));
  }, [dispatch, splitDirection]);

  const isCrossSectionEnabled = useAppSelector(selectCrossSectionEnabled);
  const sheetElevation = useAppSelector(
    selectSheetToCloudAlignmentSheetElevation,
  );

  const cloudCrossSectionProps: CrossSectionProps = useMemo(
    () => ({
      enabled: isCrossSectionEnabled,
      elevation: sheetElevation,
      onToggleCrossSection: () => {
        dispatch(setCrossSectionEnabled(!isCrossSectionEnabled));
      },
    }),
    [dispatch, isCrossSectionEnabled, sheetElevation],
  );

  const cloudObject = useCloudForSheetToCloudAlignment();
  const sheet = useSheetSelectedForAlignment("sheetToCloud");

  // Get point cloud dataSession section to use for screen label
  // Currently, only old tree structure is supported.
  // This should be updated when tackling https://faro01.atlassian.net/browse/CADBIM-793 and https://faro01.atlassian.net/browse/CADBIM-715
  const pointCloudSection = selectAncestor(
    selectIElement(cloudObject.iElement.id)(store.getState()),
    isIElementSectionDataSession,
  )(store.getState());

  assert(
    pointCloudSection && isIElementSection(pointCloudSection),
    "point cloud for alignment not defined",
  );

  return (
    <AlignmentModeSplitScreenOverlay
      splitDirection={splitDirection}
      onChangeSplitDirection={changeSplitDirection}
      secondScreenCrossSection={cloudCrossSectionProps}
      alignedElement={sheet}
      referenceElement={pointCloudSection}
    />
  );
}
